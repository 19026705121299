<mat-form-field *ngIf="!Recursive" class="w-100">
    <mat-select #MyMultiSelectTree
        [ngClass]="{'my-mat-select-tree-default': MatSelectValue === 'default', 'my-mat-select-tree' : MatSelectValue !== 'default'}" 
        [(ngModel)]="MatSelectValue"
        (closed)="CloseDropDownEvent()">
      <mat-option  style="display: none;" [value]="MatSelectValue">{{MatOptionTxt}}</mat-option>
      <ng-container *ngTemplateOutlet="treeTemplate"></ng-container>
    </mat-select>
</mat-form-field>

<ng-container *ngIf="Recursive">
    <ng-container *ngTemplateOutlet="treeTemplate"></ng-container>
</ng-container>

<ng-template #treeTemplate>
    <ng-container *ngFor="let dto of DataSource;">
        <table id="treeRow" style="width: 100%;">
            <tr>
                <td style="width: 5%;">
                    <span *ngIf="dto.Expand && dto.Childs?.length > 0"  [ngStyle]="{'margin-left.px': (Index === 0 ? 0 : 15)}" class="material-icons icon-action" style="vertical-align: sub;" (click)="dto.Expand = !dto.Expand">
                    arrow_drop_down
                    </span>
                    <span *ngIf="!dto.Expand && dto.Childs?.length > 0" class="material-icons icon-action"  [ngStyle]="{'margin-left.px': (Index === 0 ? 0 : 15)}" style="vertical-align: sub;" (click)="dto.Expand = !dto.Expand">
                        arrow_right
                    </span>
                </td>
                <td style="width: 90%;" [ngClass]="{'disabled': UoDto.Id === dto.Id}">
                    <span class="uo-name" *ngIf="dto.Parent && dto.Childs?.length <= 0" style="padding-left:25px" [ngClass]="{'selected': dto.Selected, 'disabled': UoDto.Id === dto.Id}">{{dto.Name}}</span>
                    <span class="uo-name" *ngIf="dto.Parent && dto.Childs?.length > 0" style="padding-left:0px" [ngClass]="{'selected': dto.Selected, 'disabled': UoDto.Id === dto.Id}">{{dto.Name}}</span>
                    <span class="uo-name" *ngIf="!dto.Parent && dto.Childs?.length <= 0" style="padding-left:0px" [ngClass]="{'selected': dto.Selected, 'disabled': UoDto.Id === dto.Id}">{{dto.Name}}</span>
                    <span class="uo-name" *ngIf="!dto.Parent && dto.Childs?.length > 0" style="padding-left:0px" [ngClass]="{'selected': dto.Selected, 'disabled': UoDto.Id === dto.Id}">{{dto.Name}}</span>
                </td>
                <td style="width: 10%;">
                    <mat-checkbox class="mt-2" (click)="ClickCheckbox(dto)" [(ngModel)]="dto.Selected"></mat-checkbox>
                </td>
            </tr>
        </table>
        <app-multiselect-dropdown-tree class="w-100" 
                [DataSource]="dto.Childs" 
                [UoDto]="UoDto"
                [Index]="GetIndex(dto)" 
                [Recursive]="true" 
                [MatSelectRef]="MatSelectRef"
                [SelectedUoRef]="SelectedUoRef"
                [ngStyle]="{'display': (!dto.Expand?'none':'inline')}">
        </app-multiselect-dropdown-tree>
    </ng-container>
</ng-template>
