import { BaseEntity, BaseModel } from "../../models/BaseModel";

// tslint:disable-next-line: max-line-length
export class MetadataTipologyValueModel extends BaseModel<MetadataTipologyValueEntity, MetadataTipologyValueDto, MetadataTipologyValueFilter> {
  constructor() {
    super();
  }
}

export class MetadataTipologyValueEntity extends BaseEntity {
  MetadataTipologyId: number;
  ObjectId: number | Array<number>;
  Value: string;
  constructor() {
    super();
    this.MetadataTipologyId = null;
    this.ObjectId = null;
    this.Value = null;
  }
}

export class MetadataTipologyValueDto extends MetadataTipologyValueEntity {
  Key: string;
  constructor() {
    super();
  }
}

export class MetadataTipologyValueFilter extends MetadataTipologyValueDto {
  AccountId: number;
}

