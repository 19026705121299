import { HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TemplateDto, TemplateModel } from '../models/TemplateModel';
import { DataService } from '../../data.service';
import { DocumentModel } from '../models/DocumentModel';

export class TemplateController {
    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: TemplateModel): Promise<TemplateModel> {
        let response = null;
        if (model && model.Dto) {
            response = await this.dataService.CreateOrUpdateTemplate(model);
            if (response) {
                model.Performed = response.Performed;
                if (response.Performed) {
                    model.Dto.Id = response.Entity.Id;
                }
            }
        }
        return response;
    }

    async Read(model: TemplateModel): Promise<TemplateModel> {
        const response = await this.dataService.ReadTemplate(model);
        if (response) {
            model.Performed = response.Performed;
            if (response.Performed) {
                model.Dto = response.Dto;
            }
        }
        return model;
    }

    async Get(model: TemplateModel): Promise<TemplateModel> {
        const response = await this.dataService.GetTemplate(model);
        if (response) {
            model.Performed = response.Performed;
            if (response.Performed) {
                model.Dtos = response.Dtos;
            }
        }
        return model;
    }

    async GetFirst(model: TemplateModel): Promise<TemplateModel> {
        const response = await this.dataService.GetTemplate(model);
        if (response) {
            model.Performed = response.Performed;
            if (model?.Dtos?.length > 0) {
                model.Dto = model.Dtos[0];
                model.Dtos = new Array<TemplateDto>();
              }
        }
        return model;
    }

    async Load(model: TemplateModel): Promise<TemplateModel> {
        const response = await this.dataService.LoadTemplate(model);
        if (response) {
            model.Performed = response.Performed;
            if (response.Performed) {
                model.Dtos = response.Dtos;
            }
        }
        return model;
    }

    async Delete(model: TemplateModel): Promise<TemplateModel> {
        let response = null;
        if (model) {
            response = await this.dataService.DeleteTemplate(model);
            if (response) {
                model.Performed = response.Performed;
            }
        }
        return response;
    }

    async CreateFromTemplate(model: DocumentModel): Promise<DocumentModel> {
        let response = model;
        if (model && model.Dto) {
            response = await this.dataService.CreateFromTemplate(model);
            if (response && response.Performed) {
                    response.Dto.Id = response.Entity?.Id;
            }
        }
        return response;
    }

}
