import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OrganizationUnitDto } from '../../models/OrganizationUnitModel';

@Component({
  selector: 'app-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.scss']
})
export class DropdownTreeComponent implements OnInit, AfterViewInit {
  @Input() Index = 0;
  @Input() DataSource: any[];
  @Input() set Value(data: any) {
    if (data) {
      this.InputValue = data;
      this.SetInputValue();
    }
  }
  get Value() {
    return this.InputValue;
  }
  @Input() set UoDto(data: OrganizationUnitDto) {
    if (data) {
      this.InputUoDto = data;
    }
  }
  get UoDto() {
    return this.InputUoDto;
  }
  @Input() Recursive = false;
  @Input() Placeholder: string;
  @Input() MatSelectRef: any;
  @Input() Description = '';
  @Output() ClickOnOption = new EventEmitter();
  @ViewChild('MySelectTree') MySelect: any;
  InputUoDto: OrganizationUnitDto;
  InputValue: any;
  MatSelectValue: string;
  MatOptionTxt: string;
  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.SetDefaultPlaceholder();
    this.SetInputValue();
  }

  SetDefaultPlaceholder() {
    this.MatSelectValue = 'default';
    this.MatOptionTxt = this.Placeholder;
    if (this.MySelect && !this.Recursive) {
      this.MatSelectRef = this.MySelect;
    }
  }

  SetInputValue() {
    if (this.Value) {
      const findedValue = this.FindUo(this.DataSource);
      if (findedValue) {
        this.MatSelectValue = findedValue.Id;
        this.MatOptionTxt = findedValue.Name;
        this.ResetSelectedDto();
        findedValue.Selected = true;
      }
    }
  }

  FindUo(items: OrganizationUnitDto[]) {
    let result = null;
    for (const item of items) {
      if (item.Id === this.Value) {
        return item;
      }
      if (item.Childs.length > 0) {
        result = this.FindUo(item.Childs);
        if (result) {
          break;
        }
      }
    }
    return result;
  }

  GetIndex(dto: OrganizationUnitDto) {
    if (!dto.Parent) {
      return 1;
    } else {
      return this.GetDepth(dto, this.Index);
    }
  }

  GetDepth(dto: OrganizationUnitDto, index: number) {
    return index + 1;
  }

  ClickOption(dto: OrganizationUnitDto) {
    if (this.UoDto.Id !== dto.Id) {
      if (!this.UoDto || dto && this.UoDto.Parent !== dto.Id) {
        this.ResetSelectedDto();
        dto.Selected = true;
        this.MatSelectValue = dto.Id;
        this.MatOptionTxt = dto.Name;
        this.ClickOnOption.emit(dto);
      } else if (!dto || dto && this.UoDto && this.UoDto.Parent === dto.Id) {
        this.UoDto = null;
        this.ResetSelectedDto();
        this.SetDefaultPlaceholder();
        this.ClickOnOption.emit(null);
      }
      this.CloseDropdown();
    }
  }

  CloseDropdown() {
    if (this.MatSelectRef) {
      this.MatSelectRef.close();
    }
  }

  ResetSelectedDto() {
    this.DataSource.map(d => d.Selected = false);
    for (const dtoDataSource of this.DataSource) {
      dtoDataSource.Childs.map(c => c.Selected = false);
    }
  }
}
