import { PermissionModel } from '../models/PermissionModel';
import { DataService } from '../../data.service';

export class PermissionController {

    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: PermissionModel): Promise<PermissionModel> {
        let response = model;
        if (model && model.Dto) {
            response = await this.dataService.CreateOrUpdatePermission(model);
            if (response && response.Performed) {
                response.Dto.Id = response.Entity?.Id;
            }
        }
        return response;
    }

    async Get(model: PermissionModel): Promise<PermissionModel> {
        return await this.dataService.GetPermission(model);
    }

    async Delete(model: PermissionModel): Promise<PermissionModel> {
        let response = model;
        if (model) {
            response = await this.dataService.DeletePermission(model);
        }
        return response;
    }
}
