import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class AccountDossierModel extends BaseModel<AccountDossierEntity, AccountDossierDto, AccountDossierFilter> {
    constructor() {
        super();
    }
}

export class AccountDossierEntity extends BaseEntity {
    Role: string|null;
    AccountId: number|null;
    DossierId: number|null;
    constructor() {
        super();
        this.Role = null;
        this.AccountId = null;
        this.DossierId = null;
    }
}

export class AccountDossierDto extends AccountDossierEntity {
    DisplayName: string|null;
    Username: string|null;
    Avatar: string|null;
    Checked: boolean;
    Enabled: boolean;
    constructor() {
        super();
        this.DisplayName = null;
        this.Username = null;
        this.Avatar = null;
        this.Checked = false;
        this.Enabled =false;
    }
}

export class AccountDossierFilter extends AccountDossierDto {
}
