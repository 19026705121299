<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container">
          <div class="grid-container">
            <div class="grid-column">
              <span class="grid-column-title" *ngIf="!navigatorService.Loading">
                {{navigatorService.Dictionary?.ArchiveContent + (ArchiveName || '')}}
              </span>
            </div>
          </div>
        </div>
        <div class="search-create-container">
          <button (click)='CreatePda()' mat-raised-button class="it-doc-btn-create create-button">
            <span class="text">{{navigatorService.Dictionary?.Create.toUpperCase()}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="form mt-3">
    <div class="row margin-bottom">
      <div class="it-doc-table-container">
        <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">
          <ng-container matColumnDef="{{navigatorService.Dictionary?.Name}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.Name}} </th>
            <td mat-cell *matCellDef="let element">
              <span (click)="PdaClick(element)">
                <img src="../../../../../assets/icon/work.svg">
                {{element?.Title}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnSize}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.TableColumnSize}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element?.HumanSize}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnState}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.TableColumnState}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{ element?.WaitingForUpload ?'ATTESA UPLOAD':element?.State}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.PaymentDate}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.PaymentDate}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element?.Date}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.RetentionDate}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.RetentionDate}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element?.ArchivedDate}}
              </span>
            </td>
          </ng-container>


          <ng-container matColumnDef="more-action">
            <th mat-header-cell *matHeaderCellDef class="icon-column">

            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button class="w-auto" #m="matMenuTrigger" [matMenuTriggerFor]="moreAction">
                <img src="../../../../../assets/icon/more-vert.svg" [ngClass]="{'open-menu-trigger-color': m.menuOpen}">
              </button>
              <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
                <button mat-menu-item *ngIf="element?.State === PdaStatus.CONSERVATO?.toUpperCase()"
                  (click)="DownloadPDD(element)">{{navigatorService.Dictionary?.DownloadPDD}}</button>
                  <button mat-menu-item *ngIf="element?.State === PdaStatus.APERTO?.toUpperCase()"
                  (click)="DeletePda(element)">{{navigatorService.Dictionary?.Delete}}</button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="[
                    navigatorService.Dictionary?.Name,
                    navigatorService.Dictionary?.TableColumnSize,
                    navigatorService.Dictionary?.TableColumnState,
                    navigatorService.Dictionary?.PaymentDate,
                    navigatorService.Dictionary?.RetentionDate,
                    'more-action']">
          </tr>
          <tr mat-row *matRowDef="let row;  columns: [
          navigatorService.Dictionary?.Name,
          navigatorService.Dictionary?.TableColumnSize,
          navigatorService.Dictionary?.TableColumnState,
          navigatorService.Dictionary?.PaymentDate,
          navigatorService.Dictionary?.RetentionDate,
          'more-action']">
          </tr>

        </table>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
        </div>
      </div>
      <div *ngIf="!navigatorService.Loading && !Model?.Dto" class="m-0 empty-table-box" style="padding-bottom: 200px;">
        <img src="../../../../../assets/icon/work.svg">
        <div class="description">{{navigatorService.Dictionary?.EmptyPdasMessage}}</div>
      </div>
    </div>

  </div>
