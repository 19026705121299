import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericConfirmDialogComponent } from '../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { TreeComponent } from '../../../custom-components/tree/tree.component';
import { OrganizationUnitDto, OrganizationUnitFilter, OrganizationUnitModel } from '../../../models/OrganizationUnitModel';
import { RoleModel } from '../../../models/RoleModel';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import { AccountController } from '../../../controllers/AccountController';
import { AccountRoleController } from '../../../controllers/AccountRoleController';
import { AccountRoleDto } from '../../../models/AccountRoleModel';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { CommonService } from '../../../../common.service';
import { AuthService } from '../../../../auth.service';
import { BaseOrder } from '../../../../models/BaseModel';
import { AccountDto } from '../../../models/AccountModel';
import { GenericDialogModel } from '../../../models/GenericDialogModel';
import { GenericDialogAction } from '../../../itdoc.configuration';

@Component({
  selector: 'app-uo-trashed-view',
  templateUrl: './uo-trashed-view.component.html',
  styleUrls: ['./uo-trashed-view.component.scss']
})
export class UoTrashedViewComponent implements OnInit {
  @ViewChild('uotree') UoTree: TreeComponent;

  Model: OrganizationUnitModel;
  RoleModel: RoleModel;
  Controller: OrganizationUnitController;
  AccountController: AccountController;
  AccountRoleController: AccountRoleController;
  DataSource = [];
  ResponsibleRoleId: number;
  AllAccountRole: Array<AccountRoleDto>;
  FilteredUo: Array<OrganizationUnitDto>;
  SortAscOfficeCode = true;
  SortAscName = true;
  Tab = 1;
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    private commonService: CommonService,
    public authenticationService: AuthService) {
    commonService.CurrentPageComponent = this;
    this.Model = new OrganizationUnitModel();
    this.RoleModel = new RoleModel();
    this.Controller = new OrganizationUnitController(dataService);
    this.AccountController = new AccountController(dataService);
    this.AccountRoleController = new AccountRoleController(dataService);
    this.AllAccountRole = new Array<AccountRoleDto>();
    this.FilteredUo = new Array<OrganizationUnitDto>();
  }

  ngOnInit() {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load(tab = 1) {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.Tab = (this.navigatorService.NotificationTargetId && this.authenticationService.CurrentUser.IsAdmin) ? 2 : tab;
    this.Model.Filter = new OrganizationUnitFilter();
    this.Model.Filter.Trashed = true;
    if (tab === 1) {
      this.Model.Filter.AccountId = this.authenticationService.ITDocAccount?.Dto?.Id;
    }
    if ((this.Model?.Filter?.AccountId > 0 && this.Tab === 1) || this.Tab === 2) {
      this.Model.Order = new BaseOrder();
      this.Model.Order.Name = 'Id';
      this.Model.Order.Direction = 'asc';
      this.Model = await this.Controller.GetHierarchy(this.Model);
      if (this.Model.Performed) {
        this.FilteredUo = Object.assign([], this.Model.Dtos);
        this.FilteredUo?.sort((a, b) => (a.OfficeCode.toLowerCase() > b.OfficeCode.toLowerCase()) ? 1 : -1);
        if (this.navigatorService.NotificationTargetId > 0) {
          const targetId = this.navigatorService.NotificationTargetId;
          this.navigatorService.NotificationTargetId = null;
          await this.ShowTargetNotification(targetId);
        }
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
      }
    } else {
      this.Model.Performed = true;
      this.Model.Dtos = [];
      this.FilteredUo = [];
    }
    this.navigatorService.StopLoading();
  }

  async ShowTargetNotification(targetId: number) {
    let model = new OrganizationUnitModel();
    model.Filter = new OrganizationUnitFilter();
    model.Filter.Trashed = true;
    model.Filter.Id = targetId;
    model = await this.Controller.Read(model);
    if (model.Performed && model.Dto) {
      await this.authenticationService.Initialize();
      const path = this.GetBranch(model.Dto, this.FilteredUo);
      if (path && path?.length > 0) {
        for (const depth of path) {
          this.UoTree.Expand(depth, this.UoTree.GetIndex(depth));
        }
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.UoNotFound, model.Dto?.Name);
      }
    } else {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.UoNotFound);
    }
  }

  Search(searchValue: string, dtos: OrganizationUnitDto[], recursiveStep = false) {
    if (searchValue) {
      if (!recursiveStep) {
        this.FilteredUo = [];
      }
      for (const dto of dtos) {
        if (dto.Name.toLowerCase().includes(searchValue.toLowerCase()) ||
          dto.OfficeCode.toLowerCase().includes(searchValue.toLowerCase())) {
          this.FilteredUo.push(dto);
        }
        if (dto.Childs && dto.Childs.length > 0) {
          this.Search(searchValue, dto.Childs, true);
        }
      }
    } else {
      this.ClearSearchInput();
    }
  }

  ClearSearchInput(event?: any) {
    this.FilteredUo = this.Model?.Dtos;
  }

  GetTab(accounts: Array<AccountDto>) {
    const user = accounts.find(u => u.Username === this.authenticationService.CurrentUser?.Username);
    if (user) {
      return 1;
    }
    return 2;
  }

  GetBranch(uo: OrganizationUnitDto, uos: Array<OrganizationUnitDto>, path: Array<OrganizationUnitDto> = []) {
    for (const dto of uos) {
      if (!dto.Parent) {
        path = [];
      }
      if (dto.Id === uo.Id) {
        path.push(dto);
        return path;
      } else {
        if (dto.Childs?.length > 0) {
          path.push(dto);
          return this.GetBranch(uo, dto.Childs, path);
        }
      }
    }
    return path;
  }

  async Action($event) {
    if ($event?.Action === 'RESTORE' && $event?.UoDto) {
      if ($event?.UoDto) {
        const data = new GenericDialogModel();
        data.Name = $event?.UoDto.Name;
        data.Icon = '../../../../../assets/icon/group-black.svg';
        data.Title = this.navigatorService.Dictionary?.Restore;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToRestore.toLowerCase() + '<br>' + $event?.UoDto.Name + '?';
        data.Description = this.navigatorService.Dictionary?.AssociatedUsers + ': ' + $event?.UoDto.CountAccount + ', ' +
          this.navigatorService.Dictionary?.AssociatedFiles + ': ' + $event?.UoDto.CountFiles;
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Restore;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            $event.UoDto.Trashed = false;
            let model = new OrganizationUnitModel();
            model.UpdateProperties = ['Trashed'];
            model.Dto = $event?.UoDto;
            model = await this.Controller.CreateOrUpdate(model);
            if (model?.Performed) {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RestoreSuccess, data.Name);
              await this.Load();
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
            }
          }
          this.navigatorService.StopLoading();
        });
      }
    } else if ($event?.Action === 'DELETE' && $event?.UoDto) {
      if ($event?.UoDto) {
        const data = new GenericDialogModel();
        data.Name = $event?.UoDto.Name;
        data.Icon = '../../../../../assets/icon/group-black.svg';
        data.Title = this.navigatorService.Dictionary?.Delete;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToDelete + '<br>' + $event?.UoDto.Name + '?';
        data.Description = this.navigatorService.Dictionary?.AssociatedUsers + ': ' + $event?.UoDto.CountAccount + ', ' +
          this.navigatorService.Dictionary?.AssociatedFiles + ': ' + $event?.UoDto.CountFiles;
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            let model = new OrganizationUnitModel();
            model.Filter = new OrganizationUnitFilter();
            model.Filter.Id = $event?.UoDto.Id;
            model = await this.Controller.Delele(model);
            if (model?.Performed) {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.DeleteSuccess, data.Name);
              await this.Load();
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
            }
          }
          this.navigatorService.StopLoading();
        });
      }
    }
  }
}
