import { DataService } from '../../data.service';
import { DocClassModel } from '../models/DocClassModel';
import { SystemInfoModel } from '../models/SystemInfoModel';
import { ViewRepositoryModel } from '../models/SystemInfoModel copy';

export class DocFlyController {

  constructor(private dataService: DataService) { }

  async GetArchivesInfo(model: SystemInfoModel): Promise<SystemInfoModel> {
    const response = await this.dataService.GetArchivesInfo(model);
    return response;
  }

  async DeletePda(model: ViewRepositoryModel): Promise<ViewRepositoryModel> {
    const response = await this.dataService.DeletePda(model);
    return response;
  }

  async GetDocumentModel(model: DocClassModel): Promise<DocClassModel> {
    const response = await this.dataService.GetDocumentModel(model);
    return response;
  }


  async Login(username: string, password: string, saveCredential: boolean, autoLogin: boolean = false): Promise<any> {
    const response = await this.dataService.DocFlyLogin(username, password, saveCredential, autoLogin);
    return response;
  }

  async GetRepositoryView(model: ViewRepositoryModel): Promise<ViewRepositoryModel> {
    const response = await this.dataService.GetViewRepository(model);
    return response;
  }

  async DownloadFile(model: ViewRepositoryModel): Promise<ViewRepositoryModel> {
    const response = await this.dataService.DownloadFile(model);
    return response;
  }

  async DownloadPDD(model: ViewRepositoryModel): Promise<ViewRepositoryModel> {
    const response = await this.dataService.DownloadPDD(model);
    return response;
  }

  async PdvSend(model: DocClassModel) {
    const response = await this.dataService.PdvSend(model);
    return response;
  }

  async FilesSend(model: DocClassModel) {
    const response = await this.dataService.FilesSend(model);
    return response;
  }

  async CreatePdv(model: DocClassModel) {
    const response = await this.dataService.CreatePdv(model);
    return response;
  }
  async GetDisposedLog(model: ViewRepositoryModel): Promise<ViewRepositoryModel> {
    const response = await this.dataService.GetDisposedLog(model);
    return response;
  }

  async CheckAutoLogin(): Promise<object> {
    const response = await this.dataService.CheckAutoLogin();
    return response;
  }
}
