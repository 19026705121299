import { AccountDto } from './AccountModel';
import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class OrganizationUnitModel extends BaseModel<OrganizationUnitEntity, OrganizationUnitDto, OrganizationUnitFilter> {
    constructor() {
        super();
    }
}

export class OrganizationUnitEntity extends BaseEntity {
    Name: string;
    Description: string;
    OfficeCode: string;
    Parent?: number;
    FolderName: string;
    DisplayName: string;
    Trashed: boolean;
    constructor() {
        super();
        this.Name = null;
        this.Parent = null;
        this.OfficeCode = null;
        this.FolderName = null;
        this.Description = null;
        this.Trashed = false;
    }
}

export class OrganizationUnitDto extends OrganizationUnitEntity {
    // Accounts?: Array<AccountDto>;
    AccountId: number;
    Expand: boolean;
    ParentExpandRow: number;
    Selected: boolean;
    ParentName: string;
    CountAccount: number;
    CountFiles: number;
    Childs: Array<OrganizationUnitDto>;
    UpdateDate: string;
    Users: Array<AccountDto>;
    CurrentUserRole: string;
    TipologyId: number;
    constructor() {
        super();
        // this.Accounts = new Array<AccountDto>();
        this.Expand = false;
        this.ParentExpandRow = null;
        this.Selected = false;
        this.ParentName = null;
        this.CountAccount = 0;
        this.Childs = null;
        this.AccountId = null;
    }
}

export class OrganizationUnitFilter extends OrganizationUnitDto {
}
