import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class RoleModel extends BaseModel<RoleEntity, RoleDto, RoleFilter> {
    constructor() {
        super();
    }
}

export class RoleEntity extends BaseEntity {
    Name: string;
    Permission: string;
    constructor() {
        super();
        this.Name = null;
        this.Permission = null;
    }
}

export class RoleDto extends RoleEntity {
    constructor() {
        super();
    }
}

export class RoleFilter extends RoleDto {
}
