/*
 * DICTIONARY
 * Manage all languages change of the project
 * Need to create a new .ts file in dictionary folder if more languages are needed (EX. dictionaryEN)
 * Import every dictionary created
 */
import * as DictionaryIT from './dictionaryIT';
import * as DictionaryEN from './dictionaryEN';
import { DictionaryInterface } from './dictionary.interface';

/*
 * userLanguage     -> get user language from settings or browser
 * currentLanguage  -> get last 2 characters of language const in lower case (used in getDictionay)
 * dictionary       -> final result of the function (translated keyword)
 */
export class Dictionary {
  public Dictionary: DictionaryInterface;

  constructor(language: string, appname: string, displayname: string = null) {
    language = (language && language?.length > 2 ? language.substring(0, 2) : language);
    sessionStorage.setItem('user_language', language);
    switch (language) {
      case 'en':
        this.Dictionary = new DictionaryEN.Dictionary(appname, displayname);
        break;
      default:
        this.Dictionary = new DictionaryIT.Dictionary(appname, displayname);
        break;
    }
  }
}

