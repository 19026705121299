import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class TipologyUoModel extends BaseModel<TipologyUoEntity, TipologyUoDto, TipologyUoFilter> {
    constructor() {
        super();
    }
}

export class TipologyUoEntity extends BaseEntity {
    TipologyId?: number;
    UoId?: number;
    Trashed: boolean;
    constructor() {
        super();
        this.TipologyId = null;
        this.UoId = null;
        this.Trashed = false;
    }
}

export class TipologyUoDto extends TipologyUoEntity {
    UoName: string;
    UoFolderName: string;
    constructor() {
        super();
    }
}

export class TipologyUoFilter extends TipologyUoDto {
}
