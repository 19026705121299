import { DossierModel } from '../models/DossierModel';
import { DataService } from '../../data.service';

export class DossierController {

  constructor(private dataService: DataService) { }

  async CreateOrUpdate(model: DossierModel): Promise<DossierModel> {
    let response = model;
    if (model && model.Dto) {
      response = await this.dataService.CreateOrUpdateDossier(model);
      if (response && response.Performed) {
        response.Dto.Id = response.Entity?.Id;
      }
    }
    return response;
  }

  async Get(model: DossierModel): Promise<DossierModel> {
    const response = await this.dataService.GetDossier(model);
    return response;
  }

  async Load(model: DossierModel): Promise<DossierModel> {
    const response = await this.dataService.LoadDossier(model);
    return response;
  }

  async Read(model: DossierModel): Promise<DossierModel> {
    const response = await this.dataService.ReadDossier(model);
    return response;
  }

  async Delete(model: DossierModel): Promise<DossierModel> {
    let response = model;
    response = await this.dataService.DeleteDossier(model);
    return response;
  }
}
