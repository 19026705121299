import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras } from '@angular/router';
import { FilterDocumentComponent } from '../../dialog-menu/filter-document/filter-document.component';
import { MediaViewerComponent } from '../../media-viewer/media-viewer.component';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import { ObjectDto, ObjectFilter, ObjectModel } from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { FileManagerController } from '../../../controllers/FileManagerController';
import { Constants, DocumentState, ModalRequest, Pages } from '../../../itdoc.configuration';
import { SettingsController } from '../../../../controllers/SettingsController';
import { NavigatorService } from '../../../../navigator.services';
import { DataService } from '../../../../data.service';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { BaseOrder } from '../../../../models/BaseModel';
import { FileDto, FileModel } from '../../../models/FileModel';
import { WorkflowUserDto } from '../../../../workflow/models/WorkflowUserModel';

@Component({
  selector: 'app-dialog-dashboard',
  templateUrl: './dialog-dashboard.component.html',
  styleUrls: ['./dialog-dashboard.component.scss']
})
export class DialogDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;

  Model: ObjectModel;
  CurrentFolder: ObjectDto;
  Controller: ObjectController;
  FileManagerController: FileManagerController;
  DataSource: MatTableDataSource<ObjectDto>;
  CurrentPage = 1;
  Pages: Array<number>;
  ND = Constants.ND.toString();
  SettingsController: SettingsController;
  Filter: ObjectFilter;
  FilterCount = 0;
  constructor(
    public dialogRef: MatDialogRef<DialogDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public navigatorService: NavigatorService,
    private dataService: DataService,
    private authenticationService: AuthService,
    public commonService: CommonService) {
    this.Filter = (data.Params ? data.Params.Filter : null);
    this.FilterCount = (data.Params ? data.Params.FilterCount : 0);
    this.Model = new ObjectModel();
    this.Model.Take = 10;
    this.Controller = new ObjectController(dataService);
    this.SettingsController = new SettingsController(dataService);
  }
  ngAfterViewInit(): void {
    if (this.SearchComponent) {
      this.SearchComponent.searchValue = this.data?.Params?.Search;
    }
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load() {
    this.navigatorService.StartLoading();
    if (this.data.Params) {
      await this.GetObjects(null, 1, this.data.Params.Filter, this.data.Params.Search);
    } else {
      await this.GetObjects();
    }
    if (this.navigatorService.ObjectDto) {
      const find = this.Model?.Dtos?.find(dto => dto.Id === this.navigatorService.ObjectDto.Id);
      if (find) {
        find.Selected = true;
      }
      this.navigatorService.ObjectDto = null;
    }
    this.navigatorService.StopLoading();
  }

  async GetObjects(dto: ObjectDto = null, page = 1, filter = null, search = null) {
    this.Model.Search = search ? search : this.SearchComponent?.GetSearchValue();
    if (filter) {
      this.Model.Filter = filter;
    } else {
      this.Model.Filter = new ObjectFilter();
      this.Model.Filter.State = this.data?.RequestType === ModalRequest.YOURREQUEST ? null : this.data.DataSetModel.Status;
    }
    // tslint:disable-next-line: max-line-length
    this.Model.Filter.Creator = (this.data?.RequestType === ModalRequest?.YOURREQUEST ? this.authenticationService.CurrentUser.Username : null);
    this.Model.Filter.AccountId = this.data?.RequestType === ModalRequest?.YOURDOCS ? this.authenticationService.ITDocAccount.Dto.Id : null;
    this.Model.Filter.YourRequest = (this.data?.RequestType === ModalRequest?.YOURREQUEST);
    this.Model.Filter.Type = 'file';
    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Id';
    this.Model.Order.Direction = 'desc';

    this.Model = await this.Controller.Load(this.Model);
    if (dto && this.Model.Performed && this.Model.Dtos.length > 0) {
      if (dto.Type === 'file') {
        this.Model.Dtos.find(q => q.Id === dto.Id).Selected = true;
      }
    }
    this.Paging(page);
  }

  async OpenObject(row: ObjectDto) {
    this.Model.Dtos.map(x => x.Selected = false);
    await this.commonService.OpenObject(row, this.navigatorService);
  }

  async ExtractP7M(dto: ObjectDto) {
    const fileModel = new FileModel();
    fileModel.Dto = new FileDto();
    fileModel.Dto.Id = dto.Id;
    fileModel.Dto.MimeType = dto.MimeType;
    fileModel.Dto.PhysicalName = dto.PhysicalName;
    fileModel.Dto.Path = 'itdoc/' + dto.Path;
    fileModel.Dto.OriginalExtension = dto.OriginalExtension;
    const response = await this.SettingsController.ExtractP7M(fileModel);
    if (response.Performed) {
      const c = response.Dto["originalMimeType"];
      if (response.Dto["onlyOffice"]) {
        let queryParams: NavigationExtras = null;
        queryParams = {
          queryParams: {
            id: dto.Id,
            uo: dto.UoId
          }
        };
        this.navigatorService.GoTo(Pages.OnlyOffice, queryParams, 'v2/', true);
      } else if (response.Dto["originalMimeType"].includes('image') || response.Dto["originalMimeType"].includes('video')) {
        const dataMedia = {
          Stream: response.Dto["fileContent"],
          Extension: this.commonService.GetExtension(response.Dto["originalFileName"]),
          FileName: response.Dto["originalFileName"],
          MimeType: response.Dto["originalMimeType"]
        };
        this.navigatorService.ShowDialog(MediaViewerComponent, dataMedia, '70%', '85%', '200px');
      } else {
        const blob = this.commonService.Base64toBlob(response.Dto["fileContent"], response.Dto["originalMimeType"]);
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = dto.PhysicalName;
        // start download
        element.click();
      }
    }

  }

  SelectRow(dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      this.Model.Dtos.map(o => o.Selected = false);
      dto.Selected = true;
    }
  }

  async ReadObject(parent) {
    if (parent && parent > 0) {
      let model = new ObjectModel();
      model.Filter = new ObjectFilter();
      model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      model.Filter.Id = parent;
      model = await this.Controller.Read(model);
      return model?.Dto ?? null;
    }
    return null;
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    await this.GetObjects(null, 1, this.Filter);
    this.navigatorService.StopLoading();
  }

  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    let folder = this.CurrentFolder;
    if (this.CurrentFolder?.Deep > 1) {
      this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
      folder = this.CurrentFolder;
    } else {
      this.CurrentFolder = null;
      folder = null;
    }
    await this.GetObjects(folder, null, this.CurrentPage);
    this.navigatorService.StopLoading();
  }

  async OpenFiltersDialog() {
    const data = {
      View: Pages.Dashboard, Filter: this.Filter,
      ReferralComponent: this.data.ReferralComponent, Search: this.SearchComponent?.GetSearchValue()
    };
    // tslint:disable-next-line:max-line-length
    this.navigatorService.ShowDialog(FilterDocumentComponent, data, '45%', 'fit-content', '200px', async (response: any) => {
      if (response) {
        if (!response.ReferralComponent) {
          this.navigatorService.StartLoading();
          this.FilterCount = response.FilterCount;
          this.Filter = response.Filter;

          if (this.FilterCount > 0) {
            this.Model.Skip = 0;
            await this.GetObjects(null, 1, response.Filter, response.Search);
          } else {
            this.Model = new ObjectModel();
            await this.GetObjects(null, this.CurrentPage, response.Filter, response.Search);
          }
          this.navigatorService.StopLoading();
        } else {
          if (this.data.ReferralComponent) {
            this.data.ReferralComponent.OpenRequestModal(this.data.DataSetModel, this.data.RequestType,
              this.data.ChartType, this.data.Title, response);
          }
        }
      }
    });
    this.dialogRef.close();

  }

  Redirect(dto: ObjectDto) {
    dto.IsRedirect = true;
    this.navigatorService.ObjectDto = dto;
    if (dto.State === DocumentState.ARCHIVED) {
      this.commonService.SidebarMenuClick(Pages.ViewArchive, this.navigatorService);
      this.navigatorService.GoTo(Pages.ViewArchive);
    } else if (dto.State === DocumentState.PROTOCOLLED) {
      this.commonService.SidebarMenuClick(Pages.ViewProtocols, this.navigatorService);
      this.navigatorService.GoTo(Pages.ViewProtocols);
    } else {
      this.commonService.SidebarMenuClick(Pages.Documents, this.navigatorService);
      this.navigatorService.GoTo(Pages.Documents);
    }
    this.dialogRef.close();
  }
  //#region paging
  RefreshTable(dtos: Array<ObjectDto>) {
    for (const dto of dtos) {
      if (!dto.ClassIcon && !dto.Icon) {
        this.commonService.GetIcon(dto);
      }
      if (dto?.WorkflowData?.WorkflowSteps && dto?.WorkflowData?.WorkflowSteps?.length > 0) {
        dto.Team = new Array<WorkflowUserDto>();
        for (const step of dto?.WorkflowData?.WorkflowSteps) {
          for (const user of step.WorkflowStepUsers) {
            const find = dto.Team.find(u => u.AccountId === user.AccountId);
            if (!find) {
              dto.Team.push(user);
            }
          }
        }
      }
    }

    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetObjects(this.CurrentFolder, page, this.Filter);
    this.navigatorService.StopLoading();
  }

  //#endregion
}
