import { DataService } from '../../data.service';
import { WorkflowStepModel } from '../models/WorkflowStepModel';


export class WorkflowStepController {


    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: WorkflowStepModel): Promise<WorkflowStepModel> {
        const response = await this.dataService.CreateOrUpdateWorkflowStep(model);
        return response;
    }

    async Read(model: WorkflowStepModel): Promise<WorkflowStepModel> {
        const response = await this.dataService.ReadWorkflowStep(model);
        return response;
    }

    async Get(model: WorkflowStepModel): Promise<WorkflowStepModel> {
        const response = await this.dataService.GetWorkflowStep(model);
        return response;
    }

    async Load(model: WorkflowStepModel): Promise<WorkflowStepModel> {
        const response = await this.dataService.LoadWorkflowStep(model);
        return response;
    }

    async Delete(model: WorkflowStepModel): Promise<WorkflowStepModel> {
        const response = await this.dataService.DeleteWorkflowStep(model);
        return response;
    }

}
