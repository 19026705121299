import { BaseEntity, BaseModel } from './BaseModel';
import { CurrentUserEntity } from './CurrentUserModel';

export class UserGroupModel extends BaseModel<UserGroupEntity, UserGroupDto, UserGroupFilter> {

  constructor() {
    super();
    this.Dto = new UserGroupDto();
  }
}

export class UserGroupEntity extends BaseEntity {

  UserId: number;
  GroupId: number;
  User: CurrentUserEntity;

  constructor() {
    super();
    this.UserId = null;
    this.GroupId = null;
    this.User = null;

  }
}

export class UserGroupDto extends UserGroupEntity {
  constructor() {
    super();
  }
}

export class UserGroupFilter extends UserGroupDto {
}