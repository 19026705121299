import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../../../../data.service';
import { DialogMenuComponent } from '../../dialog-menu/dialog-menu.component';
import { DocumentComponent } from '../../dialog-menu/document/document.component';
import { FolderComponent } from '../../dialog-menu/folder/folder.component';
import { GenericConfirmDialogComponent } from '../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { WorkflowPreviewComponent } from './workflow-preview/workflow-preview.component';
import { FilterDocumentComponent } from '../../dialog-menu/filter-document/filter-document.component';
import { SignatureComponent } from '../../dialog-menu/signature/signature.component';
import { AttachedDocumentComponent } from '../../dialog-menu/attached-document/attached-document.component';
import { VerifySignatureComponent } from '../../dialog-menu/signature/verify-signature/verify-signature.component';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import { ObjectDto, ObjectFilter, ObjectModel } from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { WorkflowDataController } from '../../../../workflow/controllers/WorkflowDataController';
import { SettingsController } from '../../../../controllers/SettingsController';
import { FileManagerController } from '../../../controllers/FileManagerController';
import { FavouritesController } from '../../../controllers/FavouritesController';
import { Constants, DocumentState, FileMenu, FilesMenu, GenericDialogAction, PageAction, Pages } from '../../../itdoc.configuration';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { BaseOrder } from '../../../../models/BaseModel';
import { WorkflowUserDto } from '../../../../workflow/models/WorkflowUserModel';
import { GenericDialogModel } from '../../../models/GenericDialogModel';
import { EncryptComponent } from '../../dialog-menu/encrypt/encrypt.component';
import { FavouriteDto, FavouriteFilter, FavouriteModel } from '../../../models/FavouriteModel';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;
  @Input() DashboardView = false;
  Model: ObjectModel;
  Controller: ObjectController;
  DataSource: MatTableDataSource<ObjectDto>;

  WorkflowDataController: WorkflowDataController;
  SettingsController: SettingsController;
  FileManagerController: FileManagerController;
  FavouritesController: FavouritesController;
  CurrentFolder: ObjectDto;
  CountAllObject: number = null;
  ND = Constants.ND.toString();
  Tab = 1;
  FileMenu: Array<any>;
  FilesMenu: Array<any>;
  FilterCount = 0;
  Filter: ObjectFilter;
  IsAllFavouritesSelected: boolean;
  constructor(
    dataService: DataService, public navigatorService: NavigatorService, public authenticationService: AuthService,
    public commonService: CommonService) {
    commonService.CurrentPageComponent = this;

    this.Controller = new ObjectController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
    this.FavouritesController = new FavouritesController(dataService);
    this.WorkflowDataController = new WorkflowDataController(dataService);
    this.SettingsController = new SettingsController(dataService);
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load(tab = 1) {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.Model = new ObjectModel();
    this.FilterCount = 0;
    this.Filter = null;
    this.SearchComponent?.ClearInputValue(false);
    if (this.CountAllObject === null) {
      this.CountAllObject = await this.CountAll();
    }

    this.Tab = tab;
    if (tab < 3) {
      if (this.navigatorService.NotificationTargetId > 0) {
        this.navigatorService.ObjectDto = await this.ReadObject(this.navigatorService.NotificationTargetId);
        if (this.navigatorService.ObjectDto) {
          this.navigatorService.ObjectDto.IsRedirect = true;
        } else {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.DocumentNotFound);
        }
        this.navigatorService.NotificationTargetId = null;
      }
      if (this.navigatorService.ObjectDto && this.navigatorService.ObjectDto.IsRedirect) {
        this.CurrentFolder = this.navigatorService.ObjectDto;
        this.Model.Dtos.push(this.CurrentFolder);
        this.Tab = 1;
        this.Model.Count = 1;
        this.navigatorService.ObjectDto = null;
        this.Paging();
      } else {
        await this.GetObjects();
        if (this.navigatorService.ObjectDto && !this.navigatorService.ObjectDto.IsRedirect) {
          const find = this.Model?.Dtos?.find(dto => dto.Id === this.navigatorService.ObjectDto.Id);
          if (find) {
            find.Selected = true;
          }
          this.navigatorService.ObjectDto = null;
        }
      }
    } else {
      await this.GetFavouriteObjects();
    }

    this.navigatorService.StopLoading();
  }

  async CountAll() {
    const state = [DocumentState.PROGRESS, DocumentState.REVISION, DocumentState.REVISIONED,
    DocumentState.APPROVAL, DocumentState.EDIT, DocumentState.SIGNED, DocumentState.APPROVED, DocumentState.REJECTED, null];
    return await this.Controller.CountAll(this.authenticationService.ITDocAccount.Dto.Id, state);
  }

  async GetObjects(dto: ObjectDto = null, search: string = null, page = 1, filter = null) {
    if (this.authenticationService.ITDocAccount.Dto.Id && this.authenticationService.ITDocAccount.Dto.Id > 0) {
      this.Model.Search = this.SearchComponent?.GetSearchValue();
      if (filter) {
        this.Model.Filter = filter;
      } else {
        this.Model.Filter = new ObjectFilter();
        this.Model.Filter.State = [DocumentState.PROGRESS, DocumentState.REVISION, DocumentState.REVISIONED,
        DocumentState.APPROVAL, DocumentState.EDIT, DocumentState.SIGNED, DocumentState.APPROVED, DocumentState.REJECTED, null];
        if (!search) {
          search = this.SearchComponent?.GetSearchValue();
          if (!dto && search) {
            this.Model.Search = search;
          } else if (!dto) {
            this.Model.Filter.Deep = 1;
          } else if (dto && dto.Type === 'folder') {
            this.Model.Filter.Parent = dto.Id;
            this.Model.Filter.Deep = dto.Deep + 1;
          } else if (dto && dto.Type === 'file') {
            if (dto.Deep > 1) {
              this.Model.Filter.Parent = dto.Parent;
              this.Model.Filter.Deep = dto.Deep;
            } else {
              this.Model.Filter.Deep = 1;
            }
          }
        }
        else {
          this.Model.Search = search;
        }
      }

      this.Model.Filter.RoleName = (this.Tab === 2 ? 'Admin' : null);
      this.Model.Filter.Creator = (this.Tab === 1 ? this.authenticationService.CurrentUser.Username : null);
      this.Model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      this.CurrentFolder = dto?.Type === 'folder' ? dto : await this.ReadObject(dto?.Parent);

      this.Model.Order = new BaseOrder();
      this.Model.Order.Name = 'Id';
      this.Model.Order.Direction = 'desc';

      this.Model = await this.Controller.Load(this.Model);
      if (dto && this.Model.Performed && this.Model.Dtos.length > 0) {
        if (dto.Type === 'file') {
          this.Model.Dtos.find(q => q.Id === dto.Id).Selected = true;
        }
      }
      this.Paging(page);
    }
  }

  async OpenObject(row: ObjectDto) {
    if (row.Type === 'folder') {
      this.navigatorService.StartLoading();
      this.Model.Skip = 0;
      if (this.Tab < 3) {
        await this.GetObjects(row);
      } else {
        await this.GetFavouriteObjects(row);
      }

      this.navigatorService.StopLoading();
    } else if (row.Type === 'file') {
      this.Model.Dtos.map(x => x.Selected = false);
      await this.commonService.OpenObject(row, this.navigatorService);
    }
  }

  CheckRow(event: any, dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      dto.Checked = event.checked;
    }
  }

  SelectRow(dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      this.Model.Dtos.map(o => o.Selected = false);
      dto.Selected = true;
    }
  }

  async ReadObject(parent) {
    if (parent && parent > 0) {
      let model = new ObjectModel();
      model.Filter = new ObjectFilter();
      model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      model.Filter.Id = parent;
      model = await this.Controller.Read(model);
      return model?.Dto ?? null;
    }
    return null;
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    if (this.Tab < 3) {
      await this.GetObjects(null, searchText, 1, this.Filter);
    } else {
      await this.GetFavouriteObjects(null, searchText, 1, this.Filter);
    }
    this.navigatorService.StopLoading();
  }

  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    let folder = this.CurrentFolder;
    if (folder.IsRedirect) {
      this.CurrentFolder = null;
      await this.GetObjects();
    } else {
      if (this.CurrentFolder?.Deep > 1) {
        this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
        folder = this.CurrentFolder;
      } else {
        this.CurrentFolder = null;
        folder = null;
      }
      if (this.Tab < 3) {
        await this.GetObjects(folder, null, this.Pager.CurrentPage);
      } else if (this.Tab === 3) {
        await this.GetFavouriteObjects(folder, null, this.Pager.CurrentPage);
      }
    }
    this.navigatorService.StopLoading();
  }

  HeaderCheckbox(event: any) {
    if (event) {
      const dtos = this.Model.Dtos;
      dtos?.filter(o => (o?.Authorization?.Authorized
        && (o.State === 'ARCHIVED' || o.State === 'PROGRESS'
          || o.State === 'PROTOCOLLED' || o.State === 'REJECTED')))?.map(o => o.Checked = event.checked);
    }
  }

  IsAllSelected() {
    const dtosSelected = this.Model?.Dtos?.filter(o => (o?.Authorization?.Authorized
      && (o.State === 'ARCHIVED' || o.State === 'PROGRESS'
        || o.State === 'PROTOCOLLED' || o.State === 'REJECTED')));
    const selection = new SelectionModel<ObjectDto>(true, this.Model?.Dtos?.filter(t => t.Checked && t.Type === 'file'));
    if (selection.selected.length > 0) {
      return selection.selected.length === dtosSelected?.length;
    }
    return false;
  }

  Create() {
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(DialogMenuComponent, null, '45%', 'fit-content', '200px', (response) => {
      this.navigatorService.PageAction = PageAction.New;
      if (response) {
        this.navigatorService.ShowDialog(response.MenuItemSelected.Component, response.DocumentMenuItems,
          '45%', 'fit-content', '200px', async (result) => {
            if (result) {
              if (result?.Id > 0) {
                this.Tab = 1;
                this.Model.Skip = 0;
                this.navigatorService.StartLoading();
                await this.GetObjects(result);
                this.navigatorService.StopLoading();
              }
            }
          });
      }
    });
  }

  RefreshTable(dtos: Array<ObjectDto>) {
    if (dtos) {
      for (const dto of dtos) {
        if (!dto.ClassIcon && !dto.Icon) {
          this.commonService.GetIcon(dto);
        }
        if (dto?.WorkflowData?.WorkflowSteps && dto?.WorkflowData?.WorkflowSteps?.length > 0) {
          dto.Team = new Array<WorkflowUserDto>();
          for (const step of dto?.WorkflowData?.WorkflowSteps) {
            for (const user of step.WorkflowStepUsers) {
              const find = dto.Team.find(u => u.AccountId === user.AccountId);
              if (!find) {
                dto.Team.push(user);
              }
            }
          }
        }
      }
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
    this.SetAllFavouritesSelected();
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    if (this.Tab < 3) {
      await this.GetObjects(this.CurrentFolder, null, page, this.Filter);
    } else {
      await this.GetFavouriteObjects(this.CurrentFolder, null, page, this.Filter);
    }
    this.navigatorService.StopLoading();
  }

  GetMenuFile(dto: ObjectDto) {
    const menu = new FileMenu(this.navigatorService.Dictionary, dto, this.authenticationService.ITDocAccount?.Dto);
    this.FileMenu = menu.GetMenu();
  }

  async ActionMenu(action: string, dto: ObjectDto) {
    if (action === 'edit') {
      this.navigatorService.PageAction = PageAction.Edit;
      if (dto.Type === 'file') {
        this.navigatorService.ShowDialog(DocumentComponent, dto.Id, '45%', 'fit-content', '200px', async (response) => {
          if (response) {
            if (this.Tab < 3) {
              await this.GetObjects(response, null, this.Pager.CurrentPage);
            } else {
              await this.GetFavouriteObjects(response, null, this.Pager.CurrentPage);
            }
          }
        });
      } else {
        const data = { Dto: dto };
        this.navigatorService.ShowDialog(FolderComponent, data, '45%', 'fit-content', '200px', async (response) => {
          if (response) {
            if (this.Tab < 3) {
              await this.GetObjects(response, null, this.Pager.CurrentPage);
            } else {
              await this.GetFavouriteObjects(response, null, this.Pager.CurrentPage);
            }
          }
        });
      }
    } else if (action === 'archive') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.Archive;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToArchive + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Archive;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.ARCHIVED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.ARCHIVED;
                model.Dto = dto;
                model.Action = DocumentState.ARCHIVED;
                model.UpdateProperties = ['State', 'ModificationDate', 'Modifier', 'Hash'];
                model = await this.Controller.CreateOrUpdate(model);
                if (model?.Performed) {
                  const name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.Archived, name);
                  if (this.Tab < 3) {
                    await this.GetObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  } else {
                    await this.GetFavouriteObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  }
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'delete') {
      const data = new GenericDialogModel();
      data.Icon = '../../../../../assets/icon/big-doc.svg';
      data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
        this.navigatorService.Dictionary?.ToDelete + '<br>' + data.Name + '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
        if (performed === GenericDialogAction.CONFIRM) {
          this.navigatorService.StartLoading();
          let model = new ObjectModel();
          dto.Trashed = true;
          model.Dto = dto;
          model.Action = 'TRASHED';
          model.UpdateProperties = ['Trashed'];
          model = await this.Controller.CreateOrUpdate(model);
          if (model?.Performed) {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.TrashedFile, dto.Name);
            if (this.Tab < 3) {
              await this.GetObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
            } else {
              await this.GetFavouriteObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
            }
          } else {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
          }
        }
        this.navigatorService.StopLoading();
      });
    } else if (action === 'collate') {

    } else if (action === 'protocol') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.ProtocolTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.SendToProtocol + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Protocol;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.PROTOCOLLED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.PROTOCOLLED;
                model = await this.Controller.Protocol(model);
                if (model?.Performed) {
                  // const fileModel = await this.commonService.ConvertDocument(model.Dto);
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.StateProtocolled, data.Name);
                  if (this.Tab < 3) {
                    await this.GetObjects(null, null, this.Pager.CurrentPage);
                  } else {
                    await this.GetFavouriteObjects(null, null, this.Pager.CurrentPage);
                  }
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'sendToApprovation') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.SendToApprovationTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.SendToApprovation + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Send;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.REVISIONED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = response.Dto?.Authorization?.NextAction ? response.Dto?.Authorization?.NextAction : DocumentState.APPROVAL;
                dto.Lock = true;
                dto.Version = (parseFloat(dto.Version.toString()) + 0.1);
                model.Dto = dto;
                model.Action = DocumentState.REVISIONED;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate', 'Version'];
                model = await this.Controller.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.SendToApprovationSuccess, data.Name);
                  if (this.Tab < 3) {
                    await this.GetObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  } else {
                    await this.GetFavouriteObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  }
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'sendToRevision') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.SendToRevisionTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.SendToRevision + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Send;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.REVISION);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = response.Dto?.Authorization?.NextAction ? response.Dto?.Authorization?.NextAction : DocumentState.REVISION;
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.REVISION;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate'];
                model = await this.Controller.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.SendToRevisionSuccess, data.Name);
                  if (this.Tab < 3) {
                    await this.GetObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  } else {
                    await this.GetFavouriteObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  }
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'approve') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.Approve;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToApprove + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Approve;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.APPROVED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = response.Dto?.Authorization?.NextAction ? response.Dto?.Authorization?.NextAction : DocumentState.APPROVED;
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.APPROVED;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate'];
                model = await this.Controller.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.StateApproved, data.Name);
                  if (this.Tab < 3) {
                    await this.GetObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  } else {
                    await this.GetFavouriteObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  }
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'requestEditing') {
      if (dto.Authorization.Authorized && dto.Authorization.RequestEditing) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.RequestEditingTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToRequestEditing + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Send;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.EDIT);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.EDIT;
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.EDIT;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate'];
                model = await this.Controller.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RequestEditingSuccess, data.Name);
                  if (this.Tab < 3) {
                    await this.GetObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  } else {
                    await this.GetFavouriteObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  }
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'reject') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.RejectTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToReject + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Reject;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.REJECTED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.REJECTED;
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.REJECTED;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate'];
                model = await this.Controller.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RejectedSuccess, data.Name);
                  if (this.Tab < 3) {
                    await this.GetObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  } else {
                    await this.GetFavouriteObjects(this.CurrentFolder, null, this.Pager.CurrentPage);
                  }
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'sign') {
      if (dto.IsSignatory) {
        this.navigatorService.ShowDialog(SignatureComponent, dto, '45%', 'fit-content', '200px', async (response) => {
          if (response) {
            this.navigatorService.StartLoading();
            if (this.Tab < 3) {
              await this.GetObjects(response, null, this.Pager.CurrentPage);
            } else {
              await this.GetFavouriteObjects(response, null, this.Pager.CurrentPage);
            }
            this.navigatorService.StopLoading();
          }
        });
      }
    } else if (action === 'sign-verify') {
      if (dto.IsSignatory) {
        const data = { Dto: dto };
        this.navigatorService.ShowDialog(VerifySignatureComponent, data, '45%', 'fit-content', '200px');
      }
    } else if (action === 'attached') {
      const data = { Dto: dto };
      this.navigatorService.ShowDialog(AttachedDocumentComponent, data, '45%', 'fit-content', '200px', async (response: any) => {
        if (response && response.Performed) {
          dto.CountAttachment = response.Count;
        }
      });
    } else if (action === 'encrypt' || action === 'decrypt') {
      if (dto.Type === 'file') {
        const data = { Dto: dto, SoftDescrypt: false };
        this.navigatorService.ShowDialog(EncryptComponent, data, '45%', 'fit-content', '200px', async (response) => {
          if (response?.Performed) {
            dto = response.Dto;
          }
        });
      }
    }
  }

  ShowWorkflowDetail(element) {
    if (element?.WorkflowData) {
      this.navigatorService.ShowDialog(WorkflowPreviewComponent, element, '45%', 'fit-content', '200px', async (response) => {
        if (response) {
          await this.GetObjects(response, null, this.Pager.CurrentPage);
        }
      });
    }
  }


  // #region Tab Favourite
  async GetFavouriteObjects(dto: ObjectDto = null, search: string = null, page = 1, filter: ObjectFilter = null) {
    this.Model.Search = null;
    if (filter) {
      this.Model.Filter = filter;
    } else {
      this.Model.Filter = new ObjectFilter();
    }
    this.Model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    let favouriteModel = new FavouriteModel();
    favouriteModel.Filter = new FavouriteFilter();
    favouriteModel.Filter.Uid = this.authenticationService.CurrentUser?.Username;
    favouriteModel = await this.FavouritesController.Get(favouriteModel);
    if (favouriteModel?.Performed) {
      if (favouriteModel?.Dtos?.length > 0) {
        const ids = favouriteModel?.Dtos?.map(f => f.ObjectId);
        this.Model.Filter.Id = ids;
      }
    }
    this.CurrentFolder = dto?.Type === 'folder' ? dto : null;

    this.Model.Search = this.SearchComponent?.GetSearchValue();
    if (!dto && search) {
      this.Model.Search = search;
    } else if (!dto) {
      this.Model.Filter.Deep = 1;
    } else if (dto && dto.Type === 'folder') {
      this.Model.Filter.Parent = dto.Id;
    } else if (dto && dto.Type === 'file') {
      this.Model.Filter.Parent = dto.Parent;
    }

    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Id';
    this.Model.Order.Direction = 'desc';

    this.Model = await this.Controller.Load(this.Model);
    if ((!favouriteModel?.Performed || favouriteModel?.Dtos?.length <= 0) && !dto) {
      this.Model.Dtos = [];
      this.Model.Count = 0;
    }
    if (dto && dto.Type === 'file') {
      this.Model.Dtos.find(q => q.Id === dto.Id).Selected = true;
    }
    this.Paging(page);
  }

  async HeaderFavourites(selectAll: boolean) {
    const dtos = this.Model.Dtos;
    this.navigatorService.StartLoading();
    if (selectAll) {
      const favouriteDtos = new Array<FavouriteDto>();
      for (const dto of dtos) {
        const favouriteDto = new FavouriteDto();
        if (!dto.Favourite) {
          favouriteDto.Uid = this.authenticationService.CurrentUser.Username;
          favouriteDto.ObjectId = dto.Id;
        } else {
          favouriteDto.Id = dto.Favourite?.Id;
          favouriteDto.Uid = dto.Favourite?.Uid;
          favouriteDto.ObjectId = dto.Favourite?.ObjectId;
        }
        favouriteDtos.push(favouriteDto);
      }
      const responseDtos = await this.CheckAllFavourite(favouriteDtos, selectAll);
      dtos.map(o => o.Favourite = responseDtos.find(e => e.ObjectId === o.Id));

    } else {
      const ids = dtos.map(o => o.Favourite?.Id);
      await this.CheckAllFavourite(new Array<FavouriteDto>(), selectAll, ids);
      dtos.map(o => o.Favourite = null);
      if (this.Tab === 3) {
        if (!this.CurrentFolder || this.CurrentFolder?.Deep <= 0) {
          this.Model.Dtos = this.Model.Dtos?.filter(o => o.Favourite !== null);
          this.Paging(this.Pager.CurrentPage);
        }
      }
    }
    this.SetAllFavouritesSelected();
    this.navigatorService.StopLoading();
  }

  async CheckAllFavourite(dtos: Array<FavouriteDto>, selectAll: boolean, ids = null) {
    let model = new FavouriteModel();
    if (selectAll) {
      model.Dtos = dtos;
      model = await this.FavouritesController.CreateOrUpdate(model);
      if (!model?.Performed) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
      }
    } else if (!selectAll && ids?.length > 0) {
      model.Filter = new FavouriteFilter();
      model.Filter.Id = ids;
      model = await this.FavouritesController.Delete(model);
      if (!model?.Performed) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
      }
      else {
        this.Model.Count -= dtos?.length;
      }
    }
    return model.Dtos;
  }

  async CheckFavourite(dto: ObjectDto) {
    this.navigatorService.StartLoading();
    if (dto && dto.Id > 0) {
      let model = new FavouriteModel();
      if (dto.Favourite) {
        model.Filter = new FavouriteFilter();
        model.Filter.Id = dto.Favourite.Id;
        model = await this.FavouritesController.Delete(model);
        if (model?.Performed) {
          dto.Favourite = null;
          this.Model.Count -= 1;
        }
        if (this.Tab === 3) {
          if (!this.CurrentFolder || this.CurrentFolder?.Deep <= 0) {
            this.Model.Dtos = this.Model.Dtos.filter(o => o.Favourite !== null);
            this.Paging(this.Pager.CurrentPage);
          }
        }
      } else {
        model.Dto = new FavouriteDto();
        model.Dto.Uid = this.authenticationService.CurrentUser.Username;
        model.Dto.ObjectId = dto.Id;
        model = await this.FavouritesController.CreateOrUpdate(model);
        if (model?.Performed) {
          dto.Favourite = model.Entity;
        }
      }
      if (!model?.Performed) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
      } else {
        this.SetAllFavouritesSelected();
      }
    }
    this.navigatorService.StopLoading();

  }

  SetAllFavouritesSelected() {
    if (this.Model) {
      const dtoscurrentPage = this.Model.Dtos;
      const dtosFavourite = dtoscurrentPage?.filter(o => o.Favourite !== null);
      if (this.Tab < 3) {
        this.IsAllFavouritesSelected = dtosFavourite?.length === this.Model.Dtos?.length;
      } else {
        this.IsAllFavouritesSelected = dtosFavourite?.length === this.Model.Dtos?.length && this.Model.Dtos?.length > 0;
      }
    }
  }
  // #endregion Favourite

  OpenFiltersDialog() {
    const data = { View: Pages.Documents, Filter: this.Filter };
    // tslint:disable-next-line:max-line-length
    this.navigatorService.ShowDialog(FilterDocumentComponent, data, '45%', 'fit-content', '200px', async (response: any) => {
      if (response) {
        this.navigatorService.StartLoading();
        this.FilterCount = response.FilterCount;
        this.Filter = response.Filter;
        this.Model.Skip = 0;
        if (this.Tab < 3) {
          if (this.FilterCount > 0) {
            await this.GetObjects(null, null, 1, response.Filter);
          } else {
            this.Model = new ObjectModel();
            await this.GetObjects();
          }
        } else {
          if (this.FilterCount > 0) {
            this.navigatorService.StartLoading();
            await this.GetFavouriteObjects(null, null, 1, response.Filter);
          } else {
            this.Model = new ObjectModel();
            await this.GetFavouriteObjects();
          }
        }
        this.navigatorService.StopLoading();
      }
    });
  }

  GetMenuFiles() {
    const dtos = this.Model?.Dtos?.filter(t => t.Checked);
    const menuDelete = dtos.filter(q => q.State !== DocumentState.PROGRESS && q.State !== DocumentState.REJECTED)?.length <= 0;
    const menuSign = false;
    const menuCollate = dtos.filter(q => q.State !== DocumentState.PROTOCOLLED && q.State !== DocumentState.ARCHIVED)?.length <= 0;
    const menu = new FilesMenu(this.navigatorService.Dictionary,
      menuDelete,
      menuSign,
      menuCollate);
    this.FilesMenu = menu.GetMenu();
  }

  ActionFilesMenu(action) {
    const dtos = this.Model.Dtos.filter(q => q.Checked);
    if (action === 'delete') {
      const data = new GenericDialogModel();
      data.Icon = '../../../../../assets/icon/big-doc.svg';
      data.Name = '';
      for (const dto of dtos) {
        data.Name += dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        if (dto !== dtos[dtos?.length - 1]) {
          data.Name += ',';
        }
      }
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
        this.navigatorService.Dictionary?.ToDelete + '<br>' + data.Name + '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (option) => {
        if (option === GenericDialogAction.CONFIRM) {
          this.navigatorService.StartLoading();
          const models = Array<ObjectModel>();
          for (const dto of dtos) {
            let model = new ObjectModel();
            dto.Trashed = true;
            model.Dto = dto;
            model.Action = 'TRASHED';
            model.UpdateProperties = ['Trashed'];
            model = await this.Controller.CreateOrUpdate(model);
            models.push(model);
          }
          const performed = models.filter(q => !q.Performed)?.length <= 0;
          if (performed) {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.TrashedFiles);
            if (this.Tab < 3) {
              await this.GetObjects(null, null, this.Pager.CurrentPage);
            } else {
              await this.GetFavouriteObjects(null, null, this.Pager.CurrentPage);
            }
          } else {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.TrashedFileNotCmplete);
          }
        }
        this.navigatorService.StopLoading();
      });
    }
  }

  GetMoreActionFilesDisabled() {
    return this.Model?.Dtos?.filter(t => t.Checked)?.length <= 0;
  }
}
