import { Injectable } from '@angular/core';
import { WhiteLabelService } from './white-label.service';
import { AppConfigService } from './app.config.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  chatBadge = 0;
  showChatBadge = true;

  constructor(
    private authService: AuthService,
    private appConfigService: AppConfigService,
    private whitelabelService: WhiteLabelService
  ) {
    // Listen from chat custom event
    window.addEventListener("updateChatBadge", function(evt) {
      try {
        this.setChatBadge();
      } catch (e) {
        console.log(e);
      }
    }.bind(this), false);
  }

  showChat() {
    const toggleChat = document.getElementById('controlbox');
    const chatboxes = document.getElementById('converse-chatboxes');
    if (toggleChat && chatboxes) {
      toggleChat.hidden = false;
      chatboxes.hidden = false;
      this.showChatBadge = false;
    }
  }

  hideChat() {
    const toggleChat = document.getElementById('controlbox');
    const chatboxes = document.getElementById('converse-chatboxes');
    if (toggleChat && chatboxes) {
      toggleChat.hidden = true;
      chatboxes.hidden = true;
      this.showChatBadge = true;
    }
  }

  setChatSettings() {
    const chatUrl = this.appConfigService.chatUrl.split('https://')[1];
    const settings = {
      show_send_button: true,
      muc_domain: 'conference.' + chatUrl,
      view_mode: 'fullscreen',
      // websocket_url: 'wss://' + chatUrl + '/xmpp-websocket',
      websocket_url: 'wss://' + chatUrl + ':7443/ws',
      auto_login: true,
      auto_away: 600,
      auto_join_on_invite: true,
      auto_subscribe: true,
      auto_reconnect: true,
      clear_cache_on_logout: true,
      muc_nickname_from_jid: true,
      locked_muc_nickname: true,
      allow_non_roster_messaging: true,
      hide_muc_participants: true,
      muc_show_join_leave: false,
      jid: this.authService.CurrentUser.Jid,
      id: this.authService.CurrentUser.OpenfireUsername,
      password: '5ebe2294ecd0e0f08eab7690d2a6ee69',
      nickname: this.authService.CurrentUser.Displayname,
      username: this.authService.CurrentUser.Username,
      vMeetLink: '',
      chatUrl: chatUrl,
      endpoint: this.appConfigService.apiUrl,
      chatEndpoint: this.appConfigService.apiUrl + '/' + this.appConfigService.appId + '/chat',
      logoEndpoint: 'assets/icon/chat.svg',
      discover_connection_methods: false
    };

    return settings;
  }

  logoutChat() {
    const converse = document.getElementById('conversejs');
    if (this.appConfigService.enableChat && converse) {
      const evt = new CustomEvent('ChatLogout');
      window.dispatchEvent(evt);
    }
  }

  setChatBadge() {
    this.chatBadge = Number(sessionStorage.getItem('chatBadge'));
  }
}
