import { Component, Input, OnInit } from '@angular/core';
import { CardDataModel } from '../../../models/CardDataModel';
import { DashboardCardType } from '../../../itdoc.configuration';
import { NavigatorService } from '../../../../navigator.services';


@Component({
  selector: 'app-deliberations-card',
  templateUrl: './deliberations-card.component.html',
  styleUrls: ['./deliberations-card.component.scss']
})
export class DeliberationsCardComponent implements OnInit {
  @Input() CardTitle: string;
  @Input() DatasetModel: CardDataModel[];
  DashboardCardType = DashboardCardType;

  constructor(public navigatorService: NavigatorService) { }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {


  }

}
