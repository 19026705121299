<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <h3 class="dialog-title">{{data.Title}}</h3>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons dialog-icon-close " [mat-dialog-close]="null">close</span>
        </div>
    </div>

    <mat-dialog-content class="tiny-scrollbar">
        <div class="dialog-container-message">
            <div class="icon-container">
                <img src="{{data.Icon}}">
                <p>{{data.Name}}</p>
                <p [innerHTML]="data.Description"></p>
            </div>
            <p [innerHTML]="data.Message"></p>
        </div>

    </mat-dialog-content>


    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button mat-button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{data.CancelBtnLabel.toUpperCase()}}</span>
        </button>
        <button (click)="Confirm()" mat-button class="it-doc-btn-primary" cdkFocusInitial>
            <span class="button-text">{{data.ConfirmBtnLabel.toUpperCase()}}</span>
        </button>
    </div>

</div>