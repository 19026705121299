import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CurrentUserFilter, CurrentUserModel } from '../../../models/CurrentUserModel';
import { UserGroupDto, UserGroupFilter, UserGroupModel } from '../../../models/UserGroupModel';
import { GroupModel } from '../../../models/GroupModel';
import { SettingsController } from '../../../controllers/SettingsController';
import { ItCoreController } from '../../../controllers/ItCoreController';
import { NavigatorService } from '../../../navigator.services';
import { DataService } from '../../../data.service';
import { AuthService } from '../../../auth.service';
import { PageAction } from '../../../itdoc/itdoc.configuration';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  Roles = ['admin', 'worker'];

  Model: CurrentUserModel;
  UserGroupModel: UserGroupModel;
  GroupModel: GroupModel;
  SettingsController: SettingsController;
  ItCoreController: ItCoreController;

  constructor(
    public navigatorService: NavigatorService,
    private dataService: DataService,
    public dialogRef: MatDialogRef<UserComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Model = new CurrentUserModel();
    this.UserGroupModel = new UserGroupModel();
    this.GroupModel = new GroupModel();
    this.SettingsController = new SettingsController(dataService);
    this.ItCoreController = new ItCoreController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.GroupModel = await this.ItCoreController.GetGroup(this.GroupModel);
    if (this.navigatorService.PageAction === PageAction.Edit) {
      this.Model.Filter = new CurrentUserFilter();
      this.Model.Filter.Id = this.data?.Dto?.Id;
      this.Model = await this.SettingsController.ReadUser(this.Model);
      if (this.Model.Performed && this.Model.Dto) {
        this.UserGroupModel = await this.ItCoreController.GetUsersGroup(this.UserGroupModel);
        if (this.UserGroupModel.Performed && this.UserGroupModel.Dtos && this.UserGroupModel.Dtos.length > 0) {
          const adminGroupId = this.GroupModel.Dtos.find(g => g.Name === 'admin').Id;
          const find = this.UserGroupModel.Dtos.find(ug => ug.GroupId === adminGroupId && ug.UserId === this.Model.Dto.Id);
          this.Model.Dto.SettingsRole = find ? 'admin' : 'worker';
        }
      }
    }
  }

  ValidateForm(showMessage = true) {
    const dto = this.Model?.Dto;
    if (!dto?.Name || !dto?.Surname || !dto?.Email || !dto?.SettingsRole) {
      if (showMessage) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ValidatorMessage);
      }
      return false;
    }
    if (dto?.Email) {
      const regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
      if (!regex.test(dto.Email)) {
        return false;
      }
    }
    return true;
  }

  SelectRole(event: MatSelectChange) {
    this.Model.Dto.SettingsRole = event.value;
  }

  DisableRoleSelect() {
    if (this.navigatorService.PageAction === PageAction.Edit) {
      return this.Model.Dto.IsOwner;
    }
    return false;
  }

  async SearchLdap($event) {
    if ($event && $event?.Search?.length > 3) {
      this.Model.Dtos = [];
      this.Model = await this.ItCoreController.SearchUsersLdap($event?.Search);
      if ($event.Callback) {
        $event.Callback(this.Model?.Dtos, $event.Istance);
      }
    }
  }

  LdapUsersChange($event) {
    if ($event) {
      this.Model.Dto = $event;
      this.Model.Dtos = [];
    }
  }

  async Save() {
    this.navigatorService.StartLoading();
    const isValid = this.ValidateForm();
    if (isValid) {
      this.Model.Dto.Type = this.Model.Dto.Type ? this.Model.Dto.Type : 'local';
      if (this.Model.Dto.Type !== 'ldap') {
        this.Model.Dto.Username = this.Model?.Dto?.Email;
      }
      this.Model.Dto.Displayname = this.Model?.Dto?.Name + ' ' + this.Model?.Dto?.Surname;
      this.Model.Dto.Enabled = false;
      this.Model.Dto.Attempt = 0;
      this.Model.Dto.Lock = false;
      this.Model.Dto.Trashed = false;
      this.Model.Dto.Language = this.authService?.CurrentUser?.Language;
      this.Model.Dto.IsOwner = false;
      this.Model = await this.SettingsController.CreateOrUpdateUser(this.Model);

      if (this.navigatorService.PageAction === PageAction.Edit) {
        if (this.Model.Dto.SettingsRole === 'worker') {
          if (this.UserGroupModel.Performed && this.UserGroupModel.Dtos && this.UserGroupModel.Dtos.length > 0) {
            const userGroupDtos = this.UserGroupModel.Dtos.filter(u => u.UserId === this.Model.Dto.Id);
            if (userGroupDtos && userGroupDtos.length > 0) {
              const deleteUsersGroupsModel = new UserGroupModel();
              deleteUsersGroupsModel.Filter = new UserGroupFilter();
              deleteUsersGroupsModel.Filter.Id = userGroupDtos.map(ug => ug.Id);
              await this.ItCoreController.DeleteUsersGroup(deleteUsersGroupsModel);
            }
          }
        }
        if (this.Model.Dto.SettingsRole === 'admin') {
          if (this.GroupModel && this.GroupModel.Dtos) {
            const adminGroupId = this.GroupModel.Dtos.find(g => g.Name === 'admin').Id;
            this.UserGroupModel.Dto = new UserGroupDto();
            this.UserGroupModel.Dto.UserId = this.Model?.Dto?.Id;
            this.UserGroupModel.Dto.GroupId = adminGroupId;
            this.UserGroupModel = await this.ItCoreController.CreateOrUpdateUsersGroup(this.UserGroupModel);
          }
        }
      } else if (this.navigatorService.PageAction === PageAction.New) {
        if (this.Model.Dto.SettingsRole === 'admin' && this.Model.Performed && this.Model.Entity) {
          if (this.GroupModel && this.GroupModel.Dtos) {
            const adminGroupId = this.GroupModel.Dtos.find(g => g.Name === 'admin').Id;
            this.UserGroupModel.Dto = new UserGroupDto();
            this.UserGroupModel.Dto.UserId = this.Model?.Entity?.Id;
            this.UserGroupModel.Dto.GroupId = adminGroupId;
            this.UserGroupModel = await this.ItCoreController.CreateOrUpdateUsersGroup(this.UserGroupModel);
          }
        }
      }
      if (this.Model.Performed) {
        if (this.navigatorService.PageAction === PageAction.Edit) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ToEditSuccess, this.Model.Entity.Displayname);
        }
        this.dialogRef.close(this.Model?.Entity?.Id);
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
      }
    }
    this.navigatorService.StopLoading();
  }

}
