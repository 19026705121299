import { BaseEntity, BaseModel } from '../../models/BaseModel';
import { Children, NodeProperties } from './SystemInfoModel';

export class ViewRepositoryModel extends BaseModel<ViewRepositoryEntity, ViewRepositoryDto, ViewRepositoryFilter> {
  constructor() {
    super();

  }
}

export class ViewRepositoryEntity extends BaseEntity {
  Timestamp: number;
  HasMoreItems: boolean;
  PageNum: number;
  NumberOfElements: number;
  NumberOfPages: number;
  PageSize: number;
  Children: Array<Children>; // pda list
  NodeProperties: NodeProperties;
  FinalPda: boolean;
  Pda: boolean;
  Pec: boolean;


  constructor() {
    super();
  }
}

export class ViewRepositoryDto extends ViewRepositoryEntity {
  Ticket: string;
  NodeRef: string;
  Page: number;
  File: any;
  PdaName: string;
  ArchiveName: string;
  constructor() {
    super();

  }
}

export class ViewRepositoryFilter extends ViewRepositoryDto {
}



