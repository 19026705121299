import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class AccountRoleModel extends BaseModel<AccountRoleEntity, AccountRoleDto, AccountRoleFilter> {
    constructor() {
        super();
    }
}

export class AccountRoleEntity extends BaseEntity {
    UoId: number|null;
    AccountId: any|null;
    RoleId: number|null;
    Start: number|null;
    End: number|null;
    Signatory: boolean;
    constructor() {
        super();
        this.UoId = null;
        this.AccountId = null;
        this.RoleId = null;
        this.Start = null;
        this.End = null;
        this.Signatory = false;
    }
}

export class AccountRoleDto extends AccountRoleEntity {
    constructor() {
        super();
    }
}

export class AccountRoleFilter extends AccountRoleDto {
}
