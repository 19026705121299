<div class="progress-bar-container">
    <ng-container *ngFor="let value of datasetModel; let i = index;">
        <div class="progress-bar bar-{{i}}" 
            [ngStyle]="{
                'width': value.Percentage + '%',
                'background-color':value.PercentageColor,
                'left': CalcLeftValueBar(i) + '%',
                'border-top-left-radius': FirstIndex === i ? '16px' : '0px',
                'border-bottom-left-radius': FirstIndex === i ? '16px' : '0px',
                'border-top-right-radius': LastIndex === i ? '16px' : '0px',
                'border-bottom-right-radius': LastIndex === i ? '16px' : '0px'
            }"></div>
    </ng-container>
</div>
