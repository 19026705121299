<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container ">
          <div class="grid-container">
            <div class="grid-column" *ngIf="authService.CurrentUser.IsAdmin" (click)='Load(1)'
              [ngClass]="Tab===1?'tab-selected':''">
              <h2>{{navigatorService.Dictionary?.UsersAndRoles}}</h2>
            </div>
            <div class="grid-column" (click)='LoadDigitalSignage(2)' [ngClass]="Tab===2?'tab-selected':''">
              <h2>{{navigatorService.Dictionary?.Signature}}</h2>
            </div>
          </div>
        </div>
        <div *ngIf="Tab===1" class="search-create-container">
          <app-search #search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
            [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
            [HaveActions]="false" (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)">
          </app-search>
          <img #m="matMenuTrigger" [matMenuTriggerFor]="moreAction" [src]="AddBtnSrc"
            (mouseover)="AddBtnSrc = '../../../../../assets/icon/add-circle-hover.svg'"
            (mouseout)="AddBtnSrc = '../../../../../assets/icon/add-circle.svg'" class="create-button">
          <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
            <button mat-menu-item
              (click)="AddUser(); $event.stopPropagation();">{{navigatorService.Dictionary?.AddUser}}</button>
            <button *ngIf="LdapEnabled" mat-menu-item
              (click)="ImportFromLdap(); $event.stopPropagation();">{{navigatorService.Dictionary?.ImportFromLdap}}</button>
          </mat-menu>
        </div>
        <div *ngIf="Tab===2" class="search-create-container">
          <img (click)="AddSignatureAccount()" [src]="AddBtnSrc"
            (mouseover)="AddBtnSrc = '../../../../../assets/icon/add-circle-hover.svg'"
            (mouseout)="AddBtnSrc = '../../../../../assets/icon/add-circle.svg'" class="create-button">
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="Tab===1" class="form mt-3">
    <div class="it-doc-table-container">
      <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUserName}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnUserName}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.Displayname}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUserEmail}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnUserEmail}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.Email}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnInvitationStatus}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnInvitationStatus}} </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="!element.IsOwner">
              <span>
                {{GetStatusDictionary(element)}}
              </span>
              <span *ngIf="GetStatus(element) === InvitationState.PENDING" class="ms-2 valign-icon-text clickable-icon"
                (click)='SendInvitation(element)'>
                <span class="material-icons">replay</span>
                <span><b>{{navigatorService.Dictionary?.Resend.toUpperCase()}}</b></span>
              </span>
            </ng-container>
            <span *ngIf="element.IsOwner">-</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnRole}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnRole}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{GetUserRole(element)}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="more-action">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <img src="../../../../../assets/icon/more-vert.svg">
          </th>
          <td mat-cell *matCellDef="let element">
            <img #m="matMenuTrigger" [matMenuTriggerFor]="moreAction" class="clickable-icon"
              src="../../../../../assets/icon/more-vert.svg" [ngClass]="{'open-menu-trigger-color': m.menuOpen}">
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
              <button mat-menu-item (click)='UpdateUser(element)'>{{navigatorService.Dictionary?.Update}}</button>
              <button *ngIf="!element.IsOwner && GetStatus(element) === InvitationState.ACTIVE" mat-menu-item
                (click)='DeactiveUser(element)'>{{navigatorService.Dictionary?.Deactivate}}</button>
              <button mat-menu-item *ngIf="!element.IsOwner"
                (click)="DeleteUser(element); $event.stopPropagation();">{{navigatorService.Dictionary?.Delete}}</button>
              <button *ngIf="!element.IsOwner && GetStatus(element) === InvitationState.DEACTIVE" mat-menu-item
                (click)='SendInvitation(element)'>
                {{navigatorService.Dictionary?.SendInvitation}}
              </button>
              <button *ngIf="!element.IsOwner && GetStatus(element) === InvitationState.PENDING" mat-menu-item
                (click)='SendInvitation(element)'>
                {{navigatorService.Dictionary?.ResendInvitation}}
              </button>

            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <img class="clickable-icon" src="../../../../../assets/icon/filter-icon.svg">
          </th>
          <td mat-cell *matCellDef="let element">

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="[
                    navigatorService.Dictionary?.TableColumnUserName,
                    navigatorService.Dictionary?.TableColumnUserEmail,
                    navigatorService.Dictionary?.TableColumnInvitationStatus,
                    navigatorService.Dictionary?.TableColumnRole,
                    'more-action',
                    'filter']">
        </tr>
        <tr mat-row [ngClass]="{'selected':row.Selected}" *matRowDef="let row;  columns: [
                    navigatorService.Dictionary?.TableColumnUserName,
                    navigatorService.Dictionary?.TableColumnUserEmail,
                    navigatorService.Dictionary?.TableColumnInvitationStatus,
                    navigatorService.Dictionary?.TableColumnRole,
                    'more-action',
                    'filter']" style="position: relative;">
        </tr>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
      </div>
    </div>
    <!-- END TABLE -->
    <div *ngIf="UserModel?.Dtos?.length <= 0 && !navigatorService.Loading" class="m-0 empty-table-box"
      style="padding-bottom: 200px;">
      <img src="../../../../../assets/icon/user-empty.svg">
      <div class="description">{{navigatorService.Dictionary?.EmptyUsersMessage}}</div>
    </div>
  </div>

  <div *ngIf="Tab===2" class="form mt-3">
    <div class="it-doc-table-container">
      <table mat-table [dataSource]="DataSourceSignatureAccount" matSort *ngIf="navigatorService.Dictionary">

        <ng-container matColumnDef="{{navigatorService.Dictionary?.ProfileName}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.ProfileName}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.Identifier}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.Email}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.Email}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.Email}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.Service}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.Service}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.ServiceName}}
            </span>
          </td>
        </ng-container>


        <ng-container matColumnDef="more-action">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <img src="../../../../../assets/icon/more-vert.svg">
          </th>
          <td mat-cell *matCellDef="let element">
            <img #m="matMenuTrigger" [matMenuTriggerFor]="moreAction" class="clickable-icon"
              src="../../../../../assets/icon/more-vert.svg" [ngClass]="{'open-menu-trigger-color': m.menuOpen}">
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
              <button mat-menu-item
                (click)='UpdateSignatureAccount(element);$event.stopPropagation();'>{{navigatorService.Dictionary?.Update}}</button>
              <button mat-menu-item
                (click)="DeleteSignatureAccount(element); $event.stopPropagation();">{{navigatorService.Dictionary?.Delete}}</button>

            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="[
                    navigatorService.Dictionary?.ProfileName,
                    navigatorService.Dictionary?.Email,
                    navigatorService.Dictionary?.Service,
                    'more-action']">
        </tr>
        <tr mat-row [ngClass]="{'selected':row.Selected}" *matRowDef="let row;  columns: [
                navigatorService.Dictionary?.ProfileName,
                navigatorService.Dictionary?.Email,
                navigatorService.Dictionary?.Service,
                'more-action']" style="position: relative;">
        </tr>
      </table>
    </div>

    <!-- END TABLE -->
    <div *ngIf="SignatureAccountModel?.Dtos?.length <= 0 && !navigatorService.Loading" class="m-0 empty-table-box"
      style="padding-bottom: 200px;">
      <img src="../../../../../assets/icon/signature.svg" style="width: 77px; opacity: 0.2;">
      <div class="description">{{navigatorService.Dictionary?.EmptySignatureMessage}}</div>
    </div>
  </div>
</div>
