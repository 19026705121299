<div id="deliberationsCard">
    <div *ngIf="DatasetModel" class="card-container">
        <div class="row" style="margin: 0px 0px 44px 0px;padding-top: 21px;">
            <div class="col-md-12">
                <div class="grid-container d-flex">
                    <div class="grid-column tab-selected">
                        <div class="tab-text">{{navigatorService.Dictionary?.AllDocument}}</div>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-md-12">
                <app-progress-bar-chart [DatasetModel]="DatasetModel"></app-progress-bar-chart>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-md-12">
                <div class="d-flex mt-4">
                    <div *ngFor="let data of DatasetModel; let i = index; let isLast = last;" 
                        class="legend-element-progress-chart"
                        [ngStyle]="{'margin-right': !isLast ? '5px' : '0px'}">
                        <div style="width: 100%;">
                            <div class="d-flex align-items-center text-start">
                                <span class="circle" [ngStyle]="{'background-color': data.PercentageColor}"></span>
                                <div [matTooltip]="data.Name" class="legend-name ms-2">{{data.Name}}</div>
                            </div>
                            <div class="d-flex justify-content-between" style="margin-top: 3px;">
                                <span class="legend-percentage">{{data.Percentage}}% </span>
                            </div>
                            <div class="d-flex justify-content-between" style="margin-top: 3px;">
                                <span class="legend-percentage-late">{{data.PercentageInLate}}% {{navigatorService.Dictionary?.InLate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
