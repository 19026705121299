import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import { ObjectDto, ObjectFilter, ObjectModel } from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { CommonService } from '../../../../common.service';
import { DocumentState } from '../../../itdoc.configuration';
import { BaseOrder } from '../../../../models/BaseModel';


@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;

  CurrentPage = 1;
  DataSource: MatTableDataSource<ObjectDto>;
  CurrentFolder: ObjectDto;
  Model: ObjectModel;
  Controller: ObjectController;
  Documents: Array<ObjectDto>;
  IsUpload = false;
  constructor(
    private dataService: DataService, public navigatorService: NavigatorService,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<DocumentListComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.Model = new ObjectModel();
    this.Documents = data.Documents;
    this.IsUpload = data.IsUpload ?? false;
    this.Controller = new ObjectController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    await this.GetObjects();
  }
  async GetObjects(dto: ObjectDto = null, page = 1) {
    this.navigatorService.StartLoading();
    this.Model.Search = null;
    this.Model.Filter = new ObjectFilter();
    this.Model.Filter.State = [DocumentState.PROTOCOLLED, DocumentState.ARCHIVED];

    this.Model.Search = this.SearchComponent?.GetSearchValue();
    if (!this.Model.Search) {
      if (!dto && !this.Model.Search) {
        this.Model.Filter.Deep = 1;
      } else if (dto && !this.Model.Search) {
        this.Model.Filter.Parent = dto.Id;
      }
    }

    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Id';
    this.Model.Order.Direction = 'desc';
    this.Model = await this.Controller.Load(this.Model);
    this.Paging(page);
    this.navigatorService.StopLoading();
  }


  async ReadObject(parent) {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.Id = parent;
    model = await this.Controller.Read(model);
    return model?.Dto ?? null;
  }

  async Search() {
    this.Model.Skip = 0;
    this.GetObjectSelected();
    await this.GetObjects();
  }

  async ObjectsClick(dto: ObjectDto) {
    if (dto.Type === 'folder') {
      this.navigatorService.StartLoading();
      this.CurrentFolder = dto;
      await this.GetObjects(dto);
      this.navigatorService.StopLoading();
    }
  }

  Confirm() {
    this.navigatorService.StartLoading();
    this.GetObjectSelected();
    this.dialogRef.close(this.Documents);
    this.navigatorService.StopLoading();

  }

  GetObjectSelected() {
    for (const dto of this.Model.Dtos) {
      if (!dto.Checked) {
        const index = this.Documents.findIndex(q => q.Id === dto.Id);
        if (index >= 0) {
          this.Documents.splice(index, 1);
        }
      } else {
        const document = this.Documents.find(q => q.Id === dto.Id);
        if (!document) {
          this.Documents.push(dto);
        }
      }
    }
  }

  CheckFileSize(dto: ObjectDto) {
    if (dto.Type === 'file' && dto.Enabled !== false) {
      let size = Math.round(dto.Size / 1024 / 1024);
      size = parseFloat(size.toFixed(2));
      if (size > 300) {
        dto.Enabled = false;
        dto.EnabledMessage = 'Il file supera il massimo delle dimensioni consentite (max 300MB)';
      } else {
        dto.Enabled = true;
      }
    }
  }

  CheckFileEnabled(dto: ObjectDto) {
    if (dto) {
      const ext = this.commonService.GetExtension(dto.PhysicalName);
      if (ext && dto.Enabled !== false) {
        if (!this.data.FileEnabled.includes(ext.toUpperCase())) {
          dto.Enabled = false;
          dto.EnabledMessage = 'Formato non idoneo per la conservazione della classe documentale selezionata';
        }
        else {
          dto.Enabled = true;
        }
      }
    }
  }

  // #region paging
  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    let folder = this.CurrentFolder;
    if (this.CurrentFolder?.Deep > 1) {
      this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
      folder = this.CurrentFolder;
    } else {
      this.CurrentFolder = null;
      folder = null;
    }
    await this.GetObjects(folder, this.CurrentPage);
    this.navigatorService.StopLoading();
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page, false);
    this.RefreshTable(this.Model.Dtos);
  }

  async PagingChange(page) {
    this.GetObjectSelected();
    this.navigatorService.StartLoading();
    await this.GetObjects(this.CurrentFolder, page);
    this.navigatorService.StopLoading();
  }

  RefreshTable(dtos: Array<ObjectDto>) {
    for (const dto of dtos) {
      this.commonService.GetIcon(dto);
      const extist = this.Documents.find(q => q.Id === dto.Id) ? true : false;
      dto.Checked = extist;
      dto.Enabled = !(this.IsUpload && extist);
      this.CheckFileSize(dto);
      this.CheckFileEnabled(dto);
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }

  // #endregion
}
