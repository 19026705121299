import { AuthService } from '../auth.service';
import { ChatService } from '../chat.service';

export class LoginController {
  constructor(
    private authService: AuthService,
    private chatService: ChatService
  ) { }

  async Login(username: string, password: string) {
    let response = null;
    if (username && password) {
      response = await this.authService.Login(username, password);
      if (response && response.Performed) {
        this.authService.CurrentUser = response.Dto;
      }
    }
    return response;
  }

  async Logout() {
    const signout = await this.authService.SignOutDocFly();
    const response = await this.authService.Logout();
    sessionStorage.clear();
    localStorage.clear();
    this.chatService.logoutChat();
    return response;
  }

  async SendLinkResetPassword(mail: string){
    const response = await this.authService.SendLinkResetPassword(mail);
    return response;
  }
}
