import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class FieldsModel extends BaseModel<FieldsEntity, FieldsDto, FieldsFilter> {
    constructor() {
        super();
    }
}

export class FieldsEntity extends BaseEntity {
    Name?: string;
    Type?: string;
    Properties: string;
    Default: boolean;
    constructor() {
        super();
        this.Name = null;
        this.Type = null;
        this.Properties = null;
        this.Default = false;
    }
}

export class FieldsDto extends FieldsEntity {
    PropertiesJson: PropertyDto;
    constructor() {
        super();
        this.PropertiesJson = null;
    }
}

export class FieldsFilter extends FieldsDto {
}

export class PropertyDto {
    Placeholder: string;
    Lenght: number;
    Multiple: boolean;
    Items: Array<FieldItem>;
    Checked: boolean;
    Required: boolean;
    Default: boolean;
    Token: string;
    Removable: boolean;
    constructor() {
        this.Placeholder = null;
        this.Lenght = null;
        this.Multiple = false;
        this.Items = new Array<FieldItem>();
        this.Checked = false;
        this.Required = false;
        this.Default = false;
        this.Token = null;
        this.Removable = true;
    }
}

export class FieldItem {
    Id: number;
    Value: string;
    Checked: boolean;
    constructor() {
        this.Id = 0;
        this.Value = null;
        this.Checked = false;
    }
}
