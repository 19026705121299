<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <span class="material-icons-outlined">
                        account_circle
                    </span>
                    <h2 *ngIf="Step===1">{{navigatorService.Dictionary?.Edit + " " +
                        navigatorService.Dictionary?.TitleInfo1}}</h2>
                    <h2 *ngIf="Step===2">{{navigatorService.Dictionary?.Edit + " " +
                        navigatorService.Dictionary?.TitleInfo2}}</h2>
                        <h2 *ngIf="Step===3">{{navigatorService.Dictionary?.TitleInfo4}}</h2>
                </div>

            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <div *ngIf="Step===1">
        <p class="title-description">{{navigatorService.Dictionary?.EditInfoDescription1}}</p>
        <mat-dialog-content>
            <div class="form mb-5">
                <div class="row mb-5">
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'Name'" [Type]="'text'" [Required]='true'
                            [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.Name">
                        </app-input>
                        <span class="error">{{ErrorMessageName}}</span>
                    </div>
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'Surname'" [Type]="'text'" [Required]='true'
                            [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.Surname">
                        </app-input>
                        <span class="error">{{ErrorMessageSurname}}</span>

                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-6 col-sx-12">
                        <app-picker [Placeholder]="navigatorService.Dictionary?.Birthdate" [Property]="'Birthdate'"
                            [DataValue]='Dto'>
                        </app-picker>
                    </div>
                    <div class="col-md-6 col-sx-12">
                        <app-select [Placeholder]="navigatorService.Dictionary?.Language" [Required]='true'
                            [Items]="Languages" (OnChange)="SelectLanguage($event)" [DataValue]='LanguageDto?.Id'
                            [Property]="'DisplayValue'">
                        </app-select>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div *ngIf="Step===2">
        <p class="title-description">{{navigatorService.Dictionary?.EditInfoDescription2}}</p>
        <mat-dialog-content>
            <div class="form mb-5">
                <div class="row mb-5">
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'Password'" [Type]="'text'" [Required]='false'
                            [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.NewPassword"
                            [Disabled]="Dto.Type==='ldap'">
                        </app-input>
                    </div>
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'ConfirmPassword'" [Type]="'text'"
                            [Required]='Dto.Password!==CacheDto.Password' [Maxlength]="255"
                            [Placeholder]="navigatorService.Dictionary?.ConfirmPassword"
                            [Disabled]="Dto.Type==='ldap' || this.Dto.Password?.length <= 0 || !(Dto.Password!==CacheDto.Password)">
                        </app-input>
                        <span class="error">{{ErrorMessagePassword}}</span>
                    </div>
                </div>
                <p class="title-description">{{navigatorService.Dictionary?.EditInfoDescription1}}</p>
                <div class="row mb-5">
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'Email'" [Type]="'text'" [Required]='true'
                            [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.Email"
                            [Regex]="'[a-zA-Z0-9.-_]+@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,10}$'">
                        </app-input>
                    </div>
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'ConfirmMail'" [Type]="'text'"
                            [Required]='Dto.Email!==CacheDto.Email' [Maxlength]="255"
                            [Placeholder]="navigatorService.Dictionary?.ConfirmMail"
                            [Disabled]='!(Dto.Email!==CacheDto.Email)'>
                        </app-input>
                        <span class="error">{{ErrorMessageEmail}}</span>

                    </div>

                </div>
                <div class="row mb-5">
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'Phone'" [Type]="'text'"
                            [Required]='(Dto.Phone!==CacheDto.Phone)' [Maxlength]="255"
                            [Placeholder]="navigatorService.Dictionary?.Phone">
                        </app-input>
                    </div>
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'ConfirmPhone'" [Type]="'text'"
                            [Required]='CacheDto.Phone !== Dto.Phone' [Maxlength]="255"
                            [Placeholder]="navigatorService.Dictionary?.ConfirmPhone"
                            [Disabled]="!(CacheDto.Phone !== Dto.Phone)">
                        </app-input>
                        <span class="error">{{ErrorMessagePhone}}</span>

                    </div>

                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div *ngIf="Step===3">
        <p class="title-description">{{navigatorService.Dictionary?.EditInfoDescription3}}</p>
        <mat-dialog-content>
            <div class="form mb-5">
                <div *ngIf="Dto?.IsSetEncryptionPassword" class="row mb-5">
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='Dto' [Property]="'EncryptionPassword'" [Type]="'text'" [Required]='false'
                            [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.CurrentPassword">
                        </app-input>
                        <span class="error">{{ErrorMessageEncryptionPassword}}</span>
                    </div>
                    <div class="col-md-6 col-sx-12">
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='CacheDto' [Property]="'EncryptionPassword'" [Type]="'text'" [Required]='false'
                            [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.NewPassword">
                        </app-input>
                        <span *ngIf="this.CacheDto.EncryptionPassword?.length < 6" class="error">{{ErrorMessagePassword}}</span>
                    </div>
                    <div class="col-md-6 col-sx-12">
                        <app-input [DataValue]='CacheDto' [Property]="'ConfirmEncryptionPassword'" [Type]="'text'"
                            [Required]='Dto.Password!==CacheDto.Password' [Maxlength]="255"
                            [Placeholder]="navigatorService.Dictionary?.ConfirmPassword"
                            [Disabled]="this.CacheDto.EncryptionPassword?.length <= 0 || !(CacheDto.EncryptionPassword!==CacheDto.ConfirmEncryptionPassword)">
                        </app-input>
                        <span *ngIf="this.CacheDto.EncryptionPassword?.length >= 6" class="error">{{ErrorMessagePassword}}</span>
                    </div>
                </div>

            </div>
        </mat-dialog-content>
    </div>
    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel.toUpperCase()}}</span>
        </button>
        <button (click)="Save()" mat-button class="it-doc-btn-primary" [disabled]="!FormValidation()">
            <span class="button-text">{{navigatorService.Dictionary?.Save.toUpperCase()}}</span>
        </button>
    </div>
</div>
