<div class="row">
    <div class="col-11">
        <mat-progress-bar mode="determinate" value="{{Percentage??0}}" class="progressbar">
        </mat-progress-bar>
        <p *ngIf="Error" style="color: red;position: relative;top:5px">
            {{Error}}</p>
    </div>
    <div class="col-1">
        <span>{{Percentage ?? 0}}%</span>
    </div>
</div>