import { BaseEntity, BaseModel } from '../../models/BaseModel';

export class StatisticsModel extends BaseModel<StatisticsEntity, StatisticsDto, StatisticsFilter> {
    constructor() {
        super();
    }
}

export class StatisticsEntity extends BaseEntity {
    AccountId: number;
    // Your request card data (Worker + Admin)
    YourInProgress: number;
    YourInProgressInLate: number;
    YourInRevision: number; // status in revision + in edit
    YourInRevisionInLate: number; // status in revision + in edit
    YourInApproval: number;
    YourInApprovalInLate: number;
    YourApproved: number;
    YourApprovedInLate: number;
    YourProtocolled: number;
    YourProtocolledInLate: number;
    YourArchived: number;
    YourArchivedInLate: number;
    YourRejected: number;
    YourRejectedInLate: number;
    // Documents card (Worker + Admin)
    YourDocsInRevisionCount: number;
    YourDocsInRevisionInTime: number;
    YourDocsInRevisionInLate: number;
    YourDocsInApprovalCount: number;
    YourDocsInApprovalInTime: number;
    YourDocsInApprovalInLate: number;
    YourDocsApprovedCount: number;
    YourDocsApprovedInTime: number;
    YourDocsApprovedInLate: number;
    YourDocsArchivedCount: number;
    YourDocsArchivedInTime: number;
    YourDocsArchivedInLate: number;
    // Total request card data (Admin only)
    AllInProgress: number;
    AllInProgressInLate: number;
    AllInRevision: number; // status in revision + in edit
    AllInRevisionInLate: number; // status in revision + in edit
    AllInApproval: number;
    AllInApprovalInLate: number;
    AllApproved: number;
    AllApprovedInLate: number;
    AllProtocolled: number;
    AllProtocolledInLate: number;
    AllArchived: number;
    AllArchivedInLate: number;
    AllRejected: number;
    AllRejectedInLate: number;
    // Protocols and Dossiers card (Admin only)
    AllProtocolsInput: number;
    AllProtocolsInputInLate: number;
    AllProtocolsOutput: number;
    AllProtocolsOutputInLate: number;
    AllDossiersOpen: number;
    AllDossiersArchived: number;
    constructor() {
        super();
        this.YourInProgress = 0;
        this.YourInProgressInLate = 0;
        this.YourInRevision = 0;
        this.YourInRevisionInLate = 0;
        this.YourInApproval = 0;
        this.YourInApprovalInLate = 0;
        this.YourApproved = 0;
        this.YourApprovedInLate = 0;
        this.YourProtocolled = 0;
        this.YourProtocolledInLate = 0;
        this.YourArchived = 0;
        this.YourArchivedInLate = 0;
        this.YourRejected = 0;
        this.YourRejectedInLate = 0;
        this.YourDocsInRevisionCount = 0;
        this.YourDocsInRevisionInTime = 0;
        this.YourDocsInRevisionInLate = 0;
        this.YourDocsInApprovalCount = 0;
        this.YourDocsInApprovalInTime = 0;
        this.YourDocsInApprovalInLate = 0;
        this.YourDocsApprovedCount = 0;
        this.YourDocsApprovedInTime = 0;
        this.YourDocsApprovedInLate = 0;
        this.YourDocsArchivedCount = 0;
        this.YourDocsArchivedInTime = 0;
        this.YourDocsArchivedInLate = 0;
        this.AllInProgress = 0;
        this.AllInProgressInLate = 0;
        this.AllInRevision = 0;
        this.AllInRevisionInLate = 0;
        this.AllInApproval = 0;
        this.AllInApprovalInLate = 0;
        this.AllApproved = 0;
        this.AllApprovedInLate = 0;
        this.AllProtocolled = 0;
        this.AllProtocolledInLate = 0;
        this.AllArchived = 0;
        this.AllArchivedInLate = 0;
        this.AllRejected = 0;
        this.AllRejectedInLate = 0;
        this.AllProtocolsInput = 0;
        this.AllProtocolsInputInLate = 0;
        this.AllProtocolsOutput = 0;
        this.AllProtocolsOutputInLate = 0;
        this.AllDossiersOpen = 0;
        this.AllDossiersArchived = 0;
    }
}

export class StatisticsDto extends StatisticsEntity {
    constructor() {
        super();
    }
}

export class StatisticsFilter extends StatisticsDto {
}
