import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class PermissionModel extends BaseModel<PermissionEntity, PermissionDto, PermissionFilter> {
    constructor() {
        super();
    }
}

export class PermissionEntity extends BaseEntity {
    MetadataTipologyId?: number;
    TipologyId?: number;
    ObjectId?: number;
    AccountId?: number;
    constructor() {
        super();
        this.MetadataTipologyId = null;
        this.TipologyId = null;
        this.ObjectId = null;
        this.AccountId = null;
    }
}

export class PermissionDto extends PermissionEntity {
    constructor() {
        super();
    }
}

export class PermissionFilter extends PermissionDto {
}
