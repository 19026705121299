import { MetadataTipologyValueModel } from '../models/MetadataTipologyValueModel';
import { DataService } from '../../data.service';

export class MetadataTipologyValueValueController {


    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
        let response = model;
        if (model && model.Dto) {
            response = await this.dataService.CreateOrUpdateMetadataTipologyValue(model);
        }
        return response;
    }

    async Read(model: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
        const response = await this.dataService.ReadMetadataTipologyValue(model);
        return response;
    }

    async Get(model: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
        const response = await this.dataService.GetMetadataTipologyValue(model);
        return response;
    }

    async Load(model: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
        const response = await this.dataService.LoadMetadataTipologyValue(model);
        return response;
    }

    async Delete(model: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
        let response = model;
        if (model && model.Dto) {
            response = await this.dataService.DeleteMetadataTipologyValue(model);
        }
        return response;
    }

}
