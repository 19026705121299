import { DataService } from '../data.service';
import { SettingsModel } from '../itdoc/models/SettingsModel';

export class AppConfigController {
    constructor(private dataService: DataService) { }
    
    async Get(model: SettingsModel): Promise<SettingsModel> {
        return await this.dataService.GetAppSettings(model);
    }
}
