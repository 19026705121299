import { ActivityModel } from '../models/ActivityModel';
import { DataService } from '../../data.service';

export class ActivityController {

    constructor(private dataService: DataService) { }

    async Load(model: ActivityModel): Promise<ActivityModel> {
        const response = await this.dataService.LoadActivity(model);
        return response;
    }

    async Get(model: ActivityModel): Promise<ActivityModel> {
        const response =  await this.dataService.GetActivity(model);
        return response;
    }
}
