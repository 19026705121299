import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConservationLoginComponent } from '../../dialog-menu/conservation-login/conservation-login.component';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import { ArchivesInfo, SystemInfoDto, SystemInfoModel } from '../../../models/SystemInfoModel';
import { DocFlyController } from '../../../controllers/DocFlyController';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { DataService } from '../../../../data.service';
import { Pages } from '../../../itdoc.configuration';

@Component({
  selector: 'app-conservation',
  templateUrl: './conservation-view.component.html',
  styleUrls: ['./conservation-view.component.scss']
})
export class ConservationViewComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;
  Tab = 1;
  Model: SystemInfoModel;
  DataSource: MatTableDataSource<ArchivesInfo>;
  Controller: DocFlyController;
  constructor(
    dataService: DataService, public navigatorService: NavigatorService, public authenticationService: AuthService,
    public commonService: CommonService) {
    commonService.CurrentPageComponent = this;
    this.Model = new SystemInfoModel();
    this.Model.Dto = new SystemInfoDto();
    this.Controller = new DocFlyController(dataService);
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load() {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.navigatorService.CurrentPage = this.commonService.GetMenuItem(Pages.Conservation, this.navigatorService)?.Parent;
    if (!this.authenticationService.IsAuthenticatedDocFly()) {
      const autoLogin = await this.AutoLogin();
      if (!autoLogin) {
        this.navigatorService.ShowDialog(ConservationLoginComponent, null, '300px', '330px', '330px', async (model) => {
          if (model?.Performed && model?.Code === 200) {
            this.Model.Dto.Ticket = model.Dto.Ticket;
            this.Model.Dto.Username = model.Dto.Username;
            this.Model.Dto.Page = 1;
            this.authenticationService.DocflyUser = {
              Username: model.Dto.Username,
              Ticket: model.Dto.Ticket
            };
            await this.GetArchives();
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.Model.Dto.Page = 1;
        this.authenticationService.DocflyUser = {
          Username: this.Model.Dto.Username,
          Ticket: this.Model.Dto.Ticket
        };
        await this.GetArchives();
      }
    } else {
      this.Model.Dto.Ticket = this.authenticationService.DocflyUser.Ticket;
      this.Model.Dto.Username = this.authenticationService.DocflyUser.Username;
      this.Model.Dto.Page = 1;
      await this.GetArchives();
    }
    this.navigatorService.StopLoading();
  }

  async AutoLogin() {
    const model = await this.Controller.Login(null, null, false, true);
    if (model?.Performed && model?.Code === 200) {
      this.Model.Dto.Ticket = model.Dto.Ticket;
      this.Model.Dto.Username = model.Dto.Username;
      return true;
    }
    return false;
  }

  async GetArchives() {
    this.Model = await this.Controller.GetArchivesInfo(this.Model);
    if (this.Model?.Code === 500) {
      // this.Model.Message.includes('Token valido inesistente');
      this.Model.Dto = new SystemInfoDto();
      this.authenticationService.DocflyUser = null;
      this.Load();
      this.RefreshTable([]);
    } else {
      this.RefreshTable(this.Model.Dto?.ArchivesInfo);
    }
  }

  ArchiveClick(archive: ArchivesInfo) {
    this.navigatorService.SystemInfoModel = this.Model;
    this.commonService.SidebarMenuClick(Pages.ViewPda, this.navigatorService, { queryParams: { node: archive.NodeRef }, queryParamsHandling: 'merge' });
  }

  RefreshTable(dtos: Array<ArchivesInfo>) {
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ArchivesInfo>(dtos);
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;

    this.navigatorService.StopLoading();
  }

  CreatePda(archive: ArchivesInfo) {
    this.navigatorService.SystemInfoModel = this.Model;
    this.commonService.SidebarMenuClick(Pages.Pda, this.navigatorService, { queryParams: { node: archive.NodeRef }, queryParamsHandling: 'merge' });
  }
}
