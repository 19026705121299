import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../../data.service';
import { DialogMenuComponent } from '../dialog-menu.component';
import { NavigationExtras } from '@angular/router';
import { IDialogComponent } from '../../../Interface/itdoc.dialog.interface';
import { TipologyDto, TipologyFilter, TipologyModel } from '../../../models/TipologyModel';
import { TipologyController } from '../../../controllers/TipologyController';
import { OrganizationUnitDto, OrganizationUnitModel } from '../../../models/OrganizationUnitModel';
import { MimeTypeDto, MimeTypeModel } from '../../../models/MimeTypeModel';
import { FieldItem, FieldsDto, FieldsModel } from '../../../models/FieldsModel';
import { MetadataTipologyDto, MetadataTipologyFilter, MetadataTipologyModel } from '../../../models/MetadataTipologyModel';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import { MimeTypeController } from '../../../controllers/MimeTypeController';
import { FieldsController } from '../../../controllers/FieldsController';
import { ItCoreController } from '../../../../controllers/ItCoreController';
import { TemplateDto, TemplateFilter, TemplateModel } from '../../../models/TemplateModel';
import { AccountDto, AccountFilter, AccountModel } from '../../../models/AccountModel';
import { TemplateController } from '../../../controllers/TemplateController';
import { AccountController } from '../../../controllers/AccountController';
import { MetaDataTipologyController } from '../../../controllers/MetaDataTipologyController';
import { PermissionDto, PermissionFilter, PermissionModel } from '../../../models/PermissionModel';
import { PermissionController } from '../../../controllers/PermissionController';
import { FileDto, FileModel } from '../../../models/FileModel';
import { FileManagerController } from '../../../controllers/FileManagerController';
import { ObjectController } from '../../../controllers/ObjectController';
import { TipologyUoDto, TipologyUoModel } from '../../../models/TipologyUoModel';
import { TipologyUoController } from '../../../controllers/TipologyUoController';
import { AutocompleteFilterUiComponent } from '../../../custom-components/autocomplete-ui/autocomplete-ui.component';
import { ChipsMultiselectComponent } from '../../../custom-components/chips-multiselect/chips-multiselect.component';
import { CommonService } from '../../../../common.service';
import { NavigatorService } from '../../../../navigator.services';
import { PageAction, Pages } from '../../../itdoc.configuration';
import { ObjectDto, ObjectFilter, ObjectModel } from '../../../models/ObjectModel';

@Component({
  selector: 'app-tipology',
  templateUrl: './tipology.component.html',
  styleUrls: ['./tipology.component.scss']
})
export class TipologyComponent implements OnInit, IDialogComponent<TipologyModel, TipologyController> {
  Step = 1;
  Steps = 3;
  Model: TipologyModel;
  OrganizationUnitModel: OrganizationUnitModel;
  MimeTypeModel: MimeTypeModel;
  FieldsModel: FieldsModel;
  FieldsDefaultModel: FieldsModel;
  MetaDataTipologyModel: MetadataTipologyModel;
  Controller: TipologyController;
  OrganizationUnitController: OrganizationUnitController;
  MimeTypeController: MimeTypeController;
  FieldsController: FieldsController;
  ItCoreController: ItCoreController;
  PersonalizeFields = false;
  CurrentIndexField: number;
  CurrentField: MetadataTipologyDto;
  MetadataToDelete: Array<MetadataTipologyDto>;
  Option: string;
  OptionEdit: FieldItem;
  AccountModel: AccountModel;
  TemplateModel: TemplateModel;
  TemplateController: TemplateController;
  AccountController: AccountController;
  MetaDataTipologyController: MetaDataTipologyController;
  PermissionsModel: PermissionModel;
  PermissionsController: PermissionController;
  FileModel: FileModel;
  FileManagerController: FileManagerController;
  ObjectController: ObjectController;
  SelectedGenericFieldsDtos: FieldsDto[];
  SelectedDefaultFieldsDtos: FieldsDto[];
  TipologyUoToDelete: Array<TipologyUoDto>;
  TipologyUoController: TipologyUoController;
  SelectedMimetypesDtos: MimeTypeDto[];
  @ViewChild('autocompleteUser') AutoCompleteUser: AutocompleteFilterUiComponent;
  @ViewChild('uoMultiselect') UoMultiselect: ChipsMultiselectComponent;

  constructor(
    dataService: DataService, public navigatorService: NavigatorService, private commonService: CommonService,
    public dialogRef: MatDialogRef<TipologyComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.Model = new TipologyModel();
    this.Model.Dto = new TipologyDto();
    this.OrganizationUnitModel = new OrganizationUnitModel();
    this.OrganizationUnitModel.Dto = new OrganizationUnitDto();
    this.FieldsModel = new FieldsModel();
    this.MetaDataTipologyModel = new MetadataTipologyModel();
    this.MimeTypeModel = new MimeTypeModel();
    this.FieldsDefaultModel = new FieldsModel();
    this.CurrentIndexField = 0;
    this.MetadataToDelete = new Array<MetadataTipologyDto>();
    this.AccountModel = new AccountModel();
    this.TemplateModel = new TemplateModel();
    this.TemplateModel.Dto = new TemplateDto();
    this.PermissionsModel = new PermissionModel();
    this.FileModel = new FileModel();
    this.FileModel.Dto = new FileDto();
    this.OptionEdit = null;
    this.SelectedGenericFieldsDtos = new Array<FieldsDto>();
    this.SelectedDefaultFieldsDtos = new Array<FieldsDto>();
    this.SelectedMimetypesDtos = new Array<MimeTypeDto>();
    this.TipologyUoToDelete = new Array<TipologyUoDto>();

    this.TemplateController = new TemplateController(dataService);
    this.MimeTypeController = new MimeTypeController(dataService);
    this.Controller = new TipologyController(dataService);
    this.OrganizationUnitController = new OrganizationUnitController(dataService);
    this.FieldsController = new FieldsController(dataService);
    this.ItCoreController = new ItCoreController(dataService);
    this.AccountController = new AccountController(dataService);
    this.MetaDataTipologyController = new MetaDataTipologyController(dataService);
    this.PermissionsController = new PermissionController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
    this.ObjectController = new ObjectController(dataService);
    this.TipologyUoController = new TipologyUoController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    this.OrganizationUnitModel = await this.OrganizationUnitController.Get(this.OrganizationUnitModel);
    this.MimeTypeModel = await this.MimeTypeController.Get(this.MimeTypeModel);
    this.FieldsModel = await this.FieldsController.Get(this.FieldsModel);
    if (this.FieldsModel.Performed) {
      this.FieldsModel?.Dtos?.map(x => x.PropertiesJson = JSON.parse(x.Properties));
      const fieldsDefault = this.FieldsModel?.Dtos?.filter(x => x.Default);
      this.FieldsDefaultModel?.Dtos?.push(...fieldsDefault);
      this.FieldsModel.Dtos = this.FieldsModel?.Dtos?.filter(n => !this.FieldsDefaultModel?.Dtos?.includes(n));
    }
    const preSetFields = this.FieldsDefaultModel?.Dtos?.filter(f => f.PropertiesJson?.Checked);

    for (const field of preSetFields) {
      this.SelectDefaultFields(field);
    }

    if (this.navigatorService.PageAction === PageAction.Edit) {
      this.Model.Filter = new TipologyFilter();
      this.Model.Filter.Id = this.data;
      this.Model = await this.Controller.Read(this.Model);

      this.AccountModel.Filter = new AccountFilter();
      this.AccountModel.Filter.UoIds = this.Model.Dto.TipologyUos?.map(t => t.UoId);
      this.AccountModel = await this.AccountController.Get(this.AccountModel);

      this.TemplateModel.Filter = new TemplateFilter();
      this.TemplateModel.Filter.TipologyId = this.data;
      this.TemplateModel = await this.TemplateController.GetFirst(this.TemplateModel);

      this.MetaDataTipologyModel.Filter = new MetadataTipologyFilter();
      this.MetaDataTipologyModel.Filter.TipologyId = this.data;
      this.MetaDataTipologyModel = await this.MetaDataTipologyController.Get(this.MetaDataTipologyModel);

      this.PermissionsModel.Filter = new PermissionFilter();
      this.PermissionsModel.Filter.TipologyId = this.data;
      this.PermissionsModel = await this.PermissionsController.Get(this.PermissionsModel);
      this.MetaDataTipologyModel.Dtos.map(x => x.PropertiesJson = (x.Properties ? JSON.parse(x.Properties) : null));
      for (const dto of this.MetaDataTipologyModel.Dtos) {
        // set generic fields
        const indexGenericField = this.FieldsModel.Dtos.findIndex(x => x.Id === dto.FieldId);
        if (indexGenericField >= 0) {
          const exist = this.SelectedGenericFieldsDtos.find(x => x.Id === dto.FieldId);
          if (!exist) {
            this.SelectedGenericFieldsDtos.push(this.FieldsModel.Dtos[indexGenericField]);
          }
        }
        // set default fields
        const indexDefaultField = this.FieldsDefaultModel.Dtos.findIndex(x => x.Id === dto.FieldId);
        if (indexDefaultField >= 0) {
          const exist = this.SelectedDefaultFieldsDtos.find(x => x.Id === dto.FieldId);
          if (!exist) {
            this.SelectedDefaultFieldsDtos.push(this.FieldsDefaultModel.Dtos[indexDefaultField]);
          }
        }
        dto.Permissions = this.PermissionsModel.Dtos.filter(x => x.MetadataTipologyId === dto.Id);
      }


      if (this.Model.Dto.MimeTypes) {
        const mimetipesArray = this.Model.Dto.MimeTypes.split(',');
        this.Model.Dto.MimeTypesIds = this.MimeTypeModel.Dtos.filter(x => mimetipesArray.includes(x.MimeType)).map(e => e.Id);
        // set mimetype
        const selected = [];
        for (const dto of this.MimeTypeModel.Dtos) {
          if (this.Model.Dto.MimeTypesIds.find(x => dto.Id === x)) {
            selected.push(dto);
          }
        }
        this.SelectedMimetypesDtos = selected;
      }
    }

    this.navigatorService.StopLoading();
  }

  SelectUO($event) {
    if ($event) {
      if (!this.Model.Dto.TipologyUos) {
        this.Model.Dto.TipologyUos = new Array<TipologyUoDto>();
      }
      if (this.Model.Dto?.Id > 0) {
        const dtoFinded = this.Model.Dto.TipologyUos.find(tuo => tuo.UoId === $event.Id);
        if (dtoFinded) {
          dtoFinded.Trashed = false;
        } else {
          const dto = new TipologyUoDto();
          dto.UoId = $event.Id;
          dto.UoName = $event.Name;
          dto.UoFolderName = $event.FolderName;
          this.Model.Dto.TipologyUos.push(dto);
        }
      } else {
        const dto = new TipologyUoDto();
        dto.UoId = $event.Id;
        dto.UoName = $event.Name;
        dto.UoFolderName = $event.FolderName;
        this.Model.Dto.TipologyUos.push(dto);
      }
    }
  }

  RemoveUo($event) {
    if ($event) {
      const dto = this.Model.Dto.TipologyUos.find(tuo => tuo.UoId === $event.Id);
      if (dto?.Id > 0) {
        dto.Trashed = true;
        this.TipologyUoToDelete.push(dto);
      } else {
        const index = this.Model.Dto.TipologyUos.findIndex(tuo => tuo.UoId === $event.Id);
        if (index >= 0) {
          this.Model.Dto.TipologyUos.splice(index, 1);
        }
      }
    }
  }

  GetUoSelected() {
    if (this.Model.Dto.TipologyUos) {
      const uoIds = this.Model.Dto.TipologyUos.filter(x => !x.Trashed)?.map(tuo => tuo.UoId);
      return this.OrganizationUnitModel.Dtos.filter(uo => uoIds.includes(uo.Id));
    }
    return null;
  }

  async GetAccountUo(uoIds: Array<number>) {
    this.AccountModel = new AccountModel();
    if (uoIds && uoIds?.length > 0) {
      this.AccountModel.Filter = new AccountFilter();
      this.AccountModel.Filter.UoIds = uoIds;
      this.AccountModel = await this.AccountController.Get(this.AccountModel);
      if (this.AccountModel?.Performed) {
        this.AccountModel = this.commonService.RemoveDuplicate(this.AccountModel);
      }
    }
    // this.AddDefaultAccount();
  }

  // SelectFormaFiles($event, tipologyDto: TipologyDto) {
  //   if ($event) {
  //     const exist = tipologyDto?.MimeTypesIds?.includes($event.Id);
  //     if (!exist) {
  //       tipologyDto?.MimeTypesIds?.push($event.Id);
  //     } else {
  //       const index = tipologyDto?.MimeTypesIds?.findIndex(x => x === $event.Id);
  //       tipologyDto?.MimeTypesIds?.splice(index, 1);
  //     }
  //   }
  // }

  SelectMimeTypes(mimetype: MimeTypeDto) {
    if (mimetype) {
      const tipologyDto = this.Model?.Dto;
      tipologyDto?.MimeTypesIds?.push(mimetype.Id);
      this.SelectedMimetypesDtos.push(mimetype);
    }
  }

  RemoveMimeTypesChips(mimetypeDeleted: MimeTypeDto) {
    // remove from model
    const tipologyDto = this.Model?.Dto;
    const index = tipologyDto?.MimeTypesIds?.indexOf(mimetypeDeleted.Id);
    if (index !== -1) {
      tipologyDto?.MimeTypesIds?.splice(index, 1);
    }
    // remove from chips view
    const mimetypeIndex = this.SelectedMimetypesDtos?.findIndex(x => x.Id === mimetypeDeleted.Id);
    if (mimetypeIndex >= 0) {
      this.SelectedMimetypesDtos?.splice(mimetypeIndex, 1);
    }
  }

  async Save() {
    this.navigatorService.StartLoading();
    this.Model.Performed = false;
    if (this.Model?.Dto?.MimeTypesIds) {
      const mimeTypesDtos = this.MimeTypeModel.Dtos.filter(x => this.Model.Dto.MimeTypesIds.includes(x.Id));
      if (mimeTypesDtos && mimeTypesDtos.length > 0) {
        this.Model.Dto.MimeTypes = mimeTypesDtos.map(x => x.MimeType).toString();
      }
    }
    if (this.TipologyUoToDelete?.length > 0) {
      let tipologyUoToDeleteModel = new TipologyUoModel();
      tipologyUoToDeleteModel.Dtos = this.TipologyUoToDelete;
      tipologyUoToDeleteModel = await this.TipologyUoController.CreateOrUpdate(tipologyUoToDeleteModel);
    }
    this.Model = await this.Controller.CreateOrUpdate(this.Model);
    if (this.Model && this.Model.Performed && this.Model.Code !== 409) {
      let performed = await this.CreateFolderObjectTipology(this.Model);
      if (performed) {
        performed = await this.DeleteMetadata();
        performed = await this.DeletePermission();
        if (performed) {
          this.MetaDataTipologyModel.Dtos.map(x => x.TipologyId = this.Model.Entity.Id);
          this.MetaDataTipologyModel.Dtos.map(x => x.Properties = (x.PropertiesJson ? JSON.stringify(x.PropertiesJson) : null));
          this.MetaDataTipologyModel = await this.MetaDataTipologyController.CreateOrUpdate(this.MetaDataTipologyModel);
          if (this.MetaDataTipologyModel.Performed) {
            if (this.FileModel.Dto.File) {
              this.UploadTemplate(async (result) => {
                if (performed) {
                  this.TemplateModel.Dto.TipologyId = this.Model.Entity.Id;
                  this.TemplateModel = await this.TemplateController.CreateOrUpdate(this.TemplateModel);
                  performed = this.TemplateModel.Performed;
                  if (performed) {
                    const message = this.navigatorService.PageAction === PageAction.New ? this.navigatorService.Dictionary?.ToCreateSuccessF
                      : this.navigatorService.Dictionary?.ToEditSuccessF;
                    this.navigatorService.ShowSnackBar(message, this.Model.Dto?.Name);
                    this.dialogRef.close(true);
                    this.navigatorService.StopLoading();
                  } else {
                    this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                    this.navigatorService.StopLoading();
                  }
                } else {
                  this.navigatorService.StopLoading();
                }
              });

            } else {
              if (performed) {
                const message = this.navigatorService.PageAction === PageAction.New ? this.navigatorService.Dictionary?.ToCreateSuccessF
                  : this.navigatorService.Dictionary?.ToEditSuccessF;
                this.navigatorService.ShowSnackBar(message, this.Model.Dto?.Name);
                this.dialogRef.close(true);
                this.navigatorService.StopLoading();
              } else {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                this.navigatorService.StopLoading();
              }
            }
          }
        } else {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
          this.navigatorService.StopLoading();
        }
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        this.navigatorService.StopLoading();
      }
    } else if (this.Model && this.Model.Performed && this.Model.Code === 409) {
      this.Model.Code = null;
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.TipologyExist, this.Model.Dto?.Name);
      this.navigatorService.StopLoading();
    }
    else {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
      this.navigatorService.StopLoading();
    }

  }

  async DeletePermission() {
    if (this.MetaDataTipologyModel.Dtos?.length > 0) {
      let permissionModel = new PermissionModel();
      permissionModel.Filter = new PermissionFilter();
      permissionModel.Filter.Id = this.MetaDataTipologyModel.Dtos.map(q => q?.PermissionsToDelete?.map(p => p.Id));
      if (permissionModel.Filter.Id.length > 0) {
        permissionModel.Filter.Id = permissionModel.Filter.Id.filter(q => q > 0);
      }
      if (permissionModel.Filter.Id.length > 0) {
        permissionModel = await this.PermissionsController.Delete(permissionModel);
        return permissionModel.Performed;
      }
    }
    return true;

  }
  // CreateOrUpdateTipologyUo() {
  //   if (this.Model.Dto?.TipologyUos?.length > 0) {
  //     for (const tipologyUo of this.Model.Dto.TipologyUos) {
  //       let model = new TipologyUoModel();
  //       model.Dtos = this.Model.Dto.TipologyUos;
  //       model= this.TipologyUoController.Delete
  //     }
  //   }
  //   return false;
  // }

  async CreateFolderObjectTipology(tipologyModel: TipologyModel): Promise<boolean> {
    let performed = false;
    for (const tipologyUo of tipologyModel?.Dto?.TipologyUos) {
      let model = new ObjectModel();
      model.Filter = new ObjectFilter();
      model.Filter.TipologyUoId = tipologyUo.Id;
      model.Filter.Type = 'folder';
      model.Filter.Deep = 0;
      model = await this.ObjectController.GetFirst(model);
      if (model?.Performed) {
        if (!model.Dto) {
          model.Dto = new ObjectDto();
        }
        model.Dto.Name = tipologyModel.Dto.Name;
        if (model.Dto.PhysicalName === null && tipologyUo.UoFolderName && tipologyModel.Dto?.Id >= 0) {
          model.Dto.PhysicalName = 'T' + tipologyModel.Dto.Id.toString().padStart(7, '0');
          model.Dto.Path = tipologyUo?.UoFolderName + '/' + model.Dto.PhysicalName;
          model.Dto.Deep = 0;
          model.Dto.Parent = null;
          model.Dto.TipologyUoId = tipologyUo.Id;
          model.Dto.Version = 0;
          model.Dto.Type = 'folder';

        }
        model = await this.ObjectController.CreateOrUpdate(model);
        performed = model?.Performed;
      }
    }
    return performed;
  }

  ValidateForm(step: number): boolean {
    if (step === 1) {
      if (this.Model.Dto.Name === null || this.Model.Dto?.TipologyUos?.filter(x => !x.Trashed)
        ?.length <= 0 || this.Model.Dto?.MimeTypesIds?.length <= 0) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ValidatorMessage);
        return false;
      }
    } else if (step === 2) {
      if (this.SelectedGenericFieldsDtos?.length <= 0 && this.SelectedDefaultFieldsDtos?.length <= 0) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ValidatorMessage);
        return false;
      } else if (this.PersonalizeFields && this.CurrentIndexField < this.MetaDataTipologyModel.Dtos?.length - 1) {
        return (!this.CurrentField.PropertiesJson.Placeholder ? false : true);
      }
    }
    return true;
  }

  async UploadTemplate(callback) {
    const fileReader = new FileReader();
    fileReader.onload = async (fileLoadedEvent) => {
      const base64 = fileLoadedEvent.target.result;
      let fileModel = new FileModel();
      fileModel.Dto = new FileDto();
      fileModel.Dto.PhysicalName = this.FileModel.Dto.PhysicalName;
      fileModel.Dto.Path = this.FileModel.Dto.Path;
      fileModel.Dto.File = base64;
      fileModel.Dto.Index = 1;
      fileModel.Dto.Merge = true;
     // fileModel = await this.FileManagerController.Upload(fileModel);
      const performed = fileModel.Performed;
      if (callback) {
        callback(performed);
      }
    };
    fileReader.readAsDataURL(this.FileModel.Dto.File);
  }

  async DeleteMetadata() {
    if (this.MetadataToDelete && this.MetadataToDelete.length > 0) {
      let metadataModel = new MetadataTipologyModel();
      for (const metadata of this.MetadataToDelete) {
        metadata.Permissions = metadata.Permissions.filter(p => p.Id > 0);
      }
      metadataModel.Dtos = this.MetadataToDelete;
      metadataModel = await this.MetaDataTipologyController.Delete(metadataModel);
      return metadataModel.Performed;
    }
    return true;
  }

  async Next() {
    const isValid = this.ValidateForm(this.Step);
    if (isValid) {
      if (this.Step === 2 && !this.PersonalizeFields) {
        this.PersonalizeFields = true;
        this.CurrentField = this.MetaDataTipologyModel.Dtos[this.CurrentIndexField];
        await this.GetAccountUo(this.Model.Dto?.TipologyUos?.map(q => q.UoId));
        this.AddDefaultAccount(this.CurrentField);
      } else if (this.Step === 2 && this.PersonalizeFields) {
        if (this.CurrentField.PropertiesJson.Placeholder) {
          if (this.CurrentIndexField < this.MetaDataTipologyModel.Dtos.length - 1) {
            this.PersonalizeFieldsPage(this.CurrentIndexField + 1);
          } else {
            this.PersonalizeFields = false;
            this.Step += 1;
          }
        }
      }
      else {
        this.PersonalizeFields = false;
        this.Step += 1;
      }
    }
  }

  PersonalizeFieldsPage(indexField: number) {
    this.CurrentIndexField = indexField;
    this.CurrentField = this.MetaDataTipologyModel.Dtos[indexField];
    this.AddDefaultAccount(this.CurrentField);
  }

  Back() {
    if (this.Step === 3 && !this.PersonalizeFields) {
      this.PersonalizeFields = true;
      this.Step -= 1;
    } else if (this.Step === 2 && this.PersonalizeFields && this.CurrentIndexField > 0) {
      this.PersonalizeFieldsPage(this.CurrentIndexField - 1);
    } else {
      if (this.PersonalizeFields) {
        this.PersonalizeFields = false;
      } else {
        this.Step -= 1;
      }
      if (this.Step <= 0) {
        this.Step = 0;
        this.dialogRef.close(null);
        this.navigatorService.ShowDialog(DialogMenuComponent, null, '45%', 'fit-content');
      }
    }
  }

  DisableNextButton(step: number) {
    let disabled = false;
    if (this.PersonalizeFields && !this.CurrentField.PropertiesJson.Placeholder) {
      disabled = true;
    } else {
      if (this.CurrentIndexField === this.MetaDataTipologyModel.Dtos?.length - 1) {
        const check = this.MetaDataTipologyModel.Dtos.find(m => !m.PropertiesJson.Placeholder);
        if (check) {
          disabled = true;
        }
      }
    }
    return disabled;
  }

  GetMetadataTipologyDto(dto) {
    const metadataTipologyDto = new MetadataTipologyDto();
    metadataTipologyDto.PropertiesJson = JSON.parse(dto.Properties.toString());
    metadataTipologyDto.Key = metadataTipologyDto.PropertiesJson.Placeholder;
    metadataTipologyDto.PropertiesJson.Placeholder = null;
    metadataTipologyDto.TipologyId = this.Model.Dto.Id;
    metadataTipologyDto.Type = dto.Type;
    metadataTipologyDto.FieldId = dto.Id;
    metadataTipologyDto.Name = dto.Name;
    metadataTipologyDto.Index = this.MetaDataTipologyModel.Dtos.length + 1;
    metadataTipologyDto.PropertiesJson.Default = dto.Default;
    return metadataTipologyDto;
  }

  AddOption() {
    if (this.Option) {
      if (!this.OptionEdit) {
        if (!this.CurrentField.PropertiesJson.Items.find(x => x.Value === this.Option)) {
          const fieldItem = new FieldItem();
          fieldItem.Id = this.CurrentField.PropertiesJson.Items.length + 1;
          fieldItem.Value = this.Option;
          this.CurrentField.PropertiesJson.Items.push(fieldItem);
          this.Option = null;
          this.OptionEdit = null;
        }
      } else {
        this.OptionEdit.Value = this.Option;
        this.OptionEdit = null;
        this.Option = null;
      }
    } else {
      // todo: message
    }
  }

  EditOption(item: FieldItem) {
    this.Option = item?.Value;
    this.OptionEdit = item;
  }

  DeleteOption(item: FieldItem) {
    const index = this.CurrentField.PropertiesJson.Items.findIndex(x => x === item);
    if (index >= 0) {
      this.CurrentField.PropertiesJson.Items.splice(index, 1);
    }
  }

  LdapUsersChange($event) {
    if ($event && $event.Id && $event.Id > 0) {
      const defaultAccount = this.CurrentField.Permissions.filter(x => x.AccountId === -1);
      if (defaultAccount && defaultAccount.length > 0) {
        this.CurrentField.Permissions = new Array<PermissionDto>();
      }
      const search = this.CurrentField.Permissions.find(x => x.AccountId === $event.Id);
      if (!search) {
        const permissionDto = new PermissionDto();
        permissionDto.AccountId = $event.Id;
        this.CurrentField.Permissions.push(permissionDto);
        this.AutoCompleteUser.Clear();
      }
    }
  }

  RemoveUser(account: AccountDto) {
    if (account) {
      const index = this.CurrentField.Permissions.findIndex(x => x.AccountId === account.Id);
      if (index >= 0) {
        if (this.CurrentField.Permissions[index].Id > 0) {
          if (!this.CurrentField.PermissionsToDelete) {
            this.CurrentField.PermissionsToDelete = new Array<PermissionDto>();
          }
          const permissions = this.CurrentField.Permissions.splice(index, 1);
          if (permissions && permissions.length > 0) {
            this.CurrentField.PermissionsToDelete.push(permissions[0]);
          }
        } else {
          this.CurrentField.Permissions.splice(index, 1);
        }
      }
    }
    if (this.CurrentField.Permissions.length <= 0) {
      this.AddDefaultAccount(this.CurrentField);
    }
  }

  GetMetadataAccounts() {
    const accountIds = this.CurrentField?.Permissions.map(x => x.AccountId);
    return this.AccountModel?.Dtos?.filter(x => accountIds.includes(x.Id));
  }

  FileSelected($event) {
    if ($event) {
      this.TemplateModel.Dto = new TemplateDto();
      this.TemplateModel.Dto.Name = $event.name;
      this.TemplateModel.Dto.PhysicalName = $event.name.replace(/[^a-z0-9.]/gi, '_');
      this.TemplateModel.Dto.Path = 'template';

      this.FileModel.Dto.PhysicalName = $event.name.replace(/[^a-z0-9.]/gi, '_');
      this.FileModel.Dto.Path = 'template';
      this.FileModel.Dto.File = $event;
    } else {
      this.TemplateModel = new TemplateModel();
      this.TemplateModel.Dto = new TemplateDto();
      this.FileModel = new FileModel();
      this.FileModel.Dto = new FileDto();
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.InvalidFormat);
    }
  }

  AddDefaultAccount(dto: MetadataTipologyDto = null) {
    let defaultDto = this.AccountModel.Dtos?.find(x => x.Id === -1);
    if (!defaultDto) {
      defaultDto = new AccountDto();
      defaultDto.DisplayName = this.navigatorService.Dictionary?.All;
      defaultDto.Id = -1;
      this.AccountModel.Dtos?.splice(0, 0, defaultDto);
    }
    if (dto && dto.Permissions?.length <= 0) {
      const permissionDto = new PermissionDto();
      permissionDto.AccountId = -1;
      dto.Permissions.push(permissionDto);
    }
  }

  SelectGenericFields(dto: any) {
    if (dto) {
      const metadataDto = this.GetMetadataTipologyDto(dto);
      this.MetaDataTipologyModel.Dtos.push(metadataDto);
      this.SelectedGenericFieldsDtos.push(dto);
    }
  }

  DeleteGenericFields(id: number) {
    // remove from model
    const index = this.MetaDataTipologyModel?.Dtos?.findIndex(x => x.FieldId === id);
    if (index >= 0) {
      if (this.MetaDataTipologyModel?.Dtos?.find(x => x.FieldId === id)?.Id > 0) {
        this.MetadataToDelete?.push(this.MetaDataTipologyModel?.Dtos[index]);
      }
      this.MetaDataTipologyModel?.Dtos?.splice(index, 1);
    }
    // remove from chips view
    const metadataIndex = this.SelectedGenericFieldsDtos?.findIndex(x => x.Id === id);
    if (metadataIndex >= 0) {
      this.SelectedGenericFieldsDtos?.splice(metadataIndex, 1);
    }
  }

  SelectDefaultFields(dto: any) {
    if (dto) {
      const metadataDto = this.GetMetadataTipologyDto(dto);
      metadataDto.PropertiesJson.Placeholder = metadataDto.Key;
      this.MetaDataTipologyModel.Dtos.push(metadataDto);
      this.SelectedDefaultFieldsDtos?.push(dto);
    }
  }

  DeleteDefaultFields(id: number) {
    // remove from model
    const index = this.MetaDataTipologyModel?.Dtos?.findIndex(x => x.FieldId === id);
    if (index >= 0) {
      this.MetadataToDelete?.push(this.MetaDataTipologyModel?.Dtos[index]);
      this.MetaDataTipologyModel?.Dtos?.splice(index, 1);
    }
    // remove from chips view
    const metadataIndex = this.SelectedDefaultFieldsDtos?.findIndex(x => x.Id === id);
    if (metadataIndex >= 0) {
      this.SelectedDefaultFieldsDtos?.splice(metadataIndex, 1);
    }
  }

  OpenTemplate() {
    if (this.TemplateModel?.Dto?.Id > 0) {
      let queryParams: NavigationExtras = null;
      queryParams = {
        queryParams: {
          id: this.TemplateModel.Dto.Id,
          type: 'template'
        }
      };
      this.navigatorService.GoTo(Pages.OnlyOffice, queryParams, 'v2/', true);
    }
  }

  GetNoPreSetMetadata() {
    return this.MetaDataTipologyModel?.Dtos?.filter(m => !m.PropertiesJson.Checked);
  }

  GetPreSetMetadata() {
    return this.MetaDataTipologyModel?.Dtos?.filter(m => m.PropertiesJson.Checked);
  }

  DeleteTab() {
    this.DeleteGenericFields(this.CurrentField?.FieldId);
    const index = this.CurrentIndexField > 0 ? this.CurrentIndexField - 1 : 0;
    this.PersonalizeFieldsPage(index);
    // if (this.CurrentField?.PropertiesJson?.Default && (this.CurrentField?.PropertiesJson?.Removable
    //   || !this.CurrentField?.PropertiesJson.hasOwnProperty('Removable'))) {
    //   this.DeleteDefaultFields(this.CurrentField?.FieldId);
    //   const index = this.CurrentIndexField > 0 ? this.CurrentIndexField - 1 : 0;
    //   this.PersonalizeFieldsPage(index);
    // }
    // if (!this.CurrentField?.PropertiesJson?.Default) {
    //   this.DeleteGenericFields(this.CurrentField?.FieldId);
    //   const index = this.CurrentIndexField > 0 ? this.CurrentIndexField - 1 : 0;
    //   this.PersonalizeFieldsPage(index);
    // }
  }
}
