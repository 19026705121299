import { D } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AttachmentDto, AttachmentFilter, AttachmentModel } from '../../../models/AttachmentModel';
import { AttachmentController } from '../../../controllers/AttachedController';
import { FileDto, FileModel } from '../../../models/FileModel';
import { FileManagerController } from '../../../controllers/FileManagerController';
import { ObjectDto } from '../../../models/ObjectModel';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { UploadService } from '../../../../upload.service';
import { CommonService } from '../../../../common.service';
import { DocumentState } from '../../../itdoc.configuration';


@Component({
  selector: 'app-attached-document',
  templateUrl: './attached-document.component.html',
  styleUrls: ['./attached-document.component.scss']
})
export class AttachedDocumentComponent implements OnInit {
  @ViewChild('multiUploadFile') multiFileUpload: ElementRef;
  EnableUpload = false;
  Model: AttachmentModel;
  AttachmentController: AttachmentController;
  FileModel: FileModel;
  FileManagerController: FileManagerController;
  UploadInProgress = false;
  DataSource: MatTableDataSource<AttachmentDto>;
  DataSourceFiles: MatTableDataSource<FileDto>;
  ObjectDto: ObjectDto;
  // ChunkSize = 4194304;
  constructor(
    private dataService: DataService, public navigatorService: NavigatorService, public uploadService: UploadService,
    public commonService: CommonService, public datepipe: DatePipe,
    public dialogRef: MatDialogRef<AttachedDocumentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ObjectDto = data.Dto;
    this.EnableUpload = (this.ObjectDto.State === DocumentState.PROGRESS || this.ObjectDto.State.includes(DocumentState.START)
      || this.ObjectDto.State === DocumentState.REVISION || this.ObjectDto.State === DocumentState.EDIT);
    this.Model = new AttachmentModel();
    this.AttachmentController = new AttachmentController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.Model.Filter = new AttachmentFilter();
    this.Model.Filter.ObjectId = this.ObjectDto.Id;
    this.Model = await this.AttachmentController.Get(this.Model);
    if (this.Model.Performed) {
      this.Model.Dtos.map(q => q.Selected === false);
      this.RefreshTableAttached(this.Model.Dtos);
    }
  }

  async Delete(dto: AttachmentDto) {
    dto.Selected = true;
    let model = new AttachmentModel();
    model.Filter = new AttachmentFilter();
    model.Filter.Id = dto.Id;
    model = await this.AttachmentController.Delete(model);
    if (model.Performed) {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.DeleteSuccess, dto.Name + '.'
        + this.commonService.GetExtension(dto.PhysicalName));
      const index = this.Model.Dtos.findIndex(q => q.Id === dto.Id);
      this.Model.Dtos.splice(index, 1);
      this.RefreshTableAttached(this.Model.Dtos);
    } else {
      dto.Selected = false;
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
  }

  RefreshTableFile(dtos: Array<FileDto>) {
    for (const dto of dtos) {
      const ext = this.commonService.GetExtension(dto.PhysicalName);
      this.GetIcon(ext);
    }
    this.DataSourceFiles = null;
    this.DataSourceFiles = new MatTableDataSource<FileDto>(dtos);
  }

  RefreshTableAttached(dtos: Array<AttachmentDto>) {
    for (const dto of dtos) {
      const ext = this.commonService.GetExtension(dto.PhysicalName);
      dto.Icon = this.GetIcon(ext);
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<AttachmentDto>(dtos);
  }

  async OpenObject(row: AttachmentDto) {
    if (!this.UploadInProgress) {
      const dto = new ObjectDto();
      dto.Id = row.Id;
      dto.PhysicalName = row.PhysicalName;
      dto.Path = row.Path;
      dto.MimeType = row.MimeType;
      dto.Type = 'attachment';
      await this.commonService.OpenObject(dto, this.navigatorService);
    }
  }

  async DownloadFile(dto: ObjectDto) {
    this.navigatorService.StartLoading(200000);
    try {
      const model = await this.commonService.Download(dto);
      if (model.Performed) {
        const blob = new Blob([model.Dto.File], { type: dto.MimeType });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = dto.PhysicalName;
        // start download
        element.click();
      }
    } catch (error) {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
    this.navigatorService.StopLoading();
  }

  private GetIcon(ext) {
    let icon = null;
    // presentation
    if (ext === 'ppt' || ext === 'pptx' || ext === 'odp') {
      icon = '../../assets/icon/doc_presentation.svg';
    } else if (ext === 'html') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'txt') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'jpg' || ext === 'png') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'xls' || ext === 'xlsx' || ext === 'ods') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'doc' || ext === 'docx' || ext === 'odt') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'pdf') {
      icon = '../../assets/icon/doc_pdf.svg';
    } else {
      icon = '../../assets/icon/doc_doc.svg';
    }
    return icon;
  }

  Close() {
    this.dialogRef.close(this.Model);
  }

  //#region upload
  SelectFiles(files) {
    this.FileModel = new FileModel();
    for (const file of files) {
      const extension = this.commonService.GetExtension(file.name);
      const exist = this.Model.Dtos.findIndex(q => q.Name + '.' + extension === file.name);
      const fileDto = new FileDto();
      fileDto.Name = file.name;
      fileDto.PhysicalName = file.name.replace(/[^a-z0-9.]/gi, '_');
      fileDto.Path = 'ATTACHMENTS/' + this.ObjectDto.Id.toString().padStart(7, '0');
      fileDto.File = file;
      fileDto.Performed = exist < 0;
      fileDto.Message = exist >= 0 ? this.navigatorService.Dictionary?.FileAlreadyExists : null;
      fileDto.Icon = this.GetIcon(extension);
      fileDto.Progress = 0;
      this.FileModel.Dtos.push(fileDto);
    }
    this.EnableUpload = this.FileModel?.Dtos.filter(q => q.Performed === true).length > 0;
    this.multiFileUpload.nativeElement.value = null;
    this.RefreshTableFile(this.FileModel.Dtos);
  }

  Upload() {
    this.UploadInProgress = true;
    this.UploadFile(async () => {
      this.FileModel.Dtos = [];
      await this.Load();
      this.UploadInProgress = false;
    });
  }

  Cancel() {
    this.uploadService.Cancel = true;
  }
  async UploadFile(callback) {
    if (this.FileModel && this.FileModel.Dtos.length > 0) {
      let fileCounter = 0;
      this.uploadService.Cancel = false;
      for (const dto of this.FileModel.Dtos) {
        if (dto.Performed) {
          const response = await this.uploadService.Upload(dto.File,
            dto.PhysicalName, dto.Path, dto);

          if (response['Cancell']) {
            callback();
            return;
          }
          if (response.Performed && response.Completed) {
            let model = new AttachmentModel();
            model.Dto = new AttachmentDto();
            model.Dto.Name = dto.Name.replace('.' + this.commonService.GetExtension(dto.PhysicalName), '');
            model.Dto.PhysicalName = dto.PhysicalName;
            model.Dto.Path = dto.Path;
            model.Dto.ObjectId = this.ObjectDto.Id;
            model = await this.AttachmentController.CreateOrUpdate(model);
            dto.Performed = model.Performed;
            fileCounter += 1;
          } else if (response.Error) {
            dto.Message = this.navigatorService.Dictionary?.UploadError;
          }

          // let fileModel = new FileModel();
          //   fileModel.Dto = new FileDto();
          // fileModel.Dto.Name = dto.Name;
          // fileModel.Dto.Path = dto.Path;

          // const chunks = this.GetChunks(dto.File);
          // let counter = 0;
          // for (const chunk of chunks) {
          //   fileModel.Dto.Progress += 10;
          //   this.commonService.BlobToBase64(chunk, async (result) => {
          //     counter += 1;
          //     fileModel.Dto.PhysicalName = dto.PhysicalName;
          //     fileModel.Dto.Path = dto.Path;
          //     fileModel.Dto.File = result;
          //     fileModel.Dto.Index = counter;
          //     fileModel.Dto.Merge = (counter >= chunks.length);
          //     fileModel = await this.FileManagerController.Upload(fileModel);
          //     setInterval((file, totalChunks, index) => {
          //       const tot = ((index / totalChunks) * 100);
          //       if (file.Progress < tot) {
          //         file.Progress += 1;
          //       }
          //     }, 0, dto, chunks.length, counter);
          //     dto.Performed = fileModel.Performed;
          //     if (fileModel.Dto.Merge && fileModel.Performed) {
          //       let model = new AttachmentModel();
          //       model.Dto = new AttachmentDto();
          //       model.Dto.Name = dto.Name.replace('.' + this.commonService.GetExtension(dto.PhysicalName), '');
          //       model.Dto.PhysicalName = dto.PhysicalName;
          //       model.Dto.Path = dto.Path;
          //       model.Dto.ObjectId = this.ObjectDto.Id;
          //       model = await this.AttachmentController.CreateOrUpdate(model);
          //       dto.Performed = model.Performed;
          //       fileCounter += 1;
          //       await this.navigatorService.Delay(1200);
          //       this.FileModel.Dtos = this.FileModel.Dtos.filter(q => q.Performed === false);
          //     } else {
          //       dto.Message = this.navigatorService.Dictionary?.UploadError;
          //     }
          //     if (!exit && fileCounter >= this.FileModel.Dtos.length && callback) {
          //       exit = true;
          //       callback();
          //       return;
          //     }
          //   });
          // }
        } else {
          if (callback) {
            callback();
            return;
          }
        }
      }
      if (fileCounter >= this.FileModel.Dtos.length && callback) {
        callback();
        return;
      }
    }
  }

  // private GetChunks(file: File) {
  //   const fileSize = file.size;
  //   const chunks = [];
  //   if (fileSize > 0) {
  //     for (let i = 0; i < fileSize; i += this.ChunkSize) {
  //       const blob = file.slice(i, this.ChunkSize + i);
  //       chunks.push(blob);
  //     }
  //   }
  //   return chunks;
  // }

  DeleteFile(dto: FileDto) {
    const index = this.FileModel.Dtos.findIndex(q => q.Name === dto.Name);
    this.FileModel.Dtos.splice(index, 1);
    this.RefreshTableFile(this.FileModel.Dtos);
  }
  //#endregion
}
