<div *ngIf="TotalPages>1 && Model.Count>0" class="paging-container">
  <div class="d-flex justify-content-center align-items-center">
    <div>
      <button mat-icon-button (click)="SkipPagingBack()" class="next" [disabled]="StartPage === 0"
        *ngIf="TotalPages>10">
        <mat-icon> keyboard_double_arrow_left</mat-icon>
      </button>
      <button mat-icon-button (click)="PagingBack()" class="back" [disabled]="CurrentPage<=1">
        <mat-icon> keyboard_arrow_left</mat-icon>
      </button>
    </div>

    <div class="d-flex flex-row">
      <div class="page-number-container" [ngClass]="page.toString().length>2?'margin-right':''" *ngFor="let page of Pages">
        <div [ngClass]="CurrentPage===page?'page-number-selected':'page-number'">
          <span class="page" (click)="PagingNumber(page)">{{page}}

          </span>
        </div>
      </div>
    </div>

    <div>
      <button mat-icon-button (click)="PagingNext()" class="next" [disabled]="CurrentPage===TotalPages">
        <mat-icon> keyboard_arrow_right</mat-icon>
      </button>
      <button mat-icon-button (click)="SkipPagingNext()" class="next" [disabled]="StartPage+10 >= TotalPages"
        *ngIf="TotalPages>10">
        <mat-icon> keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>

