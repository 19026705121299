import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { CardDataModel } from '../../models/CardDataModel';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],  
})

export class DoughnutChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) Chart?: BaseChartDirective;
  @Input() Width: number;
  @Input() IsEmpty: boolean;
  @Input() set DatasetModel(values: CardDataModel[]) {
    if (values && values.length > 0) {
      for (const value of values) {
        this.Data.push(value.Percentage);
        this.ChartColors.push(value.PercentageColor);
      }
      this.Load();
    }
  }
  @Input() set DataModel(value: CardDataModel) {
    if (value) {
      this.Data = [value.PercentageInTime, value.PercentageInLate];
      this.ChartColors = [value.PercentageInTimeColor, value.PercentageInLateColor];
      this.Load();
    }
  }
  Data: number[];
  ChartColors: string[];
  ChartData: ChartData<'doughnut'> = null;
  ChartType: ChartType = 'doughnut';
  ChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: '75%',
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  };

  constructor() {
    this.Data = [];
    this.ChartColors = [];
  }

  ngOnInit(): void {
    this.Load();
  }

  Load() {
    if (!this.IsEmpty) {
      this.ChartData = {
        datasets: [
          {
            data: this.Data,
            backgroundColor: this.ChartColors,
            hoverBackgroundColor: this.ChartColors,
            hoverBorderColor: '#fff',
            borderRadius: 30
          }
        ]
      };
    } else{
      this.ChartData = {
        datasets: [
          {
            data: [100],
            backgroundColor: '#BEBEBE',
            hoverBackgroundColor: '#BEBEBE',
            hoverBorderColor: '#fff',
            borderRadius: 30
          }
        ]
      };
    }
  }

}
