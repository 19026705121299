import { AttachmentModel } from '../models/AttachmentModel';
import { DataService } from '../../data.service';

export class AttachmentController {
    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: AttachmentModel): Promise<AttachmentModel> {
        const response = await this.dataService.CreateOrUpdateAttachment(model);
        return response;
    }

    async Read(model: AttachmentModel): Promise<AttachmentModel> {
        const response = await this.dataService.ReadAttachment(model);
        return response;
    }

    async Get(model: AttachmentModel): Promise<AttachmentModel> {
        const response = await this.dataService.GetAttachment(model);
        return response;
    }

    async Load(model: AttachmentModel): Promise<AttachmentModel> {
        const response = await this.dataService.LoadAttachment(model);
        return response;
    }

    async Delete(model: AttachmentModel): Promise<AttachmentModel> {
        const response = await this.dataService.DeleteAttachment(model);
        return response;
    }


}
