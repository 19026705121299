import { FileModel } from '../models/FileModel';
import { DataService } from '../../data.service';

export class FileManagerController {
    constructor(private dataService: DataService) { }

    // async Upload(model: FileModel): Promise<FileModel> {
    //     return await this.dataService.Upload(model);
    // }

    async Download(model: FileModel): Promise<FileModel> {
        return await this.dataService.Download(model);
    }

    async ScanToPdf(model: FileModel): Promise<FileModel> {
        return await this.dataService.ScanToPdf(model);
    }
}
