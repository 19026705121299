import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class AccountModel extends BaseModel<AccountEntity, AccountDto, AccountFilter> {
    constructor() {
        super();
        this.Take = 8;
    }
}

export class AccountEntity extends BaseEntity {
    Username: string|null;
    DisplayName: string|null;

    constructor() {
        super();
        this.Username = null;
        this.DisplayName = null;

    }
}

export class AccountDto extends AccountEntity {
    RoleId: number|null;
    Start?: number|null;
    End?: number|null;
    UoName: string|null;
    UoIds?: number[];
    DateString: string|null;
    AccountRoleId: number|null;
    IsAdmin: boolean;
    RoleName: string|null;
    Avatar: string|null;
    UoId?: number;
    Enabled!: boolean;
    Signatory: boolean;
    constructor() {
        super();
        this.UoName = null;
        this.RoleId = null;
        this.Start = null;
        this.End = null;
        this.DateString = null;
        this.AccountRoleId = null;
        this.IsAdmin = false;
        this.RoleName = null;
        this.Avatar = null;
        this.UoIds = [];
        this.Signatory = false;
    }
}

export class AccountFilter extends AccountDto {
}
