export class CardDataModel {
    Name: string|null;
    Status!: string|null;
    Count?: number|null;
    Percentage?: number;
    PercentageInTime?: number|null;
    PercentageInLate?: number|null;
    PercentageColor?: string|null;
    PercentageInTimeColor?: string|null;
    PercentageInLateColor?: string|null;
    Tab?: number;
    constructor() {
        this.Name = null;
        this.Count = 0;
        this.Percentage = 0;
        this.PercentageInTime = 0;
        this.PercentageInLate = 0;
        this.PercentageColor = null;
        this.Tab = 1;
    }
}
