import { Component, Input, OnInit } from '@angular/core';
import { CardDataModel } from '../../../models/CardDataModel';
import { NavigatorService } from '../../../../navigator.services';


@Component({
  selector: 'app-protocols-card',
  templateUrl: './protocols-card.component.html',
  styleUrls: ['./protocols-card.component.scss']
})
export class ProtocolsCardComponent implements OnInit {
  Tab = 1;
  datasetModel: CardDataModel[];
  filteredDataSetValue: CardDataModel[];
  @Input() CardTitle: string;
  @Input() set DatasetModel(values: CardDataModel[]) {
    if (values) {
      this.datasetModel = values;
      this.Load();
    }
  }

  constructor(public navigatorService: NavigatorService) { }

  ngOnInit(): void {
  }

  Load(tab = 1) {
    this.filteredDataSetValue = this.datasetModel?.filter(data => data.Tab === tab);
    this.Tab = tab;
  }
}
