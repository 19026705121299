<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 10px;position: relative; top: -10px;"
                        src="../../../../../assets/icon/upload.svg">
                    <h4>{{Description}}</h4>                   
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="FileModel?.Dtos?.length<=0" class="form mb-5">
        <app-progress-bar [Percentage]="uploadService?.Percentage"
            [Error]="uploadService?.Error"></app-progress-bar>
    </div>
    <div *ngIf="FileModel?.Dtos?.length > 0" class="form mb-5" style="min-height:400px;">
        <div *ngIf="!navigatorService.Loading && FileModel?.Dtos?.length > 0"
            class="it-doc-table-container max-heigth-400-overflow tiny-scrollbar">
            <table mat-table [dataSource]="DataSourceFiles">
                <ng-container matColumnDef="Name">
                    <td mat-cell *matCellDef="let element">
                        <span title="{{element.PhysicalName }}">
                            <div class="d-flex">
                                <span class="icon-text-right">
                                    <img src="{{element.Icon}}">
                                </span>
                                <div class="ellipsis-text">
                                    {{element.PhysicalName}}
                                </div>
                            </div>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Progressbar">
                    <td mat-cell *matCellDef="let element" style="width: 300px;font-size: 12px;">
                        <div class="row">
                            <div class="col-9">
                                <mat-progress-bar mode="determinate" value="{{ element.Progress}}" class="progressbar">
                                </mat-progress-bar>
                                <p *ngIf="!element.Performed" style="color: red;position: relative;top:5px">
                                    {{element.Message}}</p>
                            </div>
                            <div class="col-3">
                                <span>{{ element.Progress}}%</span>
                            </div>
                        </div>
                    </td>
                </ng-container>


                <tr mat-row [ngClass]="{'selected':row.Selected}" *matRowDef="let row;  
                columns: ['Name',
                    'Progressbar'
                    ]" style="position: relative;">
                </tr>

            </table>
        </div>
    </div>
</div>