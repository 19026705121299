import { DataService } from '../../data.service';
import { FavouriteModel } from '../models/FavouriteModel';

export class FavouritesController {

    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: FavouriteModel): Promise<FavouriteModel> {
        const response = await this.dataService.CreateOrUpdateFavourite(model);
        return response;
    }

    async Get(model: FavouriteModel): Promise<FavouriteModel> {
        const response = await this.dataService.GetFavourites(model);
        return response;
    }

    async Delete(model: FavouriteModel): Promise<FavouriteModel> {
        let response = model;
        if (model) {
            response = await this.dataService.DeleteFavourite(model);
        }
        return response;
    }
}
