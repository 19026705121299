import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Dictionary } from './dictionary/dictionary';
import { SettingsModel } from './itdoc/models/SettingsModel';
import { CurrentUserDto } from './models/CurrentUserModel';
import { NavigatorService } from './navigator.services';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {
  SVGIcon: string;
  SVGIconHover: string;
  SVGButton: string;
  SVGButtonHover: string;
  BrandLogo: any;
  Favicon: string;
  AppName: string;
  LogoPoweredCenter: any;
  LogoPoweredLeft: any;
  constructor(public navigatorService: NavigatorService, private sanitizer: DomSanitizer) {
  }

  async Inizialize(model: SettingsModel) {
    this.AppName = model.Dtos.find(q => q.Configkey === 'AppName')?.Configvalue;

    this.BrandLogo = model.Dtos.find(q => q.Configkey === 'BrandLogo')?.Configvalue;
    if (this.BrandLogo) {
      this.BrandLogo = this.sanitizer.bypassSecurityTrustUrl(this.BrandLogo);
    }
    this.navigatorService.Dictionary = new Dictionary(navigator.language, this.AppName, null).Dictionary;

    this.LogoPoweredCenter = model.Dtos.find(q => q.Configkey === 'LogoPoweredCenter')?.Configvalue;
    if (this.LogoPoweredCenter) {
      this.LogoPoweredCenter = this.sanitizer.bypassSecurityTrustUrl(this.LogoPoweredCenter);
    }
    this.LogoPoweredLeft = model.Dtos.find(q => q.Configkey === 'LogoPoweredLeft')?.Configvalue;
    if (this.LogoPoweredLeft) {
      this.LogoPoweredLeft = this.sanitizer.bypassSecurityTrustUrl(this.LogoPoweredLeft);
    }
    document.getElementById('favicon')?.setAttribute('href',
      model.Dtos.find(q => q.Configkey === 'Favicon')?.Configvalue ?
        model.Dtos.find(q => q.Configkey === 'Favicon')?.Configvalue : '');
    document.title = model.Dtos.find(q => q.Configkey === 'AppName')?.Configvalue
      ? model.Dtos.find(q => q.Configkey === 'AppName')?.Configvalue : '';
    const rgb = this.HexToRgb(model.Dtos.find(q => q.Configkey === '--MenuTextColor')?.Configvalue);
    const rgbHover = this.HexToRgb(model.Dtos.find(q => q.Configkey === '--MenuHoverTextColor')?.Configvalue);
    if (rgb.length !== 3) {
      return;
    }
    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    const result = solver.Solve();
    this.SVGIcon = result.filter;
    const colorHover = new Color(rgbHover[0], rgbHover[1], rgbHover[2]);
    const solverHover = new Solver(colorHover);
    const resultHover = solverHover.Solve();
    this.SVGIconHover = resultHover.filter;

    document.documentElement.style.setProperty('--PrimaryColor', model.Dtos.find(q => q.Configkey === '--PrimaryColor')?.Configvalue);
    document.documentElement.style.setProperty('--SecondaryColor', model.Dtos.find(q => q.Configkey === '--SecondaryColor')?.Configvalue);
    document.documentElement.style.setProperty('--HoverMenuColor', model.Dtos.find(q => q.Configkey === '--HoverMenuColor')?.Configvalue);
    document.documentElement.style.setProperty('--ButtonsColor', model.Dtos.find(q => q.Configkey === '--ButtonsColor')?.Configvalue);
    document.documentElement.style.setProperty('--HoverButtonsColor', model.Dtos.find(q => q.Configkey === '--HoverButtonsColor')?.Configvalue);
    document.documentElement.style.setProperty('--TextButtonsColor', model.Dtos.find(q => q.Configkey === '--TextButtonsColor')?.Configvalue);
    document.documentElement.style.setProperty('--HoverTextButtonsColor', model.Dtos.find(q => q.Configkey === '--HoverTextButtonsColor')?.Configvalue);
    document.documentElement.style.setProperty('--MenuTextColor', model.Dtos.find(q => q.Configkey === '--MenuTextColor')?.Configvalue);
    document.documentElement.style.setProperty('--MenuHoverTextColor', model.Dtos.find(q => q.Configkey === '--MenuHoverTextColor')?.Configvalue);
    document.documentElement.style.setProperty('--MenuColor', model.Dtos.find(q => q.Configkey === '--MenuColor')?.Configvalue);

    document.documentElement.style.setProperty('--LoginBackgroundColor', model.Dtos.find(q => q.Configkey === '--LoginBackgroundColor')?.Configvalue);
    document.documentElement.style.setProperty('--LoginTextColor', model.Dtos.find(q => q.Configkey === '--LoginTextColor')?.Configvalue);
    document.documentElement.style.setProperty('--LoginTextHoverColor', model.Dtos.find(q => q.Configkey === '--LoginTextHoverColor')?.Configvalue);
    document.documentElement.style.setProperty('--LoginButtonColor', model.Dtos.find(q => q.Configkey === '--LoginButtonColor')?.Configvalue);
    document.documentElement.style.setProperty('--LoginTextButtonColor', model.Dtos.find(q => q.Configkey === '--LoginTextButtonColor')?.Configvalue);
    document.documentElement.style.setProperty('--LoginButtonHoverColor', model.Dtos.find(q => q.Configkey === '--LoginButtonHoverColor')?.Configvalue);
  }


  HexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
      : null;
  }
}


class Color {
  R; G; B;

  constructor(r, g, b) {
    this.set(r, g, b);
  }

  toString() {
    return `rgb(${Math.round(this.R)}, ${Math.round(this.G)}, ${Math.round(this.B)})`;
  }

  set(r, g, b) {
    this.R = this.Clamp(r);
    this.G = this.Clamp(g);
    this.B = this.Clamp(b);
  }

  HueRotate(angle = 0) {
    angle = angle / 180 * Math.PI;
    const sin = Math.sin(angle);
    const cos = Math.cos(angle);

    this.Multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.140,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ]);
  }

  Grayscale(value = 1) {
    this.Multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value),
    ]);
  }

  Sepia(value = 1) {
    this.Multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value),
    ]);
  }

  Saturate(value = 1) {
    this.Multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value,
    ]);
  }

  Multiply(matrix) {
    const newR = this.Clamp(this.R * matrix[0] + this.G * matrix[1] + this.B * matrix[2]);
    const newG = this.Clamp(this.R * matrix[3] + this.G * matrix[4] + this.B * matrix[5]);
    const newB = this.Clamp(this.R * matrix[6] + this.G * matrix[7] + this.B * matrix[8]);
    this.R = newR;
    this.G = newG;
    this.B = newB;
  }

  Brightness(value = 1) {
    this.Linear(value);
  }

  Contrast(value = 1) {
    this.Linear(value, -(0.5 * value) + 0.5);
  }

  Linear(slope = 1, intercept = 0) {
    this.R = this.Clamp(this.R * slope + intercept * 255);
    this.G = this.Clamp(this.G * slope + intercept * 255);
    this.B = this.Clamp(this.B * slope + intercept * 255);
  }

  Invert(value = 1) {
    this.R = this.Clamp((value + this.R / 255 * (1 - 2 * value)) * 255);
    this.G = this.Clamp((value + this.G / 255 * (1 - 2 * value)) * 255);
    this.B = this.Clamp((value + this.B / 255 * (1 - 2 * value)) * 255);
  }

  Hsl() {
    const r = this.R / 255;
    const g = this.G / 255;
    const b = this.B / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;

        case g:
          h = (b - r) / d + 2;
          break;

        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return {
      H: h * 100,
      S: s * 100,
      L: l * 100,
    };
  }

  Clamp(value) {
    if (value > 255) {
      value = 255;
    } else if (value < 0) {
      value = 0;
    }
    return value;
  }
}

class Solver {
  Target; TargetHSL; ReusedColor: Color;

  constructor(target) {
    this.Target = target;
    this.TargetHSL = target.Hsl();
    this.ReusedColor = new Color(0, 0, 0);
  }

  Solve() {
    const result = this.SolveNarrow(this.SolveWide());
    return {
      values: result.values,
      loss: result.loss,
      filter: this.css(result.values),
    };
  }

  SolveWide() {
    const A = 5;
    const c = 15;
    const a = [60, 180, 18000, 600, 1.2, 1.2];

    let best = { loss: Infinity };
    for (let i = 0; best.loss > 25 && i < 3; i++) {
      const initial = [50, 20, 3750, 50, 100, 100];
      const result = this.Spsa(A, a, c, initial, 1000);
      if (result.loss < best.loss) {
        best = result;
      }
    }
    return best;
  }

  SolveNarrow(wide) {
    const A = wide.loss;
    const c = 2;
    const A1 = A + 1;
    const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
    return this.Spsa(A, a, c, wide.values, 500);
  }

  Spsa(A, a, c, values, iters) {
    const alpha = 1;
    const gamma = 0.16666666666666666;

    let best = null;
    let bestLoss = Infinity;
    const deltas = new Array(6);
    const highArgs = new Array(6);
    const lowArgs = new Array(6);

    for (let k = 0; k < iters; k++) {
      const ck = c / Math.pow(k + 1, gamma);
      for (let i = 0; i < 6; i++) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1;
        highArgs[i] = values[i] + ck * deltas[i];
        lowArgs[i] = values[i] - ck * deltas[i];
      }

      const lossDiff = this.Loss(highArgs) - this.Loss(lowArgs);
      for (let i = 0; i < 6; i++) {
        const g = lossDiff / (2 * ck) * deltas[i];
        const ak = a[i] / Math.pow(A + k + 1, alpha);
        values[i] = fix(values[i] - ak * g, i);
      }

      const loss = this.Loss(values);
      if (loss < bestLoss) {
        best = values.slice(0);
        bestLoss = loss;
      }
    }
    return { values: best, loss: bestLoss };

    function fix(value, idx) {
      let max = 100;
      if (idx === 2 /* saturate */) {
        max = 7500;
      } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
        max = 200;
      }

      if (idx === 3 /* hue-rotate */) {
        if (value > max) {
          value %= max;
        } else if (value < 0) {
          value = max + value % max;
        }
      } else if (value < 0) {
        value = 0;
      } else if (value > max) {
        value = max;
      }
      return value;
    }
  }

  Loss(filters) {
    // Argument is array of percentages.
    const color = this.ReusedColor;
    color.set(0, 0, 0);

    color.Invert(filters[0] / 100);
    color.Sepia(filters[1] / 100);
    color.Saturate(filters[2] / 100);
    color.HueRotate(filters[3] * 3.6);
    color.Brightness(filters[4] / 100);
    color.Contrast(filters[5] / 100);

    const colorHSL = color.Hsl();
    return (
      Math.abs(color.R - this.Target.R) +
      Math.abs(color.G - this.Target.G) +
      Math.abs(color.B - this.Target.B) +
      Math.abs(colorHSL.H - this.TargetHSL.H) +
      Math.abs(colorHSL.S - this.TargetHSL.S) +
      Math.abs(colorHSL.L - this.TargetHSL.L)
    );
  }

  css(filters) {
    function fmt(idx, multiplier = 1) {
      return Math.round(filters[idx] * multiplier);
    }
    return `filter:invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%);`;
  }
}
