<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <img class="icon" src="../../../../assets/icon/bell.svg" style="display: inline;">
                <h2 style="display: inline;
                position: relative;
                margin-left: 10px;
                top: 3px;">{{navigatorService.Dictionary?.NotificationHistory}}</h2>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <div>
        <p class="title-description"></p>
        <mat-dialog-content style="min-height: 26vh;overflow: hidden ">
            <div class="scrollable-notif-cont" [ngClass]="{'noNotifications': Model?.Dtos?.length === 0}">
                <div *ngFor="let dto of Model.Dtos; let isLast = last;" class="container m-0 p-0"
                    [ngStyle]="{'background-color': !dto.is_read?'#ececec':'#fff'}">
                    <div class="row notif-item-list m-0 ">
                        <div class="col-12 m-0 p-0">
                            <div class="notificationTitle row m-0 p-0">
                                <div class="col-12 m-0 p-0 notificationSubject">
                                    <div style="cursor:pointer;">
                                        <img class="notifications-icon" width="24" height="24" src="{{dto.Icon}}" />
                                        <span class="text">{{dto.Subject}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="notificationMessage row m-0 pb-1 pt-0 px-0">
                                <div class="col-12 m-0 p-0">
                                    <div class="messageText" [innerHTML]="dto.Message"></div>
                                </div>
                            </div>
                            <div class="notificationDate row m-0 p-0">
                                <div class="col-12 m-0 p-0">
                                    {{dto.created_at | date:'dd/MM/yyyy' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="notificationSeparator" *ngIf="!isLast">
                    <div class="last-margin" *ngIf="isLast"></div>
                </div>
                <div *ngIf="Model?.Dtos?.length === 0" class="noNotificationsContainer">
                    <span class="material-icons-outlined">notifications_off</span>
                    <p class="noNotifications">{{navigatorService.Dictionary?.NotificationEmpty}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12 " style="padding-left: 15px;padding-right: 16px;">
                  <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
                </div>
            </div>
        </mat-dialog-content>
    </div>


</div>
