import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfigService {

  apiUrl!: string|null;
  version!: string|null;
  enableChat!: boolean;
  appName!: string|null;
  defaultLanguage!: string|null;
  chatUrl!: string|null;
  appId!: string|null;
  logo!: string|null;
  constructor(private http: HttpClient) { 
   
  }

  Load(): Promise<any> {
    const promise = this.http.get('/' + environment.configFile)
      .toPromise()
      .then(data => {
        Object.assign(this, data);
        return data;
      });

    return promise;
  }
}
