<div id="{{'box-signature_'+Page}}" #box class="resizable-draggable" [style.width.px]="width" [style.height.px]="height"
    cdkDragBoundary="{{'#img_page_'+Page}}" cdkDrag
    [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
    [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)"
    (window:mouseup)="setStatus($event, 0)">
    <img id="{{'sign_' + Page}}" [src]="'data:image/jpeg;base64,'+Image" [style.width.px]="width"
        [style.height.px]="height">
    <div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    <!-- <span>{{width | number:'1.0-0'}}px</span>
    <span>{{height | number:'1.0-0'}}px</span>
    <span>({{left}}, {{top}})</span> -->
</div>