import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentComponent } from '../../../dialog-menu/document/document.component';
import { FilterDocumentComponent } from '../../../dialog-menu/filter-document/filter-document.component';
import { GenericConfirmDialogComponent } from '../../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { TipologyComponent } from '../../../dialog-menu/tipology/tipology.component';
import { SignatureComponent } from '../../../dialog-menu/signature/signature.component';
import { VerifySignatureComponent } from '../../../dialog-menu/signature/verify-signature/verify-signature.component';
import { AttachedDocumentComponent } from '../../../dialog-menu/attached-document/attached-document.component';
import { EncryptComponent } from '../../../dialog-menu/encrypt/encrypt.component';
import { AddToDossierComponent } from '../../../dialog-menu/add-to-dossier/add-to-dossier.component';
import { PagerComponent } from '../../../../custom-components/pager/pager.component';
import { SearchComponent } from '../../../../custom-components/search/search.component';
import { TipologyDto, TipologyFilter, TipologyModel } from '../../../../models/TipologyModel';
import { MimeTypeDto, MimeTypeModel } from '../../../../models/MimeTypeModel';
import { TipologyController } from '../../../../controllers/TipologyController';
import { WorkflowDataController } from '../../../../../workflow/controllers/WorkflowDataController';
import { MetadataTipologyFilter, MetadataTipologyModel } from '../../../../models/MetadataTipologyModel';
import { MetaDataTipologyController } from '../../../../controllers/MetaDataTipologyController';
import { ObjectDto, ObjectFilter, ObjectModel } from '../../../../models/ObjectModel';
import { ObjectController } from '../../../../controllers/ObjectController';
import { FileManagerController } from '../../../../controllers/FileManagerController';
import { Constants, DocumentState, FileMenu, GenericDialogAction, PageAction, Pages } from '../../../../itdoc.configuration';
import { NavigatorService } from '../../../../../navigator.services';
import { AuthService } from '../../../../../auth.service';
import { CommonService } from '../../../../../common.service';
import { DataService } from '../../../../../data.service';
import { BaseOrder } from '../../../../../models/BaseModel';
import { GenericDialogModel } from '../../../../models/GenericDialogModel';
import { WorkflowUserDto } from '../../../../../workflow/models/WorkflowUserModel';

@Component({
  selector: 'app-tipology-detail',
  templateUrl: './tipology-detail.component.html',
  styleUrls: ['./tipology-detail.component.scss']
})
export class TipologyDetailComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;

  @Input() TipologyDto: TipologyDto;
  @Input() MimeTypeModel: MimeTypeModel;
  @Output() GoBackEmit = new EventEmitter<any>();

  Controller: TipologyController;
  WorkflowDataController: WorkflowDataController;

  MimetypesDtos: MimeTypeDto[];
  MetaDataTipologyModel: MetadataTipologyModel;
  MetaDataTipologyController: MetaDataTipologyController;

  ObjectModel: ObjectModel;
  ObjectController: ObjectController;
  DataSource: MatTableDataSource<ObjectDto>;

  FileManagerController: FileManagerController;
  CurrentFolder: ObjectDto;

  ND = Constants.ND.toString();
  FileMenu: Array<any>;
  FilterCount = 0;
  Filter: ObjectFilter;
  constructor(
    dataService: DataService, public navigatorService: NavigatorService, public authenticationService: AuthService,
    public commonService: CommonService
  ) {
    this.MetaDataTipologyModel = new MetadataTipologyModel();
    this.MetaDataTipologyController = new MetaDataTipologyController(dataService);
    this.MimetypesDtos = [];

    this.ObjectModel = new ObjectModel();
    this.ObjectController = new ObjectController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
    this.Controller = new TipologyController(dataService);
    this.WorkflowDataController = new WorkflowDataController(dataService);

  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    this.MetaDataTipologyModel.Filter = new MetadataTipologyFilter();
    this.MetaDataTipologyModel.Filter.TipologyId = this.TipologyDto.Id;
    this.MetaDataTipologyModel = await this.MetaDataTipologyController.Get(this.MetaDataTipologyModel);
    if (this.MetaDataTipologyModel.Performed) {
      this.MetaDataTipologyModel.Dtos.map(x => x.PropertiesJson = (x.Properties ? JSON.parse(x.Properties) : null));
    }

    if (this.TipologyDto.MimeTypes) {
      const mimetipesArray = this.TipologyDto.MimeTypes.split(',');
      this.MimetypesDtos = this.MimeTypeModel.Dtos.filter(x => mimetipesArray.includes(x.MimeType));
      for (const dto of this.MimetypesDtos) {
        this.commonService.SetMimeTypeChipStyle(dto);
      }
    }
    await this.GetObjects();
    this.navigatorService.StopLoading();
  }

  async GetObjects(dto: ObjectDto = null, search: string = null, page = 1, filter = null) {
    if (this.authenticationService.ITDocAccount?.Dto?.Id > 0) {
      this.ObjectModel.Search = this.SearchComponent?.GetSearchValue();
      if (filter) {
        this.ObjectModel.Filter = filter;
      } else {
        this.ObjectModel.Filter = new ObjectFilter();
        this.ObjectModel.Filter.State = [DocumentState.PROGRESS, DocumentState.REVISION,
        DocumentState.APPROVAL, DocumentState.EDIT, DocumentState.SIGNED, DocumentState.APPROVED,
        DocumentState.PROTOCOLLED, DocumentState.ARCHIVED, DocumentState.REJECTED];
        this.ObjectModel.Filter.Deep = null;
      }
      this.ObjectModel.Filter.Type = 'file';
      this.ObjectModel.Filter.Creator = this.authenticationService.CurrentUser.Username;
      this.ObjectModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      this.ObjectModel.Filter.TipologyId = this.TipologyDto.Id;
      this.CurrentFolder = dto?.Type === 'folder' ? dto : await this.ReadObject(dto?.Parent);

      this.ObjectModel.Order = new BaseOrder();
      this.ObjectModel.Order.Name = 'Id';
      this.ObjectModel.Order.Direction = 'desc';

      this.ObjectModel = await this.ObjectController.Load(this.ObjectModel);
      if (dto && this.ObjectModel.Performed && this.ObjectModel.Dtos.length > 0) {
        if (dto.Type === 'file') {
          this.ObjectModel.Dtos.find(q => q.Id === dto.Id).Selected = true;
        }
      }
      this.Paging(page);
    }
  }

  async Refresh(dto) {
    this.navigatorService.StartLoading();
    this.ObjectModel = new ObjectModel();
    this.CurrentFolder = null;
    this.TipologyDto = dto;
    await this.Load();
    this.navigatorService.StopLoading();
  }

  async OpenObject(row: ObjectDto) {
    if (row.Type === 'folder') {
      this.navigatorService.StartLoading();
      await this.GetObjects(row);
      this.navigatorService.StopLoading();
    } else if (row.Type === 'file') {
      this.ObjectModel.Dtos.map(x => x.Selected = false);
      await this.commonService.OpenObject(row, this.navigatorService);
    }
  }

  CheckRow(event: any, dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      dto.Checked = event.checked;
    }
  }

  SelectRow(dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      this.ObjectModel.Dtos.map(o => o.Selected = false);
      dto.Selected = true;
    }
  }

  async ReadObject(parent) {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    model.Filter.Id = parent;
    model = await this.ObjectController.Read(model);
    return model?.Dto ?? null;
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    if (searchText) {
      this.ObjectModel.Skip = 0;
      await this.GetObjects(null, searchText);
    } else {
      this.ObjectModel.Skip = 0;
      await this.GetObjects();
    }
    this.navigatorService.StopLoading();
  }

  Update() {
    this.navigatorService.PageAction = PageAction.Edit;
    this.navigatorService.ShowDialog(TipologyComponent, this.TipologyDto.Id, '45%', 'fit-content', '200px', async (result) => {
      if (result) {
        this.navigatorService.StartLoading();
        let model = new TipologyModel();
        model.Filter = new TipologyFilter();
        model.Filter.Id = this.TipologyDto.Id;
        model = await this.Controller.Read(model);
        if (model?.Performed) {
          this.TipologyDto = model.Dto;
          await this.Load();
        }
      }
    });
  }

  async Delete() {
    if (this.TipologyDto) {
      const data = new GenericDialogModel();
      data.Name = this.TipologyDto?.Name;
      data.Icon = '../../../../../assets/icon/tipology-black.svg';
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
        this.navigatorService.Dictionary?.ToDelete + '<br>' + this.TipologyDto.Name + '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
        if (performed === GenericDialogAction.CONFIRM) {
          this.navigatorService.StartLoading();
          let model = new TipologyModel();
          model.Filter = new TipologyFilter();
          model.Filter.Id = this.TipologyDto.Id;
          model = await this.Controller.Delete(model);
          if (model?.Performed) {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.DeleteSuccess, data.Name);
            this.navigatorService.StartLoading();
            this.GoBack();
            this.navigatorService.StopLoading();
          } else {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
          }
        }
        this.navigatorService.StopLoading();
      });
    }
  }

  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    let folder = this.CurrentFolder;
    if (this.CurrentFolder?.Deep > 1) {
      this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
      folder = this.CurrentFolder;
    } else {
      this.CurrentFolder = null;
      folder = null;
    }
    await this.GetObjects(folder, null, this.Pager.CurrentPage);
    this.navigatorService.StopLoading();
  }


  RefreshTable(dtos: Array<ObjectDto>) {
    for (const dto of dtos) {
      if (!dto.ClassIcon && !dto.Icon) {
        this.commonService.GetIcon(dto);
      }
      dto.Team = new Array<WorkflowUserDto>();
      if (dto.WorkflowData && dto.WorkflowData.WorkflowSteps) {
        for (const step of dto?.WorkflowData?.WorkflowSteps) {
          for (const user of step.WorkflowStepUsers) {
            const find = dto.Team.find(u => u.AccountId === user.AccountId);
            if (!find) {
              dto.Team.push(user);
            }
          }
        }
      }
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.ObjectModel;
    this.Pager.Paging(page);
    this.RefreshTable(this.ObjectModel.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetObjects(null, null, page, this.Filter);
    this.navigatorService.StopLoading();
  }

  GoBack() {
    this.GoBackEmit.emit();
  }

  GetMenuFile(dto: ObjectDto) {
    const menu = new FileMenu(this.navigatorService.Dictionary, dto, this.authenticationService.ITDocAccount?.Dto);
    this.FileMenu = menu.GetMenu();
  }

  async ActionMenu(action: string, dto: ObjectDto) {
    if (action === 'edit') {
      this.navigatorService.PageAction = PageAction.Edit;
      this.navigatorService.ShowDialog(DocumentComponent, dto.Id, '45%', 'fit-content', '200px', async (response) => {
        if (response) {
          await this.GetObjects(response, null, this.Pager.CurrentPage);
        }
      });
    } else if (action === 'archive') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.Archive;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToArchive + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Archive;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.ARCHIVED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.ARCHIVED;
                model.Dto = dto;
                model.Action = DocumentState.ARCHIVED;
                model.UpdateProperties = ['State', 'ModificationDate'];
                model = await this.ObjectController.CreateOrUpdate(model);
                if (model?.Performed) {
                  const name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.Archived, name);
                  await this.GetObjects(null, null, this.Pager.CurrentPage);
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'delete') {
      const data = new GenericDialogModel();
      data.Icon = '../../../../../assets/icon/big-doc.svg';
      data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
        this.navigatorService.Dictionary?.ToDelete + '<br>' + data.Name + '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
        if (performed === GenericDialogAction.CONFIRM) {
          this.navigatorService.StartLoading();
          let model = new ObjectModel();
          dto.Trashed = true;
          model.Dto = dto;
          model.Action = 'TRASHED';
          model.UpdateProperties = ['Trashed'];
          model = await this.ObjectController.CreateOrUpdate(model);
          if (model?.Performed) {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.TrashedFile, dto.Name);
            await this.GetObjects(null, null, this.Pager.CurrentPage);
          } else {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
          }
        }
        this.navigatorService.StopLoading();
      });
    } else if (action === 'collate') {
      this.navigatorService.ShowDialog(AddToDossierComponent, [dto], '45%', 'fit-content', '200px');

    } else if (action === 'protocol') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.ProtocolTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.SendToProtocol + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Protocol;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.PROTOCOLLED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.PROTOCOLLED;
                model = await this.ObjectController.Protocol(model);
                if (model?.Performed) {
                 // const fileModel = await this.commonService.ConvertDocument(model.Dto);
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.StateProtocolled, data.Name);
                  await this.GetObjects(null, null, this.Pager.CurrentPage);
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'sendToApprovation') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.SendToApprovationTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.SendToApprovation + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Send;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.REVISIONED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.APPROVAL;
                dto.Lock = true;
                dto.Version = (parseFloat(dto.Version.toString()) + 0.1);
                model.Dto = dto;
                model.Action = DocumentState.REVISIONED;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate', 'Version'];
                model = await this.ObjectController.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.SendToApprovationSuccess, data.Name);
                  await this.GetObjects(null, null, this.Pager.CurrentPage);
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'sendToRevision') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.SendToRevisionTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.SendToRevision + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Send;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.REVISION);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.REVISION;
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.REVISION;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate'];
                model = await this.ObjectController.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.SendToRevisionSuccess, data.Name);
                  await this.GetObjects(null, null, this.Pager.CurrentPage);
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'approve') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.Approve;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToApprove + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Approve;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.APPROVED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.APPROVED;
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.APPROVED;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate'];
                model = await this.ObjectController.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.StateApproved, data.Name);
                  await this.GetObjects(null, null, this.Pager.CurrentPage);
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'requestEditing') {
      if (dto.Authorization.Authorized && dto.Authorization.RequestEditing) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.RequestEditingTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToRequestEditing + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Send;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.EDIT);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.EDIT;
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.EDIT;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate'];
                model = await this.ObjectController.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RequestEditingSuccess, data.Name);
                  await this.GetObjects(null, null, this.Pager.CurrentPage);
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'reject') {
      if (dto.Authorization.Authorized) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name = dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.RejectTitle;
        data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
          this.navigatorService.Dictionary?.ToReject + '<br>' + data.Name + '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Reject;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const response = await this.WorkflowDataController.
              Run(dto.Id, dto.WorkflowId, dto.WorkflowData.Id, DocumentState.PROGRESS, DocumentState.REJECTED);
            if (response?.Performed) {
              if (response.Code === 401) {
                this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
              } else {
                let model = new ObjectModel();
                dto.State = DocumentState.REJECTED;
                dto.Lock = true;
                model.Dto = dto;
                model.Action = DocumentState.REJECTED;
                model.UpdateProperties = ['State', 'Lock', 'ModificationDate'];
                model = await this.ObjectController.CreateOrUpdate(model);
                if (model?.Performed) {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RejectedSuccess, data.Name);
                  await this.GetObjects(null, null, this.Pager.CurrentPage);
                } else {
                  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
                }
              }
            }
            this.navigatorService.StopLoading();
          }
        });
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.NotAuthorizedMessage);
      }
    } else if (action === 'sign') {
      if (dto.IsSignatory) {
        this.navigatorService.ShowDialog(SignatureComponent, dto, '45%', 'fit-content', '200px', async (response) => {
          if (response) {
            this.navigatorService.StartLoading();
            await this.GetObjects(response, null, this.Pager.CurrentPage);
            this.navigatorService.StopLoading();
          }
        });
      }
    } else if (action === 'sign-verify') {
      if (dto.IsSignatory) {
        const data = { Dto: dto };
        this.navigatorService.ShowDialog(VerifySignatureComponent, data, '45%', 'fit-content', '200px');
      }
    } else if (action === 'attached') {
      const data = { Dto: dto };
      this.navigatorService.ShowDialog(AttachedDocumentComponent, data, '45%', 'fit-content', '200px', async (response: any) => {
        if (response) { }
      });
    } else if (action === 'encrypt' || action === 'decrypt') {
      if (dto.Type === 'file') {
        const data = { Dto: dto, SoftDescrypt: false };
        this.navigatorService.ShowDialog(EncryptComponent, data, '45%', 'fit-content', '200px', async (response) => {
          if (response?.Performed) {
            dto = response.Dto;
          }
        });
      }
    }
  }

  OpenFiltersDialog() {
    const data = { View: Pages.Documents, Filter: this.Filter };
    // tslint:disable-next-line:max-line-length
    this.navigatorService.ShowDialog(FilterDocumentComponent, data, '45%', 'fit-content', '200px', async (response: any) => {
      if (response) {
        this.navigatorService.StartLoading();
        this.FilterCount = response.FilterCount;
        this.Filter = response.Filter;
        this.ObjectModel.Skip = 0;
        if (this.FilterCount > 0) {
          await this.GetObjects(null, null, 1, response.Filter);
        } else {
          this.ObjectModel = new ObjectModel();
          await this.GetObjects();
        }
        this.navigatorService.StopLoading();
      }
    });
  }
}
