<div class="itdoc-page itdoc-app-container-box">
  <div class="form mt-3">
    <div class="row margin-bottom">
      <div class="col-12 col-md-10">
        <h1>{{navigatorService.Dictionary?.NewPdaTitle}}</h1>
        <p class="description mb-5">{{navigatorService.Dictionary?.NewPdaDescription}}</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col col-lg-3">
        <mat-form-field class="form-field">
          <mat-label class="placeholder">{{navigatorService.Dictionary?.DocClass}}</mat-label>
          <input class="input-text" matInput type="text" value="{{DocClass}}" disabled>
        </mat-form-field>
      </div>
      <div class="col col-lg-2">
        <app-input [DataValue]="Model.Dto" [Disabled]="true" [Placeholder]="'Nome del pacchetto'"
          [Property]="'PdaName'">
        </app-input>
      </div>
      <div class="col col-lg-2">
        <app-input [DataValue]="Model.Dto" [Disabled]="true" [Placeholder]="'ID pacchetto di versamento'"
          [Property]="'PdvId'">
        </app-input>
      </div>
      <div class="col col-lg-2">
        <mat-form-field class="form-field">
          <mat-label class="placeholder">{{navigatorService.Dictionary?.DocumentNumber}}</mat-label>
          <input class="input-text" matInput type="text" value="{{Model.Dtos.length}}" disabled>
        </mat-form-field>
        <span class="description-input">Max 5000</span>

      </div>
      <div class="col col-lg-3 text-center">
        <button (click)="AddDocuments()" mat-button class="it-doc-btn-primary w-100" [disabled]="IsFilesLimit">
          <span class="button-text">{{navigatorService.Dictionary?.AddDocuments.toUpperCase()}}</span>
        </button>
      </div>
    </div>
    <div class="data-container">
      <mat-accordion *ngFor="let file of DataSource;let i =index">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="header-panel">
            <mat-panel-title>
              <span class="title">{{navigatorService.Dictionary?.Document}} #{{Model.Skip+(i+1)}}</span>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon (click)="DeleteFile(file)" class="icon-delete" matTooltip="elimina documento">delete
              </mat-icon>
              <span class="description" matTooltip="file.File.Filename">{{file.File.Filename}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="mt-3">
            <app-picker [Type]="'datetime'" [Culture]="Culture" [Placeholder]="'Data documento'"
              [FormatDate]="'dd/MM/yyyy HH:mm'" [Property]="'ClosingDate'" [ReadOnly]="true" [DataValue]="file.File"
              [Disabled]="true"></app-picker>

            <ng-container *ngFor="let singleMetadata of file.File.Metadata.Mandatory.SingleMetadata">
              <app-input *ngIf="singleMetadata.required && !singleMetadata.type" [Property]="'value'"
                [DataValue]="singleMetadata" [Disabled]="false" [Placeholder]="singleMetadata.title">
              </app-input>
              <app-input *ngIf="singleMetadata.required && singleMetadata.type==='Integer'" [Type]="singleMetadata.type"
                [Property]="'value'" [DataValue]="singleMetadata" [Disabled]="false"
                [Placeholder]="singleMetadata.title">
              </app-input>

              <div *ngIf="singleMetadata.type==='DateTime'" style="position: relative;">
                <app-picker *ngIf="singleMetadata.required && singleMetadata.type==='DateTime'" [Type]="'datetime'"
                  [Culture]="Culture" [Placeholder]="singleMetadata.title" [FormatDate]="'dd/MM/yyyy HH:mm'"
                  [Property]="'value'" [ReadOnly]="false" [DataValue]="singleMetadata"></app-picker>
              </div>
              <app-picker *ngIf="singleMetadata.required && singleMetadata.type==='Date'"
                [Placeholder]="singleMetadata.title" [FormatDate]="'dd/MM/yyyy'" [Property]="'value'" [ReadOnly]="true"
                [DataValue]="singleMetadata"></app-picker>
            </ng-container>
          </div>
          <!--ComplexMetadata -->
          <ng-container *ngFor="let complexMetadata of file.File.Metadata.Mandatory.ComplexMetadata;">
            <mat-expansion-panel *ngIf="complexMetadata.required" (opened)="PanelOpenState = !PanelOpenState"
              (closed)="PanelOpenState = !PanelOpenState">
              <mat-expansion-panel-header class="header-panel">
                <mat-panel-title>
                  <span class="title"> {{complexMetadata.Title}}</span>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="mt-3">
                <ng-container *ngFor="let item of GetComplexMetadataArray(complexMetadata);">
                  <app-input *ngIf="!item.type" [Property]="'value'" [DataValue]="item" [Disabled]="false"
                    [Placeholder]="item.title">
                  </app-input>
                  <app-input *ngIf=" item.type" [Type]="item.type" [Property]="'value'" [DataValue]="item"
                    [Disabled]="false" [Placeholder]="item.title">
                  </app-input>

                  <div *ngIf="item.type==='DateTime'" class="mt-2 mb-2">
                    <app-picker *ngIf="item.type==='DateTime'" [Type]="'datetime'" [Culture]="Culture"
                      [Placeholder]="item.title" [FormatDate]="'dd/MM/yyyy HH:mm'" [Property]="'value'"
                      [ReadOnly]="false" [DataValue]="item"></app-picker>
                  </div>
                  <app-picker *ngIf="item.type==='Date'" [Placeholder]="item.title" [FormatDate]="'dd/MM/yyyy'"
                    [Property]="'value'" [ReadOnly]="true" [DataValue]="item"></app-picker>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </ng-container>

          <!-- Metadati previsti ma non obbligatori -->
          <mat-expansion-panel (opened)="PanelOpenState = !PanelOpenState" (closed)="PanelOpenState = !PanelOpenState"
            togglePosition="before">
            <mat-expansion-panel-header class="header-panel">
              <mat-panel-title>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
              <span class="title"> Metadati previsti ma non obbligatori</span>
            </mat-expansion-panel-header>
            <div class="mt-3">
              <ng-container *ngFor="let singleMetadata of file.File.Metadata.Mandatory.SingleMetadata">
                <app-input *ngIf="!singleMetadata.required && !singleMetadata.type" [Property]="'value'"
                  [DataValue]="singleMetadata" [Disabled]="false" [Placeholder]="singleMetadata.title">
                </app-input>
                <app-input *ngIf="!singleMetadata.required && singleMetadata.type==='Integer'"
                  [Type]="singleMetadata.type" [Property]="'value'" [DataValue]="singleMetadata" [Disabled]="false"
                  [Placeholder]="singleMetadata.title">
                </app-input>

                <div *ngIf="singleMetadata.type==='DateTime'" class="mt-2 mb-2">
                  <app-picker *ngIf="!singleMetadata.required && singleMetadata.type==='DateTime'" [Type]="'datetime'"
                    [Culture]="Culture" [Placeholder]="singleMetadata.title" [FormatDate]="'dd/MM/yyyy HH:mm'"
                    [Property]="'value'" [ReadOnly]="false" [DataValue]="singleMetadata"></app-picker>
                </div>
                <app-picker *ngIf="!singleMetadata.required && singleMetadata.type==='Date'"
                  [Placeholder]="singleMetadata.title" [FormatDate]="'dd/MM/yyyy'" [Property]="'value'"
                  [ReadOnly]="true" [DataValue]="singleMetadata"></app-picker>
              </ng-container>
            </div>
            <!--ComplexMetadata -->
            <ng-container *ngFor="let complexMetadata of file.File.Metadata.Mandatory.ComplexMetadata">
              <mat-expansion-panel *ngIf="!complexMetadata.required" (opened)="PanelOpenState = !PanelOpenState"
                (closed)="PanelOpenState = !PanelOpenState">
                <mat-expansion-panel-header class="header-panel">
                  <mat-panel-title>
                    <span class="title"> {{complexMetadata.Title}}</span>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="mt-3">
                  <ng-container *ngFor="let item of GetComplexMetadataArray(complexMetadata);">
                    <app-input *ngIf="!item.type" [Property]="'value'" [DataValue]="item" [Disabled]="false"
                      [Placeholder]="item.title">
                    </app-input>
                    <app-input *ngIf=" item.type" [Type]="item.type" [Property]="'value'" [DataValue]="item"
                      [Disabled]="false" [Placeholder]="item.title">
                    </app-input>

                    <div *ngIf="item.type==='DateTime'" class="mt-2 mb-2">
                      <app-picker *ngIf="item.type==='DateTime'" [Type]="'datetime'" [Culture]="Culture"
                        [Placeholder]="item.title" [FormatDate]="'dd/MM/yyyy HH:mm'" [Property]="'value'"
                        [ReadOnly]="false" [DataValue]="item"></app-picker>
                    </div>
                    <app-picker *ngIf="item.type==='Date'" [Placeholder]="item.title" [FormatDate]="'dd/MM/yyyy'"
                      [Property]="'value'" [ReadOnly]="true" [DataValue]="item"></app-picker>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-expansion-panel>

          <!--ExtraInfos -->
          <mat-expansion-panel (opened)="PanelOpenState = !PanelOpenState" (closed)="PanelOpenState = !PanelOpenState"
            togglePosition="before">
            <mat-expansion-panel-header class="header-panel">
              <mat-panel-title>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
              <span class="title"> INFORMAZIONI AGGIUNTIVE</span>
            </mat-expansion-panel-header>
            <div class="mt-3">
              <ng-container *ngFor="let extraInfo of file.File.Metadata.ExtraInfos">
                <app-input *ngIf="!extraInfo.type" [Property]="'value'" [DataValue]="extraInfo" [Disabled]="false"
                  [Placeholder]="extraInfo.title">
                </app-input>
                <app-input *ngIf=" extraInfo.type" [Type]="extraInfo.type" [Property]="'value'" [DataValue]="extraInfo"
                  [Disabled]="false" [Placeholder]="extraInfo.title">
                </app-input>

                <div *ngIf="extraInfo.type==='DateTime'" class="mt-2 mb-2">
                  <app-picker *ngIf="extraInfo.type==='DateTime'" [Type]="'datetime'" [Culture]="Culture"
                    [Placeholder]="extraInfo.title" [FormatDate]="'dd/MM/yyyy HH:mm'" [Property]="'value'"
                    [ReadOnly]="false" [DataValue]="extraInfo"></app-picker>
                </div>
                <app-picker *ngIf="extraInfo.type==='Date'" [Placeholder]="extraInfo.title" [FormatDate]="'dd/MM/yyyy'"
                  [Property]="'value'" [ReadOnly]="true" [DataValue]="extraInfo"></app-picker>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
        </div>
      </div>
      <div *ngIf="!navigatorService.Loading && Model?.Dto?.Files?.length>0" class="row mt-3"
        style="flex-direction: row-reverse;">
        <button (click)="SendPdv()" [disabled]="IsFilesLimit" mat-button class="it-doc-btn-primary"
          style="width: fit-content;">
          <span class="button-text">{{navigatorService.Dictionary?.GeneratePda.toUpperCase()}}</span>
        </button>
        <button (click)="Cancel()" mat-button class="it-doc-btn-cancel" style="width: fit-content;margin-right: 20px;">
          <span class="button-text">{{navigatorService.Dictionary?.Cancel.toUpperCase()}}</span>
        </button>

      </div>
    </div>
    <div *ngIf="!navigatorService.Loading && Model?.Dto?.Files?.length<=0" class="m-0 empty-table-box"
      style="padding-bottom: 200px;">
      <img src="../../../../../assets/icon/document-empty.svg">
      <div class="description">{{navigatorService.Dictionary?.EmptyDocumentMessage}}</div>
    </div>
  </div>

</div>
