<div class="itdoc-page itdoc-app-container-box">
    <div class="row m-0">
        <div class="col-md-12 p-0">
            <div class="user-menu-container">
                <img *ngIf="authenticationService?.CurrentUser?.Avatar" class="profile-picture d-inline"
                    src="data:image/png;base64,{{authenticationService?.CurrentUser?.Avatar}}">
                <span class="user-name-txt d-inline">{{authenticationService?.CurrentUser?.Displayname}}</span>
            </div>
        </div>
    </div>

    <div class="row m-0">
        <div class="col-md-12 p-0">
            <div class="title-header-container">
                <div class="title-container ">
                    <div class="grid-container">
                        <div class="grid-column" (click)='Load(1)' [ngClass]="Tab===1?'tab-selected':''">
                            <h2>{{navigatorService.Dictionary?.PrincipaInfo}}</h2>
                        </div>
                        <div class="grid-column text-center" (click)='Load(2)' [ngClass]="Tab===2?'tab-selected':''">
                            <h2>{{navigatorService.Dictionary?.Roles}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="Tab===1">
        <div class="row">
            <div class="col-md-12">
                <div class="row-info">
                    <div class="row">
                        <div class="col-11">
                            <h1 class="title">{{navigatorService.Dictionary?.TitleInfo1}}</h1>
                        </div>
                        <div class="col-1 text-center">
                            <button mat-icon-button (click)="Edit(1)">
                                <mat-icon class="material-icons-outlined ">edit</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="field-name">{{navigatorService.Dictionary?.Name}}</p>
                            <p class="field-value">{{authenticationService?.CurrentUser.Name || '-'}}</p>
                        </div>
                        <div class="col-md-4 ">
                            <p class="field-name">{{navigatorService.Dictionary?.Surname}}</p>
                            <p class="field-value">{{authenticationService?.CurrentUser.Surname || '-'}}</p>
                        </div>
                        <div class="col-md-4 ">
                            <p class="field-name">{{navigatorService.Dictionary?.Birthdate}}</p>
                            <p class="field-value">{{authenticationService?.CurrentUser.Birthdate | date:'dd/MM/yyyy' ||
                                '-'}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="field-name">{{navigatorService.Dictionary?.Language}}</p>
                            <p class="field-value">
                                {{commonService.GetLanguage(authenticationService?.CurrentUser.Language,navigatorService.Dictionary)}}
                            </p>
                        </div>
                        <div class="col-md-4 ">

                        </div>
                        <div class="col-md-4 ">

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="row-info">
                    <div class="row">
                        <div class="col-11">
                            <h1 class="title">{{navigatorService.Dictionary?.TitleInfo2}}</h1>
                        </div>
                        <div class="col-1 text-center">
                            <button mat-icon-button (click)="Edit(2)">
                                <mat-icon class="material-icons-outlined">edit</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="field-name">{{navigatorService.Dictionary?.Email}}</p>
                            <p class="field-value">{{authenticationService?.CurrentUser.Email || '-'}}</p>
                        </div>
                        <div class="col-md-4 ">
                            <p class="field-name">{{navigatorService.Dictionary?.Password}}</p>
                            <p class="field-value">********</p>
                        </div>
                        <div class="col-md-4 ">
                            <p class="field-name">{{navigatorService.Dictionary?.Phone}}</p>
                            <p class="field-value">{{authenticationService?.CurrentUser.Phone || '-'}}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="row-info">
                    <div class="row">
                        <div class="col-11">
                            <h1 class="title">{{navigatorService.Dictionary?.TitleInfo4}}</h1>
                        </div>
                        <div class="col-1 text-center">
                            <button mat-icon-button (click)="Edit(3)">
                                <mat-icon class="material-icons-outlined">edit</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="field-name">{{navigatorService.Dictionary?.Password}}</p>
                            <p class="field-value">{{authenticationService.CurrentUser.IsSetEncryptionPassword?
                                '********':'-'}}</p>
                        </div>
                        <div class="col-md-4 ">
                        </div>
                        <div class="col-md-4 ">
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="row-info">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="title">{{navigatorService.Dictionary?.TitleInfo3}}</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="field-name">{{navigatorService.Dictionary?.ItDocsRole}}</p>
                            <p class="field-value">{{commonService.GetUserRole(authenticationService?.CurrentUser)}}</p>
                        </div>
                        <div class="col-md-4 ">

                        </div>
                        <div class="col-md-4 ">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="Tab===2">
        <div class="row" style="height: 100%;">
            <div class="col-md-12">
                <div class="form mt-3">
                    <div *ngIf="UOModel?.Dtos?.length > 0" class="row w-100 table-uo-header">
                        <div class="it-doc-table-container">
                            <table mat-table [dataSource]="UODataSource" matSort *ngIf="navigatorService.Dictionary">
                                <ng-container matColumnDef="{{navigatorService.Dictionary?.Name}}">
                                    <th mat-header-cell *matHeaderCellDef>{{navigatorService.Dictionary?.Name}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{element.Name}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.OfficeCodePlaceHolder}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{navigatorService.Dictionary?.OfficeCodePlaceHolder}}
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{element.OfficeCode}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.Description}}">
                                    <th mat-header-cell *matHeaderCellDef>{{navigatorService.Dictionary?.Description}}
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{element.Description}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.Role}}">
                                    <th mat-header-cell *matHeaderCellDef>{{navigatorService.Dictionary?.Role}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>
                                            {{commonService.GetDisplayITDocsRole(element.CurrentUserRole,navigatorService.Dictionary)}}
                                        </span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="[
                                        navigatorService.Dictionary?.Name,
                                        navigatorService.Dictionary?.OfficeCodePlaceHolder,
                                        navigatorService.Dictionary?.Description,
                                        navigatorService.Dictionary?.Role,

                                    ]">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: [
                                        navigatorService.Dictionary?.Name,
                                        navigatorService.Dictionary?.OfficeCodePlaceHolder,
                                        navigatorService.Dictionary?.Description,
                                        navigatorService.Dictionary?.Role,

                                    ];">
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="UOModel?.Dtos?.length <=0 && !navigatorService.Loading" class="empty-table-box">
                        <img src="../../assets/icon/uo-empty-icon.svg">
                        <div class="description">{{navigatorService.Dictionary?.EmptyUoViewMessage}}</div>
                    </div>

                </div>
            </div>
        </div>


    </div>
