import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { throwError } from 'rxjs';
import { NotificationHistoryComponent } from './notification-history/notification-history.component';
import { ItCoreController } from '../../controllers/ItCoreController';
import { LoginController } from '../../controllers/Login';
import { NavigatorService } from '../../navigator.services';
import { AuthService } from '../../auth.service';
import { DataService } from '../../data.service';
import { CommonService } from '../../common.service';
import { AppConfigService } from '../../app.config.service';
import { ChatService } from '../../chat.service';
import { NotificationDto, NotificationFilter, NotificationModel } from '../../models/NotificationModel';
import { browserRefresh } from '../../app.component';
import { Pages } from '../../itdoc/itdoc.configuration';


declare var initializeChat: Function;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('toggleButtonNotif') toggleButton: ElementRef;
  @ViewChild('dropdownNotif') menu: ElementRef;
  Open = false;
  chatOpened = false;
  IsNotificationsRefresh = false;
  Interval: any;//NodeJS.Timer;
  ItCoreController: ItCoreController;
  LoginController: LoginController;
  constructor(
    private renderer: Renderer2,
    public navigatorService: NavigatorService,
    public authService: AuthService,
    private dataService: DataService,
    public commonService: CommonService,
    public configAppService: AppConfigService,
    public chatService: ChatService
  ) {
    this.commonService.NotificationModel = new NotificationModel();
    this.ItCoreController = new ItCoreController(dataService);
    this.LoginController = new LoginController(authService, chatService);
  }

  ngOnInit(): void {
    this.renderer.listen('window', 'click', (e: any) => {
      if (!this.toggleButton.nativeElement.contains(e.target) &&
        !this.menu.nativeElement.contains(e.target) &&
        e.target.id !== 'delete-single-notification' &&
        e.target.id !== 'read-all-notif') {
        this.Open = false;
      }
    });

    // Chat initialize
    const converse = document.getElementById('conversejs');
    if (browserRefresh) {
      if (this.configAppService.enableChat) {
        this.chatService.setChatBadge();
        if (!converse) {
          try {
            const settings = this.chatService.setChatSettings();
            initializeChat(settings);
          } catch (e) {
            throwError('Something bad happened with chat initialization;').subscribe();
          }
        }
      }
    } else {
      if (!converse && this.configAppService.enableChat) {
        this.chatService.setChatBadge();
        if (!converse) {
          try {
            const settings = this.chatService.setChatSettings();
            initializeChat(settings);
          } catch (e) {
            throwError('Something bad happened with chat initialization;').subscribe();
          }
        }
      }
    }
    // End chat initialize

    this.Load();
  }

  async Load() {
    await this.navigatorService.Wait();
    await this.GetNotifications();
    // check notifications every 1 minute
    this.UpdateNotifications();
  }


  async GetNotifications() {
    this.commonService.NotificationModel.Filter = new NotificationFilter();
    this.commonService.NotificationModel.Filter.Username = this.authService?.CurrentUser?.Username;
    if (this.commonService.NotificationModel.Filter.Username) {
      this.commonService.NotificationModel = await this.ItCoreController.GetNotification(this.commonService.NotificationModel);
      if (this.commonService.NotificationModel?.Performed) {
        for (const dto of this.commonService.NotificationModel.Dtos) {
          this.commonService.ParseNotification(dto, this.authService.CurrentUser.Username, this.navigatorService.Dictionary);
        }
      }
    }
  }

  async GetNotificationHistory() {
    this.navigatorService.ShowDialog(NotificationHistoryComponent, null, '45%', 'fit-content', '200px');
  }

  async Logout(): Promise<void> {
    const response = await this.LoginController.Logout();
    this.navigatorService.GoToExternal(Pages.Login);
  }

  async RedirectNotification(dto: NotificationDto) {
    await this.DeleteNotification(dto.id);
    if (dto?.data) {
      switch (dto.data.Type) {
        case 'NEWINTITATION': {

          break;
        }
        case 'USEREDIT': {

          break;
        }
        case 'USEREDELETE': {

          break;
        }
        case 'RESETPASSWORD': {

          break;
        }
        case 'NEWWORKFLOW': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewWorkflow) {
              this.commonService?.CurrentPageComponent?.Load(2);
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewWorkflow, this.navigatorService);
            }
          }
          break;
        }
        case 'EDITWORKFLOW': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewWorkflow) {
              this.commonService?.CurrentPageComponent?.Load(2);
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewWorkflow, this.navigatorService);
            }
          }
          break;
        }
        case 'PUBLISHWORKFLOW': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewWorkflow) {
              this.commonService?.CurrentPageComponent?.Load(2);
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewWorkflow, this.navigatorService);
            }
          }
          break;
        }
        case 'NEXTWORKFLOW': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.Documents) {
              this.commonService?.CurrentPageComponent?.Load(2);
            } else {
              this.commonService.SidebarMenuClick(Pages.Documents, this.navigatorService);
            }
          }
          break;
        }
        case 'BACKWORKFLOW': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.Documents) {
              this.commonService?.CurrentPageComponent?.Load(2);
            } else {
              this.commonService.SidebarMenuClick(Pages.Documents, this.navigatorService);
            }
          }
          break;
        }
        case 'ARCHIVED': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewArchive) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewArchive, this.navigatorService);
            }
          }
          break;
        }
        case 'PROTOCOLLED': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewProtocols) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewProtocols, this.navigatorService);
            }
          }
          break;
        }
        case 'REJECTED': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.Documents) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.Documents, this.navigatorService);
            }
          }
          break;
        }
        case 'DELETEDOCUMENT': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.FilesDelete) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.FilesDelete, this.navigatorService);
            }
          }
          break;
        }
        case 'DOSSIERCREATE': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewDossiers) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewDossiers, this.navigatorService);
            }
          }
          break;
        }
        case 'DOSSIERUPDATE': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewDossiers) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewDossiers, this.navigatorService);
            }
          }
          break;
        }
        case 'DOSSIERCLOSE': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewDossiersArchived) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewDossiersArchived, this.navigatorService);
            }
          }
          break;
        }
        case 'DELETEDOSSIER': {

          break;
        }
        case 'ADDDOSSIER': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            this.navigatorService.NotificationTargetDossierId = dto.data.DossierId;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewDossiers) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewDossiers, this.navigatorService);
            }
          }
          break;
        }
        case 'NEWUO': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewUO) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewUO, this.navigatorService);
            }
          }
          break;
        }
        case 'NEWUOUSER': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewUO) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewUO, this.navigatorService);
            }
          }
          break;
        }
        case 'DELETEUOUSER': {

          break;
        }
        case 'UPDATEUO': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewUO) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewUO, this.navigatorService);
            }
          }
          break;
        }
        case 'DELETEUO': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.UoDelete) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.UoDelete, this.navigatorService);
            }
          }
          break;
        }
        case 'EDITUOUSERROLE': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewUO) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewUO, this.navigatorService);
            }
          }
          break;
        }
        case 'NEWTIPOLOGY': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewTipology) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewTipology, this.navigatorService);
            }
          }
          break;
        }
        case 'EDITTIPOLOGY': {
          if (dto?.data?.Id > 0) {
            this.navigatorService.NotificationTargetId = dto.data.Id;
            if (this.navigatorService.CurrentPage?.Link === Pages.ViewTipology) {
              this.commonService?.CurrentPageComponent?.Load();
            } else {
              this.commonService.SidebarMenuClick(Pages.ViewTipology, this.navigatorService);
            }
          }
          break;
        }
        case 'DELETEUOTIPOLOGY': {

          break;
        }
        case 'DELETETIPOLOGY': {

          break;
        }
      }
    }
    this.Open = false;
  }

  UpdateNotifications() {
    this.Interval = setInterval(async () => {
      if (!this.IsNotificationsRefresh) {
        this.IsNotificationsRefresh = true;
        await this.GetNotifications();
        this.IsNotificationsRefresh = false;
      }
    }, 1000 * 60);
  }

  toggleNotificationList() {
    this.Open = this.Open ? false : true;
  }

  async DeleteNotification(id) {
    if (id) {
      this.commonService.NotificationModel.Filter = new NotificationFilter();
      this.commonService.NotificationModel.Filter.Id = id;
      this.commonService.NotificationModel.Filter.notifiable_id = this.authService.CurrentUser.Id;
      this.commonService.NotificationModel = await this.ItCoreController.MarkNotification(this.commonService.NotificationModel);
      this.GetNotifications();
    }
  }

  async MarkAllAsRead() {
    if (this.commonService.NotificationModel?.Dtos?.length > 0) {
      this.commonService.NotificationModel.Filter = new NotificationFilter();
      this.commonService.NotificationModel.Filter.Id = this.commonService.NotificationModel?.Dtos?.map(x => x.id);
      this.commonService.NotificationModel.Filter.notifiable_id = this.authService.CurrentUser.Id;
      this.commonService.NotificationModel = await this.ItCoreController.MarkNotification(this.commonService.NotificationModel);
      this.GetNotifications();
    }
  }

  openCloseChat() {
    // this.openSidebar.emit(true);
    if (this.chatOpened) {
      this.chatService.hideChat();
      this.chatOpened = false;

    } else {
      this.chatService.showChat();
      this.chatOpened = true;
    }
  }

  ngOnDestroy() {
    if (this.Interval) {
      clearInterval(this.Interval);
    }
  }
}
