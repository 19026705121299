import { Component, OnInit } from '@angular/core';
import { StatisticsController } from '../../controllers/StatisticController';
import { CardDataModel } from '../../models/CardDataModel';
import { StatisticsDto, StatisticsModel } from '../../models/StatisticsModel';
import { ActivityModel, ActivityFilter, ActivityDto } from '../../models/ActivityModel';

import { DashboardCardType, DocumentState } from '../../itdoc.configuration';
import { DialogDashboardComponent } from './dialog-dashboard/dialog-dashboard.component';
import { ActivityController } from '../../controllers/ActivityController';
import { NavigatorService } from '../../../navigator.services';
import { AuthService } from '../../../auth.service';
import { DataService } from '../../../data.service';
import { CommonService } from '../../../common.service';
import { BaseOrder } from '../../../models/BaseModel';

@Component({
  selector: 'app-itdoc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  DashboardCardType = DashboardCardType;
  YourRequest: CardDataModel[];
  AllDocument: CardDataModel[];
  AllProtocolsDossiers: CardDataModel[];
  RevisionDocs: CardDataModel;
  InApprovalDocs: CardDataModel;
  ApprovedDocs: CardDataModel;
  ArchivedDocs: CardDataModel;
  StatisticsModel: StatisticsModel;
  ActivityModel: ActivityModel;
  StatistiscController: StatisticsController;
  ActivityController: ActivityController;
  IsEmptyYourRequest = false;
  IsEmptyAllDocument = false;
  ShowComponentDocumentView = false;
  constructor(
    public navigatorService: NavigatorService, public authService: AuthService,
    dataService: DataService, public commonService: CommonService) {
    this.StatistiscController = new StatisticsController(dataService);
    this.ActivityController = new ActivityController(dataService);
  }

  ngOnInit() {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    await this.BuildStatistics();
    await this.BuildActivity();
    this.navigatorService.StopLoading();
    this.ShowComponentDocumentView = true;
  }

  async BuildStatistics() {
    this.StatisticsModel = new StatisticsModel();
    this.StatisticsModel.Dto = new StatisticsDto();
    this.StatisticsModel.Dto.AccountId = this.authService.ITDocAccount?.Dto?.Id;
    this.StatisticsModel = await this.StatistiscController.CreateOrUpdate(this.StatisticsModel);
    if (!this.StatisticsModel.Dto) {
      this.StatisticsModel.Dto = new StatisticsDto();
    }
    // your request data
    // const requestInProgress = new CardDataModel();
    // requestInProgress.Status = DocumentState.PROGRESS;
    // requestInProgress.Name = this.navigatorService.Dictionary?.StateInProgress;
    // requestInProgress.Percentage = this.StatisticsModel.Dto.YourInProgress;
    // requestInProgress.PercentageInLate = this.StatisticsModel.Dto.YourInProgressInLate;
    // requestInProgress.PercentageColor = '#06bcf2';

    const requestInRevision = new CardDataModel();
    requestInRevision.Status = DocumentState.REVISION;
    requestInRevision.Name = this.navigatorService.Dictionary?.StateInRevision;
    requestInRevision.Percentage = this.StatisticsModel.Dto.YourInRevision;
    requestInRevision.PercentageInLate = this.StatisticsModel.Dto.YourInRevisionInLate;
    requestInRevision.PercentageColor = '#7606f2';

    const requestInApproval = new CardDataModel();
    requestInApproval.Status = DocumentState.APPROVAL;
    requestInApproval.Name = this.navigatorService.Dictionary?.StateInApproval;
    requestInApproval.Percentage = this.StatisticsModel.Dto.YourInApproval;
    requestInApproval.PercentageInLate = this.StatisticsModel.Dto.YourInApprovalInLate;
    requestInApproval.PercentageColor = '#00157b';

    const requestApproved = new CardDataModel();
    requestApproved.Status = DocumentState.APPROVED;
    requestApproved.Name = this.navigatorService.Dictionary?.StateApproved;
    requestApproved.Percentage = this.StatisticsModel.Dto.YourApproved;
    requestApproved.PercentageInLate = this.StatisticsModel.Dto.YourApprovedInLate;
    requestApproved.PercentageColor = '#00a812';

    const requestProtocolled = new CardDataModel();
    requestProtocolled.Status = DocumentState.PROTOCOLLED;
    requestProtocolled.Name = this.navigatorService.Dictionary?.StateProtocolled;
    requestProtocolled.Percentage = this.StatisticsModel.Dto.YourProtocolled;
    requestProtocolled.PercentageInLate = this.StatisticsModel.Dto.YourProtocolledInLate;
    requestProtocolled.PercentageColor = '#0f55aa';

    const requestArchived = new CardDataModel();
    requestArchived.Status = DocumentState.ARCHIVED;
    requestArchived.Name = this.navigatorService.Dictionary?.StateArchived;
    requestArchived.Percentage = this.StatisticsModel.Dto.YourArchived;
    requestArchived.PercentageInLate = this.StatisticsModel.Dto.YourArchivedInLate;
    requestArchived.PercentageColor = '#a8a8a8';

    // const requestReject = new CardDataModel();
    // requestReject.Status = DocumentState.REJECTED;
    // requestReject.Name = this.navigatorService.Dictionary?.StateReject;
    // requestReject.Percentage = this.StatisticsModel.Dto.YourRejected;
    // requestReject.PercentageInLate = this.StatisticsModel.Dto.YourRejectedInLate;
    // requestReject.PercentageColor = '#ff5300';
    const totRequest = requestInRevision.Percentage + requestInApproval.Percentage +
      requestApproved.Percentage + requestProtocolled.Percentage + requestArchived.Percentage;
    this.IsEmptyYourRequest = totRequest <= 0;
    this.YourRequest = [requestInRevision, requestInApproval, requestApproved,
      requestProtocolled, requestArchived];
    if (totRequest > 0 && totRequest < 100) {
      this.YourRequest.find(q => q.Percentage > 0).Percentage += (100 - totRequest);
    } else if (totRequest > 0 && totRequest > 100) {
      this.YourRequest.find(q => q.Percentage > 0).Percentage -= (totRequest - 100);
    }

    // All Documents
    // const allInProgress = new CardDataModel();
    // allInProgress.Name = this.navigatorService.Dictionary?.StateInProgress;
    // allInProgress.Percentage = this.StatisticsModel.Dto.AllInProgress;
    // allInProgress.PercentageInLate = this.StatisticsModel.Dto.AllInProgressInLate;
    // allInProgress.PercentageColor = '#06bcf2';

    const allInRevision = new CardDataModel();
    allInRevision.Name = this.navigatorService.Dictionary?.StateInRevision;
    allInRevision.Percentage = this.StatisticsModel.Dto.AllInRevision;
    allInRevision.PercentageInLate = this.StatisticsModel.Dto.AllInRevisionInLate;
    allInRevision.PercentageColor = '#7606f2';

    const allInApproval = new CardDataModel();
    allInApproval.Name = this.navigatorService.Dictionary?.StateInApproval;
    allInApproval.Percentage = this.StatisticsModel.Dto.AllInApproval;
    allInApproval.PercentageInLate = this.StatisticsModel.Dto.AllInApprovalInLate;
    allInApproval.PercentageColor = '#00157b';

    const allApproved = new CardDataModel();
    allApproved.Name = this.navigatorService.Dictionary?.StateApproved;
    allApproved.Percentage = this.StatisticsModel.Dto.AllApproved;
    allApproved.PercentageInLate = this.StatisticsModel.Dto.AllApprovedInLate;
    allApproved.PercentageColor = '#00a812';

    const allProtocolled = new CardDataModel();
    allProtocolled.Name = this.navigatorService.Dictionary?.StateProtocolled;
    allProtocolled.Percentage = this.StatisticsModel.Dto.AllProtocolled;
    allProtocolled.PercentageInLate = this.StatisticsModel.Dto.AllProtocolledInLate;
    allProtocolled.PercentageColor = '#0f55aa';

    const allArchived = new CardDataModel();
    allArchived.Name = this.navigatorService.Dictionary?.StateArchived;
    allArchived.Percentage = this.StatisticsModel.Dto.AllArchived;
    allArchived.PercentageInLate = this.StatisticsModel.Dto.AllArchivedInLate;
    allArchived.PercentageColor = '#a8a8a8';

    // const allReject = new CardDataModel();
    // allReject.Name = this.navigatorService.Dictionary?.StateReject;
    // allReject.Percentage = this.StatisticsModel.Dto.AllRejected;
    // allReject.PercentageInLate = this.StatisticsModel.Dto.AllRejectedInLate;
    // allReject.PercentageColor = '#ff5300';
    const allTot =  allInRevision.Percentage + allInApproval.Percentage + allApproved.Percentage +
      allProtocolled.Percentage + allArchived.Percentage;

    this.IsEmptyAllDocument = allTot <= 0;
    this.AllDocument = [allInRevision, allInApproval, allApproved,
      allProtocolled, allArchived];
    if (allTot > 0 && allTot < 100) {
      this.AllDocument.find(q => q.Percentage > 0).Percentage += (100 - allTot);
    } else if (allTot > 0 && allTot > 100) {
      this.AllDocument.find(q => q.Percentage > 0).Percentage -= (allTot - 100);
    }

    const allProtocolledInput = new CardDataModel();
    allProtocolledInput.Name = this.navigatorService.Dictionary?.Input;
    allProtocolledInput.Percentage = this.StatisticsModel.Dto.AllProtocolsInput;
    allProtocolledInput.PercentageInLate = this.StatisticsModel.Dto.AllProtocolsInputInLate;
    allProtocolledInput.PercentageColor = '#5ebcd3';
    allProtocolledInput.Tab = 1;

    const allProtocolledOutput = new CardDataModel();
    allProtocolledOutput.Name = this.navigatorService.Dictionary?.Output;
    allProtocolledOutput.Percentage = this.StatisticsModel.Dto.AllProtocolsOutput;
    allProtocolledOutput.PercentageInLate = this.StatisticsModel.Dto.AllProtocolsOutputInLate;
    allProtocolledOutput.PercentageColor = '#3e3594';
    allProtocolledOutput.Tab = 1;
    const allProtocolledTot = allProtocolledInput.Percentage + allProtocolledOutput.Percentage;
    if (allProtocolledTot > 0 && allProtocolledTot < 100) {
      if (allProtocolledInput.Percentage > 0) {
        allProtocolledInput.Percentage += (100 - allProtocolledTot);
      } else {
        allProtocolledOutput.Percentage += (100 - allProtocolledTot);
      }
    } else if (allProtocolledTot > 0 && allProtocolledTot > 100) {
      if (allProtocolledInput.Percentage > 0) {
        allProtocolledInput.Percentage -= (allProtocolledTot - 100);
      } else {
        allProtocolledOutput.Percentage -= (allProtocolledTot - 100);
      }
    }
    const allDossierOpen = new CardDataModel();
    allDossierOpen.Name = this.navigatorService.Dictionary?.Open;
    allDossierOpen.Percentage = this.StatisticsModel.Dto.AllDossiersOpen;
    allDossierOpen.PercentageInLate = 0;
    allDossierOpen.PercentageColor = '#5ebcd3';
    allDossierOpen.Tab = 2;

    const allDossierClosed = new CardDataModel();
    allDossierClosed.Name = this.navigatorService.Dictionary?.Archived;
    allDossierClosed.Percentage = this.StatisticsModel.Dto.AllDossiersArchived;
    allDossierClosed.PercentageInLate = 0;
    allDossierClosed.PercentageColor = '#3e3594';
    allDossierClosed.Tab = 2;
    const allDossierTot = allDossierOpen.Percentage + allDossierClosed.Percentage;
    if (allDossierTot > 0 && allDossierTot < 100) {
      if (allDossierOpen.Percentage > 0) {
        allDossierOpen.Percentage += (100 - allDossierTot);
      } else {
        allDossierClosed.Percentage += (100 - allDossierTot);
      }
    } else if (allDossierTot > 0 && allDossierTot > 100) {
      if (allDossierOpen.Percentage > 0) {
        allDossierOpen.Percentage -= (allDossierTot - 100);
      } else {
        allDossierClosed.Percentage -= (allDossierTot - 100);
      }
    }
    this.AllProtocolsDossiers = [allProtocolledInput, allProtocolledOutput, allDossierOpen, allDossierClosed];

    // docs minimal card counter data
    this.RevisionDocs = new CardDataModel();
    this.RevisionDocs.Status = DocumentState.REVISION;
    this.RevisionDocs.Name = this.navigatorService.Dictionary?.StateInRevision;
    this.RevisionDocs.Count = this.StatisticsModel.Dto.YourDocsInRevisionCount;
    this.RevisionDocs.PercentageInTime = this.StatisticsModel.Dto.YourDocsInRevisionInTime;
    this.RevisionDocs.PercentageInLate = this.StatisticsModel.Dto.YourDocsInRevisionInLate;
    this.RevisionDocs.PercentageInTimeColor = '#97e7b6';
    this.RevisionDocs.PercentageInLateColor = '#943594';
    const totRevisionDocs = this.RevisionDocs.PercentageInTime + this.RevisionDocs.PercentageInLate;
    if (totRevisionDocs > 0 && totRevisionDocs < 100) {
      if (this.RevisionDocs.PercentageInTime > 0) {
        this.RevisionDocs.PercentageInTime += (100 - totRevisionDocs);
      } else {
        this.RevisionDocs.PercentageInLate += (100 - totRevisionDocs);
      }
    } else if (totRevisionDocs > 0 && totRevisionDocs > 100) {
      if (this.RevisionDocs.PercentageInTime > 0) {
        this.RevisionDocs.PercentageInTime -= (totRevisionDocs - 100);
      } else {
        this.RevisionDocs.PercentageInLate -= (totRevisionDocs - 100);
      }
    }

    this.InApprovalDocs = new CardDataModel();
    this.InApprovalDocs.Status = DocumentState.APPROVAL;
    this.InApprovalDocs.Name = this.navigatorService.Dictionary?.StateInApproval;
    this.InApprovalDocs.Count = this.StatisticsModel.Dto.YourDocsInApprovalCount;
    this.InApprovalDocs.PercentageInTime = this.StatisticsModel.Dto.YourDocsInApprovalInTime;
    this.InApprovalDocs.PercentageInLate = this.StatisticsModel.Dto.YourDocsInApprovalInLate;
    this.InApprovalDocs.PercentageInTimeColor = '#97e7b6';
    this.InApprovalDocs.PercentageInLateColor = '#943594';
    const totInApprovalDocs = this.InApprovalDocs.PercentageInTime + this.InApprovalDocs.PercentageInLate;
    if (totInApprovalDocs > 0 && totInApprovalDocs < 100) {
      if (this.InApprovalDocs.PercentageInTime > 0) {
        this.InApprovalDocs.PercentageInTime += (100 - totInApprovalDocs);
      } else {
        this.InApprovalDocs.PercentageInLate += (100 - totInApprovalDocs);
      }
    } else if (totInApprovalDocs > 0 && totInApprovalDocs > 100) {
      if (this.InApprovalDocs.PercentageInTime > 0) {
        this.InApprovalDocs.PercentageInTime -= (totInApprovalDocs - 100);
      } else {
        this.InApprovalDocs.PercentageInLate -= (totInApprovalDocs - 100);
      }
    }

    this.ApprovedDocs = new CardDataModel();
    this.ApprovedDocs.Status = DocumentState.APPROVED;
    this.ApprovedDocs.Name = this.navigatorService.Dictionary?.StateApproved;
    this.ApprovedDocs.Count = this.StatisticsModel.Dto.YourDocsApprovedCount;
    this.ApprovedDocs.PercentageInTime = this.StatisticsModel.Dto.YourDocsApprovedInTime;
    this.ApprovedDocs.PercentageInLate = this.StatisticsModel.Dto.YourDocsApprovedInLate;
    this.ApprovedDocs.PercentageInTimeColor = '#97e7b6';
    this.ApprovedDocs.PercentageInLateColor = '#943594';
    const totApprovedDocs = this.ApprovedDocs.PercentageInTime + this.ApprovedDocs.PercentageInLate;
    if (totApprovedDocs > 0 && totApprovedDocs < 100) {
      if (this.ApprovedDocs.PercentageInTime > 0) {
        this.ApprovedDocs.PercentageInTime += (100 - totApprovedDocs);
      } else {
        this.ApprovedDocs.PercentageInLate += (100 - totApprovedDocs);
      }
    } else if (totApprovedDocs > 0 && totApprovedDocs > 100) {
      if (this.ApprovedDocs.PercentageInTime > 0) {
        this.ApprovedDocs.PercentageInTime -= (totApprovedDocs - 100);
      } else {
        this.ApprovedDocs.PercentageInLate -= (totApprovedDocs - 100);
      }
    }

    this.ArchivedDocs = new CardDataModel();
    this.ArchivedDocs.Status = DocumentState.ARCHIVED;
    this.ArchivedDocs.Name = this.navigatorService.Dictionary?.StateArchived;
    this.ArchivedDocs.Count = this.StatisticsModel.Dto.YourDocsArchivedCount;
    this.ArchivedDocs.PercentageInTime = this.StatisticsModel.Dto.YourDocsArchivedInTime;
    this.ArchivedDocs.PercentageInLate = this.StatisticsModel.Dto.YourDocsArchivedInLate;
    this.ArchivedDocs.PercentageInTimeColor = '#97e7b6';
    this.ArchivedDocs.PercentageInLateColor = '#943594';
    const totArchivedDocs = this.ArchivedDocs.PercentageInTime + this.ArchivedDocs.PercentageInLate;
    if (totArchivedDocs > 0 && totArchivedDocs < 100) {
      if (this.ArchivedDocs.PercentageInTime > 0) {
        this.ArchivedDocs.PercentageInTime += (100 - totArchivedDocs);
      } else {
        this.ArchivedDocs.PercentageInLate += (100 - totArchivedDocs);
      }
    } else if (totArchivedDocs > 0 && totArchivedDocs > 100) {
      if (this.ArchivedDocs.PercentageInTime > 0) {
        this.ArchivedDocs.PercentageInTime -= (totArchivedDocs - 100);
      } else {
        this.ArchivedDocs.PercentageInLate -= (totArchivedDocs - 100);
      }
    }
  }

  public OpenRequestModal(model: any, requestType: string, chartType: DashboardCardType, title: string, params = null) {
    const data = {
      Title: title,
      DataSetModel: model,
      ChartType: chartType,
      RequestType: requestType,
      ReferralComponent: this,
      Params: params
    };
    this.navigatorService.ShowDialog(DialogDashboardComponent, data, '60%', 'fit-content', '200px', async (response: any) => {
      if (response) {

      }
    }, '850px');
  }


  //#region activity
  async BuildActivity() {
    if (this.authService.ITDocAccount.Dto.Id && this.authService.ITDocAccount.Dto.Id > 0) {
      this.ActivityModel = new ActivityModel();
      this.ActivityModel.Filter = new ActivityFilter();
      // this.ActivityModel.Filter.Uid = this.authService.CurrentUser.Username;
      this.ActivityModel.Order = new BaseOrder();
      this.ActivityModel.Order.Name = 'Id',
        this.ActivityModel.Order.Direction = 'desc';
      this.ActivityModel = await this.ActivityController.Load(this.ActivityModel);
      if (this.ActivityModel?.Performed) {
        for (const dto of this.ActivityModel.Dtos) {
          this.ParseActivity(dto);
        }
      }
    }
  }

  ParseActivity(dto: ActivityDto) {
    if (dto.Params) {
      dto.ParamsDecode = JSON.parse(dto.Params);
      const type = dto.ParamsDecode.Type;
      if (type && dto.ObjectType !== 'workflow') {
        switch (type) {
          case 'NEWINTITATION': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationNewInvitationFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationNewInvitationThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleNewInvitation;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'USEREDIT': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationUserEditFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationUserEditThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleUserEdit;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'USEREDELETE': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationUserDeleteFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationUserDeleteThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleUserDelete;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'RESETPASSWORD': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationResetPasswordFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationResetPasswordThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleResetPassword;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'NEWWORKFLOW': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationNewWorkflowFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationNewWorkflowThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleNewWorkflow;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'EDITWORKFLOW': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationEditWorkflowFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationEditWorkflowThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleEditWorkflow;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'PUBLISHWORKFLOW': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationPublishWorkflowFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationPublishWorkflowThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitlePublishWorkflow;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'NEXTWORKFLOW':
          case 'REVISION':
          case 'REVISIONED':
          case 'APPROVAL':
          case 'APPROVED': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              dto.ParamsDecode.Status = this.commonService.GetDisplayState(this.navigatorService.Dictionary, dto.Type);
              message = type === 'REVISION' || type === 'APPROVAL' ? this.navigatorService.Dictionary?.NotificationNextDocumentFirstPerson
                : this.navigatorService.Dictionary?.NotificationNextDocumentFirstPerson;
            } else {
              dto.ParamsDecode.Status = this.commonService.GetDisplayState(this.navigatorService.Dictionary, dto.Type);
              message = type === 'REVISIONED' || type === 'APPROVED' ?
                this.navigatorService.Dictionary?.NotificationConfirmDocumentThirdPerson
                : this.navigatorService.Dictionary?.NotificationConfirmDocumentThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleNextWorkflow;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'BACKWORKFLOW': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationBackWorkflowFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationBackWorkflowThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleBackWorkflow;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'ARCHIVED': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationArchivedDocumentFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationArchivedDocumentThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleArchivedDocument;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'PROTOCOLLED': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationProtocolledDocumentFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationProtocolledDocumentThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleProtocolledDocument;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'REJECTED': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationRejectedDocumentFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationRejectedDocumentThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleRejectedDocument;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DELETEDOCUMENT': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationDeletedDocumentFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationDeletedDocumentThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleDeletedDocument;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DOSSIERCREATE': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationNewDossierFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationNewDossierThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleNewDossier;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DOSSIERUPDATE': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationUpdateDossierFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationUpdateDossierThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleUpdateDossier;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DOSSIERCLOSE': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationArchivedDossierFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationArchivedDossierThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleArchivedDossier;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DELETEDOSSIER': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationDeletedDossierFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationDeletedDossierThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleDeletedDossier;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'ADDDOSSIER': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationAddedToDossierFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationAddedToDossierThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleAddedToDossier;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'NEWUO': {
            const message = this.navigatorService.Dictionary?.NotificationNewUoThirdPerson;
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleNewUo;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'NEWUOUSER': {
            const message = this.navigatorService.Dictionary?.NotificationNewUoUserThirdPerson;
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleNewUoUser;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DELETEUOUSER': {
            const message = this.navigatorService.Dictionary?.NotificationDeleteUoUserThirdPerson;
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleDeleteUoUser;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'UPDATEUO': {
            const message = this.navigatorService.Dictionary?.NotificationUpdateUoThirdPerson;
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleUpdateUo;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DELETEUO': {
            let message = null;
            if (dto.ParamsDecode.Role) {
              message = this.navigatorService.Dictionary?.NotificationDeletedUoFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationDeletedUoThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleDeletedUo;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'EDITUOUSERROLE': {
            const message = this.navigatorService.Dictionary?.NotificationEditUoUserRoleThirdPerson;
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleEditUoUserRole;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'NEWTIPOLOGY': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationNewTipologyFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationNewTipologyThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleNewTipology;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'EDITTIPOLOGY': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationEditTipologyFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationEditTipologyThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleEditTipology;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DELETEUOTIPOLOGY': {
            const message = this.navigatorService.Dictionary?.NotificationDeleteUoTipologyThirdPerson;
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleDeleteUoTipology;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
          case 'DELETETIPOLOGY': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationDeleteTipologyFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationDeleteTipologyThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleDeleteTipology;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          } case 'CREATEATTACHMENT': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationAddAttachmentFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationAddAttachmentThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleAddAttachment;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          } case 'DELETEATTACHMENT': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = this.navigatorService.Dictionary?.NotificationDeleteAttachmentFirstPerson;
            } else {
              message = this.navigatorService.Dictionary?.NotificationDeleteAttachmentThirdPerson;
            }
            dto.Icon = this.commonService.GetIconNotificationActivity(type);
            dto.Subject = this.navigatorService.Dictionary?.NotificationTitleDeleteAttachment;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          } case 'UPDATE': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationEditFolderFirstPerson
                : this.navigatorService.Dictionary?.NotificationEditFileFirstPerson;
            } else {
              message = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationEditFolderThirdPerson
                : this.navigatorService.Dictionary?.NotificationEditFileThirdPerson;
            }
            dto.Icon = dto.ObjectType === 'folder' ? this.commonService.GetIconNotificationActivity('FOLDER')
              : this.commonService.GetIconNotificationActivity(type);
            dto.Subject = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationTitleEditFolder
              : this.navigatorService.Dictionary?.NotificationTitleEditFile;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          } case 'CREATE': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationCreateFolderFirstPerson
                : this.navigatorService.Dictionary?.NotificationCreateFileFirstPerson;
            } else {
              message = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationCreateFolderThirdPerson
                : this.navigatorService.Dictionary?.NotificationCreateFileThirdPerson;
            }
            dto.Icon = dto.ObjectType === 'folder' ? this.commonService.GetIconNotificationActivity('FOLDER')
              : this.commonService.GetIconNotificationActivity(type);
            dto.Subject = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationTitleCreateFolder
              : this.navigatorService.Dictionary?.NotificationTitleCreateFile;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          } case 'TRASHED': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationDeletedFolderFirstPerson
                : this.navigatorService.Dictionary?.NotificationDeletedDocumentFirstPerson;
            } else {
              message = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationDeletedFolderThirdPerson
                : this.navigatorService.Dictionary?.NotificationDeletedDocumentThirdPerson;
            }
            dto.Icon = dto.ObjectType === 'folder' ? this.commonService.GetIconNotificationActivity('FOLDER')
              : this.commonService.GetIconNotificationActivity(type);
            dto.Subject = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationTitleDeletedFolder
              : this.navigatorService.Dictionary?.NotificationTitleDeletedDocument;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          } case 'RESTORE': {
            let message = null;
            if (dto.ParamsDecode.SenderUsername === this.authService.CurrentUser.Username) {
              message = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationRestoreFolderFirstPerson
                : this.navigatorService.Dictionary?.NotificationRestoreDocumentFirstPerson;
            } else {
              message = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationRestoreFolderThirdPerson
                : this.navigatorService.Dictionary?.NotificationRestoreDocumentThirdPerson;
            }
            dto.Icon = dto.ObjectType === 'folder' ? this.commonService.GetIconNotificationActivity('FOLDER')
              : this.commonService.GetIconNotificationActivity(type);
            dto.Subject = dto.ObjectType === 'folder' ? this.navigatorService.Dictionary?.NotificationTitleRestoreFolder
              : this.navigatorService.Dictionary?.NotificationTitleRestoreDocument;
            dto.Message = this.BuildActivityMessage(dto.ParamsDecode, message);
            break;
          }
        }
      }
    }
  }

  BuildActivityMessage(data: any, message: string) {
    const properties = Object.keys(data);
    for (const prop of properties) {
      message = message.replace(':' + prop, data[prop]);
    }
    return message;
  }


  //#endregion
}
