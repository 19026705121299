import { BaseEntity, BaseModel } from '../../models/BaseModel';

export class SystemInfoModel extends BaseModel<SystemInfoEntity, SystemInfoDto, SystemInfoFilter> {

  constructor() {
    super();
  }
}

export class SystemInfoEntity extends BaseEntity {
  TotUser: number;
  TotArchive: number;
  SubClasses: Array<SubClasses>;
  DiskSpaceUsed: number;
  SubClassesNumber: number;
  ArchivesInfo: Array<ArchivesInfo>;
  HasPlugins: boolean;
  Home: string;
  ExpirationDate: string;

  constructor() {
    super();
  }
}

export class SystemInfoDto extends SystemInfoEntity {
  Ticket: string;
  Username: string;
  Page: number;
  ViewRepository: ViewRepository;
  Name: string;
  constructor() {
    super();

  }
}

export class SystemInfoFilter extends SystemInfoDto {
}



export class SubClasses {
  Archive: string;
  Name: string;
  Title: string;
  Description: string;
  Enabled: boolean;
  Assigned: boolean;
  VerifyEnabled: boolean;
  SignEnabled: boolean;
  YearsKeep: number;
  FilesEnabled: Array<string>;
  OriginalClassName: string;
  EnabledRo: boolean;

  constructor() {
  }

}

export class ArchivesInfo {
  NodeRef: string;
  Name: string;
  Read: boolean;
  Write: boolean;
  DocClassPermissions: Array<DocClassPermissions>;
  ViewRepository: ViewRepository;
  constructor() {
  }
}

export class DocClassPermissions {
  Read: boolean;
  Write: boolean;
  ClassType: string;

  constructor() {
  }
}
export class ViewRepository {
  Timestamp: number;
  HasMoreItems: boolean;
  PageNum: number;
  NumberOfElements: number;
  NumberOfPages: number;
  PageSize: number;
  Children: Array<Children>; // pda list
  NodeProperties: NodeProperties;
  FinalPda: boolean;
  Pda: boolean;
  Pec: boolean;

  constructor() {
  }
}

export class Children {
  Archived: boolean;
  OwnerFolder: boolean;
  Active: boolean;
  NodeRef: string;
  Title: string;
  TypeNamespace: string;
  TypeLocalName: string;
  Date: string;
  Creator: string;
  State: string;
  Size: number;
  Limit: number;
  DocsCount: number;
  ArchivedDate: string;
  Object: string;
  ExtFields: any;
  Pda: boolean;
  Pec: boolean;
  FinalPda: boolean;
  PluginFolder: boolean;
  HumanSize: string;
  WaitingForIndex: boolean;
  WaitingForUpload: boolean;
  Ipdv: boolean;
  UpdatePda: boolean;

  constructor() {
  }
}

export class NodeProperties {
  Archived: boolean;
  OwnerFolder: boolean;
  Active: boolean;
  NodeRef: string;
  Title: string;
  TypeNamespace: string;
  TypeLocalName: string;
  Date: string;
  State: string;
  RagioneSociale: string;
  PartitaIva: string;
  CodiceFiscale: string;
  RagioneSocialeProd: string;
  Size: number;
  Limit: number;
  DocsCount: number;
  Object: number;
  ExtFields: any;
  Pda: boolean;
  Pec: boolean;
  FinalPda: boolean;
  PluginFolder: boolean;
  HumanSize: string;
  WaitingForIndex: boolean;
  WaitingForUpload: boolean;
  Ipdv: boolean;
  UpdatePda: boolean;

  constructor() {
  }
}

