import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { ObjectDto, ObjectFilter, ObjectModel } from '../../../models/ObjectModel';
import { AccountDossierDto, AccountDossierFilter, AccountDossierModel } from '../../../models/AccountDossierModel';
import { AccountFilter, AccountModel } from '../../../models/AccountModel';
import { ObjectDossierDto, ObjectDossierFilter, ObjectDossierModel } from '../../../models/ObjectDossierModel';
import { OrganizationUnitModel } from '../../../models/OrganizationUnitModel';
import { AccountController } from '../../../controllers/AccountController';
import { DossierController } from '../../../controllers/DossierController';
import { ObjectController } from '../../../controllers/ObjectController';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import { AccountDossierController } from '../../../controllers/AccountDossierController';
import { ObjectDossierController } from '../../../controllers/ObjectDossierController';
import { NotificationController } from '../../../../controllers/NotificationController';
import { AutocompleteFilterUiComponent } from '../../../custom-components/autocomplete-ui/autocomplete-ui.component';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import { Constants, DocumentState, DossierRole, DossierState, PageAction } from '../../../itdoc.configuration';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { DossierDto, DossierFilter, DossierModel } from '../../../models/DossierModel';
import { BaseOrder } from '../../../../models/BaseModel';


@Component({
  selector: 'app-dossiers',
  templateUrl: './dossiers.component.html',
  styleUrls: ['./dossiers.component.scss']
})
export class DossiersComponent implements OnInit {
  Step = 1;
  Steps = 3;
  Model: DossierModel;
  PersonalizeFields = false;
  Pages: Array<number>;
  CurrentPage = 1;
  DataSource: MatTableDataSource<ObjectDto>;
  CurrentFolder: ObjectDto;
  ObjectModel: ObjectModel;
  AccountModel: AccountModel;
  AccountDossierModel: AccountDossierModel;
  AccountDossierToDeleteModel: AccountDossierModel;
  ObjectDossierModel: ObjectDossierModel;
  ObjectDossierToDeleteModel: ObjectDossierModel;
  OrganizationUnitModel: OrganizationUnitModel;
  AccountController: AccountController;
  Controller: DossierController;
  ObjectController: ObjectController;
  OrganizationUnitController: OrganizationUnitController;
  AccountDossierController: AccountDossierController;
  ObjectDossierController: ObjectDossierController;
  NotificationController: NotificationController;
  ND = Constants.ND.toString();
  ResponsibleDto: AccountDossierDto;
  @ViewChild('autocompleteResponsible') AutoCompleteResponsible: AutocompleteFilterUiComponent;
  @ViewChild('autocompleteUser') AutoCompleteUser: AutocompleteFilterUiComponent;
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;

  constructor(
    dataService: DataService, public navigatorService: NavigatorService,
    public authenticationService: AuthService, public commonService: CommonService,
    public dialogRef: MatDialogRef<DossiersComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.Model = new DossierModel();
    this.Model.Dto = new DossierDto();
    this.Model.Dto.CreatorDisplayName = authenticationService.CurrentUser.Displayname;
    this.Model.Dto.State = DossierState.OPEN;
    this.AccountDossierModel = new AccountDossierModel();
    this.AccountDossierToDeleteModel = new AccountDossierModel();
    this.OrganizationUnitModel = new OrganizationUnitModel();
    this.ObjectModel = new ObjectModel();
    this.ObjectDossierModel = new ObjectDossierModel();
    this.ObjectDossierToDeleteModel = new ObjectDossierModel();
    this.Controller = new DossierController(dataService);
    this.AccountDossierController = new AccountDossierController(dataService);
    this.ObjectDossierController = new ObjectDossierController(dataService);
    this.ObjectController = new ObjectController(dataService);
    this.AccountController = new AccountController(dataService);
    this.OrganizationUnitController = new OrganizationUnitController(dataService);
    this.NotificationController = new NotificationController(dataService);
  }

  ngOnInit() {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    this.OrganizationUnitModel = await this.OrganizationUnitController.Get(this.OrganizationUnitModel);
    if (this.navigatorService.PageAction === PageAction.Edit) {
      this.Model.Filter = new DossierFilter();
      this.Model.Filter.Id = this.data;
      this.Model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      this.Model = await this.Controller.Read(this.Model);

      this.AccountDossierModel.Filter = new AccountDossierFilter();
      this.AccountDossierModel.Filter.DossierId = this.data;
      this.AccountDossierModel = await this.AccountDossierController.Get(this.AccountDossierModel);
      if (this.AccountDossierModel?.Performed) {
        this.ResponsibleDto = this.AccountDossierModel.Dtos.find(q => q.Role === DossierRole.Responsible);
      }

      this.AccountModel = new AccountModel();
      this.AccountModel.Filter = new AccountFilter();
      this.AccountModel.Filter.UoId = this.Model.Dto.UoId;
      this.AccountModel = await this.AccountController.Get(this.AccountModel);

      this.ObjectDossierModel.Filter = new ObjectDossierFilter();
      this.ObjectDossierModel.Filter.DossierId = this.data;
      this.ObjectDossierModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      this.ObjectDossierModel.Filter.Trashed = false;
      this.ObjectDossierModel = await this.ObjectDossierController.Get(this.ObjectDossierModel);
    }
    this.navigatorService.StopLoading();
  }

  async GetObjects(dto: ObjectDto = null, search: string = null, page = 1) {
    this.navigatorService.StartLoading();
    this.ObjectModel.Search = null;
    this.ObjectModel.Filter = new ObjectFilter();
    this.ObjectModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    this.ObjectModel.Filter.State = [DocumentState.PROTOCOLLED, DocumentState.ARCHIVED, null];

    if (!search) {
      search = this.SearchComponent?.GetSearchValue();
      if (!dto && search) {
        this.ObjectModel.Search = search;
      } else if (!dto) {
        this.ObjectModel.Filter.Deep = 1;
      } else if (dto) {
        this.ObjectModel.Filter.Parent = dto.Id;
      }
    } else {
      this.ObjectModel.Search = search;
    }

    this.ObjectModel.Order = new BaseOrder();
    this.ObjectModel.Order.Name = 'Id';
    this.ObjectModel.Order.Direction = 'desc';
    this.ObjectModel = await this.ObjectController.Load(this.ObjectModel);
    this.CheckDocument();
    this.Paging(page);
    this.navigatorService.StopLoading();
  }

  CheckDocument() {
    if (this.ObjectModel?.Performed && this.ObjectModel?.Dtos?.length > 0) {
      if (this.ObjectDossierModel?.Dtos && this.ObjectDossierModel?.Dtos.length > 0) {
        for (const dto of this.ObjectDossierModel.Dtos) {
          this.commonService.GetIcon(dto);
          const document = this.ObjectModel.Dtos.find(o => o.Id === dto.ObjectId);
          if (document) {
            document.Checked = true;
          }
        }
      }
    }
  }

  async ObjectsClick(dto: ObjectDto) {
    if (dto.Type === 'folder') {
      this.navigatorService.StartLoading();
      this.CurrentFolder = dto;
      await this.GetObjects(dto);
      this.navigatorService.StopLoading();
    }
  }

  async ReadObject(parent) {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    model.Filter.Id = parent;
    model = await this.ObjectController.Read(model);
    return model?.Dto ?? null;

  }

  async SelectUo($event) {
    if (!this.Model.Dto.UoId) {
      this.AccountModel = new AccountModel();
      this.AccountModel.Filter = new AccountFilter();
      this.AccountModel.Filter.UoId = $event;
      this.AccountModel = await this.AccountController.Get(this.AccountModel);
    }
    if (this.Model.Dto.UoId !== $event) {
      this.Model.Dto.Uo = this.OrganizationUnitModel.Dtos.find(uo => uo.Id === $event);
      if (this.Model.Dto.Id > 0) {
        this.AccountDossierToDeleteModel.Dtos = this.AccountDossierModel.Dtos.filter(ad => ad.Id > 0);
      }
      this.AccountDossierModel.Dtos = [];
    }
    this.Model.Dto.UoId = $event;


  }

  async Next() {
    if (this.Model) {
      const isValid = this.ValidateForm(this.Step);
      if (isValid) {
        this.Step += 1;
        if (this.Step === 2) {
          await this.GetObjects();
        }
      }
    }
  }

  Back() {
    this.Step -= 1;
    if (this.Step <= 0) {
      this.Step = 1;
    }
  }

  ValidateForm(step: number, showMessage = true) {
    if (step === 1) {
      if (this.Model?.Dto?.Name && this.Model?.Dto?.UoId && this.Model?.Dto?.Label) {
        const responsible = this.AccountDossierModel.Dtos.filter(a => a.Role === DossierRole.Responsible);
        const operators = this.AccountDossierModel.Dtos.filter(a => a.Role === DossierRole.Operator);
        if (responsible.length > 0 &&
          operators && operators.length > 0) {
          return true;
        }
      } else {
        if (showMessage) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RequiredFieldsMessage);
        }
        return false;
      }
    }      
     return true;    
  }

  async Search(searchText: string) {
    this.ObjectModel.Skip = 0;
    if (searchText) {
      await this.GetObjects(null, searchText);
    } else {
      await this.GetObjects();
    }
  }

  SelectObjectDossier(event: any, element: ObjectDto) {
    if (element && element.Id > 0) {
      element.Checked = event.checked;
      if (event.checked === true) {
        // checked
        const objectDossierDto = new ObjectDossierDto();
        objectDossierDto.DossierId = this.Model.Dto.Id;
        objectDossierDto.Name = element.Name;
        objectDossierDto.PhysicalName = element.PhysicalName;
        objectDossierDto.ClassIcon = element.ClassIcon;
        objectDossierDto.Icon = element.Icon;
        objectDossierDto.ObjectId = element.Id;
        this.ObjectDossierModel.Dtos.push(objectDossierDto);
      } else {
        // unchecked
        const objectDossierDto = this.ObjectDossierModel.Dtos.find(x => x.ObjectId === element.Id);
        if (objectDossierDto.Id > 0) {
          this.ObjectDossierToDeleteModel.Dtos.push(objectDossierDto);
        }
        const index = this.ObjectDossierModel.Dtos.findIndex(x => x.ObjectId === element.Id);
        if (index >= 0) {
          this.ObjectDossierModel.Dtos.splice(index, 1);
        }
      }
    }
  }

  IsCheckedObjectDossier(element: ObjectDto) {
    if (element) {
      if (this.ObjectDossierModel && this.ObjectDossierModel.Dtos) {
        const finded = this.ObjectDossierModel.Dtos.find(odm => odm.Path === element.Path && odm.PhysicalName === element.PhysicalName);
        if (finded) {
          return true;
        }
      }
    }
    return false;
  }

  async Save() {
    this.navigatorService.StartLoading();
    if (this.navigatorService.PageAction === PageAction.Edit) {
      this.Model.Dto.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    }
    // save dossier
    this.Model = await this.Controller.CreateOrUpdate(this.Model);
    if (this.Model?.Performed && this.Model.Entity && this.Model.Entity.Id > 0) {
      this.AccountDossierModel.Dtos.map(x => x.DossierId = this.Model.Entity.Id);
      this.ObjectDossierModel.Dtos.map(y => y.DossierId = this.Model.Entity.Id);
      // remove account to delete
      if (this.AccountDossierToDeleteModel && this.AccountDossierToDeleteModel.Dtos && this.AccountDossierToDeleteModel.Dtos.length > 0) {
        this.AccountDossierToDeleteModel.Filter = new AccountDossierFilter();
        this.AccountDossierToDeleteModel.Filter.Id = this.AccountDossierToDeleteModel.Dtos.map(x => x.Id);
        await this.AccountDossierController.Delete(this.AccountDossierToDeleteModel);
      }
      // save account selected
      if (this.AccountDossierModel && this.AccountDossierModel.Dtos && this.AccountDossierModel.Dtos.length > 0) {
        this.AccountDossierModel = await this.AccountDossierController.CreateOrUpdate(this.AccountDossierModel);
        if (this.navigatorService.PageAction === PageAction.New) {
          await this.NotificationController.NewDossier(this.Model.Dto.Id);
        }
      }
      //  object dossier to delete
      if (this.ObjectDossierToDeleteModel && this.ObjectDossierToDeleteModel.Dtos && this.ObjectDossierToDeleteModel.Dtos.length > 0) {
        this.ObjectDossierToDeleteModel.Filter = new ObjectDossierFilter();
        this.ObjectDossierToDeleteModel.Filter.Id = this.ObjectDossierToDeleteModel.Dtos.map(x => x.Id);
        this.ObjectDossierToDeleteModel = await this.ObjectDossierController.Delete(this.ObjectDossierToDeleteModel);
      }
      // save object dossier
      if (this.ObjectDossierModel && this.ObjectDossierModel.Dtos && this.ObjectDossierModel.Dtos.length > 0) {
        this.ObjectDossierModel = await this.ObjectDossierController.CreateOrUpdate(this.ObjectDossierModel);
      }

      const message = this.navigatorService.PageAction === PageAction.New ? this.navigatorService.Dictionary?.ToCreateSuccess
        : this.navigatorService.Dictionary?.ToEditSuccess;
      this.navigatorService.ShowSnackBar(message, this.Model.Dto?.Name);
      this.dialogRef.close(true);
    }
    this.navigatorService.StopLoading();
  }

  // #region paging
  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    let folder = this.CurrentFolder;
    if (this.CurrentFolder?.Deep > 1) {
      this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
      folder = this.CurrentFolder;
    } else {
      this.CurrentFolder = null;
      folder = null;
    }
    await this.GetObjects(folder, null, this.CurrentPage);
    this.navigatorService.StopLoading();
  }

  Paging(page: number = 1) {
    if (this.Step === 2) {
      this.Pager.Model = this.ObjectModel;
      this.Pager.Paging(page, false);
      this.RefreshTable(this.ObjectModel.Dtos);
    }
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetObjects(this.CurrentFolder, null, page);
    this.navigatorService.StopLoading();
  }

  RefreshTable(dtos: Array<ObjectDto>) {
    for (const dto of dtos) {
      this.commonService.GetIcon(dto);
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }
  // #endregion

  //#region users dossier
  ResponsibleSelected($event) {
    if ($event) {
      if (this.ResponsibleDto && this.ResponsibleDto.Username !== $event.Username) {
        this.AccountDossierModel.Dtos.splice(this.AccountDossierModel.Dtos.findIndex(q => q.Username === this.ResponsibleDto.Username), 1);
      }
      const exist = this.AccountDossierModel.Dtos.find(q => q.Username === $event.Username);
      if (!exist) {
        this.ResponsibleDto = new AccountDossierDto();
        this.ResponsibleDto.Id = 0;
        this.ResponsibleDto.AccountId = $event.Id;
        this.ResponsibleDto.Role = DossierRole.Responsible;
        this.ResponsibleDto.DisplayName = $event.DisplayName;
        this.ResponsibleDto.Avatar = $event.Avatar;
        this.ResponsibleDto.Username = $event.Username;
        this.AccountDossierModel.Dtos.push(this.ResponsibleDto);
      }
      this.AutoCompleteResponsible.Clear();
    }
  }

  UsersSelected($event) {
    if ($event) {
      const exist = this.AccountDossierModel.Dtos.find(q => q.Username === $event.Username);
      if (!exist) {
        const accountDossierDto = new AccountDossierDto();
        accountDossierDto.Id = 0;
        accountDossierDto.AccountId = $event.Id;
        accountDossierDto.Role = DossierRole.Operator;
        accountDossierDto.DisplayName = $event.DisplayName;
        accountDossierDto.Avatar = $event.Avatar;
        accountDossierDto.Username = $event.Username;
        this.AccountDossierModel.Dtos.push(accountDossierDto);
      }
      this.AutoCompleteUser.Clear();
    }
  }

  DeleteAccountDossier(dto: AccountDossierDto) {
    if (dto) {
      const index = this.AccountDossierModel.Dtos.findIndex(q => q.Username === dto.Username);
      if (index >= 0) {
        if (dto.Id > 0) {
          this.AccountDossierToDeleteModel.Dtos.push(dto);
        }
        if (dto.Role === DossierRole.Responsible) {
          this.ResponsibleDto = null;
        }
        this.AccountDossierModel.Dtos.splice(index, 1);
      }
    }
  }

  GetFilterAccounts(isResponsible) {
    if (isResponsible) {
      const usernames = this.AccountDossierModel.Dtos.filter(q => q.Role !== DossierRole.Responsible).map(q => q.Username);
      if (this.ResponsibleDto) {
        return this.AccountModel?.Dtos.filter(q => q.Username !== this.ResponsibleDto.Username && !usernames.includes(q.Username));
      } else {
        return this.AccountModel?.Dtos.filter(q => !usernames.includes(q.Username));
      }
    } else if (!isResponsible && this.AccountDossierModel.Dtos.length > 0) {
      const usernames = this.AccountDossierModel.Dtos.map(q => q.Username);
      return this.AccountModel?.Dtos.filter(q => !usernames.includes(q.Username));
    } else {
      return this.AccountModel?.Dtos;
    }
  }
  //#endregion
  // SelectResponsible(event: any) {
  //   if (event && event.Id && event.Id > 0) {
  //     const accountDossierDto = new AccountDossierDto();
  //     accountDossierDto.AccountId = event.Id;
  //     accountDossierDto.DisplayName = event.DisplayName;
  //     accountDossierDto.Username = event.Username;
  //     accountDossierDto.Role = DossierRole.Responsible;
  //     accountDossierDto.Avatar = event.Avatar;
  //     this.AccountDossierModel.Dtos.push(accountDossierDto);
  //     this.AutoCompleteResponsibleUser.Clear();
  //   }
  // }

  // SelectOperator(event: any) {
  //   if (event && event.Id && event.Id > 0) {
  //     const accountDossierDto = new AccountDossierDto();
  //     accountDossierDto.AccountId = event.Id;
  //     accountDossierDto.DisplayName = event.DisplayName;
  //     accountDossierDto.Username = event.Username;
  //     accountDossierDto.Role = DossierRole.Operator;
  //     accountDossierDto.Avatar = event.Avatar;
  //     this.AccountDossierModel.Dtos.push(accountDossierDto);
  //     this.AutoCompleteOperatorUser.Clear();
  //   }
  // }

  // AddDefaultResponsible() {
  //   const accountDossierDto = new AccountDossierDto();
  //   accountDossierDto.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
  //   accountDossierDto.DisplayName = this.authenticationService.ITDocAccount.Dto.DisplayName;
  //   accountDossierDto.Username = this.authenticationService.ITDocAccount.Dto.Username;
  //   accountDossierDto.Role = DossierRole.Responsible;
  //   accountDossierDto.Avatar = this.authenticationService.ITDocAccount.Dto.Avatar;
  //   this.AccountDossierModel.Dtos.push(accountDossierDto);
  // }

  // GetAccountAutocomplete(dtos: AccountDto[]) {
  //   if (dtos && dtos.length > 0) {
  //     if (this.AccountDossierModel.Dtos && this.AccountDossierModel.Dtos.length > 0) {
  //       const accountAutocomplete = dtos.filter(account => {
  //         return this.AccountDossierModel.Dtos.findIndex(accountSelected =>
  //           accountSelected.AccountId === account.Id) === -1;
  //       });
  //       return accountAutocomplete;
  //     } else {
  //       return dtos;
  //     }
  //   }
  // }

  // GetResponsibleAccount() {
  //   if (this.AccountDossierModel.Dtos && this.AccountDossierModel.Dtos.length > 0) {
  //     return this.AccountDossierModel.Dtos.filter(account => account.Role === DossierRole.Responsible);
  //   }
  // }

  // GetOperatorAccount() {
  //   if (this.AccountDossierModel.Dtos && this.AccountDossierModel.Dtos.length > 0) {
  //     return this.AccountDossierModel.Dtos.filter(account => account.Role === DossierRole.Operator);
  //   }
  // }

  // RemoveUser(account: AccountDossierDto) {
  //   if (account) {
  //     const index = this.AccountDossierModel.Dtos.findIndex(x => x.AccountId === account.AccountId && x.Role === account.Role);
  //     if (index >= 0) {
  //       if (account.Id > 0) {
  //         this.AccountDossierToDeleteModel.Dtos.push(account);
  //       }
  //       this.AccountDossierModel.Dtos.splice(index, 1);
  //     }
  //   }
  // }



  // ClearSearchInput(value: boolean) {
  //   this.Search(null);
  // }

  // async CloseDropdownUo(dtos: OrganizationUnitDto[]) {
  //   if (dtos) {
  //     const ids = [];
  //     for (const dto of dtos) {
  //       ids.push(dto.Id);
  //     }
  //     await this.LoadAccount(ids);
  //   }
  // }
}
