import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubClasses, SystemInfoDto, SystemInfoModel } from '../../../models/SystemInfoModel';
import { NavigatorService } from '../../../../navigator.services';
import { DataService } from '../../../../data.service';
import { CommonService } from '../../../../common.service';
import { AuthService } from '../../../../auth.service';


@Component({
  selector: 'app-document-class',
  templateUrl: './document-class.component.html',
  styleUrls: ['./document-class.component.scss']
})
export class DocumentClassComponent implements OnInit {

  Model: SystemInfoModel;
  constructor(
    dataService: DataService, public navigatorService: NavigatorService, public authenticationService: AuthService,
    public commonService: CommonService, public dialogRef: MatDialogRef<DocumentClassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.Model = new SystemInfoModel();
    this.Model.Dto = new SystemInfoDto();
    this.Model.Dto.Id = 0;
    this.Model.Dto.Name = null;
    this.Model.Dtos = data.SubClasses;
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
  }

  ClassSelected($event: SubClasses) {
    this.Model.Dto = this.Model.Dtos.find(q => q.Name === $event);
  }

  Next() {
    this.dialogRef.close(this.Model.Dto);
  }
}
