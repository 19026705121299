<div class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 11px;" src="../../../../../assets/icon/user.svg">
                    <h2>{{navigatorService.Dictionary?.AddUserTitle}}</h2>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <div>
        <p *ngIf="navigatorService.PageAction !== 'Edit'" class="title-description mb-1">
            {{navigatorService.Dictionary?.AddUserDescription}}</p>
        <p *ngIf="navigatorService.PageAction === 'Edit'" class="title-description mb-1">
            {{navigatorService.Dictionary?.EditUserDescription}}</p>
        <div class="required-label">
            <span class="star">*</span>
            <span>{{navigatorService.Dictionary?.RequiredFields}}</span>
        </div>
        <mat-dialog-content>
            <div class="form mb-5">
                <div class="row" *ngIf="navigatorService.PageAction !== 'Edit' && data?.Action==='ldap'">
                    <div class="col-md-12 col-sm-12">
                        <app-mt-autocomplete #autocompleteUser [appearance]="'legacy'" [Items]="Model?.Dtos"
                        [DisplayProperties]="['Displayname']"
                        [Placeholder]="navigatorService.Dictionary?.SearchLdap" [SearchProperties]="['Displayname']"
                        [LoaderType]="'Spinner'" [DropDownMode]="false" [DataValue]=""
                        (SelectionChange)="LdapUsersChange($event)" [Multiple]="false"
                        (OnChange)='SearchLdap($event)' [OptionIcon]="'add'"></app-mt-autocomplete>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.Name" [Disabled]="data?.Action==='ldap' || Model?.Dto?.Type === 'ldap'"
                            [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Surname'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.Surname" [Disabled]="data?.Action==='ldap' || Model?.Dto?.Type === 'ldap'"
                            [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Email'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.Email"
                            [Description]="navigatorService.Dictionary?.Maxlength50" [Regex]="'[a-zA-Z0-9.-_]+@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,10}$'"
                            [Disabled]="navigatorService.PageAction === 'Edit' || data?.Action==='ldap'">
                        </app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field *ngIf="!Model.Dto?.IsOwner" class="w-100">
                            <mat-label>{{navigatorService.Dictionary?.RolePlaceholder}}</mat-label>
                            <mat-select [value]="Model?.Dto?.SettingsRole" (selectionChange)="SelectRole($event)"
                                [disabled]="DisableRoleSelect()" required>
                                <mat-option *ngFor="let role of Roles" [value]="role">
                                    {{role === 'admin' ? 'Admin' : 'Worker'}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="Model.Dto?.IsOwner" class="w-100">
                            <mat-label>Owner</mat-label>
                            <mat-select [disabled]="true">
                                <mat-option selected>Owner</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel.toUpperCase()}}</span>
        </button>
        <button (click)="Save()" mat-button class="it-doc-btn-primary" [disabled]="!ValidateForm(false)"
            cdkFocusInitial>
            <span *ngIf="navigatorService.PageAction ==='New'"
                class="button-text">{{navigatorService.Dictionary?.Confirm.toUpperCase()}}</span>
            <span *ngIf="navigatorService.PageAction ==='Edit'"
                class="button-text">{{navigatorService.Dictionary?.Confirm.toUpperCase()}}</span>
        </button>
    </div>
</div>
