import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Controller as ItDocController, Action as ItDocAction } from './itdoc/itdoc.configuration';
import { OrganizationUnitModel } from './itdoc/models/OrganizationUnitModel';
import { AccountModel } from './itdoc/models/AccountModel';
import { MetadataTipologyModel } from './itdoc/models/MetadataTipologyModel';
import { MetadataTipologyValueModel } from './itdoc/models/MetadataTipologyValueModel';
import { TemplateModel } from './itdoc/models/TemplateModel';
import { PermissionModel } from './itdoc/models/PermissionModel';
import { TipologyModel } from './itdoc/models/TipologyModel';
import { FileModel } from './itdoc/models/FileModel';
import { ObjectModel } from './itdoc/models/ObjectModel';
import { RoleModel } from './itdoc/models/RoleModel';
import { MimeTypeModel } from './itdoc/models/MimeTypeModel';
import { FieldsModel } from './itdoc/models/FieldsModel';
import { SettingsModel } from './itdoc/models/SettingsModel';
import { DossierModel } from './itdoc/models/DossierModel';
import { AccountDossierModel } from './itdoc/models/AccountDossierModel';
import { ObjectDossierModel } from './itdoc/models/ObjectDossierModel';
import { DocumentModel } from './itdoc/models/DocumentModel';
import { AccountRoleModel } from './itdoc/models/AccountRoleModel';
import { TipologyUoModel } from './itdoc/models/TipologyUoModel';
import { UserGroupModel } from './models/UserGroupModel';
import { FavouriteModel } from './itdoc/models/FavouriteModel';
import { ActivityModel } from './itdoc/models/ActivityModel';
import { UserModel } from './models/UserModel';
import { WorkflowUserModel } from './workflow/models/WorkflowUserModel';
import { WorkflowStepModel } from './workflow/models/WorkflowStepModel';
import { WorkflowModel } from './workflow/models/WorkflowModel';
import { WorkflowDataModel } from './workflow/models/WorkflowDataModel';
import { CurrentUserModel } from './models/CurrentUserModel';
import { GroupModel } from './models/GroupModel';
import { LinkTokenModel } from './models/LinkToken';
import { NotificationModel } from './models/NotificationModel';
import { UserAppsModel } from './models/UserApps';
import { StatisticsModel } from './itdoc/models/StatisticsModel';
import { SignatureAccountModel } from './models/SignatureAccount';
import { SignatureServiceModel } from './models/SignatureService';
import { AttachmentModel } from './itdoc/models/AttachmentModel';
import { EncryptionModel } from './models/EncryptionModel';
import { AppConfigService } from './app.config.service';
import { AuthService } from './auth.service';
import { DocClassModel } from './itdoc/models/DocClassModel';
import { DocFlyDocClassModel } from './itdoc/models/DocFlyDocClassModel';
import { SystemInfoModel } from './itdoc/models/SystemInfoModel';
import { ViewRepositoryModel } from './itdoc/models/SystemInfoModel copy';

@Injectable()
export class DataService {
  constructor(private http: HttpClient, private configAppService: AppConfigService, private authService: AuthService) {
  }

  public async AsyncPost<TModel>(controller: string, action: string, requestModel: TModel) {
    await this.authService.RefreshToken();
    const response = await this.http.post<TModel>(`${this.configAppService.apiUrl}` + '/' + this.configAppService.appId +
      (controller ? '/' + controller : '')
      + '/' + action, requestModel, { observe: 'response' }).toPromise();
    return response;
  }
  public async AsyncGet<TModel>(controller: string, action: string, requestModel: TModel = null) {
    await this.authService.RefreshToken();
    const response = await this.http.get<any>(`${this.configAppService.apiUrl}` + '/' + this.configAppService.appId +
      (controller ? '/' + controller : '')
      + '/' + action, { observe: 'response' }).toPromise();
    return response;
  }

  public async AsyncITCorePost<TModel>(controller: string, action: string, requestModel: TModel) {
    this.authService.RefreshToken();
    const response = await this.http.post<TModel>(`${this.configAppService.apiUrl}` + (controller ? '/' + controller : '')
      + '/' + action, requestModel, { observe: 'response' }).toPromise();
    return response;
  }

  public async AsyncITCoreGet<TModel>(controller: string, action: string) {
    this.authService.RefreshToken();
    const response = await this.http.get(`${this.configAppService.apiUrl}` + (controller ? '/' + controller : '')
      + '/' + action, { observe: 'response' }).toPromise();
    return response;
  }

  // #region itdoc
  //#region "Uo"
  public async GetOrganizationalUnit(requestModel: OrganizationUnitModel) {
    const response = await this.AsyncPost(ItDocController.Uo, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateOrganizationalUnit(requestModel: OrganizationUnitModel) {
    const response = await this.AsyncPost(ItDocController.Uo, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadOrganizationalUnit(requestModel: OrganizationUnitModel) {
    const response = await this.AsyncPost(ItDocController.Uo, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetHierarchyOrganizationalUnit(requestModel: OrganizationUnitModel) {
    const response = await this.AsyncPost(ItDocController.Uo, 'hierarchy', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteOrganizationUnit(requestModel: OrganizationUnitModel): Promise<OrganizationUnitModel> {
    const response = await this.AsyncPost(ItDocController.Uo, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }


  // #endregion

  //#region "Account"
  public async LoadAccount(requestModel: AccountModel) {
    const response = await this.AsyncPost(ItDocController.Account, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetAccount(requestModel: AccountModel): Promise<AccountModel> {
    const response = await this.AsyncPost(ItDocController.Account, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateAccount(requestModel: AccountModel): Promise<AccountModel> {
    const response = await this.AsyncPost(ItDocController.Account, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteAccount(requestModel: AccountModel): Promise<AccountModel> {
    const response = await this.AsyncPost(ItDocController.Account, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region MetadataTipology
  public async DeleteMetaDataTipology(requestModel: MetadataTipologyModel): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipology, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async LoadMetaDataTipology(requestModel: MetadataTipologyModel): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipology, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetMetaDataTipology(requestModel: MetadataTipologyModel): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipology, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadMetaDataTipology(requestModel: MetadataTipologyModel): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipology, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateMetaDataTipology(requestModel: MetadataTipologyModel): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipology, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region MetadataTipologyValue
  public async DeleteMetadataTipologyValue(requestModel: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipologyValue, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async LoadMetadataTipologyValue(requestModel: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipologyValue, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetMetadataTipologyValue(requestModel: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipologyValue, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadMetadataTipologyValue(requestModel: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipologyValue, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateMetadataTipologyValue(requestModel: MetadataTipologyValueModel): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(ItDocController.MetadataTipologyValue, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region Template
  public async DeleteTemplate(requestModel: TemplateModel): Promise<TemplateModel> {
    const response = await this.AsyncPost(ItDocController.Template, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadTemplate(requestModel: TemplateModel): Promise<TemplateModel> {
    const response = await this.AsyncPost(ItDocController.Template, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetTemplate(requestModel: TemplateModel): Promise<TemplateModel> {
    const response = await this.AsyncPost(ItDocController.Template, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadTemplate(requestModel: TemplateModel): Promise<TemplateModel> {
    const response = await this.AsyncPost(ItDocController.Template, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateTemplate(requestModel: TemplateModel): Promise<TemplateModel> {
    const response = await this.AsyncPost(ItDocController.Template, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region Permission
  public async CreateOrUpdatePermission(requestModel: PermissionModel): Promise<PermissionModel> {
    const response = await this.AsyncPost(ItDocController.Permission, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetPermission(requestModel: PermissionModel): Promise<PermissionModel> {
    const response = await this.AsyncPost(ItDocController.Permission, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeletePermission(requestModel: PermissionModel): Promise<PermissionModel> {
    const response = await this.AsyncPost(ItDocController.Permission, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region Tipology
  public async GetTipology(requestModel: TipologyModel): Promise<TipologyModel> {
    const response = await this.AsyncPost(ItDocController.Tipology, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadTipology(requestModel: TipologyModel): Promise<TipologyModel> {
    const response = await this.AsyncPost(ItDocController.Tipology, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateTipology(requestModel: TipologyModel): Promise<TipologyModel> {
    const response = await this.AsyncPost(ItDocController.Tipology, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadTipology(requestModel: TipologyModel): Promise<TipologyModel> {
    const response = await this.AsyncPost(ItDocController.Tipology, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region file manager
  // @deprecate
  // public async Upload(requestModel: FileModel) {
  //   const response = await this.AsyncPost(ItDocController.FileManager, ItDocAction.UploadChunk, requestModel);
  //   if (response) {
  //     requestModel.Performed = response?.body?.Performed;
  //     requestModel.Message = response?.body?.Message;
  //     requestModel.Code = response?.body?.Code;
  //     requestModel.Dto = response?.body?.Dto;
  //   } else {
  //     requestModel.Performed = false;
  //   }
  //   return requestModel;
  // }

  public async AsyncPostUpload<TModel, TModelOptions>(body: TModel, options: TModelOptions | any): Promise<any> {
    await this.authService.RefreshToken();
    const url = `${this.configAppService.apiUrl}` + '/' + this.configAppService.appId + '/'
      + ItDocController.FileManager + '/' + ItDocAction.Upload;
    const response = await this.http.post<TModel>(url, body, options).toPromise();
    return response;
  }

  public async Download(requestModel: FileModel) {
    await this.authService.RefreshToken();
    const response = await this.http.post(`${this.configAppService.apiUrl}` + '/' + this.configAppService.appId +
      (ItDocController.FileManager ? '/' + ItDocController.FileManager : '')
      + '/' + ItDocAction.Download, requestModel, { observe: 'response', responseType: 'arraybuffer' }).toPromise();
    if (response) {
      requestModel.Performed = true;
      requestModel.Dto.File = response?.body;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }

  public async ScanToPdf(requestModel: FileModel) {
    const response = await this.AsyncPost(ItDocController.FileManager, ItDocAction.ScanToPdf, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }
  // #endregion

  // #region Object
  public async CountObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(ItDocController.Object, ItDocAction.Count, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(ItDocController.Object, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(ItDocController.Object, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(ItDocController.Object, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(ItDocController.Object, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(ItDocController.Object, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ProtocolObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(ItDocController.Object, ItDocAction.Protocol, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  //#endregion



  //#region Activity
  public async LoadActivity(requestModel: ActivityModel): Promise<ActivityModel> {
    const response = await this.AsyncPost(ItDocController.Activity, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
    }
    return requestModel;
  }
  public async GetActivity(requestModel: ActivityModel): Promise<ActivityModel> {
    const response = await this.AsyncPost(ItDocController.Activity, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      // requestModel.Code = response.Code;
    }
    return requestModel;
  }

  //#endregion

  public async GetRole(requestModel: RoleModel) {
    const response = await this.AsyncPost(ItDocController.Role, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetMimeType(requestModel: MimeTypeModel): Promise<MimeTypeModel> {
    const response = await this.AsyncPost(ItDocController.MimeType, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateMimeType(requestModel: MimeTypeModel): Promise<MimeTypeModel> {
    const response = await this.AsyncPost(ItDocController.Account, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetFields(requestModel: FieldsModel): Promise<FieldsModel> {
    const response = await this.AsyncPost(ItDocController.Fields, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateFields(requestModel: FieldsModel): Promise<FieldsModel> {
    const response = await this.AsyncPost(ItDocController.Fields, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadAccount(requestModel: AccountModel): Promise<AccountModel> {
    const response = await this.AsyncITCorePost(ItDocController.Account, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }


  public async GetAppSettings(requestModel: SettingsModel): Promise<SettingsModel> {
    const response = await this.AsyncITCorePost(ItDocController.Settings, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetConfigKey(requestModel: SettingsModel): Promise<SettingsModel> {
    const response = await this.AsyncITCorePost(ItDocController.Settings, ItDocAction.GetConfigKey, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async SettingsSave(requestModel: SettingsModel): Promise<SettingsModel> {
    const response = await this.AsyncITCorePost(ItDocController.Settings, ItDocAction.Set, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetGroupMail(requestModel: any): Promise<any> {
    const response = await this.AsyncPost(ItDocController.Account + '/' + ItDocController.UsersGroupsMails, ItDocAction.Get, requestModel);
    return response;
  }

  public async CreateOrUpdateDossier(requestModel: DossierModel): Promise<DossierModel> {
    const response = await this.AsyncPost(ItDocController.Dossier, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetDossier(requestModel: DossierModel): Promise<DossierModel> {
    const response = await this.AsyncPost(ItDocController.Dossier, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadDossier(requestModel: DossierModel): Promise<DossierModel> {
    const response = await this.AsyncPost(ItDocController.Dossier, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadDossier(requestModel: DossierModel): Promise<DossierModel> {
    const response = await this.AsyncPost(ItDocController.Dossier, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteDossier(requestModel: DossierModel): Promise<DossierModel> {
    const response = await this.AsyncPost(ItDocController.Dossier, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateAccountDossier(requestModel: AccountDossierModel): Promise<AccountDossierModel> {
    const response = await this.AsyncPost(ItDocController.AccountDossier, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetAccountDossier(requestModel: AccountDossierModel): Promise<AccountDossierModel> {
    const response = await this.AsyncPost(ItDocController.AccountDossier, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadAccountDossier(requestModel: AccountDossierModel): Promise<AccountDossierModel> {
    const response = await this.AsyncPost(ItDocController.AccountDossier, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteAccountDossier(requestModel: AccountDossierModel): Promise<AccountDossierModel> {
    const response = await this.AsyncPost(ItDocController.AccountDossier, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateObjectDossier(requestModel: ObjectDossierModel): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(ItDocController.ObjectDossier, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetObjectDossier(requestModel: ObjectDossierModel): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(ItDocController.ObjectDossier, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async LoadObjectDossier(requestModel: ObjectDossierModel): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(ItDocController.ObjectDossier, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadObjectDossier(requestModel: ObjectDossierModel): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(ItDocController.ObjectDossier, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteObjectDossier(requestModel: ObjectDossierModel): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(ItDocController.ObjectDossier, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateFromTemplate(requestModel: DocumentModel) {
    const response = await this.AsyncPost(ItDocController.Template, ItDocAction.CreateFromTemplate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.Dto = response?.body?.Dto;
    }
    return requestModel;
  }

  //#region OnlyOffice
  public async CreateOnlyOfficeFile(requestModel: FileModel): Promise<FileModel> {
    const response = await this.AsyncPost(ItDocController.OnlyOffice, ItDocAction.Create, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.Dto = response?.body?.Dto;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }

  public async OpenOnlyOfficeFile(requestModel: FileModel): Promise<any> {
    const response = await this.AsyncPost(ItDocController.OnlyOffice, ItDocAction.Open, requestModel);
    return response?.body;

  }

  public async ConvertOnlyOfficeFile(requestModel: FileModel): Promise<any> {
    const response = await this.AsyncPost(ItDocController.OnlyOffice, ItDocAction.Convert, requestModel);
    return response?.body;

  }

  //#endregion


  public async GetAccountRole(requestModel: AccountRoleModel): Promise<AccountRoleModel> {
    const response = await this.AsyncPost(ItDocController.AccountRole, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteAccountRole(requestModel: AccountRoleModel): Promise<AccountRoleModel> {
    const response = await this.AsyncPost(ItDocController.AccountRole, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteTipology(requestModel: TipologyModel): Promise<TipologyModel> {
    const response = await this.AsyncPost(ItDocController.Tipology, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteTipologyUo(requestModel: TipologyUoModel): Promise<TipologyUoModel> {
    const response = await this.AsyncPost(ItDocController.TipologyUo, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateTipologyUo(requestModel: TipologyUoModel): Promise<TipologyUoModel> {
    const response = await this.AsyncPost(ItDocController.TipologyUo, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetFavourites(requestModel: FavouriteModel) {
    const response = await this.AsyncPost(ItDocController.Favourites, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateFavourite(requestModel: FavouriteModel): Promise<FavouriteModel> {
    const response = await this.AsyncPost(ItDocController.Favourites, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {

      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteFavourite(requestModel: FavouriteModel): Promise<FavouriteModel> {
    const response = await this.AsyncPost(ItDocController.Favourites, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  //#endregion

  //#region Core
  public async IsAdmin(): Promise<boolean> {
    const response = await this.AsyncITCoreGet(ItDocController.User, ItDocAction.IsAdmin);
    if (response?.body) {
      return response.body['IsAdmin'];
    }
    return false;
  }

  public async GetUsersGroup(requestModel: UserGroupModel): Promise<UserGroupModel> {
    const response = await this.AsyncITCorePost(ItDocController.UserGroup, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async CreateOrUpdateUsersGroup(requestModel: UserGroupModel): Promise<UserGroupModel> {
    const response = await this.AsyncITCorePost(ItDocController.UserGroup, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async DeleteUsersGroup(requestModel: UserGroupModel): Promise<UserGroupModel> {
    const response = await this.AsyncITCorePost(ItDocController.UserGroup, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async SearchUser(requestModel: UserModel): Promise<UserModel> {
    const response = await this.AsyncITCorePost(ItDocController.User, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }
  public async SearchUserItDoc(requestModel: UserModel): Promise<UserModel> {
    const response = await this.AsyncPost(ItDocController.User, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }
  public async ReadUser(requestModel: UserModel): Promise<UserModel> {
    const response = await this.AsyncPost(ItDocController.User, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
    }
    return requestModel;
  }
  //#endregion

  // #region workflow
  public async LoadWorkflow(requestModel: WorkflowModel): Promise<WorkflowModel> {
    const response = await this.AsyncPost(ItDocController.Workflow, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.Count = response?.body?.Count;

    }
    return requestModel;
  }
  public async DeleteWorkflow(requestModel: WorkflowModel): Promise<WorkflowModel> {
    const response = await this.AsyncPost(ItDocController.Workflow, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetWorkflow(requestModel: WorkflowModel): Promise<WorkflowModel> {
    const response = await this.AsyncPost(ItDocController.Workflow, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadWorkflow(requestModel: WorkflowModel): Promise<WorkflowModel> {
    const response = await this.AsyncPost(ItDocController.Workflow, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateWorkflow(requestModel: WorkflowModel): Promise<WorkflowModel> {
    const response = await this.AsyncPost(ItDocController.Workflow, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }



  public async LoadWorkflowStep(requestModel: WorkflowStepModel): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowStep, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async DeleteWorkflowStep(requestModel: WorkflowStepModel): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowStep, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetWorkflowStep(requestModel: WorkflowStepModel): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowStep, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadWorkflowStep(requestModel: WorkflowStepModel): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowStep, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateWorkflowStep(requestModel: WorkflowStepModel): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowStep, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }



  public async LoadWorkflowUser(requestModel: WorkflowUserModel): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowUser, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async DeleteWorkflowUser(requestModel: WorkflowUserModel): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowUser, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetWorkflowUser(requestModel: WorkflowUserModel): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowUser, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadWorkflowUser(requestModel: WorkflowUserModel): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowUser, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateWorkflowUser(requestModel: WorkflowUserModel): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowUser, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }



  public async RunWorkflowData(requestModel: WorkflowDataModel): Promise<WorkflowDataModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowData, ItDocAction.Run, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteWorkflowData(requestModel: WorkflowDataModel) {
    const response = await this.AsyncPost(ItDocController.WorkflowData, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadWorkflowData(requestModel: WorkflowDataModel): Promise<WorkflowDataModel> {
    const response = await this.AsyncPost(ItDocController.WorkflowData, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region current user
  public async GetSettingsUser(requestModel: CurrentUserModel): Promise<CurrentUserModel> {
    const response = await this.AsyncPost(ItDocController.User, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }
  public async LoadSettingsUser(requestModel: CurrentUserModel): Promise<CurrentUserModel> {
    const response = await this.AsyncPost(ItDocController.User, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadSettingsUser(requestModel: CurrentUserModel): Promise<CurrentUserModel> {
    const response = await this.AsyncPost(ItDocController.User, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
    }
    return requestModel;
  }

  public async CreateOrUpdateUser(requestModel: CurrentUserModel): Promise<CurrentUserModel> {
    const response = await this.AsyncPost(ItDocController.User, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async SearchUsersLdap(requestModel: CurrentUserModel): Promise<CurrentUserModel> {
    const response = await this.AsyncITCorePost(ItDocController.User, ItDocAction.LdapSearch, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LdapEnabled(): Promise<boolean> {
    const response = await this.AsyncITCoreGet(ItDocController.User, 'ldapenabled');
    if (response) {
      return response.body?.toString() === 'true';
    }
    return false;
  }
  // #endregion

  // #region group
  public async GetGroup(requestModel: GroupModel): Promise<GroupModel> {
    const response = await this.AsyncITCorePost(ItDocController.Group, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }
  // #endregion

  // #region invitation
  public async SendInvitation(requestModel: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(ItDocController.Invitation, ItDocAction.Send, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetInvitation(requestModel: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(ItDocController.Invitation, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async GetResetTokenFirst(requestModel: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(ItDocController.ResetPassword, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async AcceptInvitation(requestModel: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(ItDocController.Invitation, ItDocAction.Accept, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ResetPassword(requestModel: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(ItDocController.ResetPassword, ItDocAction.Reset, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region user apps
  public async DeleteUserApps(requestModel: UserAppsModel) {
    const response = await this.AsyncITCorePost(ItDocController.UserApps, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateUserApps(requestModel: UserAppsModel) {
    const response = await this.AsyncITCorePost(ItDocController.UserApps, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region notification
  public async GetNotification(requestModel: NotificationModel): Promise<NotificationModel> {
    const response = await this.AsyncPost(ItDocController.Notification, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async LoadNotificationHistory(requestModel: NotificationModel): Promise<NotificationModel> {
    const response = await this.AsyncPost(ItDocController.Notification, 'all', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
    }
    return requestModel;
  }

  public async MarkNotification(requestModel: NotificationModel): Promise<NotificationModel> {
    const response = await this.AsyncITCorePost(ItDocController.Notification, 'mark', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
    }
    return requestModel;
  }
  public async NewWorkflow(requestModel: NotificationModel): Promise<NotificationModel> {
    const response = await this.AsyncPost(ItDocController.Notification, 'newworkflow', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async NewUo(requestModel: NotificationModel): Promise<NotificationModel> {
    const response = await this.AsyncPost(ItDocController.Notification, 'newuo', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async NewDossier(requestModel: NotificationModel): Promise<NotificationModel> {
    const response = await this.AsyncPost(ItDocController.Notification, 'newdossier', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  public async ReadStatistics(requestModel: StatisticsModel): Promise<StatisticsModel> {
    const response = await this.AsyncPost(ItDocController.Statistics, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateStatistics(requestModel: StatisticsModel): Promise<StatisticsModel> {
    const response = await this.AsyncPost(ItDocController.Statistics, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  //#region signature
  public async GetSignatureService(requestModel: SignatureServiceModel): Promise<SignatureServiceModel> {
    const response = await this.AsyncITCorePost(ItDocController.SignatureService, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetSignatureAccount(requestModel: SignatureAccountModel): Promise<SignatureAccountModel> {
    const response = await this.AsyncITCorePost(ItDocController.SignatureAccount, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateSignatureAccount(requestModel: SignatureAccountModel): Promise<SignatureAccountModel> {
    const response = await this.AsyncITCorePost(ItDocController.SignatureAccount, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteSignatureAccount(requestModel: SignatureAccountModel): Promise<SignatureAccountModel> {
    const response = await this.AsyncITCorePost(ItDocController.SignatureAccount, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async Sign(requestModel: SignatureAccountModel): Promise<SignatureAccountModel> {
    const response = await this.AsyncPost(ItDocController.Signature, ItDocAction.Sign, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async SignatureVerify(requestModel: FileModel): Promise<any> {
    const response = await this.AsyncITCorePost(ItDocController.Signature, 'verify', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ExtractP7M(requestModel: FileModel): Promise<any> {
    const response = await this.AsyncITCorePost(ItDocController.Signature, 'extractp7m', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetPreview(requestModel: FileModel): Promise<FileModel> {
    const response = await this.AsyncITCorePost(ItDocController.Signature, ItDocAction.Get + '/preview', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.Count = response?.body?.Count;
    }
    return requestModel;
  }
  //#endregion

  //#region "Attachment"
  public async LoadAttachment(requestModel: AttachmentModel) {
    const response = await this.AsyncPost(ItDocController.Attachment, ItDocAction.Load, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetAttachment(requestModel: AttachmentModel): Promise<AttachmentModel> {
    const response = await this.AsyncPost(ItDocController.Attachment, ItDocAction.Get, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadAttachment(requestModel: AttachmentModel): Promise<AttachmentModel> {
    const response = await this.AsyncPost(ItDocController.Attachment, ItDocAction.Read, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateAttachment(requestModel: AttachmentModel): Promise<AttachmentModel> {
    const response = await this.AsyncPost(ItDocController.Attachment, ItDocAction.CreateOrUpdate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteAttachment(requestModel: AttachmentModel): Promise<AttachmentModel> {
    const response = await this.AsyncPost(ItDocController.Attachment, ItDocAction.Delete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  public async Decrypt(requestModel: EncryptionModel): Promise<EncryptionModel> {
    const response = await this.AsyncPost(ItDocController.File, ItDocAction.Decrypt, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async Encrypt(requestModel: EncryptionModel): Promise<EncryptionModel> {
    const response = await this.AsyncPost(ItDocController.File, ItDocAction.Encrypt, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CheckEncryptionPassword(requestModel: EncryptionModel): Promise<EncryptionModel> {
    const response = await this.AsyncPost(ItDocController.File, 'checkencryptionpassword', requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  // #region docFly
  public async GetDocumentModel(requestModel: DocClassModel) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.ModelGet, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async GetDocFlyDocClass(requestModel: DocFlyDocClassModel) {
    const response = await this.AsyncPost(ItDocController.DocFly, ItDocAction.ClassGet, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async GetArchivesInfo(requestModel: SystemInfoModel) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.GetSystemInfo, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async DocFlyLogin(username: string, password: string, saveCredential: boolean, autoLogin: boolean = false) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.Login,
      { Username: username, Password: password, SaveCredential: saveCredential, AutoLogin: autoLogin });
    return response?.body;
  }

  async GetViewRepository(requestModel: ViewRepositoryModel) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.GetRepositoryInfo, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async DownloadFile(requestModel: ViewRepositoryModel) {
    await this.authService.RefreshToken();
    const response = await this.http.post(`${this.configAppService.apiUrl}` + '/' + this.configAppService.appId
      + '/' + ItDocController.Conservation + '/' + ItDocAction.Download, requestModel,
      { observe: 'response', responseType: 'arraybuffer' }).toPromise();
    if (response) {
      requestModel.Performed = true;
      requestModel.Dto.File = response?.body;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }

  async DownloadPDD(requestModel: ViewRepositoryModel) {
    await this.authService.RefreshToken();
    const response = await this.http.post(`${this.configAppService.apiUrl}` + '/' + this.configAppService.appId
      + '/' + ItDocController.Conservation + '/' + ItDocAction.DownloadPDD, requestModel,
      { observe: 'response', responseType: 'arraybuffer' }).toPromise();
    if (response) {
      requestModel.Performed = true;
      requestModel.Dto.File = response?.body;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }

  async PdvSend(requestModel: DocClassModel) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.PdvSend, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async FilesSend(requestModel: DocClassModel) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.FilesSend, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async CreatePdv(requestModel: DocClassModel) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.PdvCreate, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.PdvModels = response?.body?.PdvModels;
    }
    return requestModel;
  }
  async DeletePda(requestModel: ViewRepositoryModel) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.PdaDelete, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async GetDisposedLog(requestModel: ViewRepositoryModel) {
    const response = await this.AsyncPost(ItDocController.Conservation, ItDocAction.PdaLog, requestModel);
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async CheckAutoLogin(): Promise<any> {
    const response = await this.AsyncGet(ItDocController.Conservation, ItDocAction.CheckAutologin);
    if (response?.body) {
      return response?.body?.Dto?.IsAutoLogin;
    }
    return false;
  }
  // #endregion docFly
}
