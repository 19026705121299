import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class AttachmentModel extends BaseModel<AttachmentEntity, AttachmentDto, AttachmentFilter> {
    constructor() {
        super();
        this.Take = 10;
    }
}

export class AttachmentEntity extends BaseEntity {
    ObjectId: number|null;
    Name: string|null; // 500
    PhysicalName: string|null; // 500
    Path: string|null; // 4000
    Creator: string|null; // 500
    CreationDate: number|null;
    Hash: string|null;
    constructor() {
        super();
        this.Name = null;
        this.PhysicalName = null;
        this.Path = null;
        this.Creator = null;
        this.CreationDate = null;
        this.Hash = null;
        this.ObjectId = null;
    }
}

export class AttachmentDto extends AttachmentEntity {
    Selected!: boolean;
    Icon!: string|null;
    CreationDateString!: string|null;
    MimeType!: string|null;
    Size!: number|null;
    constructor() {
        super();
    }
}

export class AttachmentFilter extends AttachmentDto {

}
