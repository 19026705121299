import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() Placeholder: string;
  @Input() FiltersLabelBtn: string;
  @Input() ActionsLabelBtn: string;
  @Input() HaveFilters: boolean;
  @Input() HaveActions: boolean;
  @Input() FilterCount = 0;
  @Input() Interactive = false;
  @Input() ItemsCount = 0;
  @Input() TriggerMenuItems: Array<any>;
  @Input() ModalStyle = false;
  @Output() OnEnterPress: EventEmitter<string> = new EventEmitter();
  @Output() OnFiltersBtnPress: EventEmitter<any> = new EventEmitter();
  @Output() OnItemActionsClick: EventEmitter<boolean> = new EventEmitter();
  @Output() OnClearBtnPress: EventEmitter<boolean> = new EventEmitter();
  @Output() OnKeyUp: EventEmitter<string> = new EventEmitter();

  searchValue = '';

  constructor() { }

  ngOnInit(): void {
  }

  SearchEvent(search: string) {
    search = (search ? search : null);
    this.OnEnterPress.emit(search);
  }

  InteractiveSearch(search: string) {
    if (this.Interactive) {
      if (search?.length >= 3 || search?.length <= 0) {
        this.OnKeyUp.emit(search);
      }
    }
  }

  OpenFiltersDialog() {
    const search = (this.searchValue ? this.searchValue : null);
    this.OnFiltersBtnPress.emit(search);
  }

  ItemActionsMenuClick(item) {
    this.OnItemActionsClick.emit(item);
  }

  ClearInputValue(emit = true) {
    this.searchValue = '';
    if (emit) {
      this.OnClearBtnPress.emit(null);
    }
  }

  GetSearchValue() {
    return (this.searchValue ? this.searchValue : null);
  }

}
