import { BaseEntity, BaseModel } from "../../models/BaseModel";
import { WorkflowStepDto } from './WorkflowStepModel';

export class WorkflowModel extends BaseModel<WorkflowEntity, WorkflowDto, WorkflowFilter> {
    constructor() {
        super();
    }
}

export class WorkflowEntity extends BaseEntity {
    Name: string;
    RelevantSector: string;
    TimeLimit: number;
    State: string;
    constructor() {
        super();
        this.Name = null;
        this.RelevantSector = null;
        this.TimeLimit = null;
        this.State = null;
    }
}

export class WorkflowDto extends WorkflowEntity {
    WorkflowSteps: Array<WorkflowStepDto>;
    // GUI
    IsExpanded: boolean;
    IsDisabled: boolean;
    IsEditing: boolean;
    constructor() {
        super();
        this.WorkflowSteps = new Array<WorkflowStepDto>();
        this.IsExpanded = false;
        this.IsDisabled = false;
        this.IsEditing = false;
    }
}

export class WorkflowFilter extends WorkflowDto {
}
