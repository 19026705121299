import { ObjectDossierFilter, ObjectDossierModel } from '../models/ObjectDossierModel';
import { DataService } from '../../data.service';

export class ObjectDossierController {

    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: ObjectDossierModel): Promise<ObjectDossierModel> {
        // let response = model;
        // if (model && model.Dto) {
        //     response = await this.dataService.CreateOrUpdateObjectDossier(model);
        //     if (response && response.Performed) {
        //         response.Dto.Id = response.Entity?.Id;
        //     }
        // }
        const response = await this.dataService.CreateOrUpdateObjectDossier(model);
        if (response && response.Performed && response.Dto) {
            response.Dto.Id = response.Entity?.Id;
        }
        return response;
    }

    async Get(model: ObjectDossierModel): Promise<ObjectDossierModel> {
       return await this.dataService.GetObjectDossier(model);
    }

    async Load(model: ObjectDossierModel): Promise<ObjectDossierModel> {
      return await this.dataService.LoadObjectDossier(model);
   }

    async Read(model: ObjectDossierModel): Promise<ObjectDossierModel> {
        return await this.dataService.ReadObjectDossier(model);
    }

    async Delete(model: ObjectDossierModel): Promise<ObjectDossierModel> {
        const response = await this.dataService.DeleteObjectDossier(model);
        return response;
    }
}
