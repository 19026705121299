import { AccountDossierFilter, AccountDossierModel } from '../models/AccountDossierModel';
import { DataService } from '../../data.service';

export class AccountDossierController {

    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: AccountDossierModel): Promise<AccountDossierModel> {
        // let response = model;
        // if (model && model.Dto) {
        //     response = await this.dataService.CreateOrUpdateAccountDossier(model);
        //     if (response && response.Performed) {
        //         response.Dto.Id = response.Entity?.Id;
        //     }
        // }
        const response = await this.dataService.CreateOrUpdateAccountDossier(model);
        if (response && response.Performed && response.Dto) {
            response.Dto.Id = response.Entity?.Id;
        }
        return response;
    }

    async Get(model: AccountDossierModel): Promise<AccountDossierModel> {
        const response = await this.dataService.GetAccountDossier(model);
        return response;
    }

    async Read(model: AccountDossierModel): Promise<AccountDossierModel> {
        const response = await this.dataService.ReadAccountDossier(model);
        return response;
    }

    async Delete(model: AccountDossierModel): Promise<AccountDossierModel> {
        const response = await this.dataService.DeleteAccountDossier(model);
        return response;
    }
}
