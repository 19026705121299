import { BaseEntity, BaseModel } from '../../models/BaseModel';
import { Constants } from '../itdoc.configuration';

export class ActivityModel extends BaseModel<ActivityEntity, ActivityDto, ActivityFilter> {
    constructor() {
        super();
    }
}

export class ActivityEntity extends BaseEntity {
    Uid?: string|null;
    AffectedUid: string|null;
    ObjectType: string|null;
    ObjectId: number|null;
    Date: number|null;
    Type: any;
    App: string;
    Params: string|null;
    Message: string|null;
    constructor() {
        super();
        this.Uid = null;
        this.AffectedUid = null;
        this.ObjectType = null;
        this.ObjectId = null;
        this.Date = null;
        this.Type = null;
        this.App = Constants.AppId;
        this.Params = null;
        this.Message = null;

    }
}

export class ActivityDto extends ActivityEntity {
    DateString!: string;
    Title!: string;
    DisplayMessage!: string;
    Icon!: string;
    AffectedDisplayUid!: string;
    ParamsDecode: any;
    Subject!: string;
    AffectedAvatar!: string;
    Avatar!: string;
    DisplayName!: string;
    constructor() {
        super();
    }
}

export class ActivityFilter extends ActivityDto {
    constructor() {
        super();
    }
}
