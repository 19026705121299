import { BaseEntity, BaseModel } from "../../models/BaseModel";
import { PropertyDto } from './FieldsModel';
import { MetadataTipologyValueDto } from './MetadataTipologyValueModel';
import { PermissionDto } from './PermissionModel';

export class MetadataTipologyModel extends BaseModel<MetadataTipologyEntity, MetadataTipologyDto, MetadataTipologyFilter> {
    constructor() {
        super();
    }
}

export class MetadataTipologyEntity extends BaseEntity {
    TipologyId: number;
    Key: string;
    Indexed: boolean;
    FieldId: number;
    Properties: string;
    constructor() {
        super();
        this.TipologyId = null;
        this.Key = null;
        this.Indexed = false;
        this.FieldId = null;
        this.Properties = null;
    }

}

export class MetadataTipologyDto extends MetadataTipologyEntity {
    Type?: string;
    PropertiesJson: PropertyDto;
    Name: string;
    Index: number;
    Permissions: Array<PermissionDto>;
    PermissionsToDelete: Array<PermissionDto>;
    MetadataTipologyValue: MetadataTipologyValueDto;
    constructor() {
        super();
        this.Type = null;
        this.PropertiesJson = null;
        this.Name = null;
        this.Index = -1;
        this.Permissions = new Array<PermissionDto>();
        this.PermissionsToDelete = new Array<PermissionDto>();
    }
}

export class MetadataTipologyFilter extends MetadataTipologyDto {
    AccountId: number;
}

