<div class="wizard-container">
</div>

<div class="wizard-step-container">
  <div class="text-icon action">
    <span [mat-dialog-close]>
      {{navigatorService.Dictionary?.WizardSkipAll}}
      <mat-icon class="icon">arrow_forward_ios</mat-icon>
    </span>
    <div *ngIf="Step>0" class="menu">
      <mat-list>
        <div *ngFor="let item of Menu">
          <div *ngIf="!item.Visible" style="height: 50px;"></div>
          <div *ngIf="item.Visible && item.Name === 'settings'" style="
          position: fixed;
          bottom: 20%;
          width: 240px;">
            <app-sidebar-menu [MenuItem]="item" [EnableExpandClick]="false">
            </app-sidebar-menu>
          </div>
          <div *ngIf="item.Visible && item.Name !== 'settings'">
            <app-sidebar-menu [MenuItem]="item" [EnableExpandClick]="false">
            </app-sidebar-menu>
          </div>
        </div>
      </mat-list>
    </div>
    <div [ngClass]="Step>0?'steps-m-240':'steps-m-0'" class="steps  tiny-scrollbar">
      <div *ngIf="Step<=0">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h1 class="title">{{navigatorService.Dictionary?.WizardWelcome}}</h1>
            <div class="description">
              {{navigatorService.Dictionary?.Use}}<img
                style="width: 11%;height: auto;margin-left: 5px; margin-right: 20px;vertical-align: bottom; max-height:172px"
                [src]="data?.BrandLogo">
              <span [outerHTML]="navigatorService.Dictionary?.WizardDescription1"></span>
              <br><br>
              <span [outerHTML]="this.data.IsAdmin?navigatorService.Dictionary?.WizardDescription2:navigatorService.Dictionary?.WizardUserDescription2"></span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
      <div *ngIf="Step>0 && Step<TotalSection" class="d-flex justify-content-center"
        [ngClass]="CurrentSubStep.CssClass">
        <img src="{{CurrentSubStep?.Path}}">
      </div>
      <div *ngIf="Step===TotalSection" [ngClass]="CurrentSubStep.CssClass">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="end-title">
              <h1 [outerHTML]="navigatorService.Dictionary?.WizardEndTitle">
              </h1>
            </div>
            <div class="description-end">
              <img src="../../../../assets/wizard/it/arrow.png">
              <br><br>
              <span [outerHTML]="navigatorService.Dictionary?.WizardEndDescription"></span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="Step>0" class="bottom-bar d-flex justify-content-between">
    <ng-container>
      <div class="navigation">
        <div class="d-flex justify-content-between" style="width: fit-content;padding-left: 6%;">
          <div *ngFor="let section of Sections.Steps;">
            <div (click)="GoToSection(section)" [ngClass]="section.Current?'oval-full':'oval-empty'" class="action">
            </div>
          </div>
        </div>
        <div class="text-icon pt-2 action">
          <span (click)="SkipSection()">
            {{navigatorService.Dictionary?.JumpSection}}
            <mat-icon class="icon">arrow_forward_ios</mat-icon>
          </span>
        </div>
      </div>
      <button class="button" *ngIf="Step!==TotalSection && Step>0"
        (click)="Next()">{{navigatorService.Dictionary?.Next}}</button>
      <button class="button" *ngIf="Step===TotalSection"
        [mat-dialog-close]>{{navigatorService.Dictionary?.Close}}</button>
    </ng-container>
  </div>

  <div *ngIf="Step<=0" class="bottom-bar">
    <ng-container>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 d-flex justify-content-center">
          <button class="button-start m-3" (click)="Start()">{{navigatorService.Dictionary?.LetsStart}}</button>
        </div>
        <div class="col-md-1"></div>
      </div>
    </ng-container>
  </div>

</div>
