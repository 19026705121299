import { Component, Input, OnInit } from '@angular/core';
import { OrganizationUnitDto, OrganizationUnitFilter, OrganizationUnitModel } from '../../models/OrganizationUnitModel';
import { OrganizationUnitComponent } from '../../pages/dialog-menu/organization-unit/organization-unit.component';
import { Constants, GenericDialogAction, PageAction, Pages } from '../../itdoc.configuration';
import { DataService } from '../../../data.service';
import { NavigatorService } from '../../../navigator.services';
import { GenericDialogModel } from '../../models/GenericDialogModel';
import { GenericConfirmDialogComponent } from '../../pages/dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { CustomFileModel } from '../../models/CustomFileModel';
import { OrganizationUnitController } from '../../controllers/OrganizationUnitController';
import { RoleController } from '../../controllers/RoleController';
import { RoleModel } from '../../models/RoleModel';
import { AccountDto, AccountFilter, AccountModel } from '../../models/AccountModel';
import { AccountController } from '../../controllers/AccountController';
import { AuthService } from '../../../auth.service';
import { CommonService } from '../../../common.service';
import { BaseOrder } from '../../../models/BaseModel';
@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input() Index = 0;
  @Input() DataSource: Array<OrganizationUnitDto>;
  @Input() Tab;
  @Input() IsTrashedView = false;
  Constants = Constants;
  Controller: OrganizationUnitController;
  AccountController: AccountController;
  RoleController: RoleController;
  constructor(
    public navigatorService: NavigatorService, public dataService: DataService,
    public authenticationService: AuthService, public commonService: CommonService) {
    this.Controller = new OrganizationUnitController(dataService);
    this.RoleController = new RoleController(dataService);
    this.AccountController = new AccountController(dataService);
  }

  ngOnInit(): void {
  }

  async RefreshLevelNode(parent, rowIndex) {
    let model = new OrganizationUnitModel();
    this.navigatorService.StartLoading();
    model.Filter = new OrganizationUnitFilter();
    model.Filter.Parent = parent;
    model.Filter.Trashed = this.IsTrashedView;
    if (this.Tab === 1) {
      model.Filter = new OrganizationUnitFilter();
      model.Filter.AccountId = this.authenticationService.ITDocAccount?.Dto?.Id;
    }
    if ((model?.Filter?.AccountId > 0 && this.Tab === 1) || this.Tab === 2) {
      model.Order = new BaseOrder();
      model.Order.Name = 'Id';
      model.Order.Direction = 'asc';
      model = await this.Controller.GetHierarchy(model);
      if (model.Performed) {
        this.DataSource = Object.assign([], model.Dtos);
        this.DataSource?.sort((a, b) => (a.OfficeCode.toLowerCase() > b.OfficeCode.toLowerCase()) ? 1 : -1);
        for (const child of this.DataSource) {
          child.ParentExpandRow = rowIndex;
        }
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
      }

    } else {
      model.Performed = true;
      model.Dtos = [];
      this.DataSource = [];
    }
    this.navigatorService.StopLoading();
  }

  GetIndex(dto: OrganizationUnitDto) {
    if (!dto.Parent) {
      return 1;
    } else {
      return this.GetDepth(dto, this.Index);
    }
  }

  GetDepth(dto: OrganizationUnitDto, index: number) {
    return index + 1;
  }

  Edit(dto: OrganizationUnitDto) {
    this.navigatorService.PageAction = PageAction.Edit;
    this.navigatorService.ShowDialog(OrganizationUnitComponent,
      { UoId: dto.Id, Action: 'EDIT' }, '45%', 'fit-content', '200px', (response) => {
        if (response?.Performed) {
          if (this.navigatorService.CurrentPage?.Link === Pages.ViewUO) {
            this.RefreshLevelNode(dto.Parent, dto.ParentExpandRow);
          } else {
            this.navigatorService.GoTo(Pages.ViewUO);
          }
        }
      });
  }

  AddUser(dto: OrganizationUnitDto) {
    this.navigatorService.PageAction = PageAction.Edit;
    this.navigatorService.ShowDialog(OrganizationUnitComponent,
      { UoId: dto.Id, Action: 'ADDUSER' }, '45%', 'fit-content', '200px', (response) => {
        if (response?.Performed) {
          if (this.navigatorService.CurrentPage?.Link === Pages.ViewUO) {
            this.RefreshLevelNode(dto.Parent, dto.ParentExpandRow);
          } else {
            this.navigatorService.GoTo(Pages.ViewUO);
          }
        }
      });
  }

  async Delete(dto: OrganizationUnitDto) {
    if (dto) {
      const data = new GenericDialogModel();
      data.Name = dto.Name;
      data.Icon = '../../../../../assets/icon/group-black.svg';
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
        this.navigatorService.Dictionary?.MoveToTrash.toLowerCase() + '<br>' + dto.Name + '?';
      data.Description = this.navigatorService.Dictionary?.AssociatedUsers + ': ' + dto.CountAccount + ', ' +
        this.navigatorService.Dictionary?.AssociatedFiles + ': ' + dto.CountFiles;
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
        if (performed === GenericDialogAction.CONFIRM) {
          this.navigatorService.StartLoading();
          dto.Trashed = true;
          let model = new OrganizationUnitModel();
          model.UpdateProperties = ['Trashed'];
          model.Dto = dto;
          model = await this.Controller.CreateOrUpdate(model);
          if (model?.Performed) {
            this.navigatorService.Loading = false;
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MoveToTrashSuccessMessage, data.Name);
            this.RefreshLevelNode(dto.Parent, dto.ParentExpandRow);
          } else {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
          }
        }
        this.navigatorService.StopLoading();
      });
    }
  }

  Restore(dto: OrganizationUnitDto) {
    const data = new GenericDialogModel();
    data.Name = dto.Name;
    data.Icon = '../../../../../assets/icon/group-black.svg';
    data.Title = this.navigatorService.Dictionary?.Restore;
    data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
      this.navigatorService.Dictionary?.ToRestore.toLowerCase() + '<br>' + dto.Name + '?';
    data.Description = this.navigatorService.Dictionary?.AssociatedUsers + ': ' + dto.CountAccount + ', ' +
      this.navigatorService.Dictionary?.AssociatedFiles + ': ' + dto.CountFiles;
    data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Restore;
    data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
    this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
      if (performed === GenericDialogAction.CONFIRM) {
        this.navigatorService.StartLoading();
        dto.Trashed = false;
        let model = new OrganizationUnitModel();
        model.UpdateProperties = ['Trashed'];
        model.Dto = dto;
        model = await this.Controller.CreateOrUpdate(model);
        if (model?.Performed) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RestoreSuccess, data.Name);
          this.navigatorService.StopLoading();
          this.RefreshLevelNode(dto.Parent, dto.ParentExpandRow);
        } else {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        }
      }
      this.navigatorService.StopLoading();
    });
  }

  async PermanentDelete(dto: OrganizationUnitDto) {

    const data = new GenericDialogModel();
    data.Name = dto.Name;
    data.Icon = '../../../../../assets/icon/group-black.svg';
    data.Title = this.navigatorService.Dictionary?.Delete;
    data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
      this.navigatorService.Dictionary?.ToDelete + '<br>' + dto.Name + '?';
    data.Description = this.navigatorService.Dictionary?.AssociatedUsers + ': ' + dto.CountAccount + ', ' +
      this.navigatorService.Dictionary?.AssociatedFiles + ': ' + dto.CountFiles;
    data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
    data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
    this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
      if (performed === GenericDialogAction.CONFIRM) {
        this.navigatorService.StartLoading();
        let model = new OrganizationUnitModel();
        model.Filter = new OrganizationUnitFilter();
        model.Filter.Id = dto.Id;
        model = await this.Controller.Delele(model);
        if (model?.Performed) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.DeleteSuccess, data.Name);
          this.RefreshLevelNode(dto.Parent, dto.ParentExpandRow);
        } else {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        }
      }
      this.navigatorService.StopLoading();
    });
  }

  Expand(dto: OrganizationUnitDto, indexRow: number) {
    if (dto?.Childs?.length > 0) {
      dto.Expand = !dto.Expand;
      for (const child of dto.Childs) {
        child.ParentExpandRow = indexRow + 1;
      }
    }
  }

  // #region Export
  ExportAction(dto: OrganizationUnitDto) {
    const dtos = new Array<OrganizationUnitDto>();
    dtos.push(dto);
    this.Export(dtos);
  }

  async Export(uoDtos) {
    this.navigatorService.StartLoading();
    const roleHeader = ['Id', 'Name', 'Permission'];
    const uoHeader = ['Id', 'Name', 'Description', 'OfficeCode', 'Parent', 'FolderName'];
    const accountHeader = ['Id', 'Username', 'DisplayName', 'UoId'];
    const accountRoleHeader = ['Id', 'UoId', 'AccountId', 'RoleId', 'Start', 'End'];
    await this.BindUsersUo(uoDtos);
    let modelExport = [
      { Name: 'UO', Data: { Header: uoHeader, Rows: uoHeader.toString().replace(/,/g, ';') + '\r\n' } },
      { Name: 'Account', Data: { Header: accountHeader, Rows: accountHeader.toString().replace(/,/g, ';') + '\r\n' } },
      { Name: 'AccountRole', Data: { Header: accountRoleHeader, Rows: accountRoleHeader.toString().replace(/,/g, ';') + '\r\n' } }
    ];
    modelExport = this.ExportUos(uoDtos, modelExport);
    let roleModel = new RoleModel();
    roleModel = await this.RoleController.Get(roleModel);
    const roleRows = this.commonService.ConvertToCSV(roleModel?.Dtos, roleHeader);
    const fileNameZip = 'Uo_export';
    const uoModel = modelExport.find(m => m.Name === 'UO');
    const accountModel = modelExport.find(m => m.Name === 'Account');
    const accountRoleModel = modelExport.find(m => m.Name === 'AccountRole');
    this.commonService.CreateZip([
      new CustomFileModel('File-1_OrganizationUnit.csv', uoModel.Data.Rows),
      new CustomFileModel('File-2_OrganizationUnitAccounts.csv', accountModel.Data.Rows),
      new CustomFileModel('File-3_AccountRole.csv', accountRoleModel.Data.Rows),
      new CustomFileModel('File-4_Role.csv', roleRows)],
      fileNameZip);
    this.navigatorService.StopLoading();
  }

  ExportUos(uoDtos: Array<OrganizationUnitDto>, model) {
    model = this.ExportUo(uoDtos, model);
    return model;
  }

  ExportUo(uoDtos: OrganizationUnitDto[], model: any) {
    const uoModel = model.find(m => m.Name === 'UO');
    const accountModel = model.find(m => m.Name === 'Account');
    const accountRoleModel = model.find(m => m.Name === 'AccountRole');
    for (const dto of uoDtos) {
      uoModel.Data.Rows += this.commonService.ConvertToCSV([dto], uoModel.Data.Header);
      accountModel.Data.Rows += this.commonService.ConvertToCSV(dto.Users, accountModel.Data.Header);
      const accountsRole = dto.Users.map(userRole => ({
        Id: userRole.AccountRoleId,
        UoId: userRole.UoId,
        AccountId: userRole.Id,
        RoleId: userRole.RoleId,
        Start: userRole.Start,
        End: userRole.End
      }));
      accountRoleModel.Data.Rows += this.commonService.ConvertToCSV(accountsRole, accountRoleModel.Data.Header);
      if (dto.Childs && dto.Childs.length > 0) {
        return this.ExportUo(dto.Childs, model);
      }
    }
    return model;
  }

  async BindUsersUo(dtos: OrganizationUnitDto[]) {
    for (const dto of dtos) {
      let accountModel = new AccountModel();
      accountModel.Filter = new AccountFilter();
      accountModel.Filter.UoId = dto.Id;
      accountModel = await this.AccountController.Get(accountModel);
      if (accountModel.Performed && accountModel.Dtos && accountModel.Dtos.length > 0) {
        if (!dto.Users) {
          dto.Users = new Array<AccountDto>();
        }
        dto.Users.push(...accountModel.Dtos);
      }
      if (dto.Childs && dto.Childs.length > 0) {
        await this.BindUsersUo(dto.Childs);
      }
    }
  }
  // #endregion

}
