import { BaseEntity, BaseModel } from './BaseModel';

export class LinkTokenModel extends BaseModel<LinkTokenEntity, LinkTokenDto, LinkTokenFilter> {

    constructor() {
        super();
    }
}

export class LinkTokenEntity extends BaseEntity {
    Username: string;
    Email: string;
    Type: string;
    Appid: string;
    Token: string;

    constructor() {
        super();
        this.Username = null;
        this.Email = null;
        this.Type = null;
    }
}

export class LinkTokenDto extends LinkTokenEntity {
    Password: string;
    RelativeUrl: string;
    Role: string;
    constructor() {
        super();
    }
}

export class LinkTokenFilter extends LinkTokenDto {
}
