import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { FileDto } from './itdoc/models/FileModel';

@Injectable()
export class UploadService {

    private ChunkSize = 2 * 1024 * 1024;
    private CurrentChunk: number = null;
    private TotalChunks: number = null;
    public Percentage = 0;
    public Error = null;
    public Cancel = false;
    constructor(private dataservice: DataService) { }

    async Upload(file: File, name: string, path: string, dto: FileDto = null): Promise<any> {
        // if (file.size > this.ChunkSize) {
        const chunks = this.ChunkFile(file, this.ChunkSize);
        this.TotalChunks = chunks.length;
        let response: any = false;
        for (let index = this.CurrentChunk = 0; index < chunks.length; index++) {
            if (this.Cancel) {
                return response;
            }
            this.CurrentChunk = index;
            const chunk = chunks[index];
            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('fileName', name);
            formData.append('chunks', String(chunks.length));
            formData.append('chunk', String(index));
            formData.append('path', String(path));

            response = await this.dataservice.AsyncPostUpload(formData, {
                observe: 'response'
            }).catch((err: HttpErrorResponse) => {
                return err;
            }).then(result => {
                return result?.body ?? result?.error;
            });
            this.Percentage = response?.Percentage;
            if (dto) {
                dto.Progress = this.Percentage;
                dto.Message = response?.Error;
            }
            this.Error = response?.Error;
            if (response && !response.Performed) {
                this.TotalChunks = this.CurrentChunk = null;
                break;
            }
        }
        response['Cancell'] = this.Cancel;
        return response;
        // this.TotalChunks = this.CurrentChunk = null;
        // if (response?.executed === false) {
        //     return {
        //         uploaded: false,
        //         fileName: file.name,
        //         errorMessage: response?.errorMessage ?? 'Unknown error'
        //     };
        // }
        // return {
        //     uploaded: response?.executed ?? false,
        //     fileName: file.name
        // };
        // } else {
        //     // Direct upload
        //     const formData = new FormData();
        //     formData.append('file', file);
        //     formData.append('path', String(path));
        //     let response = false;
        // await this.commonService
        // 	.__postAsync('files/upload', formData, {
        // 		observe: 'response'
        // 	}).catch((err: HttpErrorResponse) => {
        // 		return err;
        // 	}).then(result => {
        // 		response = result?.body ?? result?.error;
        // 	});

        //     return response;
        // }
    }

    private ChunkFile(file: File, chunkSize: number) {
        const arrayLength = file.size;
        const tempArray = [];
        let tot = 0;
        for (let index = 0; index < arrayLength; index += chunkSize) {
            const chunk = file.slice(index, index + chunkSize);
            tot += chunk.size;
            tempArray.push(chunk);
        }
        return tempArray;
    }
}
