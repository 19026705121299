import { X } from '@angular/cdk/keycodes';
import { FieldsModel } from '../models/FieldsModel';
import { DataService } from '../../data.service';

export class FieldsController {
    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: FieldsModel): Promise<FieldsModel> {
        let response = model;
        if (model && model.Dto) {
            response = await this.dataService.CreateOrUpdateFields(model);
            if (response && response.Performed) {
                response.Dto.Id = response.Entity?.Id;
            }
        }
        return response;
    }

    async Get(model: FieldsModel): Promise<FieldsModel> {
        const response = await this.dataService.GetFields(model);
        if (response) {
            if (response.Performed) {
                response.Dtos.map(x => x.Default = this.GetBoolean(x.Default));
            }
        }
        return response;
    }

    private GetBoolean(value) {
        switch (value) {
            case true:
            case 'true':
            case 1:
            case '1':
                return true;
            default:
                return false;
        }
    }
}
