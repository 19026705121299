import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CurrentUserDto } from './models/CurrentUserModel';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountDto, AccountFilter, AccountModel } from './itdoc/models/AccountModel';
import { LinkTokenDto, LinkTokenModel } from './models/LinkToken';
import { Action, Constants, Controller, Pages } from './itdoc/itdoc.configuration';
import { NavigatorService } from './navigator.services';
import { Dictionary } from './dictionary/dictionary';
import { WhiteLabelService } from './white-label.service';
import { AppConfigService } from './app.config.service';
import moment from 'moment';

@Injectable()
export class AuthService {
  ITDocAccount: AccountModel;
  public TokenRefresh = false;
  private currentUser: CurrentUserDto;
  set CurrentUser(value: CurrentUserDto) {
    sessionStorage.setItem('_itul_', btoa(JSON.stringify(value)));
    this.currentUser = value;
  }

  get CurrentUser(): CurrentUserDto {
    if (sessionStorage.getItem('_itul_')) {
      if (this.IsJsonString(atob(sessionStorage.getItem('_itul_')))) {
        this.currentUser = JSON.parse(atob(sessionStorage.getItem('_itul_')));
      } else {
        this.currentUser = null;
        localStorage.clear();
        sessionStorage.clear();
      }
    }
    return this.currentUser;
  }

  private docflyUser: any;
  set DocflyUser(value) {
    sessionStorage.setItem('_dfitul_', btoa(JSON.stringify(value)));
    this.docflyUser = value;
  }

  get DocflyUser(): any {
    if (sessionStorage.getItem('_dfitul_')) {
      if (this.IsJsonString(atob(sessionStorage.getItem('_dfitul_')))) {
        this.docflyUser = JSON.parse(atob(sessionStorage.getItem('_dfitul_')));
      } else {
        this.docflyUser = null;
        localStorage.clear();
        sessionStorage.clear();
      }
    }
    return this.docflyUser;
  }

  constructor(
    private http: HttpClient, public jwtHelper: JwtHelperService,
    public navigatorService: NavigatorService, public whiteLabelService: WhiteLabelService,
    private configAppService: AppConfigService) {
  }

  public async AsyncPost<TModel>(controller: string, action: string, request: TModel, app: string = null) {
    const response = await this.http.post<TModel>(`${this.configAppService.apiUrl}` + '/' + (app ? app + '/' : '') + controller
      + (action ? '/' + action : ''), request, { observe: 'response' }).toPromise();
    if (response) {
      sessionStorage.setItem('token', response?.headers?.get('Authorization'));
      localStorage.setItem('token', response?.headers?.get('Authorization'));
    }
    return response?.body;
  }

  public async AsyncGet(controller: string) {
    const response = await this.http.get<any>(`${this.configAppService.apiUrl}` + '/' + controller, { observe: 'response' }).toPromise();
    return response?.body;
  }

  public async Login(username: string, password: string): Promise<any> {
    const request = { username, password };
    const response = await this.AsyncPost('login', '', request, 'itdoc');
    return (response ? response : null);
  }


  public async Logout(): Promise<any> {
    const response = await this.AsyncGet('logout');
    this.currentUser = null;
    return (response ? response : null);
  }

  public async SignOutDocFly(): Promise<any> {
    if (this.DocflyUser) {
      const request = { Ticket: this.DocflyUser.Ticket };
      const response = await this.AsyncPost('conservation', 'logout', request, 'itdoc');
      this.docflyUser = null;
      return (response ? response : null);
    }
    return true;
  }

  async RefreshToken() {
    try {
      if (this.TokenRefresh) {
        await this.navigatorService.Delay(500);
        await this.RefreshToken();
      } else {
        const token = this.GetToken();
        if (token && this.IsAuthenticated()) {
          const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
          if (expirationDate) {
            const currentDate = new Date();
            if (currentDate.getFullYear() === expirationDate.getFullYear() && currentDate.getMonth() === expirationDate.getMonth()
              && currentDate.getDate() === expirationDate.getDate()) {
              const diff = moment(expirationDate).diff(currentDate);
              const diffMinutes = Math.ceil((diff / 1000) / 60);
              if (diffMinutes < 30) {
                this.TokenRefresh = true;
                await this.AsyncGet('refresh');
                this.TokenRefresh = false;
              }
            }
          }
        }
      }
    } catch (error) {
      this.TokenRefresh = false;
    }
  }

  public GetToken() {
    let token = localStorage.getItem('token');
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    return token;
  }

  public IsAuthenticated() {
    try {
      const token = this.GetToken();
      return !this.jwtHelper.isTokenExpired(token);
    } catch (error) {

    }
    return false;
  }
  public IsAuthenticatedDocFly() {
    try {
      return this.DocflyUser ? true : false;
    } catch (error) {

    }
    return false;
  }
  async Initialize() {
    this.ITDocAccount = new AccountModel();
    this.ITDocAccount.Dto = new AccountDto();
    await this.ReadITDocAccount();
    this.ITDocAccount.Dto.IsAdmin = await this.IsAdmin();
    this.currentUser.IsAdmin = this.ITDocAccount.Dto.IsAdmin;
    this.CurrentUser = this.currentUser;
    this.navigatorService.Dictionary = new Dictionary(this.CurrentUser.Language, this.whiteLabelService.AppName,
      this.CurrentUser.Displayname).Dictionary;
  }

  async ReadITDocAccount() {
    this.ITDocAccount.Filter = new AccountFilter();
    this.ITDocAccount.Filter.Username = this.CurrentUser?.Username;
    this.ITDocAccount = await this.GetAccount(this.ITDocAccount);
    if (this.ITDocAccount?.Performed && this.ITDocAccount?.Dtos?.length > 0) {
      this.ITDocAccount.Dto = this.ITDocAccount.Dtos[0];
      this.ITDocAccount.Dtos = [];
    }
    return this.ITDocAccount.Performed;
  }

  async SendLinkResetPassword(mail) {
    let linkTokenModel = new LinkTokenModel();
    linkTokenModel.Dto = new LinkTokenDto();
    linkTokenModel.Dto.Username = mail;
    linkTokenModel.Dto.Email = mail;
    linkTokenModel.Dto.RelativeUrl = window.location.origin + '/' + Constants.AppId + '/' + Pages.ResetPassword;
    linkTokenModel = await this.AsyncPost(Controller.ResetPassword, Action.Send, linkTokenModel, Constants.AppId);
    return linkTokenModel;
  }

  private IsJsonString(json: string) {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  }

  public async IsAdmin(): Promise<boolean> {
    const response = await this.AsyncGet('user/isadmin');
    if (response) {
      return response['IsAdmin'];
    }
    return false;
  }

  public async GetAccount(requestModel: AccountModel): Promise<AccountModel> {
    const response = await this.AsyncPost('account', 'get', requestModel, this.configAppService.appId);
    if (response) {
      requestModel.Performed = response?.Performed;
      requestModel.Dtos = response?.Dtos;
      requestModel.Count = response?.Count;
      requestModel.Message = response?.Message;
      requestModel.Code = response?.Code;
    }
    return requestModel;
  }
}
