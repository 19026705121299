import { Component, Input, OnInit } from '@angular/core';
import { CardDataModel } from '../../models/CardDataModel';

@Component({
  selector: 'app-progress-bar-chart',
  templateUrl: './progress-bar-chart.component.html',
  styleUrls: ['./progress-bar-chart.component.scss']
})
export class ProgressBarChartComponent implements OnInit {
  FirstIndex = null;
  LastIndex = null;
  datasetModel: CardDataModel[] = null;
  @Input() set DatasetModel(values: CardDataModel[]) {
    if (values &&  values?.length > 0) {
      this.datasetModel = values;
      this.FirstIndex = this.GetFirstIndexWithValue();
      this.LastIndex = this.GetLastIndexWithValue();
    }
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  CalcLeftValueBar(index: number) {
    let leftValue = 0;
    if (this.datasetModel) {      
      for (let i = index; i > 0; i--) {
        leftValue += this.datasetModel[i - 1].Percentage;
      }      
    }
    return leftValue;
  }

  GetFirstIndexWithValue() {
    const elementsWithValue = this.datasetModel.filter(q => q.Percentage > 0);
    if (elementsWithValue && elementsWithValue.length > 0) {
      const lastElementWithValue = elementsWithValue[0];
      const firstIndex = this.datasetModel.indexOf(lastElementWithValue);
      return firstIndex;
    }
    return 0;
  }

  GetLastIndexWithValue() {
    const elementsWithValue = this.datasetModel.filter(q => q.Percentage > 0);
    if (elementsWithValue && elementsWithValue.length > 0) {
      const lastElementWithValue = elementsWithValue[elementsWithValue.length - 1];
      const lastIndex = this.datasetModel.indexOf(lastElementWithValue);
      return lastIndex;
    }
    return 0;
  }
}
