<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container">
          <div class="grid-container">
            <div class="grid-column">
              <span class="grid-column-title">
                {{navigatorService.Dictionary?.ArchiveList}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form mt-3">
    <div class="row margin-bottom">
      <div class="it-doc-table-container">
        <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">
          <ng-container matColumnDef="{{navigatorService.Dictionary?.BusinessName}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.BusinessName}} </th>
            <td mat-cell *matCellDef="let element">
              <span (click)="ArchiveClick(element)">
                <img src="../../../../../assets/icon/work.svg">
                {{element?.ViewRepository?.NodeProperties?.RagioneSociale}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.VatNumber}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.VatNumber}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element?.ViewRepository?.NodeProperties?.PartitaIva}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.FiscalCode}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.FiscalCode}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element?.ViewRepository?.NodeProperties?.CodiceFiscale}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.CreationDate}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.CreationDate}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element?.ViewRepository?.NodeProperties?.Date}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnSize}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.TableColumnSize}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element?.ViewRepository?.NodeProperties?.HumanSize}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnState}}">
            <th mat-header-cell *matHeaderCellDef>
              {{navigatorService.Dictionary?.TableColumnState}} </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{element?.ViewRepository?.NodeProperties?.State}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="more-action">
            <th mat-header-cell *matHeaderCellDef class="icon-column">

            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button class="w-auto" #m="matMenuTrigger" [matMenuTriggerFor]="moreAction">
                <img src="../../../../../assets/icon/more-vert.svg" [ngClass]="{'open-menu-trigger-color': m.menuOpen}">
              </button>
              <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
                <button mat-menu-item (click)="CreatePda(element)">{{navigatorService.Dictionary?.NewPda}}</button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="[
                    navigatorService.Dictionary?.BusinessName,
                    navigatorService.Dictionary?.VatNumber,
                    navigatorService.Dictionary?.FiscalCode,
                    navigatorService.Dictionary?.CreationDate,
                    navigatorService.Dictionary?.TableColumnSize,
                    navigatorService.Dictionary?.TableColumnState,
                    'more-action']">
          </tr>
          <tr mat-row *matRowDef="let row;  columns: [
        navigatorService.Dictionary?.BusinessName,
        navigatorService.Dictionary?.VatNumber,
        navigatorService.Dictionary?.FiscalCode,
        navigatorService.Dictionary?.CreationDate,
        navigatorService.Dictionary?.TableColumnSize,
        navigatorService.Dictionary?.TableColumnState,
        'more-action']">
          </tr>

        </table>
      </div>
      <div *ngIf="!navigatorService.Loading && !Model?.Dto" class="m-0 empty-table-box" style="padding-bottom: 200px;">
        <img src="../../../../../assets/icon/work.svg">
        <div class="description">{{navigatorService.Dictionary?.EmptyArchiveMessage}}</div>
      </div>

    </div>

  </div>
