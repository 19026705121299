import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { DialogMenuComponent } from '../dialog-menu.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProgressBarPopupComponent } from '../progress-bar-popup/progress-bar-popup.component';
import { IDialogComponent } from '../../../Interface/itdoc.dialog.interface';
import { ObjectDto, ObjectFilter, ObjectModel } from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import { TipologyDto, TipologyFilter, TipologyModel } from '../../../models/TipologyModel';
import { OrganizationUnitDto, OrganizationUnitFilter, OrganizationUnitModel } from '../../../models/OrganizationUnitModel';
import { TipologyController } from '../../../controllers/TipologyController';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import { ItCoreController } from '../../../../controllers/ItCoreController';
import { AutocompleteFilterUiComponent } from '../../../custom-components/autocomplete-ui/autocomplete-ui.component';
import { MetadataTipologyDto, MetadataTipologyFilter, MetadataTipologyModel } from '../../../models/MetadataTipologyModel';
import { MetaDataTipologyController } from '../../../controllers/MetaDataTipologyController';
import { MetadataTipologyValueValueController } from '../../../controllers/MetaDataTipologyValueController';
import { FileDto, FileModel } from '../../../models/FileModel';
import { FileManagerController } from '../../../controllers/FileManagerController';
import { MimeTypeModel } from '../../../models/MimeTypeModel';
import { MimeTypeController } from '../../../controllers/MimeTypeController';
import { TemplateController } from '../../../controllers/TemplateController';
import { TemplateFilter, TemplateModel } from '../../../models/TemplateModel';
import { Constants, DateFormat, DocumentState, PageAction, Pages, WorkflowState } from '../../../itdoc.configuration';
import { WorkflowDataController } from '../../../../workflow/controllers/WorkflowDataController';
import { WorkflowController } from '../../../../workflow/controllers/WorkflowController';
import { WorkflowFilter, WorkflowModel } from '../../../../workflow/models/WorkflowModel';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { UploadService } from '../../../../upload.service';
import { WorkflowDataFilter, WorkflowDataModel } from '../../../../workflow/models/WorkflowDataModel';
import { DocumentDto, DocumentModel, Token } from '../../../models/DocumentModel';
import { MetadataTipologyValueDto, MetadataTipologyValueFilter, MetadataTipologyValueModel } from '../../../models/MetadataTipologyValueModel';
import { BaseOrder } from '../../../../models/BaseModel';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, AfterViewInit, IDialogComponent<ObjectModel, ObjectController> {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;
  Model: ObjectModel;
  ObjectModel: ObjectModel;
  TipologyModel: TipologyModel;
  OrganizationUnitModel: OrganizationUnitModel;
  TipologyController: TipologyController;
  Controller: ObjectController;
  OrganizationUnitController: OrganizationUnitController;
  ItCoreController: ItCoreController;
  ReadOnly = true;
  DocumentMenuItems: Array<any>;
  MenuItemSelected: any;
  Step = 1;
  Steps = 4;
  DataSource: MatTableDataSource<ObjectDto>;
  @ViewChild('autocompleteUser') AutoCompleteUser: AutocompleteFilterUiComponent;
  @ViewChild(MatSort) sort: MatSort;
  Pages: Array<number>;
  CurrentPage = 1;
  SliderCurrentPage = 1;
  CurrentFolder: ObjectDto;
  MetaDataTipologyModel: MetadataTipologyModel;
  MetadataTipologyController: MetaDataTipologyController;
  MetadataTipologyValueController: MetadataTipologyValueValueController;
  FolderModel: ObjectModel;
  FolderNewDto: ObjectDto;
  ShowNewFolder: boolean;
  FileModel: FileModel;
  FileManagerController: FileManagerController;
  MimeTypeModel: MimeTypeModel;
  MimeTypeController: MimeTypeController;
  @ViewChild('uploadFile') fileUpload: ElementRef;
  @ViewChild('multiUploadFile') multiFileUpload: ElementRef;
  TemplateController: TemplateController;
  TemplateModel: TemplateModel;
  ExistTemplate = false;
  ND = Constants.ND.toString();
  Tipologies: Array<TipologyDto>;
  WorkflowDataController: WorkflowDataController;
  WorkflowController: WorkflowController;
  WorkflowModel: WorkflowModel;
  ShowProgressBar = true;
  constructor(
    private dataService: DataService, public navigatorService: NavigatorService, public uploadService: UploadService,
    private authenticationService: AuthService, public commonService: CommonService, public datepipe: DatePipe,
    public dialogRef: MatDialogRef<DocumentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (navigatorService.PageAction === PageAction.New) {
      this.DocumentMenuItems = data;
    }
    this.Model = new ObjectModel();
    this.Model.Dto = new ObjectDto();
    this.ObjectModel = new ObjectModel();
    this.TipologyModel = new TipologyModel();
    this.TipologyModel.Dto = new TipologyDto();
    this.TipologyModel.Filter = new TipologyFilter();
    if (this.navigatorService.PageAction === PageAction.New) {
      this.TipologyModel.Filter.Archived = false;
      this.Model.Dto.Authorization.Authorized = true;
      this.Model.Action = 'CREATE';
    } else if (this.Model.Dto.State === DocumentState.PROGRESS) {
      this.Model.Dto.Authorization.Authorized = true;
    }
    this.OrganizationUnitModel = new OrganizationUnitModel();
    this.OrganizationUnitModel.Dto = new OrganizationUnitDto();
    this.OrganizationUnitModel.Filter = new OrganizationUnitFilter();
    this.MetaDataTipologyModel = new MetadataTipologyModel();
    this.MetaDataTipologyModel.Filter = new MetadataTipologyFilter();
    this.MetaDataTipologyModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    this.FolderModel = new ObjectModel();
    this.MimeTypeModel = new MimeTypeModel();
    this.WorkflowModel = new WorkflowModel();
    this.WorkflowModel.Filter = new WorkflowFilter();
    this.WorkflowModel.Filter.State = WorkflowState.PUBLISHED;
    this.Controller = new ObjectController(dataService);
    this.TipologyController = new TipologyController(dataService);
    this.OrganizationUnitController = new OrganizationUnitController(dataService);
    this.ItCoreController = new ItCoreController(dataService);
    this.MetadataTipologyController = new MetaDataTipologyController(dataService);
    this.MetadataTipologyValueController = new MetadataTipologyValueValueController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
    this.MimeTypeController = new MimeTypeController(dataService);
    this.TemplateController = new TemplateController(dataService);
    this.WorkflowController = new WorkflowController(dataService);
    this.WorkflowDataController = new WorkflowDataController(dataService);

    this.Pages = new Array<number>();
    this.Pages.push(1);
    this.Tipologies = new Array<TipologyDto>();
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    // await this.navigatorService.Wait();
    this.TipologyModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    this.TipologyModel = await this.TipologyController.Get(this.TipologyModel);
    this.WorkflowModel = await this.WorkflowController.Get(this.WorkflowModel);
    if (this.navigatorService.PageAction === PageAction.Edit) {
      this.Step = 2;
      this.Model.Filter = new ObjectFilter();
      this.Model.Filter.Id = this.data;
      this.Model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      this.Model = await this.Controller.Read(this.Model);
      if (!this.Model.Performed) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        this.dialogRef.close(true);
      } else {
        this.OrganizationUnitModel.Filter.TipologyId = this.Model.Dto.TipologyId;
        this.OrganizationUnitModel = await this.OrganizationUnitController.Get(this.OrganizationUnitModel);
      }

    }
    this.navigatorService.StopLoading();
  }

  async SelectTipology($event) {
    if ($event && $event > 0 && (this.Model.Dto.TipologyId !== $event)) {
      this.navigatorService.StartLoading();
      this.Model.Dto.TipologyId = $event;
      this.ExistTemplate = await this.LoadTemplate($event);
      this.MimeTypeModel = await this.MimeTypeController.Get(this.MimeTypeModel);
      this.OrganizationUnitModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      this.OrganizationUnitModel.Filter.TipologyId = $event;
      this.OrganizationUnitModel = await this.OrganizationUnitController.Get(this.OrganizationUnitModel);
      this.navigatorService.StopLoading();
    }
  }

  SelectUo($event) {
    if ($event && $event > 0) {
      this.Model.Dto.UoId = $event;
    }
  }

  SelectWorkflow($event) {
    if ($event && $event > 0) {
      this.Model.Dto.WorkflowId = $event;
    }
  }

  async LoadTemplate(tipologyId: number) {
    this.TemplateModel = new TemplateModel();
    this.TemplateModel.Filter = new TemplateFilter();
    this.TemplateModel.Filter.TipologyId = tipologyId;
    this.TemplateModel = await this.TemplateController.GetFirst(this.TemplateModel);
    if (this.TemplateModel.Performed && this.TemplateModel.Dto && this.TemplateModel.Dto.Id > 0) {
      return true;
    }
    return false;
  }

  ComboItemSelected($event, dto: MetadataTipologyDto) {
    if ($event) {
      if (!dto.PropertiesJson.Multiple) {
        dto.MetadataTipologyValue.Value = JSON.stringify(dto.PropertiesJson.Items.find(x => x.Id === $event));
      } else {
        dto.MetadataTipologyValue.Value = JSON.stringify(dto.PropertiesJson.Items.filter(x => x.Checked === true));
      }
    }

  }

  async Save() {
    this.navigatorService.StartLoading();
    const isValid = this.ValidateForm(this.Step);
    if (isValid) {
      let performed = false;
      const selectedFolder = this.ObjectModel.Dtos.find(o => o.Selected && o.Type === 'folder');
      const folder = selectedFolder ? selectedFolder : this.CurrentFolder;
      if (this.Model.Dto.PhysicalName === null || this.navigatorService.PageAction === PageAction.New) {
        this.Model.Dto.PhysicalName = this.Model.Dto.Name.replace(/[^a-z0-9]/gi, '_') + '.' + this.Model.Dto.Format;
        this.Model.Dto.Path = folder.Path;
        if (this.FileModel && this.FileModel.Dto && this.FileModel.Dto.File) {
          this.FileModel.Dto.Path = this.Model.Dto.Path;
        }
        this.Model.Dto.Parent = (folder.Id && folder.Id === 0 ? null : folder.Id);
        this.Model.Dto.Deep = (this.Model.Dto.Parent ? folder.Deep += 1 : 0);
        this.Model.Dto.Version = 1;
        this.Model.Dto.State = DocumentState.PROGRESS.toString();
        if (this.FileModel?.Dto) {
          this.FileModel.Dto.Name = this.Model.Dto.Name;
          this.FileModel.Dto.PhysicalName = this.Model.Dto.PhysicalName;

        }
      }
      this.Model.Dto.TipologyUoId = this.TipologyModel.Dtos?.find(t => t.Id === this.Model.Dto.TipologyId)?.TipologyUos
        ?.find(x => x.UoId === this.Model.Dto.UoId && x.TipologyId === this.Model.Dto.TipologyId)?.Id;
      this.Model.Dto.Type = 'file';
      this.Model.Dto.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      this.Model.Dto.Input = this.navigatorService?.CurrentPage?.Link === Pages.ViewProtocols ? true : false;
      this.Model = await this.Controller.CreateOrUpdate(this.Model);
      if (this.Model.Performed) {
        if (this.Model.Code && this.Model.Code === 409) {
          this.Model.Code = null;
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.FileExist, this.Model.Dto.Name);
          this.navigatorService.StopLoading();
        } else {
          performed = await this.SaveMetadataValue();
          if (performed) {
            performed = await this.CreateFile();
            if (performed) {
              if (this.navigatorService.PageAction === PageAction.Edit) {
                if (this.Model.Dto.WorkflowData.WorkflowId !== this.Model.Dto.WorkflowId) {
                  const oldWorkflowDataId = this.Model.Dto.WorkflowData.Id;
                  let model = new WorkflowDataModel();
                  model.Filter = new WorkflowDataFilter();
                  model.Filter.Id = oldWorkflowDataId;
                  model = await this.WorkflowDataController.Delete(model);
                }
              }
              await this.WorkflowDataController.Run(this.Model.Entity.Id, this.Model.Dto.WorkflowId, 0);
            }
          }
          this.UploadFile((result) => {
            if (result) {
              const message = this.navigatorService.PageAction === PageAction.New ? this.navigatorService.Dictionary?.ToCreateSuccess
                : this.navigatorService.Dictionary?.ToEditSuccess;
              this.navigatorService.ShowSnackBar(message, this.Model?.Dto?.Name
                + '.' + this.commonService.GetExtension(this.Model?.Dto?.PhysicalName));
              this.dialogRef.close(this.Model.Dto);
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.MessageGenericError, this.Model?.Dto?.Name
                + '.' + this.commonService.GetExtension(this.Model?.Dto?.PhysicalName));
              this.dialogRef.close();
            }
            this.navigatorService.StopLoading();
          });
        }

      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        this.navigatorService.StopLoading();
      }

    } else {
      this.navigatorService.StopLoading();
    }
  }

  async CreateFile(): Promise<boolean> {
    if (this.Model.Dto.Template && this.Model.Dto.Format === 'docx') {
      let documentModel = new DocumentModel();
      documentModel.Dto = new DocumentDto();
      documentModel.Dto.Id = this.Model.Dto.Id;
      documentModel.Dto.TemplateName = this.TemplateModel.Dto.PhysicalName;
      documentModel.Dto.FileName = this.Model.Dto.PhysicalName;
      documentModel.Dto.FilePath = this.Model.Dto.Path;
      for (const metadata of this.MetaDataTipologyModel.Dtos) {
        let value = metadata.MetadataTipologyValue.Value;
        if (metadata.Type === 'combo') {
          const jsonModel = JSON.parse(metadata.MetadataTipologyValue.Value);
          if (Array.isArray(jsonModel)) {
            const items = jsonModel.filter(x => x.Checked === true);
            if (items?.length > 0) {
              value = items.map(x => x.Value).toString();
            } else {
              value = null;
            }
          } else {
            value = JSON.parse(metadata.MetadataTipologyValue.Value).Value;
          }
        }
        const token = new Token();
        token.Key = metadata.PropertiesJson.Token;
        token.Value = value;
        documentModel.Dto.Tokens.push(token);
      }
      documentModel = await this.TemplateController.CreateFromTemplate(documentModel);
      if (documentModel?.Performed) {
        this.Model.Dto.MimeType = documentModel?.Dto?.MimeType;
      }
      return documentModel.Performed;
    } else if (this.Model.Dto.Format !== 'pdf') {
      if (!this.FileModel?.Dto?.MimeType || (!this.FileModel?.Dto?.MimeType?.includes('image')
        && !this.FileModel?.Dto?.MimeType?.includes('video')) && !this.FileModel?.Dto.File) {
        let fileModel = new FileModel();
        fileModel.Dto = new FileDto();
        fileModel.Dto.Path = this.Model.Dto.Path;
        fileModel.Dto.PhysicalName = this.Model.Dto.PhysicalName;
        fileModel = await this.dataService.CreateOnlyOfficeFile(fileModel);
        if (fileModel?.Performed) {
          this.Model.Dto.MimeType = fileModel?.Dto?.MimeType;
        }
        return fileModel?.Performed;
      }
    }
    return true;
  }

  async SaveMetadataValue(): Promise<boolean> {
    const metadatavalue = this.MetaDataTipologyModel.Dtos.map(x => x.MetadataTipologyValue);
    for (const dto of metadatavalue) {
      dto.ObjectId = this.Model.Dto.Id;
      const model = new MetadataTipologyValueModel();
      model.Dto = dto;
      const response = await this.MetadataTipologyValueController.CreateOrUpdate(model);
      if (!response.Performed) {
        return false;
      }
    }
    return true;
  }

  async Next() {
    this.FolderNewDto = null;
    if (this.Step === 1 && this.Model.Dto.Format) {
      const isValid = this.ValidateForm(this.Step);
      if (isValid) {
        this.Step += 1;
      }
    } else if (this.Step === 2) {
      const isValid = this.ValidateForm(this.Step);
      if (isValid && this.navigatorService.PageAction === PageAction.New) {
        this.navigatorService.StartLoading();
        this.FolderModel.Filter = new ObjectFilter();
        this.FolderModel.Filter.TipologyUoId = this.TipologyModel.Dtos?.find(t => t.Id === this.Model.Dto.TipologyId)?.TipologyUos
          ?.find(x => x.UoId === this.Model.Dto.UoId && x.TipologyId === this.Model.Dto.TipologyId)?.Id;
        this.FolderModel.Filter.Deep = 0;
        this.FolderModel.Filter.Type = 'folder';
        this.FolderModel = await this.Controller.GetFirst(this.FolderModel);
        this.Step += 1;
        await this.GetObjects(this.FolderModel.Dto);
        this.navigatorService.StopLoading();
      } else {
        this.MetaDataTipologyModel.Filter.TipologyId = this.Model.Dto.TipologyId;
        await this.GetMetadataTipology();
        this.Step += 2;
      }
    } else if (this.Step === 3) {
      const isValid = this.ValidateForm(this.Step);
      if (isValid) {
        this.MetaDataTipologyModel.Filter.TipologyId = this.Model.Dto.TipologyId;
        await this.GetMetadataTipology();
        this.Step += 1;
      }
    } else if (this.Step === 4) {
      const isValid = this.ValidateForm(this.Step);
      if (isValid) {
        this.Step += 1;
        if (this.Steps === 5) {
          this.SelectSliderImage(this.FileModel.Dtos[0]);
        }
      }
    }
  }

  Back() {
    this.FolderNewDto = null;
    if (this.navigatorService.PageAction === PageAction.Edit) {
      if (this.Step === this.Steps) {
        this.Step -= 2;
      } else {
        this.Step -= 1;
      }
    } else {
      this.Step -= 1;
      if (this.Step === 1) {
        this.multiFileUpload.nativeElement.value = null;
      }

      if (this.Step <= 0) {
        this.Step = 0;
        this.dialogRef.close(null);
        this.navigatorService.ShowDialog(DialogMenuComponent, null, '45%', 'fit-content');
      }
    }
  }

  async GetMetadataTipology() {
    this.navigatorService.StartLoading();
    this.MetaDataTipologyModel = await this.MetadataTipologyController.Get(this.MetaDataTipologyModel);
    if (this.MetaDataTipologyModel.Performed) {
      this.MetaDataTipologyModel.Dtos.map(x => x.PropertiesJson = (x.Properties ? JSON.parse(x.Properties) : null));
      if (this.Model.Dto.Id > 0) {
        let model = new MetadataTipologyValueModel();
        model.Filter = new MetadataTipologyValueFilter();
        model.Filter.ObjectId = this.Model.Dto.Id;
        model = await this.MetadataTipologyValueController.Get(model);
        for (const dto of this.MetaDataTipologyModel.Dtos) {
          const value = model.Dtos.find(v => v.MetadataTipologyId === dto.Id);
          if (value) {
            dto.MetadataTipologyValue = value;
          } else {
            dto.MetadataTipologyValue = new MetadataTipologyValueDto();
            dto.MetadataTipologyValue.MetadataTipologyId = dto.Id;
          }
        }
      } else {
        for (const dto of this.MetaDataTipologyModel.Dtos) {
          dto.MetadataTipologyValue = new MetadataTipologyValueDto();
          dto.MetadataTipologyValue.MetadataTipologyId = dto.Id;
        }
      }
    }
    this.navigatorService.StopLoading();
  }

  ValidateForm(step: number): boolean {
    if (step === 1) {
      if (this.Model.Dto.Name === null || this.Model.Dto.TipologyId < 0) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ValidatorMessage);
        return false;
      } else if (!this.IsFileAllowed()) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MimeTypesAllowed);
        return false;
      }
    } else if (step === 2) {
      if (!this.Model.Dto.UoId || !this.Model.Dto.WorkflowId) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ValidatorMessage);
        return false;
      }
    }
    else if (step === 3) {
      if (!this.CurrentFolder && !this.ObjectModel.Dtos.find(o => o.Selected && o.Type === 'folder')) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ValidatorSelectedFolder);
        return false;
      }
    } else if (step === 4) {
      for (const dto of this.MetaDataTipologyModel.Dtos) {
        if (dto.PropertiesJson.Required && !dto.MetadataTipologyValue.Value) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ValidatorMessage);
          return false;
        }
      }
    }
    return true;
  }

  EnableDisableButtonNext(step: number): boolean {
    if (step === 1) {
      if (this.Model.Dto.Name === null || this.Model.Dto.TipologyId < 0) {
        return false;
      }
    } else if (step === 2) {
      if (!this.Model.Dto.UoId || !this.Model.Dto.WorkflowId) {
        return false;
      }
    } else if (step === 3) {
      if (!this.CurrentFolder && !this.ObjectModel.Dtos.find(o => o.Selected && o.Type === 'folder')) {
        return false;
      }
    } else if (step === 4) {
      for (const dto of this.MetaDataTipologyModel.Dtos) {
        if (dto.PropertiesJson.Required && !dto.MetadataTipologyValue.Value) {
          return false;
        }
      }
    }
    return true;
  }



  async ItemClick(item) {
    this.Steps = 4;
    const authorization = this.Model.Dto.Authorization;
    this.Model.Dto = new ObjectDto();
    this.Model.Dto.Authorization = authorization;
    this.FileModel = null;
    this.DocumentMenuItems.map(x => x.Selected = false);
    item.Selected = !item.Selected;
    this.Model.Dto.Format = item.Type;
    this.FilterTipology(this.Model.Dto.Format);
    if (item.Name === 'upload-document') {
      const fileUpload = this.fileUpload.nativeElement;
      this.FileModel = new FileModel();
      this.FileModel.Dto = new FileDto();

      fileUpload.onchange = () => {
        Array.prototype.forEach.call(fileUpload.files, file => {
          this.FileModel.Dto.PhysicalName = file.name.replace(/[^a-z0-9.]/gi, '_');
          this.FileModel.Dto.Path = null;
          this.FileModel.Dto.File = file;
          this.FileModel.Dto.MimeType = file.type;

          this.Model.Dto.Format = this.commonService.GetExtension(this.FileModel.Dto.PhysicalName);
          this.Model.Dto.Name = file.name.replace('.' + this.commonService.GetExtension(this.FileModel.Dto.PhysicalName), '');
          this.FilterTipology(null, this.FileModel.Dto.MimeType);
        });
        // fileUpload.files.forEach(file => {
        //   this.FileModel.Dto.PhysicalName = file.name.replace(/[^a-z0-9.]/gi, '_');
        //   this.FileModel.Dto.Path = null;
        //   this.FileModel.Dto.File = file;
        //   this.FileModel.Dto.MimeType = file.type;

        //   this.Model.Dto.Format = this.commonService.GetExtension(this.FileModel.Dto.PhysicalName);
        //   this.Model.Dto.Name = file.name.replace('.' + this.commonService.GetExtension(this.FileModel.Dto.PhysicalName), '');
        //   this.FilterTipology(null, this.FileModel.Dto.MimeType);
        // });

      };
      fileUpload.click();
    } else if (item.Name === 'scan-document') {
      this.Steps = 5;
      const fileUpload = this.multiFileUpload.nativeElement;
      this.FileModel = new FileModel();
      const datenow = this.datepipe.transform(new Date(), DateFormat.ddMMyyyyHHmmss);
      this.Model.Dto.Format = 'pdf';
      fileUpload.onchange = async () => {
        for (const file of fileUpload.files) {
          const fileDto = new FileDto();
          fileDto.PhysicalName = datenow + file.name.replace(/[^a-z0-9.]/gi, '_');
          fileDto.Path = null;
          fileDto.File = file;
          fileDto.BlobUrl = await this.commonService.GetBlobUrl(file);
          this.FileModel.Dtos.push(fileDto);

          // const fileReader = new FileReader();
          // fileReader.onload = (fileLoadedEvent) => {
          //   const base64 = fileLoadedEvent.target.result;
          //   const fileDto = new FileDto();
          //   fileDto.PhysicalName = datenow + file.name.replace(/[^a-z0-9.]/gi, '_');
          //   fileDto.Path = null;
          //   fileDto.File = base64;
          //   this.FileModel.Dtos.push(fileDto);
          // };
          // fileReader.readAsDataURL(file);
        }
        this.FilterTipology('pdf');
      };
      fileUpload.click();
    }
  }

  async FilterTipology(format: string, mimeType: string = null) {
    this.Tipologies = new Array<TipologyDto>();
    this.Model.Dto.TipologyId = -1;
    if (format != null) {
      if (format === 'docx') {
        this.Tipologies = this.TipologyModel.Dtos.filter(x => x.MimeTypes.includes('application/msword') ||
          x.MimeTypes.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document'));
      } else if (format === 'xlsx') {
        this.Tipologies = this.TipologyModel.Dtos.filter(x => x.MimeTypes.includes('application/vnd.ms-excel') ||
          x.MimeTypes.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
      } else if (format === 'pptx') {
        this.Tipologies = this.TipologyModel.Dtos.filter(x => x.MimeTypes.includes('application/vnd.ms-powerpoint') ||
          x.MimeTypes.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation'));
      } else if (format === 'pdf') {
        this.Tipologies = this.TipologyModel.Dtos.filter(x => x.MimeTypes.includes('application/pdf'));
      }
    } else if (mimeType !== null) {
      this.Tipologies = this.TipologyModel.Dtos.filter(x => x.MimeTypes.includes(mimeType));
    }
  }

  async UploadFile(callback) {
    if (this.FileModel && this.FileModel.Dto && this.FileModel.Dto.Id <= 0) {
      const data = this.FileModel;
      this.navigatorService.ShowDialog(ProgressBarPopupComponent, data, '35%', 'fit-content', '200px', async (response: any) => {
        if (response) {
          if (callback) {
            this.navigatorService.StartLoading();
            callback(response.Performed);
            return;
          }
        }
      });
    } else if (this.FileModel && this.FileModel.Dtos.length > 0) {
      // scan pdf
      const date = Date();
      this.FileModel.Dtos.map(x => x.Path = this.Model.Dto.Path + '/temp' + this.datepipe.transform(date, 'ddMMyyyyhhmmssSSS'));
      this.navigatorService.ShowDialog(ProgressBarPopupComponent, this.FileModel, '45%', 'fit-content', '200px', async (response: any) => {
        if (response) {
          if (callback) {
            this.navigatorService.StartLoading();
            this.FileModel.Dto = new FileDto();
            this.FileModel.Dto.Path = this.Model.Dto.Path;
            this.FileModel.Dto.PhysicalName = this.Model.Dto.PhysicalName;
            this.FileModel = await this.FileManagerController.ScanToPdf(this.FileModel);
            if (this.FileModel?.Performed) {
              this.Model.Dto.MimeType = this.FileModel?.Dto?.MimeType;
            }
            if (callback) {
              callback(response.Performed);
              return;
            }
          }
        }
        if (callback) {
          callback(false);
          return;
        }
      });
    } else {
      if (callback) {
        callback(true);
        return;
      }
    }
  }


  IsFileAllowed(): boolean {
    const tipology = this.TipologyModel.Dtos.find(x => x.Id === this.Model.Dto.TipologyId);
    if (tipology) {
      if (this.FileModel && this.FileModel.Dto?.File) {
        const allowed = tipology.MimeTypes.includes(this.FileModel.Dto.File.type);
        return allowed;
      } else {
        const mimetypeTipology = tipology.MimeTypes.split(',');
        const mimetyped = this.MimeTypeModel.Dtos.filter(x => mimetypeTipology.includes(x.MimeType));
        let allowed = false;
        const format = (this.Model.Dto?.Format ? this.Model.Dto?.Format : this.commonService.GetExtension(this.Model.Dto.PhysicalName));
        if (mimetyped.find(x => x.Extension === '.' + format)) {
          allowed = true;
        }
        return allowed;
      }
    }
    return false;
  }

  IsScanDocument() {
    const isScanDocument = this.DocumentMenuItems?.find(x => x.Selected === true)?.Name === 'scan-document';
    return isScanDocument;
  }

  SelectSliderImage(dto: FileDto) {
    this.FileModel?.Dtos.map(x => x.Selected = false);
    dto.Selected = true;
    this.SliderCurrentPage = this.FileModel.Dtos.findIndex(x => x.PhysicalName === dto.PhysicalName) + 1;
  }

  DeleteSliderImage(dto: FileDto) {
    if (this.FileModel?.Dtos?.length > 1) {
      const index = this.FileModel.Dtos.findIndex(x => x.PhysicalName === dto.PhysicalName);
      if (index >= 0) {
        this.FileModel.Dtos.splice(index, 1);
        this.SelectSliderImage(this.FileModel.Dtos[0]);
      }
    }
  }

  GetSliderImageSelected() {
    return this.FileModel.Dtos.find(x => x.Selected === true)?.BlobUrl;
  }

  SliderPagingClick(action: string) {
    if (action === 'previous') {
      this.SliderCurrentPage = (this.SliderCurrentPage > 1 ? this.SliderCurrentPage - 1 : 1);
    } else {
      this.SliderCurrentPage = ((this.SliderCurrentPage + 1) < this.FileModel.Dtos.length
        ? this.SliderCurrentPage + 1 : this.FileModel.Dtos.length);
    }
    this.SelectSliderImage(this.FileModel.Dtos[this.SliderCurrentPage - 1]);
  }

  drop(event: CdkDragDrop<FileDto[]>) {
    moveItemInArray(this.FileModel.Dtos, event.previousIndex, event.currentIndex);
    this.SelectSliderImage(this.FileModel.Dtos[event.currentIndex]);
  }

  // #region Step 3
  async GetObjects(dto: ObjectDto = null, search: string = null, page = 1) {
    this.ObjectModel.Search = null;
    this.ObjectModel.Filter = new ObjectFilter();
    this.ObjectModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    this.ObjectModel.Filter.TipologyId = this.Model.Dto.TipologyId;
    this.ObjectModel.Filter.State = [DocumentState.PROGRESS, DocumentState.REVISION,
    DocumentState.APPROVAL, DocumentState.EDIT, DocumentState.SIGNED, DocumentState.APPROVED, null];
    this.CurrentFolder = dto;
    if (!search) {
      search = this.SearchComponent?.GetSearchValue();
      if (!dto && search) {
        this.ObjectModel.Search = search;
      } else if (!dto) {
        this.ObjectModel.Filter.Deep = 1;
      } else if (dto) {
        this.ObjectModel.Filter.Parent = dto.Id;
      }
    }
    else {
      this.ObjectModel.Search = search;
      this.ObjectModel.Filter.Type = 'folder';
    }
    if (!this.CurrentFolder) {
      this.CurrentFolder = this.FolderModel.Dto;
    }
    this.ObjectModel.Order = new BaseOrder();
    this.ObjectModel.Order.Name = 'Id';
    this.ObjectModel.Order.Direction = 'desc';
    this.ObjectModel = await this.Controller.Load(this.ObjectModel);
    this.Paging(page);
  }

  async ObjectsClick(dto: ObjectDto) {
    if (dto.Type === 'folder') {
      this.navigatorService.StartLoading();
      this.ObjectModel.Skip = 0;
      await this.GetObjects(dto);
      this.ObjectModel.Dtos.map(o => o.Selected = false);
      this.navigatorService.StopLoading();
    }
  }

  SelectRow(dto: ObjectDto) {
    this.ObjectModel.Dtos.map(o => o.Selected = false);
    dto.Selected = true;
  }

  async ReadObject(parent) {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    model.Filter.Id = parent;
    model = await this.Controller.Read(model);
    return model?.Dto ?? null;
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.ObjectModel.Skip = 0;
    await this.GetObjects(null, searchText);
    this.navigatorService.StopLoading();
  }


  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    let folder = this.CurrentFolder;
    if (this.CurrentFolder?.Deep > 1) {
      this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
      folder = this.CurrentFolder;
    } else {
      this.CurrentFolder = null;
      folder = null;
    }
    await this.GetObjects(folder, null, this.CurrentPage);
    this.navigatorService.StopLoading();
  }

  Paging(page: number = 1) {
    if (this.Step === 3) {
      this.Pager.Model = this.ObjectModel;
      this.Pager.Paging(page);
      this.RefreshTable(this.ObjectModel.Dtos);
    }
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetObjects(this.CurrentFolder, null, page);
    this.navigatorService.StopLoading();
  }

  RefreshTable(dtos: Array<ObjectDto>) {
    for (const dto of dtos) {
      this.commonService.GetIcon(dto);
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }
  // #endregion


  async ShowCreateFolder() {
    this.FolderNewDto = new ObjectDto();
  }

  async SaveFolder() {
    if (this.FolderNewDto && this.FolderNewDto.Name?.length > 0) {
      this.navigatorService.StartLoading();
      let model = new ObjectModel();
      if (this.FolderNewDto.PhysicalName === null || this.FolderNewDto?.Id <= 0) {
        this.FolderNewDto.PhysicalName = this.FolderNewDto.Name.toUpperCase().replace(/[^a-z0-9]/gi, '_');
        this.FolderNewDto.Path = this.CurrentFolder.Path + '/' + this.FolderNewDto.PhysicalName;
        this.FolderNewDto.Parent = (this.CurrentFolder.Id && this.CurrentFolder.Id === 0 ? null : this.CurrentFolder.Id);
        this.FolderNewDto.Deep = (this.CurrentFolder ? this.CurrentFolder.Deep += 1 : 1);
        this.FolderNewDto.Version = 0;
      }
      this.FolderNewDto.TipologyUoId = this.TipologyModel.Dtos.find(t => t.Id === this.Model.Dto.TipologyId)
        ?.TipologyUos.find(tuo => tuo.UoId === this.Model.Dto.UoId)?.Id;
      this.FolderNewDto.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      model.Action = 'CREATE';
      this.FolderNewDto.Type = 'folder';
      model.Dto = this.FolderNewDto;
      model = await this.Controller.CreateOrUpdate(model);
      if (model.Performed) {
        if (model.Code && model.Code === 409) {
          model.Code = null;
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.FolderExist);
        } else {
          const message = this.navigatorService.Dictionary?.ToCreateSuccessF;
          this.navigatorService.ShowSnackBar(message, this.FolderNewDto?.Name);
          this.FolderNewDto = null;
          this.ObjectModel.Skip = 0;
          await this.GetObjects(this.CurrentFolder);
        }
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.SaveError);
        this.navigatorService.StopLoading();
      }

      this.navigatorService.StopLoading();
    } else {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.FolderNameEmpty);
    }

  }
}



