<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <div class="valign-icon-text">
                <img style="margin-right: 11px; position: relative;
                top: -2px;" src="../../../../../assets/icon/lock.svg">
                <h2 *ngIf="!ObjectDto.Crypt">{{navigatorService.Dictionary?.Encrypt}}</h2>
                <h2 *ngIf="ObjectDto.Crypt">{{navigatorService.Dictionary?.Decrypt}}</h2>

            </div>
            <p *ngIf="!ObjectDto.Crypt" class="title-description">
                {{navigatorService.Dictionary?.EncryptDescription}}</p>
            <p *ngIf="ObjectDto.Crypt" class="title-description">
                {{navigatorService.Dictionary?.DecryptDescription}}</p>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>
    <div>
        <mat-dialog-content>
            <div class="form">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Password'" [Type]="'password'" [Required]='true'
                            [Placeholder]="navigatorService.Dictionary?.Password"></app-input>
                    </div>
                    <div class="error" *ngIf="ErrorMessage">{{ErrorMessage}}</div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button (click)="Confirm()" mat-button class="it-doc-btn-primary" [disabled]="!IsValid()">
            <span class="button-text">{{navigatorService.Dictionary?.Confirm}}</span>
        </button>
    </div>
</div>