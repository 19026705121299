import { BaseEntity, BaseModel } from "../../models/BaseModel";
import { TipologyUoDto } from './TipologyUoModel';

export class TipologyModel extends BaseModel<TipologyEntity, TipologyDto, TipologyFilter> {
    constructor() {
        super();
        this.Take = 10;
    }

    IsValid() {
        return true;
    }
}
export class TipologyEntity extends BaseEntity {
    Name: string; // 500
    Description: string; // 500
    MimeTypes: string; // 4000
    Archived: any;
    constructor() {
        super();
        this.Name = null;
        this.Description = null;
        this.MimeTypes = null;
        this.Archived = false;
    }
}
export class TipologyDto extends TipologyEntity {
    AccountId: number;
    MimeTypesIds: Array<number>;
    Open: boolean;
    TipologyUos: Array<TipologyUoDto>;
    constructor() {
        super();
        this.MimeTypesIds = new Array<number>();
        this.AccountId = null;
        this.Open = false;
    }
}

export class TipologyFilter extends TipologyDto {
}
