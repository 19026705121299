import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigatorService } from '../../../../navigator.services';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(public navigatorService: NavigatorService,
    public dialogRef: MatDialogRef<MessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    if (this.data.Message) {
      this.data.Message = this.data.Message.replace(new RegExp('Errore:', 'g'), '<br/><br/>Errore:<br/>');
    }
  }

}
