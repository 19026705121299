import { DictionaryInterface } from './dictionary.interface';
export class Dictionary implements DictionaryInterface {
  DocumentNumber =  'Numero documenti';
  LimitFiles = 'Si possono caricare al massimo 5000 files per singolo PDA';
  PDDNotAvailable = 'PDD non disponibile';
  Index = 'indice di versamento';
  ShowLog = 'Dettagli errore';
  CreatedPda = 'Pacchetto di archiviazione creato correttamente';
  DeletedPda: 'Pacchetto di archiviazione eliminato';
  GeneratePda = 'Genera pacchetto di archiviazione';
  ArchiveList = 'Lista archivi';
  ArchiveContent = 'Contenuto Archivio: ';
  PdaContent = 'Contenuto Pda: ';
  NewPdaTitle = 'Genera indice del pacchetto di versamento';
  NewPdaDescription = 'Il form permette di creare il pacchetto di versamento inserendo uno o più documenti. Per ciascun documento sarà necessario specificare i relativi metadati per creare l\'indice.';
  AddDocuments = 'Aggiungi documenti';
  DocClassDescription = 'Seleziona la classe documentale e prosegui per effettuare il versamento';
  DocClass = 'Classe documentale';
  NewPda = 'Nuovo pacchetto di archiviazione(PDA)';
  EditPda = 'Modifica di archiviazione(PDA)';
  CreatePDA = 'Crea pacchetto di archiviazione';
  UploadPDA = 'Carica pacchetto di archiviazione';
  Conservation = 'Conservazione';
  ConservationLoginDescription = 'Inserisci le credenziali del servizio di conservazione DocFly';
  ConservationLoginTitle = 'Accedi a Docfly';
  Username = 'Nome utente';
  DocFlyLogin = 'Accedi';
  BusinessName = 'Ragione sociale';
  VatNumber = 'Partita iva';
  FiscalCode = 'Codice fiscale';
  EmptyArchiveMessage = 'Nessun archivio da visualizzare';
  PaymentDate = 'Data versamento';
  RetentionDate = 'Data conservazione';
  EmptyPdasMessage = 'Nessun pacchetto di versamento da visualizzare';
  ViewPda = 'Pacchetti di archiviazione (PDA)';
  DetailPda = 'Dettagli pacchetto di archiviazione';
  DownloadPDD = 'Scarica PDD';
  WizardEndTitle = 'Come funziona {#APPNAME#} finisce qui, ma puoi sempre <br> navigare cliccando lì';
  WizardEndDescription = 'Vi auguriamo buon lavoro con i vostri progetti e colleghi.';
  LetsStart = 'Cominciamo';
  Use = 'Utilizza ';
  WizardDescription1 = 'per gestire facilmente il tuo <strong>smartworking</strong>,<br> creando <strong>documenti</strong> e <strong>fascicoli</strong>, invitare i colleghi a <br>'
    + 'collaborare per finalizzare i tuoi <strong>protocolli</strong>. <strong>Aumenta</strong>la tua <strong>produttività</strong> con avanzati <br>'
    + 'servizi di comunicazione e strumenti di monitoraggio.';
  WizardDescription2 = 'Sei un <strong>Amministratore</strong> di {#APPNAME#}, <br>'
    + 'scopriamo tutte le funzionalità del tuo profilo.';
  WizardUserDescription2 = 'Sei un <strong>Worker</strong> di {#APPNAME#}, <br>'
    + 'scopriamo tutte le funzionalità del tuo profilo.';
  WizardWelcome = 'Benvenuto a bordo {#DISPALYNAME#}';
  JumpSection = 'Salta questa sezione';
  WizardSkipAll = 'Salta tutto';
  HowWorks = 'Come funziona {#APPNAME#}';
  ErrorRequired = 'Campo obbligatorio';
  Timestamp = 'Marca temporale';
  TimestampDescription = 'Applica la marca temporale';
  TimestampUsername = 'Username marca temporale';
  TimestampPassword = 'Password marca temporale';
  DossierNotFound = 'Il dossier non è più disponibile';
  DocumentNotFound = 'Il documento non è più disponibile';
  TipologyNotFound = 'Struttura metadati non disponibile';
  UoNotFound = 'L\'unità organizzativa non è più disponibile';
  SignatureOwner = 'Titolare della firma';
  English = 'Inglese';
  Italian = 'Italiano';
  Language = 'Lingua';
  ValidityCertificate = 'Validità del certificato';
  CertifyingAuthority = 'Authorità Certificatrice';
  DomainQualifier = 'Qualificatore di dominio';
  OrganizationName = 'Tipo di organizzazione';
  AlternativeName = 'Nome alternativo';
  SerialNumber = 'Numero Seriale';
  SignVerify = 'Dettagli firme';
  NotificationHistory = 'Cronologia notifiche';
  Yesterday = 'Ieri alle ';
  Today = 'Oggi alle ';
  InvalidOTP = 'OTP non valido';
  AddSignature = 'Aggiungi firma';
  GraphicSign = 'Grafica';
  InvisibleSign = 'Invisibile';
  SignatureMode = 'Modalità di firma';
  SignatureType = 'Tipo di firma';
  SignaturePlace = 'Luogo';
  SignatureReason = 'Causale';
  ComparePassword = 'Le password devono corrispondere';
  CompareEmail = 'L\'indirizzo email deve corrispondere';
  ComparePhone = 'Il  numero di telefono deve corrispondere';
  ConfirmMail = 'Conferma la tua nuova email';
  ConfirmPhone = 'Conferma il tuo nuovo telefono';
  EditInfoDescription1 = 'Modifica le impostazioni utente e conferma';
  EditInfoDescription2 = 'Modifica la tua password e conferma';
  Edit = 'Modifica';
  Phone = 'Telefono';
  TitleInfo1 = 'Nome e data di nascita';
  TitleInfo2 = 'Password e contatti';
  TitleInfo3 = 'Informazioni operative';
  ItDocsRole = 'Ruolo {#APPNAME#}';
  Birthdate = 'Data di nascita';
  Roles = 'Ruoli';
  PrincipaInfo = 'Info principali';
  Profile = 'Profilo';
  FolderNameEmpty = 'Digitare il nome della cartella';
  AddFolder = 'Aggiungi una Cartella';
  Signatory = 'Firmatario';
  SelectOperators = 'Operatori';
  Operator = 'Operatore';
  PersonCharge = 'Responsabile';
  CollateSuccess = 'Fascicolazione completata con successo';
  TrashedFiles = 'File eliminati';
  TrashedFileNotCmplete = 'Non è stato possibile eliminare uno o più file ';
  CountAttachment = 'Numero allegati';
  Title = 'Titolo';
  Recipient = 'Destinatario';
  Sender = 'Mittente';
  FileAlreadyExists = 'File già presente';
  UploadError = 'Errore caricamento';
  Upload = 'Carica';
  Uploading = 'Caricamento {#filename#} in corso';
  UploadCompleted = 'Caricamento completato';
  ExportLog = 'Esporta in txt';
  Close = 'Chiudi';
  DragAttachments = 'Trascina qui i tuoi allegati o fai clic per selezionarli dal tuo computer';
  ManageAttachements = 'Gestisci i tuoi allegati';
  AttachedDocuments = 'Documenti allegati';
  Attached = 'Allegati';
  LastActivity = 'Ultime attività';
  ActivityEmpty = 'Non ci sono attività';
  MessageConvertFail = 'Conversione documento fallita.';
  TypeOtp = 'Inserisci l\'otp';
  ToSignSuccess = 'Firmato con successo';
  ResendOtp = 'Non hai ricevuto il codice? Fare clic per inviare nuovamente';
  TypeOtpCode = 'Digita il codice otp';
  SignatureService = 'Servizio di firma';
  SignatureDescription = 'Imposta i dati della tua firma';
  SignatureDescription2 = 'Seleziona e aggiungi una firma';
  EmptySignatureMessage = 'Non ci sono account di firma';
  ProfileName = 'Nome profilo';
  Service = 'Servizio';
  Sign = 'Firma';
  YourRequest = 'Le mie richieste';
  DeleteField = 'Elimina campo';
  MarkAllAsRead = 'Segna tuttte come già lette';
  MoveToTrashSuccessMessage = 'Spostato nel cestino correttamente';
  MoveToTrash = 'Spostare nel cestino';
  NoDeletedUoMessage = 'Non ci sono Unità Organizzative eliminate';
  YourUoDeleted = 'Le tue Unità Organizzative Eliminate';
  AllUoDeleted = 'Tutte le Unità Organizzative Eliminate';
  TrashedUo = 'UO eliminate';
  AssociatedFiles = 'Files associati';
  AssociatedUsers = 'Utenti associati';
  Identifier = 'Identificativo';
  TableColumnResponsible = 'Responsabile';
  TableColumnDossierIdentifier = 'Identificativo';
  DossierUpdateMessage = 'Fascicolo modificato correttamente.';
  DossierArchivedMessage = 'Fascicolo archiviato correttamente.';
  DossierDeleteMessage = 'Fascicolo eliminato correttamente.';
  DeleteUserMessage = 'Utente eliminato';
  ResetPasswordSend = 'Email di recupero password inviata correttamente. Controlla la tua casella di posta';
  AccountVerifiedSuccessfully = 'Account verificato';
  Password = 'Password';
  DescriptionInvitationldapForm = 'Inserisci la password  per verificare il tuo account {#APPNAME#}';
  SearchLdap = 'Cerca nel tuo ldap';
  ImportFromLdap = 'Importa da ldap';
  MatchPasswordError = 'Le password inserite non sono uguali';
  MissingMail = 'Inserisci l\'email';
  UserNotFound = 'Email non trovata. Inserisci l\'email del tuo account.';
  ResetFilters = 'Azzera Filtri';
  MetadataStructurePlaceholder = 'Struttura Metadati';
  CreationDatePlaceholder = 'Data creazione';
  FileExtensionPlaceholder = 'Estensione file';
  StatusPlaceholder = 'Stato';
  UoPlaceholder = 'Unità Organizzativa';
  EmptyWorkflowMessage = 'Non ci sono ancora Workflow!';
  DossierClose = 'Fascicoli Archiviati';
  Open = 'Aperto';
  RemoveFromDossier = 'Rimuovi dal fascicolo';
  StateRevisioned = 'Revisionato';
  WorkflowReport = 'Riepilogo del flusso di lavoro';
  TableColumnStep = 'Step';
  InvalidFormat = 'Formato file non valido.';
  StateProtocolled = 'Protocollato';
  SendToProtocol = 'Protocollare';
  ProtocolTitle = 'Protocolla';
  Protocol = 'Protocolla';
  YourDeleteDocument = 'I Tuoi Documenti Eliminati';
  AllDeleteDocument = 'Tutti i Documenti Eliminati';
  StateReject = 'Rifiutato';
  RejectedSuccess = 'Rifiutato';
  Reject = 'Rifiuta';
  ToReject = 'Rifiutare il documento';
  RejectTitle = 'Rifiuta';
  StateEdit = 'In modifica';
  RequestEditingSuccess = 'Richiesta di modifica inviata';
  ToRequestEditing = 'Richiedere una modifica';
  RequestEditingTitle = 'Richiedi Modifica';
  FilesArchived = 'Documenti Archiviati';
  ToArchive = 'Archiviare';
  StateArchived = 'Archiviato';
  StateApproved = 'Approvato';
  ToApprove = 'Approvare';
  StateInApproval = 'In Approvazione';
  NotAuthorizedMessage = 'Non sei autorizzato ad effettuare questa azione';
  SendToApprovationSuccess = 'mandato in approvazione';
  SendToRevisionSuccess = 'mandato in revisione';
  StateInRevision = 'In Revisione';
  Approve = 'Approva';
  StateInProgress = 'In Corso';
  Workflow = 'Workflow';
  Preview = 'Anteprima';
  TipologyExist = 'Struttura metadati già presente';
  UoExist = 'Unità organizzativa già presente. Verifica che il nome e il codice ufficio siano corretti';
  Admin = 'Admin';
  ToCreateSuccessF = 'Creata correttamente';
  ToEditSuccessF = 'Modificata correttamente';
  ToEditSuccess = 'Modificato correttamente';
  ToCreateSuccess = 'Creato correttamente';
  ToRestore = 'Ripristinare';
  RestoreSuccess = 'Ripristino con successo';
  AllFileSelected = 'tutti i file selezionati';
  TrashedFile = 'è stato spostato nel cestino';
  ToDelete = 'Eliminare';
  AreYouSureWantMsg = 'Sei sicuro di voler';
  SendToApprovationTitle = 'Manda in Approvazione';
  SendToRevisionTitle = 'Manda in Revisione';
  SendToApprovation = 'Mandare in Approvazione';
  SendToRevision = 'Mandare in Revisione';
  NewFolderDescription = 'Imposta le proprietà della cartella';
  TableColumnVersion = 'Versione';
  Favourites = 'Preferiti';
  AllDocument = 'Tutti i Documenti';
  YourDocument = 'I miei Documenti';
  DossierLabelPlaceholder = 'Etichetta';
  YourDossier = 'I Tuoi Fascicoli';
  AllDossier = 'Tutti i Fascicoli';
  TableColumnStatus = 'Stato';
  TableColumnDossierOwner = 'Owner Dossier';
  TableColumnTeam = 'Team';
  AddUser = 'Aggiungi Utente';
  Export = 'Esporta';
  ExpirationDate = 'Data di scadenza';
  DossierFilterPlaceholder = 'Seleziona fascicoli';
  DoossierKeyFilterPlaceholder = 'Seleziona identificativo di fascicolo';
  Filter = 'Filtri';
  DossierFilterSubTitle = 'Seleziona i parametri dei fascicoli che vuoi visualizzare';
  CleanFilter = 'Azzera filtri';
  IntoWork = 'Rimetti in lavorazione';
  ActivityTitleShare = 'Condivisione';
  ActivityTitleDossier = 'Fascicolo';
  ActivityTitleDownload = 'Download';
  ActivityTitleCopy = 'Copia';
  ActivityTitleUpdate = 'Modifica';
  ActivityTitleCreate = 'Creazione';
  ActivityListFile = 'ATTIVITÀ DEL FILE';
  ActivityRemoveShare = 'rimosso la condivisione del';
  ActivityUpdateShare = 'modificato la condivisione del';
  PrepositionWith = 'con';
  ActivityCreateShare = 'condiviso';
  ActivityRemoveDossier = 'rimosso dal fascicolo';
  ActivityAddDossier = 'aggiunto al fascicolo';
  ActivityDownload = 'scaricato';
  ActivityCopy = 'copiato';
  ActivityUpdate = 'modificato';
  ActivityCreate = 'creato';
  ActivityFile = 'il file';
  ActivityFolder = 'la cartella';
  ActivitySuject = 'Hai|ha';
  EmptySignage = 'Nessuna firma apposta';
  ShareOthers = 'Codiviso con <b>{{|DISPLAYNAMEUID|}}</b> da <b>{{|DISPLAYNAMEOWNERID|}}</b>';
  NewDocumentPdf = 'Nuovo PDF';
  EditDocumentDescription2 = 'Modifica gli attributi del documento';
  SignatureTabTitle = 'Firme apposte';
  OTPValidationMessage = 'Inserisci l\'OTP di autenticazione';
  OTPPlaceholder = 'OTP';
  OTPDescription = 'Inserisci l\'OTP di firma';
  OTPTitle = 'Autenticazione';
  RemovePassword = 'Rimuovi password';
  ShareBy = 'Condiviso da ';
  DeleteShare = 'Rimuovi';
  SetExipryDate = 'Imposta scadenza';
  SetPassword = 'Imposta password';
  DenyDownload = 'Impedisci download';
  AllowEdit = 'Consenti modifica';
  ShareWithPlaceholder = 'Condividi con';
  TableColumnState = 'Stato';
  FileExist = 'Esiste già un file con questo nome, sceglierne un altro';
  AdvancedSearchDescription = 'Effettua una ricerca avanzata applicando uno o più filtri';
  CreationDate = 'Data creazione';
  ModificationDate = 'Data modifica';
  SearchInMetadata = 'Cerca nei metadata';
  SearchInFiles = 'Cerca nei file';
  AdvancedSearch = 'Ricerca Avanzata';
  Apply = 'Applica';
  SaveErrorAddDossier = 'Non è stato possibile aggiungere uno o più file al fascicolo';
  TableColumnProtocol = 'Protocollo';
  ScanDocument = 'Pdf';
  OperationSuccess = 'L\'operazione è stata eseguita correttamente.';
  MimeTypesAllowed = 'La tipologia documento selezionata non accetta il formato del file che si vuole creare';
  MessagevalidationFormDigitalsignage = 'Compilare tutti i campi del form';
  MessageSignatureSuccess = 'Il documento è stato firmato';
  MessageSignatureFileNotFound = 'Il documento non è stato trovato.';
  RestoreDocument = 'Ripristina documento';
  RestoreDocumentDescription = 'Procedere con il ripristino dei documenti?';
  TrashedSuccess = 'I file sono stati spostati nel cestino';
  RestoredSuccess = 'File ripristinati';
  PermanentlyDelete = 'Eliminare Definitivamente';
  Restore = 'Ripristina';
  FilesDelete = 'Documenti eliminati';
  DeleteSuccess = 'Eliminazione effettuata con successo';
  DeleteDocument = 'Elimina documento';
  DeleteDocumentDescription = 'Procedere con l\'eliminazione?';

  SelectMoveCopyObject = 'Seleziona la destinazione';
  Copy = 'Copia';
  Move = 'Sposta';
  EditFolder = 'Modifica cartella';
  EditDocument = 'Modifica documento';
  MoveCopy = 'Sposta o Copia';
  FoldersSelected = 'Selezionate';
  Selected = 'Selezionato';
  File = 'File';
  FolderSelected = 'Selezionata';
  Folders = 'Cartelle';
  Selecteds = 'Selezionati';
  AddFavourites = 'Aggiungi ai preferiti';
  Modify = 'Modifica';
  Signature = 'Firma digitale';
  SendToEmail = 'Invia via email';
  Send = 'Invia';
  Crypt = 'Cifra';
  Download = 'Scarica';
  Delete = 'Elimina';
  CreateFromTemplate = 'Crea file da Template';
  NewDocumentDescription4 = 'Compila i metadata del documento';
  ValidatorSelectedFolder = 'Seleziona una cartella';
  ValidatorMessage = 'Compilare tutti i campi obligatori';
  ValidatorMessageResponsible = 'Selezionare almeno un Responsabile';
  FolderExist = 'Cartella già esistente. Scegliere un altro nome';
  NewFolderDescription2 = 'Seleziona gli utenti che avranno accesso a questa cartella';
  FolderMessageValidator1 = 'Inserire il nome della cartella e la destinazione';
  FolderName = 'Nome cartella';
  NewFolder = 'Nuova cartella';
  TipologySummarySubTitle1 = 'Nome tipologia documento';
  TipologySummaryDescription = 'Confermi la struttura metadata con le seguenti caratteristiche? ';
  MetadataToken = 'Definisci il token relativo al metadato';
  OperatorWorkPlaceholder = 'Chi può lavorarlo';
  TokenPlaceholder = 'Inserisci un token ';
  OperatorWorks = 'Chi può lavorare con questo dato';
  SelectedItems = 'Elementi della selezione inseriti';
  AddItem = 'Aggiungi opzione';
  MaxPlaceHolder = 'Max.';
  Characters = 'caratteri';
  Maxlength500 = 'Max. 500 caratteri';
  Update = 'Modifica';
  OrganizationalUnitTitleEdit = 'Modifica unità organizzativa';
  OrganizationalUnitDescription5 = 'Dipendenza';
  OrganizationalUnitDescription6 = 'Numero di utenti';
  OrganizationalUnitTitle = 'Nuova unità organizzativa';
  OrganizationalUnitDescription4 = 'Seleziona l’unità organizzativa gerarchicamente superiore';
  OrganizationalUnitDescription3 = 'Confermi di voler creare una nuova Unità organizzativa con queste caratteristiche?';
  OrganizationUnitNamePlaceholder = 'Nome';
  OfficeCodePlaceHolder = 'Codice ufficio';
  DescriptionUoPlaceholder = 'Descrizione unità organizzativa';
  GroupPlaceholder = 'Seleziona un gruppo';
  SelectUserPlaceholder = 'Seleziona utente';
  SelectOrganizationUnitPlaceholder = 'Unità organizzativa';
  OrganizationalUnitDescription2 = 'Imposta i membri dell\'unità organizzativa';
  OrganizationalUnitDescription = 'Imposta i dati dell\'unità organizzativa';
  UoImageTitle = 'Non sono presenti unità organizzative';
  UoImageIstruction1 = 'Crea una nuova unità organizzativa';
  UoImageIstruction2 = 'Definisci gli utenti che ne fanno parte';
  UoImageIstruction3 = 'Imposta la relazione con un altra unità organizzativa';
  UoSubTitleDescription = 'Imposta le diverse unità organizzative che operano sul documentale in base alla loro relazione gerarchica';
  UoSubTitle = 'Definisci le unità organizzative';
  Maxlength250 = 'Max. 250 caratteri';
  FieldRequired = 'Rendi campo obbigatorio';
  TipologyPlaceholderField = 'Inserisci l’intestazione del campo';
  TipologyFieldPreview = 'Anteprima elemento';
  TipologyDescription3 = 'Definisci i parametri del campo ';
  EmptyBasket = 'Non hai ancora inserito campi generici';
  TipologyDescription2 = 'Imposta i campi della struttura metadati';
  FieldPlaceholder = 'Seleziona campi generici';
  TemplateUploadPlaceholder = 'Carica template';
  ButtonUpload = 'Carica';
  NewDocumentDescription3 = 'Seleziona la cartella di destinazione';
  TableColumnName = 'Nome';
  TableColumnCreate = 'Creato';
  TableColumnUpdate = 'Ultima Modifica';
  TableColumnSize = 'Dimensione';
  All = 'Tutti';
  Back = 'Indietro';
  OrganizationalUnits = 'Unità organizzative';
  OrganizationalUnit = 'Unità organizzativa';
  AccountPlaceholder = 'Chi può lavorarlo';
  DocumentName = 'Nome Documento';
  Maxlength50 = 'Max. 50 caratteri';
  Maxlength16 = 'Max. 16 caratteri';
  NewDocumentDescription2 = 'Imposta le proprietà del nuovo documento';
  NewDocumentDescription = 'Qui puoi aggiungere, lavorare e modificare tutte le informazioni primarie del documento';
  NewDocument = 'Crea documento';
  UploadDocument = 'Carica file';
  TxtDocument = 'Nota';
  PptDocument = 'Presentazione';
  XlsDocument = 'Foglio di calcolo';
  DocDocument = 'Testo';
  AppsAndProcessess = 'App e processi';
  CreateAppsRequestDescription = 'Crea una nuova app di richiesta aziendale';
  AppsRequest = 'App di richiesta';
  MenuDialogDescription = 'Seleziona il tipo di elemento che vuoi creare';
  CreateDossierDescription = 'Crea un fascicolo, seleziona i documenti previsti e definiscine gli operatori coinvolti';
  CreateFolderDescription = 'Crea luna cartella per la gestione dei tuoi documenti';
  CreateTipologyDescription = 'Imposta la struttura meatdati';
  CreateDocumentDescription = 'Crea o carica un nuovo documento';
  DocumentTipology = 'Tipologia documento';
  Dossier = 'Fascicolo';
  Document = 'Documento';
  Folder = 'Cartella';
  Description = 'Descrizione';
  Years = 'Anni';
  Months = 'Mesi';
  Days = 'Giorni';
  CreateTipology = 'Crea tipologia documento';
  Tipology = 'Struttura Metadati';
  Next = 'Avanti';
  Cancel = 'Annulla';
  SelectUser = 'Nome membro';
  UOUsersDescription = 'Definisci gli utenti associati all\'unità organizzativa';
  Users = 'Utenti';
  SaveError = 'Si è verificato un errore durante il salvataggio.';
  Name = 'Nome';
  Create = 'Crea';
  CreateUO = 'Crea unità organizzativa';
  CreateDocument = 'Crea documento';
  CreateDossier = 'Crea fascicolo';
  SaveSuccess = 'Salvataggio effettuato con successo.';
  Documentary = 'Documentale';
  Dossiers = 'Fascicoli';
  DossiersDescription = 'Monitora i fascicoli di tua competenza';
  Documents = 'Documenti';
  Activities = 'Attività';
  OrganizationUnitPlaceholder = 'Seleziona unità organizzativa';
  TipologyTitle = 'Struttura Metadati';
  TipologyDescription = 'Crea tipologia documento e metadati';
  ModifyTipologyDescription = 'Modifica la struttura metadati';
  DocumentTipologyFileType = 'File accettati';
  // Placeholder
  TipologyPlaceholder = 'Struttura metadati';
  TipologyNamePlaceholder = 'Nome tipologia documento';

  RolePlaceholder = 'Seleziona un ruolo';

  DossierDescription = 'Imposta le proprietà del nuovo fascicolo';
  DossierDescription2 = 'Seleziona gli approvatori e chi può lavorare il fascicolo';
  DossierDescription3 = 'Seleziona i file che vuoi inserire nel fascicolo da I tuoi documenti';
  DossierDescription4 = 'Confermi di voler creare un nuovo Fascicolo con queste caratteristiche?';
  DossierNamePlaceholder = 'Nome Fascicolo';
  DossierIdPlaceholder = 'Identificativo fascicolo';
  ResponsiblePlaceholder = 'Responsabile';
  OperatorDossierPlaceholder = 'Operatori';
  MaxLength12 = 'Max. 12 caratteri';
  DossierSearchPlaceholder = 'Cerca nei tuoi documenti';
  RequiredFieldsMessage = 'Completa tutti i campi obbligatori';
  RequiredFields = 'Campi obbligatori';
  DossierNameSummary = 'Nome fascicolo';
  DossierIdSummary = 'Chiave di fascicolo';
  LastEdit = 'Ultima modifica';
  DossierFile = 'File associati';
  DossierFileDescription = 'Visualizza, lavora i documenti o disassociali dal fascicolo';
  UnlinkFile = 'Scollega';
  NoFileDossier = 'Nessun file associato';
  SearchFiltersLabel = 'Filtra';
  SearchActionsLabel = 'Azioni';
  DossiersViewSearchPlaceholder = 'Cerca Fascicoli';
  PageSubtitleSearch = 'Cerca nel tuo documentale';
  DossiersPageSubtitleDescription = 'Ricerca i fascicoli di tua competenza nel documentale aziendale';
  TipologySearchPlaceholder = 'Cerca tipologia documento';
  TipologyPageSubtitleDescription = 'Ricerca le tipologie di documento di tua competenza nel documentale aziendale';
  OrganizationUnitSearchPlaceholder = 'Cerca nella sezione I tuoi documenti';
  DocumentPageSubtitleDescription = 'Ricerca protocolli, fascicoli e documenti di tua competenza nel documentale aziendale';
  DocumentViewSearchPlaceholder = 'Cerca (Protocollo, nome documento)';
  AddToDossier = 'Fascicola';
  SelectAddToDossier = 'Seleziona uno o più fascicoli';
  Add = 'Aggiungi';
  InLate = 'In ritardo';
  InTime = 'In tempo';

  FolderSearchPlaceholder = 'Cerca cartella';
  Confirm = 'Conferma';
  AppsAndProcessesSectionTitle = 'App e processi';
  DocumentarySectionTitle = 'Documentale';
  MyRequestsTitle = 'Stato delle mie richieste';
  MyDeliberateRequestsTitle = 'Le tue richieste deliberate';
  OthersRequestsTitle = 'Stato delle richieste altrui';
  Import = 'Importa';
  CreateUoDescription = 'Crea una nuova unità organizzativa, definendone la struttura  e gli utenti';
  DossierImageTitle = 'Non sono presenti fascicoli';
  DossierImageDescription1 = 'Definisci il nome e gli attributi del tuo nuovo fascicolo';
  DossierImageDescription2 = 'Seleziona i file che vuoi associare e salva il fascicolo';
  TipologyImageTitle = 'Non sono presenti tipologie documenti';
  TipologyImageDescription1 = 'Definisci il nome e gli attributi della tua nuova tipologia di documento';
  TipologyImageDescription2 = 'Imposta la struttura di metadati della tipologia documento e salva';
  UnderProcessing = 'In lavorazione';
  InConservation = 'In conservazione';
  Approved = 'Approvati';
  InModification = 'In modifica';
  InApproval = 'In approvazione';
  ExportUoTree = 'Esporta alberatura unità organizzative';
  ArchiveTipologyTitle = 'Archivia tipologie';
  Archived = 'Archiviato';
  Archive = 'Archivia';
  ArchiveTipologyMsg = 'Le tipologie documento <b>{|tipologyNames|}</b> sono associate a documenti. Confermando le tipologie di documento saranno archiviate e non eliminate.';
  ArchiveTipologyMsg2 = 'Vuoi procedere con l\'archiviazione delle tipologie selezionate?';
  DeletedTipologySuccessMsg = 'Tipologie eliminate correttamente.';
  DeletedTipologyErrorMsg = 'Alcune delle tipologie selezionate non sono state eliminate a causa di un errore.';
  DeleteTipologyTitle = 'Elimina tipologie';
  DeleteTipologyMsg = 'Vuoi procedere con l\'eliminazione delle tipologie selezionate?';
  DescriptionExpandCard = 'Visualizza e lavora i documenti {|documentState|}';
  BusinessDocuments = 'Documenti aziendali';
  SearchUoPlaceholder = 'Cerca unità organizzativa';
  DeleteDossierTitle = 'Elimina fascicoli';
  DeleteDossierDescription = 'Vuoi procedere con l\'eliminazione dei fascicoli selezionati?';
  SharePasswordDialogTitle = 'Password di condivisione';
  SharePasswordDialogBubtitle = 'La condivisione richiede una password per accedere al file';
  SharePasswordPlaceholder = 'Password codivisione';
  ErrorSharePassword = 'Password non valida';
  InResolution = 'In delibera';
  Determined = 'Deliberate';
  ApprovedRequests = 'Approvate';
  Refuse = 'Rifiutate';
  Member = 'Membro';
  Date = 'Data';
  Role = 'Ruolo';
  Sharing = 'Condivisione';
  DescriptionDossierIdInput = 'Verrà assegnato automaticamente dal sistema nella forma DOS20210000001';
  SearchPlaceholder = 'Cerca ...';
  TrashedDocumentSubtitleSearch = 'Cerca tra i file eliminati';
  TrashedDocumentSubtitleDescription = 'Ricerca tra i documenti eliminati di tua competenza';
  DeleteAllBtnLabel = 'Elimina tutto';
  NoAddedDocumentsMsg = 'Al momento non sono stati aggiunti documenti';
  NoTrashedFileMsg = 'Non ci sono Documenti eliminati!';
  DocumentsInsertedTitle = 'Documenti inseriti';
  MessageGenericError = 'Si è verificato un errore';
  TableColumnOrganizationUnit = 'Unità Organizzativa';
  TableColumnFileType = 'Tipo File';
  TableColumnSignatories = 'Firmatari';
  TitleDocumentsSectionTipologyDetail = 'Documenti con questa tipologia di metadati';
  YourUo = 'Le tue Unità Organizzative';
  AllUo = 'Tutte le Unità Organizzative';
  TitleDocumentsSectionDossierDetail = 'Documenti in questo Fascicolo';

  LoginSubTitle = 'Ciao, benvenuto in {#APPNAME#}';
  LoginUsernamePassword = 'Inserici Username e Password per effettuare l\'accesso';
  LoginRememberMe = 'Ricordami';
  ForgotPassword = 'Ho dimenticato la password';
  LoginEmailForgot = 'Inserisci la mail del tuo account {#APPNAME#} per ricevere il link e creare una nuova passord';
  LoginTempPassword = 'Hai effettuato l\'accesso con una password temporanea. Creane una nuova';

  DaysLabel = 'Giorni';
  EmptyUoViewMessage = 'Non ci sono ancora Unità Organizzative!';
  EmptyTipologyViewMessage = 'Non ci sono ancora Stutture Metadati!';
  EmptyDossierMessage = 'Non ci sono ancora Fascicoli!';
  EmptyDocumentMessage = 'Non ci sono ancora Documenti!';
  EmptyProtocolsMessage = 'Non ci sono ancora Protocolli!';
  EmptyArchivedDocumentsMessage = 'Non ci sono Documenti archiviati!';
  EmptyUsersMessage = 'Non ci sono ancora Utenti!';
  Protocols = 'Protocolli';
  AllProtocols = 'Tutti i Protocolli';
  Input = 'Input';
  Output = 'Output';
  TableColumnNumber = 'Numero';
  TableColumnSource = 'Sorgente';
  ProtocolsArchived = 'Protocolli Archiviati';
  ProtocolsDeleted = 'Protocolli Eliminati';
  Settings = 'Impostazioni';
  UsersAndRoles = 'Utenti & Ruoli';
  TableColumnUserName = 'Nome utente';
  TableColumnUserEmail = 'Email';
  TableColumnInvitationStatus = 'Stato invito';
  TableColumnRole = 'Ruolo';
  AddUserTitle = 'Aggiungi Utente';
  AddUserDescription = 'Aggiungi il tuo utente';
  EditUserDescription = 'Modifica il tuo utente';
  Surname = 'Cognome';
  Email = 'Email';
  Owner = 'Owner';
  Worker = 'Worker';
  SendInvitation = 'Manda Invito';
  Resend = 'Rinvia';
  ResendInvitation = 'Rinvia Invito';
  Activated = 'Attivato';
  Deactivated = 'Disattivato';
  Pending = 'In attesa';
  Deactivate = 'Disattiva';
  DeactivatedUserMessage = 'Utente disattivato correttamente';
  AddedUserMessage = 'Utente aggiunto correttamente';
  UpdatedUserMessage = 'Utente modificato correttamente';
  AddToDossierDescription = 'Seleziona uno o più fascicoli a cui aggiungere il documento';
  AddToDossierDescriptions = 'Seleziona uno o più fascicoli a cui aggiungere i documenti selezionati';
  SendInvitationMessage = 'Invito inviato correttamente';
  PasswordSavedSuccessfully = 'Passoword salvata correttamente, effettua il login';
  DescriptionInvitationForm = 'Inserisci una password per il tuo account {#APPNAME#}';
  NewPassword = 'Nuova password';
  PasswordRequired = 'Password obbligatoria';
  PasswordSixCharacters = 'La password deve contenere almeno 6 caratteri';
  ConfirmPassword = 'Conferma Password';
  UsernameRequired = 'Username obbligatorio';
  UsernameSixCharacters = 'Il nome utente deve contenere almeno 3 caratteri';
  InvalidUsername = 'Username non valida';

  // #region workflow
  YourWorkflow = 'Il tuo Workflow';
  YourWorkflows = 'I tuoi Workflow';
  Workflows = 'Workflows';
  NewWorkflow = 'Nuovo Workflow';
  CreateWFButton = 'Crea il tuo primo Workflow';
  CreateWFTitle = 'Crea il tuo Workflow';
  CreateWFPoint1 = '1. Assegna un nome e un settore';
  CreateWFPoint2 = '2. Definisci i tuoi step';
  CreateWFPoint3 = '3. Aggiungi membri e assegna loro un ruolo.';
  SetYourWF = 'Imposta il tuo Workflow';
  WFNamePlaceholder = 'Nome Workflow';
  WFRelevantSectorPlaceholder = 'Settore di riferimento';
  WFStepNamePlaceholder = 'Nome Step';
  WFUserOrUoPlaceholder = 'Nome utente o UO';
  RequiredAdminMessage = 'Selezionare un Admin nell\'ultimo step del Workflow';
  WorkflowDurationPlaceholder = 'Durata workflow';
  Revisor = 'Revisore';
  Approver = 'Approvatore';
  Publish = 'Pubblica';
  Save = 'Salva';
  Discard = 'Annulla';
  WorkflowSavedMessage = 'è stato salvato e modificato correttamente.';
  WorkflowPublishedMessage = 'è stato salvato e pubblicato correttamente.';
  WorkflowSavedErrorMessage = 'non è stato salvato a causa di un errore.';
  DraftLabel = 'Bozza';
  WorkflowDeletedMessage = 'Workflow eliminato';
  // #endregion workflow

  // #region notification
  NotificationEmpty = 'Nessuna notifica';
  NotificationTitleNewInvitation = 'Nuovo utente';
  NotificationNewInvitationFirstPerson = 'Hai invitato :RecipientDisplayname a partecipare come :Role';
  NotificationNewInvitationThirdPerson = ':SenderDisplayname ha invitato :RecipientDisplayname a partecipare come :Role';

  NotificationTitleNewWorkflow = 'Nuova bozza di flusso';
  NotificationNewWorkflowFirstPerson = 'Hai creato con successo la bozza di flusso :WorkflowName';
  NotificationNewWorkflowThirdPerson = ':WorkflowName è stato creato con successo come bozza di flusso da :SenderDisplayname';

  NotificationTitleEditWorkflow = 'Modifica flusso';
  NotificationEditWorkflowFirstPerson = 'Hai modificato con successo in stato di bozza il flusso :WorkflowName';
  NotificationEditWorkflowThirdPerson = ':WorkflowName è stato modificato con successo in stato di bozza da :SenderDisplayname';

  NotificationTitlePublishWorkflow = 'Nuovo flusso pubblicato';
  NotificationPublishWorkflowFirstPerson = 'Hai pubblicato con successo il flusso :WorkflowName';
  NotificationPublishWorkflowThirdPerson = ':WorkflowName è stato pubblicato con successo da :SenderDisplayname';

  NotificationTitleNextWorkflow = 'Avanzamento documento';
  NotificationNextWorkflowFirstPerson = ':ObjectName è passato a te dallo step :PreviousStepName del flusso :WorkflowName';
  NotificationNextWorkflowThirdPerson = ':ObjectName è passato al/all\' :Role :RecipientDisplayname, dello step :NextStepName del flusso :WorkflowName';

  NotificationTitleBackWorkflow = 'Retrocessione documento';
  NotificationBackWorkflowFirstPerson = ':ObjectName è tornato al :Role :RecipientDisplayname dello step :NextStepName del flusso :WorkflowName';
  NotificationBackWorkflowThirdPerson = ':ObjectName è tornato a te, dallo step :PreviousStepName del flusso :WorkflowName';

  NotificationTitleArchivedDocument = 'Documento archiviato';
  NotificationArchivedDocumentFirstPerson = 'Hai archiviato con successo il documento :ObjectName';
  NotificationArchivedDocumentThirdPerson = ':ObjectName è stato archiviato con successo da :SenderDisplayname';

  NotificationTitleProtocolledDocument = 'Documento protocollato';
  NotificationProtocolledDocumentFirstPerson = 'Hai protocollato con successo il documento :ObjectName';
  NotificationProtocolledDocumentThirdPerson = ':ObjectName è stato protocollato con successo da :SenderDisplayname';

  NotificationTitleRejectedDocument = 'Documento rifiutato';
  NotificationRejectedDocumentFirstPerson = 'Hai rifiutato con successo il documento :ObjectName';
  NotificationRejectedDocumentThirdPerson = ':ObjectName è stato rifiutato con successo da :SenderDisplayname';

  NotificationTitleDeletedDocument = 'Documento eliminato';
  NotificationDeletedDocumentFirstPerson = 'Hai eliminato il documento :ObjectName';
  NotificationDeletedDocumentThirdPerson = ':ObjectName è stato eliminato da :SenderDisplayname';

  NotificationTitleNewDossier = 'Nuovo Fascicolo';
  NotificationNewDossierFirstPerson = 'Hai creato con successo il fascicolo :DossierName';
  NotificationNewDossierThirdPerson = 'Il fascicolo :DossierName è stato creato con successo da :SenderDisplayname';

  NotificationTitleUpdateDossier = 'Fascicolo modificato';
  NotificationUpdateDossierFirstPerson = 'Hai modificato con successo il fascicolo :DossierName';
  NotificationUpdateDossierThirdPerson = 'Il fascicolo :DossierName è stato modificato con successo da :SenderDisplayname';

  NotificationTitleArchivedDossier = 'Fascicolo archiviato';
  NotificationArchivedDossierFirstPerson = 'Hai archiviato con successo il fascicolo :DossierName';
  NotificationArchivedDossierThirdPerson = 'Il fascicolo :DossierName è stato archiviato con successo da :SenderDisplayname';

  NotificationTitleDeletedDossier = 'Fascicolo eliminato';
  NotificationDeletedDossierFirstPerson = 'Hai eliminato con successo il fascicolo :DossierName';
  NotificationDeletedDossierThirdPerson = 'Il fascicolo :DossierName è stato eliminato con successo da :SenderDisplayname';

  NotificationTitleAddedToDossier = 'Documento fascicolato';
  NotificationAddedToDossierFirstPerson = 'Hai fascicolato il documento :ObjectName nel fascicolo :DossierName';
  NotificationAddedToDossierThirdPerson = ':ObjectName è stato fascicolato in :DossierName da :SenderDisplayname';

  NotificationTitleNewUo = 'Unità Organizzativa';
  NotificationNewUoThirdPerson = ':UOName è stata creata con successo da :SenderDisplayname';

  NotificationTitleNewUoUser = 'Unità Organizzativa';
  NotificationNewUoUserThirdPerson = 'Sei stato inserito come :Role nell\'Unità Organizzativa :UOName';

  NotificationTitleDeleteUoUser = 'Unità Organizzativa';
  NotificationDeleteUoUserThirdPerson = 'Sei stato eliminato dall\'Unità Organizzativa :UOName';

  NotificationTitleUpdateUo = 'Unità Organizzativa';
  NotificationUpdateUoThirdPerson = ':UOName è stata modificata con successo da :SenderDisplayname';

  NotificationTitleDeletedUo = 'Unità Organizzativa';
  NotificationDeletedUoFirstPerson = 'La tua Unità Organizzativa :UOName è stata eliminata con successo da :SenderDisplayname';
  NotificationDeletedUoThirdPerson = 'L\'Unità Organizzativa :UOName è stata eliminata con successo da :SenderDisplayname';

  NotificationTitleEditUoUserRole = 'Unità Organizzativa';
  NotificationEditUoUserRoleThirdPerson = 'Il tuo ruolo è stato modificato in :Role nell\'Unità Organizzativa :UOName';

  NotificationTitleNewTipology = 'Nuova struttura metadati';
  NotificationNewTipologyFirstPerson = 'Hai creato con successo la struttura di metadati :TipologyName';
  NotificationNewTipologyThirdPerson = 'La Struttura di metadati :TipologyName è stata creata con successo da :SenderDisplayname ed è disponibile';

  NotificationTitleEditTipology = 'Struttura modificata';
  NotificationEditTipologyFirstPerson = 'Hai modificato la struttura di metadati :TipologyName';
  NotificationEditTipologyThirdPerson = 'La Struttura di metadati :TipologyName è stata modificata con successo da :SenderDisplayname ed è disponibile';

  NotificationTitleDeleteUoTipology = 'Struttura modificata';
  NotificationDeleteUoTipologyThirdPerson = 'La Struttura di metadati :TipologyName è stata modificata da :SenderDisplayname e non è più disponibile nell\'unità organizzativa :UoName';

  NotificationTitleDeleteTipology = 'Struttura eliminata';
  NotificationDeleteTipologyFirstPerson = 'Hai eliminato con successo la struttura di metadati :TipologyName';
  NotificationDeleteTipologyThirdPerson = 'La Struttura di metadati :TipologyName è stata eliminata con successo da :SenderDisplayname';

  NotificationTitleUserEdit = 'Utente modificato';
  NotificationUserEditFirstPerson = 'Hai modificato l\'utente :RecipientDisplayname';
  NotificationUserEditThirdPerson = ':SenderDisplayname ha modificato l\'utente :RecipientDisplayname';

  NotificationTitleResetPassword = 'Reset Password';
  NotificationResetPasswordFirstPerson = 'Hai reimpostato la password dell\'utente :RecipientDisplayname';
  NotificationResetPasswordThirdPerson = ':SenderDisplayname ha reimpostato la password dell\'utente :RecipientDisplayname';

  NotificationTitleUserDelete = 'Utente eliminato';
  NotificationUserDeleteFirstPerson = 'Hai eliminato l\'utente :RecipientDisplayname';
  NotificationUserDeleteThirdPerson = ':SenderDisplayname ha eliminato l\'utente :RecipientDisplayname';

  NotificationTitleEditFile = 'Modifica documento';
  NotificationEditFileFirstPerson = 'Hai modificato il documento :ObjectName';
  NotificationEditFileThirdPerson = ':SenderDisplayname ha modificato il documento :ObjectName';

  NotificationTitleCreateFile = 'File aggiunto';
  NotificationCreateFileFirstPerson = 'Hai aggiunto il documento :ObjectName';
  NotificationCreateFileThirdPerson = ':SenderDisplayname ha aggiunto il documento :ObjectName';

  NotificationNextDocumentFirstPerson = 'Hai mandato :Status il documento :ObjectName';
  NotificationNextDocumentThirdPerson = ':SenderDisplayname ha mandato :Status il documento :ObjectName';

  NotificationConfirmDocumentFirstPerson = 'Hai :Status il documento :ObjectName';
  NotificationConfirmDocumentThirdPerson = ':SenderDisplayname ha :Status il documento :ObjectName';

  NotificationTitleEditFolder = 'Modifica cartella';
  NotificationEditFolderFirstPerson = 'Hai modificato la cartella :ObjectName';
  NotificationEditFolderThirdPerson = ':SenderDisplayname ha modificato la cartella :ObjectName';

  NotificationTitleCreateFolder = 'Creazione cartella';
  NotificationCreateFolderFirstPerson = 'Hai aggiunto la cartella :ObjectName';
  NotificationCreateFolderThirdPerson = ':SenderDisplayname ha aggiunto la cartella :ObjectName';

  NotificationTitleDeletedFolder = 'Eliminazione cartella';
  NotificationDeletedFolderFirstPerson = 'Hai eliminato la cartella :ObjectName';
  NotificationDeletedFolderThirdPerson = ':SenderDisplayname ha eliminato la cartella :ObjectName';

  NotificationRestoreFolderFirstPerson = 'Hai ripristinato la cartella :ObjectName';
  NotificationRestoreDocumentFirstPerson = 'Hai ripristinato il documento :ObjectName';
  NotificationRestoreFolderThirdPerson = ':SenderDisplayname ha ripristinato la cartella :ObjectName';
  NotificationRestoreDocumentThirdPerson = ':SenderDisplayname ha ripristinato il documento :ObjectName';
  NotificationTitleRestoreFolder = 'Ripristino cartella';
  NotificationTitleRestoreDocument = 'Ripristino documento';

  NotificationTitleAddAttachment = 'Allegato caricato';
  NotificationAddAttachmentThirdPerson = ':SenderDisplayname ha allegato il file :AttachmentName al documento :ObjectName';
  NotificationAddAttachmentFirstPerson = 'Hai allegato il file :AttachmentName al documento :ObjectName';

  NotificationTitleDeleteAttachment = 'Allegato eliminato';
  NotificationDeleteAttachmentThirdPerson = ':SenderDisplayname ha eliminato l\'allegato :AttachmentName del documento :ObjectName';
  NotificationDeleteAttachmentFirstPerson = 'Hai eliminato l\'allegato :AttachmentName del documento :ObjectName';
  // #endregion notification

  Decrypt = 'Decripta';
  Encrypt = 'Cripta';
  EncryptDescription = 'Inserisci la password del tuo account per criptare il file';
  DecryptDescription = 'Inserisci la password del tuo account per decriptare il file';
  FileNotFound = 'File non trovato';
  FileDecryptedSuccess = 'File decriptato con successo';
  WrongPassword = 'Password errata';
  FileEncryptedSuccess = 'File criptato con successo';
  EditInfoDescription3 = 'Imposta la tua password di cifratura e conferma';
  CurrentPassword = 'Password corrente';
  TitleInfo4 = 'Cifratura';
  PasswordLength = 'La password deve essere lunga almeno di 6 caratteri';
  CryptographyDisabled = 'Crittografia non abilitata';
  constructor(appname: string = '', displayname: string = '') {
    const properties = Object.getOwnPropertyNames(this);
    for (const property of properties) {
      this[property] = this[property]?.replace('{#APPNAME#}', appname);
      this[property] = this[property]?.replace('{#DISPALYNAME#}', displayname);
    }
  }
}


