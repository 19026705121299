import { BaseEntity, BaseModel } from './BaseModel';

export class SignatureServiceModel extends BaseModel<SignatureServiceEntity, SignatureServiceDto, SignatureServiceFilter> {
    constructor() {
        super();
    }
}

export class SignatureServiceEntity extends BaseEntity {
    ServiceName: string;
    Description: string;
    ServiceType: string;
    ServiceUrl: string;
    ServiceUsername: string;
    ServicePassword: string;
    ServiceToken: string;
    ServiceActive: boolean;
    ServiceHasVerify: boolean;
    ServiceVerifyUrl: string;
    ServiceModuleRoot: string;
    ServiceModuleClass: string;
    ServiceModuleClassVerify: string;
    ServiceHasP7mPackaging: boolean;
    ServiceSupportedSignatures: string;
    ServiceRequireOtp: boolean;
    IsMultiSignature: boolean;
    IsMultipageSignature: boolean;
    SignatureMu: string;
    ExtraParams: string;
    constructor() {
        super();
        this.ServiceName = null;
        this.Description = null;
        this.ServiceType = null;
        this.ServiceUrl = null;
        this.ServiceUsername = null;
        this.ServicePassword = null;
        this.ServiceToken = null;
        this.ServiceActive = null;
        this.ServiceHasVerify = null;
        this.ServiceVerifyUrl = null;
        this.ServiceModuleRoot = null;
        this.ServiceModuleClass = null;
        this.ServiceModuleClassVerify = null;
        this.ServiceHasP7mPackaging = null;
        this.ServiceSupportedSignatures = null;
        this.ServiceRequireOtp = null;
        this.IsMultiSignature = null;
        this.IsMultipageSignature = null;
        this.SignatureMu = null;
        this.ExtraParams = null;
    }
}

export class SignatureServiceDto extends SignatureServiceEntity {

    constructor() {
        super();
    }
}

export class SignatureServiceFilter extends SignatureServiceDto {
}

