import { AccountModel } from '../models/AccountModel';
import { DataService } from '../../data.service';

export class AccountController {


    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: AccountModel): Promise<AccountModel> {
        let response = model;
        if (model && (model.Dto || model.Dtos.length > 0)) {
            response = await this.dataService.CreateOrUpdateAccount(model);
        }
        return response;
    }

    async Read(model: AccountModel): Promise<AccountModel> {
        const response = await this.dataService.ReadAccount(model);
        return response;
    }

    async Get(model: AccountModel): Promise<AccountModel> {
        const response = await this.dataService.GetAccount(model);
        return response;
    }

    async Load(model: AccountModel): Promise<AccountModel> {
        const response = await this.dataService.LoadAccount(model);
        return response;
    }


}
