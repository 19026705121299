import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OrganizationUnitDto } from '../../models/OrganizationUnitModel';

@Component({
  selector: 'app-multiselect-dropdown-tree',
  templateUrl: './multiselect-dropdown-tree.component.html',
  styleUrls: ['./multiselect-dropdown-tree.component.scss']
})
export class MultiselectDropdownTreeComponent implements OnInit, AfterViewInit {
  @Input() Index = 0;
  private dataSource: any[];

  get DataSource() {
    return this.dataSource;
  }

  @Input() set DataSource(value: any) {
    if (value?.length > 0) {
      for (const dto of value) {
        if (dto.Selected) {
          this.ClickCheckbox(dto);
        }
      }
      this.MapTextAndValueMatSelect();
    }
    this.dataSource = value;
  }


  @Input() UoDto: OrganizationUnitDto;
  @Input() Recursive = false;
  @Input() Placeholder: string;
  @Input() MatSelectRef: any;
  @Input() SelectedUoRef: any = [];
  @Output() ClosedDropdown = new EventEmitter();
  @ViewChild('MyMultiSelectTree') MySelect: any;
  InputValue: any;
  MatSelectValue: string;
  MatOptionTxt: string;
  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // set default placeholder
    this.SetDefaultPlaceholder();
    if (this.MySelect && !this.Recursive) {
      this.MatSelectRef = this.MySelect;
    }
  }

  SetDefaultPlaceholder() {
    this.MatSelectValue = 'default';
    this.MatOptionTxt = this.Placeholder;
  }

  FindUo(items: OrganizationUnitDto[]) {
    let result = null;
    for (const item of items) {
      if (item.Id === this.InputValue) {
        return item;
      }
      if (item.Childs.length > 0) {
        result = this.FindUo(item.Childs);
        if (result) {
          break;
        }
      }
    }
    return result;
  }

  GetIndex(dto: OrganizationUnitDto) {
    if (!dto.Parent) {
      return 1;
    } else {
      return this.GetDepth(this.Index);
    }
  }

  GetDepth(index: number) {
    return index + 1;
  }

  ClickCheckbox(dto: OrganizationUnitDto) {
    const dtoIndex = this.SelectedUoRef.findIndex(d => d.Id === dto.Id);
    if (dtoIndex >= 0) {
      // remove dto
      this.SelectedUoRef.splice(dtoIndex, 1);
    } else if (dtoIndex === -1) {
      // add dto
      this.SelectedUoRef.push(dto);
    }
  }

  MapTextAndValueMatSelect() {
    if (this.SelectedUoRef && this.SelectedUoRef.length > 0) {
      this.MatOptionTxt = this.SelectedUoRef.map(elem => {
        return elem.Name;
      }).join(', ');
      this.MatSelectValue = this.SelectedUoRef.map(elem => {
        return elem.Id;
      }).join(', ');
    } else {
      this.SetDefaultPlaceholder();
    }
  }

  CloseDropDownEvent() {
    this.MapTextAndValueMatSelect();
    this.ClosedDropdown.emit(this.SelectedUoRef);
  }
}
