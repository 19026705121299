import { TipologyUoModel } from '../models/TipologyUoModel';
import { DataService } from '../../data.service';


export class TipologyUoController {
    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: TipologyUoModel): Promise<TipologyUoModel> {
        let response = null;
        response = await this.dataService.CreateOrUpdateTipologyUo(model);
        if (response?.Performed && response.Dto) {
            response.Dto.Id = response.Entity?.Id;
        }
        return response;
    }

    async Delete(model: TipologyUoModel): Promise<TipologyUoModel> {
        const response = await this.dataService.DeleteTipologyUo(model);
        return response;
    }
}
