<div class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 11px;" src="../../../../../assets/icon/signature.svg">
                    <h2>{{navigatorService.Dictionary?.SignVerify}}</h2>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <div class="required-label">
        <span>{{navigatorService.Dictionary?.Document}}: </span>
        <span class="text">{{ObjectDto.Name + '.' +
            commonService.GetExtension(ObjectDto.PhysicalName) }}</span>
    </div>

    <mat-dialog-content class="default-scrollbar">
        <div class="mb-5" *ngIf="navigatorService.Loading === false">
            <div class="mb-3">
                <span class="title-description">Firme presenti: </span>
                <span class="text">{{Model?.Dtos?.length}}</span>
            </div>
            <div *ngFor="let dto of Model?.Dtos">
                <div class="signature-verify-card">
                    <div>
                        <span class="title-description">{{navigatorService.Dictionary?.SignatureOwner}}</span>
                        <div class="card-header mb-4">
                            <div class="signatory-name">
                                {{dto.givenName + ' ' + dto.surname}}
                            </div>
                        </div>
                        <!-- <img mat-card-avatar class="avatar"
                            src="data:image/png;base64,{{authenticationService?.CurrentUser?.Avatar}}">
                        <mat-card-title>{{navigatorService.Dictionary?.Signatory}}</mat-card-title>
                        <mat-card-subtitle></mat-card-subtitle> -->
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-4 mb-3">
                                <span
                                    class="title-description">{{navigatorService.Dictionary?.ValidityCertificate}}</span>
                                <div>
                                    <span class="text">{{dto.validitySince | date:'dd/MM/yyyy HH:mm'}}</span>
                                </div>
                                <div>
                                    <span class="text">{{dto.validityUntil | date:'dd/MM/yyyy HH:mm'}}</span>
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <span
                                    class="title-description">{{navigatorService.Dictionary?.CertifyingAuthority}}</span>
                                <div>
                                    <span class="text">{{dto.commonName}}</span>
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <span class="title-description">{{navigatorService.Dictionary?.DomainQualifier}}</span>
                                <div>
                                    <span class="text">{{dto.domainQualifier}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-4 mb-3">
                                <span class="title-description">{{navigatorService.Dictionary?.OrganizationName}}</span>
                                <div>
                                    <span class="text">{{dto.organizationUnitName}}</span>
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <span class="title-description">{{navigatorService.Dictionary?.AlternativeName}}</span>
                                <div>
                                    <span class="text">{{dto.issuerAltName}}</span>
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <span class="title-description">{{navigatorService.Dictionary?.SerialNumber}}</span>
                                <div>
                                    <span class="text">{{dto.certSerialNumber}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row " *ngIf="dto.signingTime">
                            <div class="col-4 mb-3">
                                <span class="title-description">{{navigatorService.Dictionary?.Timestamp}}</span>
                                <div>
                                    <span class="text">{{(dto.signingTime | date:'dd/MM/yyyy HH:mm') || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                               
                            </div>
                            <div class="col-4 mb-3">
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>


</div>