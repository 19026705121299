export class BaseModel<TEntity extends BaseEntity, TDto extends TEntity, TFilter extends TDto> {
    public Skip: number;
    public Take: number;
    public Count: number;
    public Search: any;
    public Filter!: TFilter;
    public Order!: BaseOrder;
    public Dtos: TDto[] | any;
    public Dto: TDto|null;
    public Entity!: TEntity;
    public Performed: boolean;
    public UpdateProperties!: Array<string>;
    public Message: string|null;
    public Code: number|null;

    constructor() {
        this.Skip = 0;
        this.Take = 20;
        this.Count = 0;
        this.Search = null;
        this.Performed = false;
        this.Dtos = new Array<TDto>();
        this.Dto = null;
        this.Message = null;
        this.Code = null;
    }

}

export class BaseEntity {
    public Id: any;
    constructor() {
        this.Id = 0;
    }
}

export class BaseOrder {
    public Direction!: string|null;
    public Name!: string|null;
}

