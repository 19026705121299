import { TipologyModel } from '../models/TipologyModel';
import { DataService } from '../../data.service';

export class TipologyController {
  constructor(private dataService: DataService) { }

  async CreateOrUpdate(model: TipologyModel): Promise<TipologyModel> {
    let response = null;
    if (model && model.Dto) {
      response = await this.dataService.CreateOrUpdateTipology(model);
      if (response?.Performed) {
        response.Dto.Id = response.Entity?.Id;
      }
    }
    return response;
  }

  async Get(model: TipologyModel): Promise<TipologyModel> {
    const response = await this.dataService.GetTipology(model);
    if (response) {
      model.Performed = response.Performed;
      if (response.Performed) {
        model.Dtos = response.Dtos;
      }
    }
    return model;
  }

  async Load(model: TipologyModel): Promise<TipologyModel> {
    const response = await this.dataService.LoadTipology(model);
    if (response) {
      model.Performed = response.Performed;
      if (response.Performed) {
        model.Dtos = response.Dtos;
      }
    }
    return model;
  }

  async Read(model: TipologyModel): Promise<TipologyModel> {
    const response = await this.dataService.ReadTipology(model);
    if (response) {
      model.Performed = response.Performed;
      if (response.Performed) {
        model.Dto = response.Dto;
      }
    }
    return model;
  }

  async Delete(model: TipologyModel): Promise<TipologyModel> {
    const response = await this.dataService.DeleteTipology(model);
    return response;
  }
}
