import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { ObjectDto, ObjectFilter, ObjectModel } from '../../../models/ObjectModel';
import { TipologyFilter, TipologyModel } from '../../../models/TipologyModel';
import { OrganizationUnitDto, OrganizationUnitFilter, OrganizationUnitModel } from '../../../models/OrganizationUnitModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { TipologyController } from '../../../controllers/TipologyController';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { CommonService } from '../../../../common.service';
import { AuthService } from '../../../../auth.service';
import { DocumentState, PageAction } from '../../../itdoc.configuration';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  Model: ObjectModel;
  ObjectModel: ObjectModel;
  TipologyModel: TipologyModel;
  OrganizationUnitModel: OrganizationUnitModel;

  Controller: ObjectController;
  TipologyController: TipologyController;
  OrganizationUnitController: OrganizationUnitController;

  Step = 1;
  Steps = 2;

  Pages: Array<number>;
  CurrentPage = 1;
  CurrentFolder: ObjectDto;
  DataSource: MatTableDataSource<ObjectDto>;


  constructor(
    private dataService: DataService, public navigatorService: NavigatorService,
    private authenticationService: AuthService, public commonService: CommonService, public datepipe: DatePipe,
    public dialogRef: MatDialogRef<FolderComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.Model = new ObjectModel();
    if (navigatorService.PageAction === PageAction.New) {
      this.Model.Dto = new ObjectDto();
      this.Model.Dto.Type = 'folder';
    } else if (navigatorService.PageAction === PageAction.Edit) {
      this.Model.Dto = data.Dto;
    }
    this.ObjectModel = new ObjectModel();
    this.TipologyModel = new TipologyModel();
    this.TipologyModel.Filter = new TipologyFilter();
    this.OrganizationUnitModel = new OrganizationUnitModel();
    this.OrganizationUnitModel.Dto = new OrganizationUnitDto();
    this.OrganizationUnitModel.Filter = new OrganizationUnitFilter();
    this.Controller = new ObjectController(dataService);
    this.TipologyController = new TipologyController(dataService);
    this.OrganizationUnitController = new OrganizationUnitController(dataService);

  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    // await this.navigatorService.Wait();
    this.OrganizationUnitModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    this.TipologyModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    this.TipologyModel = await this.TipologyController.Get(this.TipologyModel);
    this.OrganizationUnitModel = await this.OrganizationUnitController.Get(this.OrganizationUnitModel);
    this.navigatorService.StopLoading();
  }

  async SelectTipology($event) {
    if ($event) {
      this.navigatorService.StartLoading();
      this.Model.Dto.TipologyId = $event;
      this.navigatorService.StopLoading();
    }
  }

  SelectUo($event) {
    if ($event && $event > 0) {
      this.Model.Dto.UoId = $event;

    }
  }

  async Next() {
    const isValid = this.ValidationForm(this.Step);
    if (isValid) {
      this.Step += 1;
      if (this.Step === this.Steps) {
        this.navigatorService.StartLoading();
        await this.GetObjects(this.CurrentFolder);
        let model = new ObjectModel();
        model.Filter = new ObjectFilter();
        model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
        model.Filter.TipologyUoId = this.TipologyModel.Dtos.find(t => t.Id === this.Model.Dto.TipologyId)
          ?.TipologyUos.find(tuo => tuo.UoId === this.Model.Dto.UoId)?.Id;
        model.Filter.Type = 'folder';
        model.Filter.Deep = 0;
        model = await this.Controller.GetFirst(model);
        this.CurrentFolder = model?.Dto;
        this.navigatorService.StopLoading();
      }
    }
  }


  Back() {
    this.Step -= 1;
    if (this.Step <= 0) {
      this.Step = 1;
    }
  }

  ValidationForm(step: number) {
    if (step === 1) {
      if (!this.Model.Dto?.Name || !this.Model.Dto?.TipologyId || !this.Model.Dto?.UoId) {
        const message = this.navigatorService.Dictionary?.ValidatorMessage;
        this.navigatorService.ShowSnackBar(message);
        return false;
      }
    } else if (step === 2) {
      if (!this.Model.Dto?.Name || !this.Model.Dto?.TipologyId) {
        if (this.Model.Dto.Id <= 0 && !this.CurrentFolder) {
          const message = this.navigatorService.Dictionary?.FolderMessageValidator1;
          this.navigatorService.ShowSnackBar(message);
          return false;
        }
      }
    }
    return true;

  }

  async Save() {
    const isValid = this.ValidationForm(this.Steps);
    if (isValid) {
      this.navigatorService.StartLoading();
      if (this.Model.Dto.PhysicalName === null || this.Model.Dto?.Id <= 0) {
        this.Model.Dto.PhysicalName = this.Model.Dto.Name.toUpperCase().replace(/[^a-z0-9]/gi, '_');
        this.Model.Dto.Path = this.CurrentFolder.Path + '/' + this.Model.Dto.PhysicalName;
        this.Model.Dto.Parent = (this.CurrentFolder.Id && this.CurrentFolder.Id === 0 ? null : this.CurrentFolder.Id);
        this.Model.Dto.Deep = (this.Model.Dto.Parent ? this.CurrentFolder.Deep += 1 : 0);
        this.Model.Dto.Version = 0;
      }
      this.Model.Dto.TipologyUoId = this.TipologyModel.Dtos.find(t => t.Id === this.Model.Dto.TipologyId)
        ?.TipologyUos.find(tuo => tuo.UoId === this.Model.Dto.UoId)?.Id;
      this.Model.Dto.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
      this.Model = await this.Controller.CreateOrUpdate(this.Model);
      if (this.Model.Performed) {
        if (this.Model.Code && this.Model.Code === 409) {
          this.Model.Code = null;
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.FolderExist);
        } else {

          const message = this.navigatorService.PageAction === PageAction.New ? this.navigatorService.Dictionary?.ToCreateSuccessF
            : this.navigatorService.Dictionary?.ToEditSuccessF;
          this.navigatorService.ShowSnackBar(message, this.Model.Dto?.Name);
          this.dialogRef.close(this.Model.Dto);
        }
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.SaveError);
        this.navigatorService.StopLoading();
      }
    }
    this.navigatorService.StopLoading();
  }

  // #region Step 3
  async GetObjects(dto: ObjectDto = null, search: string = null, page = 1) {
    this.ObjectModel.Search = null;
    this.ObjectModel.Filter = new ObjectFilter();
    this.ObjectModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    this.ObjectModel.Filter.State = [DocumentState.PROGRESS, DocumentState.REVISION, DocumentState.REVISIONED,
    DocumentState.APPROVAL, DocumentState.EDIT, DocumentState.SIGNED, DocumentState.APPROVED, DocumentState.REJECTED, null];
    this.CurrentFolder = dto;
    search = this.SearchComponent?.GetSearchValue();
    if (!dto && search) {
      this.ObjectModel.Search = search;
      this.ObjectModel.Filter.TipologyUoId = this.TipologyModel.Dtos.find(t => t.Id === this.Model.Dto.TipologyId)
        ?.TipologyUos.find(tuo => tuo.UoId === this.Model.Dto.UoId)?.Id;
      this.ObjectModel.Filter.Type = 'folder';
    } else if (!dto) {
      this.ObjectModel.Filter.Deep = 1;
      this.ObjectModel.Filter.TipologyUoId = this.TipologyModel.Dtos.find(t => t.Id === this.Model.Dto.TipologyId)
        ?.TipologyUos.find(tuo => tuo.UoId === this.Model.Dto.UoId)?.Id;
    } else if (dto) {
      this.ObjectModel.Filter.Parent = dto.Id;
    }
    this.ObjectModel = await this.Controller.Get(this.ObjectModel);
    this.Paging(page);
  }

  async ObjectsClick(dto: ObjectDto) {
    if (dto.Type === 'folder') {
      this.navigatorService.StartLoading();
      await this.GetObjects(dto);
      this.navigatorService.StopLoading();
    }
  }

  SelectRow(dto: ObjectDto) {
    if (dto && dto?.Id > 0 && dto?.Type === 'folder') {
      this.CurrentFolder = dto;
    }
    this.ObjectModel.Dtos.map(o => o.Selected = false);
    dto.Selected = true;
  }


  async ReadObject(parent) {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    model.Filter.Id = parent;
    model = await this.Controller.Read(model);
    return model?.Dto ?? null;
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    await this.GetObjects(null, searchText);
    this.navigatorService.StopLoading();
  }


  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    let folder = this.CurrentFolder;
    if (this.CurrentFolder?.Deep > 1) {
      this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
      folder = this.CurrentFolder;
    } else {
      this.CurrentFolder = null;
      folder = null;
    }
    await this.GetObjects(folder, null, this.CurrentPage);
    this.navigatorService.StopLoading();
  }

  Paging(page: number = 1) {
    let dtos = new Array<ObjectDto>();
    if (this.ObjectModel.Count > 0) {
      const totalPages = Math.ceil(this.ObjectModel.Count / this.ObjectModel.Take);
      this.Pages = new Array<number>();
      for (let i = 0; i < totalPages; i++) {
        this.Pages.push(i + 1);
      }
      if (page > this.Pages.length) {
        page = this.Pages.length;
      }
      this.CurrentPage = page;

      this.ObjectModel.Skip = this.ObjectModel.Take * (page - 1);
      dtos = this.ObjectModel.Dtos.slice(this.ObjectModel.Skip, this.ObjectModel.Skip + this.ObjectModel.Take);
    } else {
      this.Pages = new Array<number>();
      this.CurrentPage = 1;
    }
    this.RefreshTable(dtos);
  }

  PagingBack() {
    const page = ((this.CurrentPage - 1) > 0 ? this.CurrentPage - 1 : 1);
    this.Paging(page);
  }

  PagingNext() {
    const page = ((this.CurrentPage + 1) <= this.Pages.length ? this.CurrentPage + 1 : this.Pages.length);
    this.Paging(page);
  }

  RefreshTable(dtos: Array<ObjectDto>) {
    for (const dto of dtos) {
      this.commonService.GetIcon(dto);
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }
  // #endregion

}


