import { BaseEntity, BaseModel } from './BaseModel';

export class EncryptionModel extends BaseModel<EncryptionEntity, EncryptionDto, EncryptionFilter> {

    constructor() {
        super();
    }
}

export class EncryptionEntity extends BaseEntity {
    Path: string;
    File: string;
    constructor() {
        super();
        this.Path = null;
        this.File = null;
    }
}

export class EncryptionDto extends EncryptionEntity {
    Password: string;
    SoftDecrypt: boolean;
    constructor() {
        super();
    }
}

export class EncryptionFilter extends EncryptionDto {
}
