import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IDialogComponent } from '../../../../Interface/itdoc.dialog.interface';
import { ActivityDto, ActivityFilter, ActivityModel } from '../../../../models/ActivityModel';
import { ActivityController } from '../../../../controllers/ActivityController';
import { WorkflowDataFilter, WorkflowDataModel } from '../../../../../workflow/models/WorkflowDataModel';
import { WorkflowDataController } from '../../../../../workflow/controllers/WorkflowDataController';
import { NavigatorService } from '../../../../../navigator.services';
import { CommonService } from '../../../../../common.service';
import { DataService } from '../../../../../data.service';
import { ObjectDto } from '../../../../models/ObjectModel';
import { Constants } from '../../../../itdoc.configuration';


@Component({
  selector: 'app-workflow-preview',
  templateUrl: './workflow-preview.component.html',
  styleUrls: ['./workflow-preview.component.scss']
})
export class WorkflowPreviewComponent implements OnInit, AfterViewInit, IDialogComponent<ActivityModel, ActivityController> {
  Model: ActivityModel;
  WorkflowDataModel: WorkflowDataModel;
  Controller: ActivityController;
  WorkflowDataController: WorkflowDataController;
  DataSource: MatTableDataSource<ActivityDto>;
  constructor(
    public navigatorService: NavigatorService, private dataService: DataService,public commonService: CommonService,
    public dialogRef: MatDialogRef<WorkflowPreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: ObjectDto) {
    this.Model = new ActivityModel();
    this.Model.Filter = new ActivityFilter();
    this.Model.Filter.App = Constants.AppId;
    this.Model.Filter.ObjectId = data.Id;
    this.Model.Filter.ObjectType = 'workflow';
    // this.Model.Filter.Type = [DocumentState.REVISION, DocumentState.APPROVAL, DocumentState.APPROVED, 'CREATE',
    // DocumentState.ARCHIVED, DocumentState.PROTOCOLLED, DocumentState.REJECTED, DocumentState.REVISIONED];
    this.Controller = new ActivityController(dataService);

    this.WorkflowDataModel = new WorkflowDataModel();
    this.WorkflowDataModel.Filter = new WorkflowDataFilter();
    this.WorkflowDataModel.Filter.Id = data.WorkflowData.Id;
    this.WorkflowDataController = new WorkflowDataController(dataService);
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.WorkflowDataModel = await this.WorkflowDataController.Read(this.WorkflowDataModel);
    if (this.WorkflowDataModel?.Performed) {
      this.Model = await this.Controller.Get(this.Model);
      if (this.Model?.Performed) {
        for (const dataStep of this.data.WorkflowData?.WorkflowDataSteps) {
          dataStep.Name = this.WorkflowDataModel.Dto?.WorkflowSteps.find(s => s.Id === dataStep.WorkflowStepId)?.Name;
        }
        for (const dto of this.Model.Dtos) {
          dto.ParamsDecode =  JSON.parse(dto.Params);
        }
        this.RefreshTable(this.Model?.Dtos);
      }
    }

  }

  RefreshTable(dtos: Array<ActivityDto>) {
    this.DataSource = new MatTableDataSource<ActivityDto>(dtos);
  }

  GetWidth() {
    const steps = this.data?.WorkflowData?.WorkflowDataSteps.length - 1;
    return (100 / steps) - 5 + '%';
  }
}
