import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class MimeTypeModel extends BaseModel<MimeTypeEntity, MimeTypeDto, MimeTypeFilter> {
    constructor() {
        super();
    }
}

export class MimeTypeEntity extends BaseEntity {
    Name?: string;
    MimeType?: string;
    Extension?: string;
    ChipIcon?: string;
    ChipBackgroundColor?: string;

    constructor() {
        super();
        this.Name = null;
        this.MimeType = null;
        this.Extension = null;
        this.ChipIcon = null;
        this.ChipBackgroundColor = null;
    }
}

export class MimeTypeDto extends MimeTypeEntity {
    constructor() {
        super();
    }
}

export class MimeTypeFilter extends MimeTypeDto {
}
