import { DataService } from '../data.service';
import { NotificationDto, NotificationModel } from '../models/NotificationModel';

export class NotificationController {

    constructor(private dataService: DataService) { }

    async NewWorkflow(workflowId: number, workflowName: string): Promise<NotificationModel> {
        let notificationModel = new NotificationModel();
        notificationModel.Dto = new NotificationDto();
        notificationModel.Dto.Id = workflowId;
        notificationModel.Dto.WorkflowName = workflowName;
        notificationModel = await this.dataService.NewWorkflow(notificationModel);
        return notificationModel;
    }

    async NewUo(uoId: number): Promise<NotificationModel> {
        let notificationModel = new NotificationModel();
        notificationModel.Dto = new NotificationDto();
        notificationModel.Dto.Id = uoId;
        notificationModel = await this.dataService.NewUo(notificationModel);
        return notificationModel;
    }

    async NewDossier(dossierId: number): Promise<NotificationModel> {
        let notificationModel = new NotificationModel();
        notificationModel.Dto = new NotificationDto();
        notificationModel.Dto.Id = dossierId;
        notificationModel = await this.dataService.NewDossier(notificationModel);
        return notificationModel;
    }
}
