import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocClassDto, DocClassModel } from '../../../models/DocClassModel';
import { DocFlyController } from '../../../controllers/DocFlyController';
import { NavigatorService } from '../../../../navigator.services';
import { CommonService } from '../../../../common.service';
import { DataService } from '../../../../data.service';


@Component({
  selector: 'app-uploadqueque',
  templateUrl: './uploadqueque.component.html',
  styleUrls: ['./uploadqueque.component.scss']
})
export class UploadquequeComponent implements OnInit {
  Model: DocClassModel;
  UploadInProgress = false;
  ChunkSize = 4194304;
  Controller: DocFlyController;
  ProgressUpload: number;
  FileUploading: string;
  Message: string;
  UploadCompleted: number;
  IsUploadFilesOnly: boolean;
  constructor(
    public navigatorService: NavigatorService, dataService: DataService, public commonService: CommonService,
    public dialogRef: MatDialogRef<UploadquequeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.Model = data.Model;
    this.Message = '';
    this.ProgressUpload = 0;
    this.UploadCompleted = 0;
    this.IsUploadFilesOnly = data.IsUploadFilesOnly ?? false;
    this.Controller = new DocFlyController(dataService);
  }

  ngOnInit(): void {
  }

  Load() {

  }

  Close() {
    if (this.UploadCompleted === 1) {
      this.dialogRef.close(true);
    } else if (this.UploadCompleted === 2) {
      this.dialogRef.close(false);
    } else {
      this.dialogRef.close(null);

    }
  }

  ExportTXTLog() {
    const content = this.Message.replace(/<br>/g, '\n');
    const blob = this.commonService.Base64toBlob(btoa(content), 'text/plain');
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = 'Log.txt';
    // start download
    element.click();
  }

  //#region upload
  Upload() {
    this.ProgressUpload = 0;
    this.Message = '';
    this.FileUploading = '';
    this.UploadInProgress = true;
    this.UploadFile(async () => {
      this.UploadInProgress = false;
    });
  }

  async UploadFile(callback) {
    const progresChunk = 100 / (this.Model.PdvModels.length + this.Model.Dto.Files.length);
    let progressTemp = 0;
    let error = false;
    if (!this.IsUploadFilesOnly) {
      if (this.Model && this.Model.PdvModels.length > 0) {
        // upload degli indici
        for (const pdv of this.Model.PdvModels) {
          const pdvName = pdv.multipart.find(q => q.name === 'filename').contents;
          this.FileUploading = this.navigatorService.Dictionary.Uploading.replace('{#filename#}',
            ' ' + this.navigatorService.Dictionary.Index
            + ': ' + pdvName);
          pdv.multipart.find(q => q.name === 'username').contents = this.Model.Dto.Username ? this.Model.Dto.Username : null;
          pdv.multipart.find(q => q.name === 'password').contents = this.Model.Dto.Password ? this.Model.Dto.Password : null;

          let model = new DocClassModel();
          model.PdvModels.push(pdv);
          model = await this.Controller.PdvSend(model);
          // model.Performed = true;
          if (!model.Performed) {
            error = true;
            this.Message += this.navigatorService.Dictionary.UploadError + ' ' + this.navigatorService.Dictionary.Index
              + ': ' + pdvName + '<br>';
            this.UploadCompleted = 2;
            this.UploadInProgress = false;
            break;
          }
          progressTemp = progresChunk;
          this.ProgressUpload += parseInt(progressTemp.toString(), 10);
        }
        if (!error) {        // upload dei file
          for (const file of this.Model.Dto.Files) {
            this.FileUploading = this.navigatorService.Dictionary.Upload + ' file: ' + file.File.Filename;
            let model = new DocClassModel();
            model.Dto = new DocClassDto();
            model.Dto.Owner = this.Model.Dto.Owner;
            model.Dto.Username = this.Model.Dto.Username;
            model.Dto.Password = this.Model.Dto.Password;
            model.Dto.PdaName = this.Model.Dto.PdaName;
            model.Dto.Files.push(file);
            model = await this.Controller.FilesSend(model);
            // model.Performed = false;
            progressTemp = progresChunk;
            this.ProgressUpload += parseInt(progressTemp.toString(), 10);
            if (!model.Performed) {
              if (model.Code === 404) {
                this.Message += this.navigatorService.Dictionary.FileNotFound + ' File: ' + file.File.Filename + '<br>';
              } else {
                this.Message += this.navigatorService.Dictionary.UploadError + ' File: ' + file.File.Filename + '<br>';
              }
            }
          }
        }
        if (this.ProgressUpload <= 100 && !error) {
          this.ProgressUpload = 100;
          this.UploadCompleted = 1;
        }
        this.UploadInProgress = false;
        this.FileUploading = this.navigatorService.Dictionary.UploadCompleted;
        if (callback) {
          callback();
        }
      }
    } else {
      for (const file of this.Model.Dtos) {
        this.FileUploading = this.navigatorService.Dictionary.Upload + ' file: ' + file.PhysicalName;
        let model = new DocClassModel();
        model.Dto = new DocClassDto();
        model.Dto.Owner = this.Model.Dto.Owner;
        model.Dto.Username = this.Model.Dto.Username;
        model.Dto.Password = this.Model.Dto.Password;
        model.Dto.PdaName = this.Model.Dto.PdaName;
        file["Filename"] = file.PhysicalName;
        file["FilePath"] = file.Path;
        model.Dto.Files.push({ File: file });

        model = await this.Controller.FilesSend(model);
        // model.Performed = false;
        progressTemp = progresChunk;
        this.ProgressUpload += parseInt(progressTemp.toString(), 10);
        if (!model.Performed) {
          if (model.Code === 404) {
            this.Message += this.navigatorService.Dictionary.FileNotFound + ' File: ' + file.PhysicalName + '<br>';
          } else {
            this.Message += this.navigatorService.Dictionary.UploadError + ' File: ' + file.PhysicalName + '<br>';
          }
        }
      }
      if (this.ProgressUpload <= 100 && !error) {
        this.ProgressUpload = 100;
        this.UploadCompleted = 1;
      }
      this.UploadInProgress = false;
      this.FileUploading = this.navigatorService.Dictionary.UploadCompleted;
      if (callback) {
        callback();
      }
    }
  }

  //#endregion
}
