import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class WorkflowUserModel extends BaseModel<WorkflowUserEntity, WorkflowUserDto, WorkflowUserFilter> {
    constructor() {
        super();
    }
}

export class WorkflowUserEntity extends BaseEntity {
    WorkflowStepId: number;
    AccountId: number;
    UoId: number;
    RoleId: number;
    constructor() {
        super();
        this.AccountId = null;
        this.UoId = null;
        this.RoleId = null;
    }
}

export class WorkflowUserDto extends WorkflowUserEntity {
    Avatar?: string;
    Name: string; // name user or Uo name
    Type: string; // user or UO
    constructor() {
        super();
        this.Avatar = null;
        this.Name = null;
        this.Type = null;
    }
}

export class WorkflowUserFilter extends WorkflowUserDto {
}
