import { BaseEntity, BaseModel } from './BaseModel';

export class GroupModel extends BaseModel<GroupEntity, GroupDto, GroupFilter> {

  constructor() {
    super();
    this.Dto = new GroupDto();
  }
}

export class GroupEntity extends BaseEntity {

  Name: string;
  Type: string;

  constructor() {
    super();
    this.Name = null;
    this.Type = null;

  }
}

export class GroupDto extends GroupEntity {
  constructor() {
    super();
  }
}

export class GroupFilter extends GroupDto {
}
