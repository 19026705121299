import { AccountDossierDto } from './AccountDossierModel';
import { BaseEntity, BaseModel } from "../../models/BaseModel";
import { OrganizationUnitEntity } from './OrganizationUnitModel';

export class DossierModel extends BaseModel<DossierEntity, DossierDto, DossierFilter> {
    constructor() {
        super();
        this.Take = 10;
    }
}

export class DossierEntity extends BaseEntity {
    Name: string|null;
    Key: string|null;
    Creator!: string|null; // 500
    CreationDate!: number|null;
    Modifier!: string|null; // 500
    ModificationDate!: number|null;
    Label!: string|null;
    UoId: number|null;
    State: any;
    constructor() {
        super();
        this.Name = null;
        this.Key = null;
        this.UoId = null;
    }
}

export class DossierDto extends DossierEntity {
    CreationDateString: string;
    ModificationDateString: string;
    Open: boolean;
    AccountId: number;
    Prefix: string;
    Checked: boolean;
    CreatorDisplayName: string;
    Uo: OrganizationUnitEntity;
    AccountDossiers: Array<AccountDossierDto>;
    Selected: boolean;
    constructor() {
        super();
        this.CreationDateString = null;
        this.ModificationDateString = null;
        this.Open = false;
        this.AccountId = null;
        this.Checked = false;
        this.Selected = false;
    }
}

export class DossierFilter extends DossierDto {
    DossierSelected: Array<DossierDto>;
    IdentifiedSelected: Array<DossierDto>;
    ResponsabileSelected: Array<AccountDossierDto>;
    OperatorDossier: Array<AccountDossierDto>;
    SearchUo: any;
}
