import { BaseEntity, BaseModel } from './BaseModel';

export class UserModel extends BaseModel<UserEntity, UserDto, UserFilter> {

    constructor() {
        super();
        this.Dto = new UserDto();
    }
}

export class UserEntity extends BaseEntity {
    Account: string;
    Nome: string;
    Email: string;
    Groups: Array<string>;
    Avatar: string;

    constructor() {
        super();
        this.Account = null;
        this.Nome = null;
        this.Email = null;
        this.Groups = new Array<string>();
        this.Avatar = null;
    }
}

export class UserDto extends UserEntity {
    constructor() {
        super();

    }
}

export class UserFilter extends UserDto {
    Enabled: boolean;
}



