<div class="itdoc-app-container-box">
    <div class="row m-0">
        <div class="col-md-11 p-0">
            <div class="title-header-container">
                <div class="title-container valign-icon-text">
                    <span class="material-icons back-icon" (click)="GoBack()">chevron_left</span>
                    <h2>{{DossierDto.Name}}</h2>
                </div>
            </div>
        </div>
        <div class="col-md-1 pt-4 text-center">
            <button mat-button class="it-doc-btn-primary-circle" #m="matMenuTrigger" [matMenuTriggerFor]="moreAction">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
                <button mat-menu-item *ngIf="DossierDto.State!=='CLOSE'"
                    (click)='Update()'>{{navigatorService.Dictionary?.Update}}</button>
                <button mat-menu-item *ngIf="DossierDto.State!=='CLOSE'"
                    (click)='Archives(DossierDto)'>{{navigatorService.Dictionary?.Archive}}</button>
                <button mat-menu-item (click)='Delete()'>{{navigatorService.Dictionary?.Delete}}</button>
            </mat-menu>
        </div>
    </div>

    <div class="content pb-5">
        <div class="row m-0">
            <div class="col-md-6 summary-box-title">{{navigatorService.Dictionary?.Name}}</div>
            <div class="col-md-6 summary-box-title">{{navigatorService.Dictionary?.Identifier}}</div>
        </div>
        <div class="row m-0">
            <div class="col-md-6">
                <div class="summary-box">{{DossierDto.Name}}</div>
            </div>
            <div class="col-md-6">
                <div class="summary-box">{{DossierDto.Key}}</div>
            </div>
        </div>
        <div class="mt-4"></div>
        <div class="row m-0">
            <div class="col-md-6 summary-box-title">{{navigatorService.Dictionary?.OrganizationalUnit}}</div>
            <div class="col-md-6 summary-box-title">{{navigatorService.Dictionary?.DossierLabelPlaceholder}}</div>
        </div>
        <div class="row m-0">
            <div class="col-md-6">
                <div class="summary-box">{{DossierDto.Uo?.Name}}</div>
            </div>
            <div class="col-md-6">
                <div class="summary-box">{{DossierDto.Label}}</div>
            </div>
        </div>
        <div class="mt-4"></div>
        <div class="row m-0">
            <div class="col-md-6 summary-box-title">Owner</div>
            <div class="col-md-6 summary-box-title">{{navigatorService.Dictionary?.LastEdit}}</div>
        </div>
        <div class="row m-0">
            <div class="col-md-6">
                <div class="summary-box">{{DossierDto.CreatorDisplayName}}</div>
            </div>
            <div class="col-md-6">
                <div class="summary-box">{{DossierDto.ModificationDateString}}</div>
            </div>
        </div>

    </div>
</div>

<!-- Documents section -->
<div class="itdoc-app-container-box">
    <div class="row m-0">
        <div class="col-md-12 p-0">
            <div class="title-header-container">
                <div class="title-container valign-icon-text">
                    <h2>{{navigatorService.Dictionary?.TitleDocumentsSectionDossierDetail}}</h2>
                </div>
                <div class="search-create-container">
                    <app-search #search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
                        [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
                        [HaveActions]="false" (OnEnterPress)="Search()" (OnClearBtnPress)="Search()">
                    </app-search>
                </div>
            </div>
        </div>
    </div>

    <div class="it-doc-table-container mt-3">
        <!-- <div class="row breadcrumb-lite" *ngIf="this.CurrentFolder">
            <div class="col-md-4">
                <button mat-icon-button (click)="Refresh()" [disabled]="!this.CurrentFolder">
                    <mat-icon class="icon-text-right"> keyboard_arrow_left</mat-icon>
                    <span *ngIf="CurrentFolder">{{navigatorService.Dictionary?.AllDocument}}
                    </span>
                </button>
            </div>
            <div class="col-md-4 text-center current-folder">
                <span>{{CurrentFolder?.Name}}</span>
            </div>
            <div class="col-md-4"></div>
        </div> -->
        <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">
            <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnName}}">
                <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnName}} </th>
                <td mat-cell *matCellDef="let element">
                    <span (click)="OpenObject(element);$event.stopPropagation();" class="clickable-icon"
                        title="{{element.Name + (element.Type==='file'?'.' + commonService.GetExtension(element.PhysicalName):'')}}">
                        <div class="d-flex">
                            <span class="icon-text-right"><img src="{{element.Icon}}"></span>
                            <div class="ellipsis-text">{{element.Name + (element.Type==='file'?'.' +
                                commonService.GetExtension(element.PhysicalName):'')}}</div>
                        </div>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnVersion}}">
                <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnVersion}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        {{element.Version && element.Version>0?'v.'+element.Version: ND}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnState}}">
                <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnState}} </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.Type==='file'" class="material-icons"
                        [ngClass]="'icon-circle-'+ element.State?.toLowerCase()">
                        circle</span>
                    <span>
                        {{commonService.GetDisplayState(navigatorService.Dictionary,element.State)}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="{{navigatorService.Dictionary?.Admin}}">
                <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.Admin}} </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.Admins?.length>0">
                        <img *ngIf="element.Admins?.length>1" src="../../../../assets/icon/info-icon.svg"
                            class="icon-action" [matMenuTriggerFor]="adminsMenu">
                        {{element.Admins[0].DisplayName}}
                    </span>
                    <mat-menu #adminsMenu="matMenu" class="more-action-menu">
                        <div class="admins-container">
                            <p class="admin-count">Admin(<strong>{{element.Admins?.length-1}}</strong>)</p>
                            <span *ngFor="let admin of element.Admins">
                                <span *ngIf="admin.Username!==element.Admins[0].Username">{{admin.DisplayName}}</span>
                            </span>
                        </div>
                    </mat-menu>
                </td>
            </ng-container>

            <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnTeam}}">
                <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnTeam}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        <ng-container *ngIf="element?.Team?.length > 0 && element?.Team?.length <= 3">
                            <span *ngFor="let user of element?.Team; let i=index">
                                <img *ngIf="user.Avatar" class="profile-picture"
                                    [ngClass]="i>0?'profile-picture-over':''" matTooltip="{{user.Name}}"
                                    [ngStyle]="{'left.px': (i>0 && i<=3 && (i>0 && element?.Team[i-1].Avatar)?i*(-8):0)}"
                                    src="data:image/png;base64,{{user.Avatar}}"></span>
                        </ng-container>
                        <ng-container *ngIf="element?.Team?.length > 0 && element?.Team?.length > 3">
                            <span *ngFor="let user of element?.Team; let i=index;">
                                <img *ngIf="i<=3 && user.Avatar" class="profile-picture"
                                    [ngStyle]="{'left.px': (i>0 && i<=3  && (i>0 && element?.Team[i-1].Avatar)?i*(-8):0)}"
                                    matTooltip="{{user.Name}}" src="data:image/png;base64,{{user.Avatar}}">
                            </span>
                            <div *ngIf="(element?.Team?.length - 4)>0" class="profile-picture-circle"
                                [ngStyle]="{'left.px': 4*(-8)}">
                                +{{element?.Team?.length - 4}}</div>
                        </ng-container>
                        <ng-container *ngIf="!element?.Team || element?.Team?.length === 0">-</ng-container>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUpdate}}">
                <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnUpdate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.Deep>=0">
                        {{element.ModificationDateString?.substring(0,10)}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="more-action">
                <th mat-header-cell *matHeaderCellDef class="icon-column">
                    <img *ngIf="DossierDto.State!=='CLOSE'" src="../../../../../assets/icon/more-vert.svg">
                </th>
                <td mat-cell *matCellDef="let element">
                    <img #m="matMenuTrigger" *ngIf="DossierDto.State!=='CLOSE'" [matMenuTriggerFor]="moreAction"
                        class="clickable-icon" src="../../../../../assets/icon/more-vert.svg"
                        [ngClass]="{'open-menu-trigger-color': m.menuOpen}">
                    <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
                        <button mat-menu-item
                            (click)="RemoveObjectFromDossier(element)">{{navigatorService.Dictionary?.RemoveFromDossier}}</button>
                        <button *ngIf="!element.Crypt" mat-menu-item
                            (click)="Cryptography(element)">{{navigatorService.Dictionary?.Encrypt}}</button>
                        <button *ngIf="element.Crypt" mat-menu-item
                            (click)="Cryptography(element)">{{navigatorService.Dictionary?.Decrypt}}</button>
                    </mat-menu>
                </td>
            </ng-container>

            <ng-container matColumnDef="filter">
                <th mat-header-cell *matHeaderCellDef class="icon-column">
                    <span class="filter-badge" [matBadgeHidden]="FilterCount === 0" matBadge="{{FilterCount}}">
                        <img *ngIf="DossierDto.State!=='CLOSE'" class="clickable-icon" (click)="OpenFiltersDialog()"
                            src="../../../../../assets/icon/filter-icon.svg">
                    </span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <img *ngIf="element.Type==='folder'" (click)="OpenObject(element);$event.stopPropagation();"
                        class="clickable-icon" src="../../../../../assets/icon/forward.svg">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="[
                navigatorService.Dictionary?.TableColumnName,
                navigatorService.Dictionary?.TableColumnVersion,
                navigatorService.Dictionary?.TableColumnState,
                navigatorService.Dictionary?.Admin,
                navigatorService.Dictionary?.TableColumnTeam,
                navigatorService.Dictionary?.TableColumnUpdate,
                'more-action',
                'filter']">
            </tr>
            <tr mat-row (click)="SelectRow(row)" [ngClass]="{'selected':row.Selected}" *matRowDef="let row;  columns: [
                 navigatorService.Dictionary?.TableColumnName,
                 navigatorService.Dictionary?.TableColumnVersion,
                 navigatorService.Dictionary?.TableColumnState,
                 navigatorService.Dictionary?.Admin,
                 navigatorService.Dictionary?.TableColumnTeam,
                 navigatorService.Dictionary?.TableColumnUpdate,
                 'more-action',
                'filter']">
            </tr>
        </table>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
        </div>
    </div>
</div>
