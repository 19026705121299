import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent<TDto> implements OnInit {
  @ViewChild('uploadFile') fileUpload: ElementRef;
  @Input() Placeholder = '';
  @Input() ButtonText = 'Carica';
  @Input() Required: false;
  @Input() Property: string;
  @Output() OnChange: EventEmitter<any> = new EventEmitter();
  @Output() Open: EventEmitter<any> = new EventEmitter();

  private dataValue: TDto = null;
  get DataValue() {
    if (this.dataValue && this.dataValue[this.Property]) {
      this.Selected = true;
    }
    return this.dataValue;
  }

  @Input() set DataValue(value: TDto) {
    if (value && value[this.Property]) {
      this.Selected = true;
    }
    this.dataValue = value;
  }
  Selected = false;
  constructor() {

  }

  ngOnInit() {

  }

  FileSelected() {
    const fileUpload = this.fileUpload.nativeElement;
    const self = this;

    fileUpload.onchange = () => {
      if (fileUpload.files?.length > 0) {
        const file = fileUpload.files[0];
        const isValid = this.fileUpload.nativeElement.accept?.includes(file.type);
        if (isValid) {
          this.Selected = true;
          this.OnChange.emit(file);
        } else {
          this.Selected = false;
          this.OnChange.emit(null);
        }
      }
      // fileUpload.files.forEach(file => {
      //   this.Selected = true;
      //   this.OnChange.emit(file);

      // });
    };
    fileUpload.click();
  }

  OpenFile() {
    if (this.Open && this.dataValue['Id'] > 0) {
      this.Open.emit();
    }
  }
}
