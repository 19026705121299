import { OrganizationUnitModel } from '../models/OrganizationUnitModel';
import { DataService } from '../../data.service';

export class OrganizationUnitController {
    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: OrganizationUnitModel): Promise<OrganizationUnitModel> {
        let response = model;
        if (model && model.Dto) {
            response = await this.dataService.CreateOrUpdateOrganizationalUnit(model);
            if (response && response.Performed) {
                response.Dto.Id = response.Entity?.Id;
            }
        }
        return response;
    }

    async Get(model: OrganizationUnitModel): Promise<OrganizationUnitModel> {
        return await this.dataService.GetOrganizationalUnit(model);
    }

    async Read(model: OrganizationUnitModel): Promise<OrganizationUnitModel> {
        return await this.dataService.ReadOrganizationalUnit(model);
    }

    async GetHierarchy(model: OrganizationUnitModel): Promise<OrganizationUnitModel> {
        return await this.dataService.GetHierarchyOrganizationalUnit(model);
    }


    async Delele(model: OrganizationUnitModel): Promise<OrganizationUnitModel> {
        const response = await this.dataService.DeleteOrganizationUnit(model);
        return response;
    }
}
