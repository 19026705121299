<div class="itdoc-page ">
  <div class="row">
    <div class="col-md-11">
      <h2>{{data.Title}}</h2>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>
  <div>
    <mat-dialog-content>
      <div class="form">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="form-field">
              <span class="input-text" [innerHTML]="data.Message"></span>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>
