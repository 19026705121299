import { DataService } from '../../data.service';
import { StatisticsModel } from '../models/StatisticsModel';

export class StatisticsController {


    constructor(private dataService: DataService) { }


    async Read(model: StatisticsModel): Promise<StatisticsModel> {
        const response = await this.dataService.ReadStatistics(model);
        return response;
    }

    async CreateOrUpdate(model: StatisticsModel): Promise<StatisticsModel> {
        const response = await this.dataService.CreateOrUpdateStatistics(model);
        return response;
    }


}
