<div class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <h2>{{data.Title}}</h2>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>
    <div class="row form">
        <div class="row">
            <div class="col-md-3">
                <app-doughnut-chart *ngIf="data?.RequestType === 'YOURREQUEST'" [IsEmpty]="false" [Width]="200"
                    [DatasetModel]="data?.DataSetModel">
                </app-doughnut-chart>
                <app-doughnut-chart *ngIf="data?.RequestType === 'YOURDOCS'" [IsEmpty]="false" [Width]="200"
                    [DataModel]="data?.DataSetModel">
                </app-doughnut-chart>
                <div class="d-flex tiny-scrollbar" *ngIf="data?.RequestType === 'YOURREQUEST'">
                    <div class="grid">
                        <div class="grid-container">
                            <ng-container
                                *ngFor="let data of data?.DataSetModel; let i = index;let isFirst = first;let isLast = last">
                                <ng-container *ngIf="data.Percentage>0">

                                    <div class="grid-column">
                                        <div class="legend-modal-container"
                                            [ngStyle]="{'margin-right':!isLast?'20px':'0'}">
                                            <span class="material-icons"
                                                [ngClass]="'icon-circle-'+ data?.Status.toLowerCase()">circle</span>
                                            <span class="text-legend">{{data?.Name}}</span>
                                        </div>
                                    </div>

                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="d-flex tiny-scrollbar" *ngIf="data?.RequestType === 'YOURDOCS'">
                    <div class="legend-modal-container" style="margin-right: 20px;padding-left: 40px;">
                        <span class="material-icons" [ngClass]="'icon-circle-intime'">circle</span>
                        <span class="text-legend">{{data?.PercentageInLate}} {{navigatorService.Dictionary?.InTime}}
                        </span>
                    </div>
                    <div class="legend-modal-container ">
                        <span class="material-icons" [ngClass]="'icon-circle-inlate'">circle</span>
                        <span class="text-legend">{{data?.PercentageInLate}}
                            {{navigatorService.Dictionary?.InLate}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-12 col-sm-12 ">
                        <app-search #search [Placeholder]="navigatorService.Dictionary?.FolderSearchPlaceholder"
                            [FilterCount]="FilterCount" [HaveFilters]="true" [HaveActions]="false" [ModalStyle]="true"
                            (OnFiltersBtnPress)="OpenFiltersDialog()" (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)">
                        </app-search>
                    </div>
                </div>
                <div class="it-doc-table-container">
                    <table mat-table [dataSource]="DataSource" matSort>
                        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnName}}">
                            <th mat-header-cell *matHeaderCellDef>
                                {{navigatorService.Dictionary?.TableColumnName}} </th>
                            <td mat-cell *matCellDef="let element">
                                <span (click)="OpenObject(element);$event.stopPropagation();" class="clickable-icon"
                                    title="{{element.Name + (element.Type==='file'?'.' +
                                commonService.GetExtension(element.PhysicalName):'')}}">
                                    <div class="d-flex">
                                        <span class="icon-text-right">
                                            <span *ngIf="!element.IsLate" class="material-icons"
                                                [ngClass]="'icon-circle-intime-overicon'">circle</span>
                                            <span *ngIf="element.IsLate" class="material-icons"
                                                [ngClass]="'icon-circle-inlate-overicon'">circle</span>
                                            <img src="{{element.Icon}}"></span>
                                        <div class="ellipsis-text">{{element.Name + (element.Type==='file'?'.' +
                                            commonService.GetExtension(element.PhysicalName):'')}}</div>
                                    </div>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnVersion}}">
                            <th mat-header-cell *matHeaderCellDef>
                                {{navigatorService.Dictionary?.TableColumnVersion}} </th>
                            <td mat-cell *matCellDef="let element">
                                <span>
                                    {{element.Version && element.Version>0?'v.'+ element.Version: ND}}
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnState}}">
                            <th mat-header-cell *matHeaderCellDef>
                                {{navigatorService.Dictionary?.TableColumnState}} </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container *ngIf="element?.State">
                                    <div id="span-preview">
                                        <span *ngIf="element.Type==='file'" class="material-icons"
                                            [ngClass]="'icon-circle-'+ element.State?.toLowerCase()">
                                            circle</span>
                                        {{commonService.GetDisplayState(navigatorService.Dictionary,element.State)}}
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!element?.State">-</ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUpdate}}">
                            <th mat-header-cell *matHeaderCellDef>
                                {{navigatorService.Dictionary?.TableColumnUpdate}} </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.Deep>=0">
                                    {{element.ModificationDateString}}
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="redirect">
                            <th mat-header-cell *matHeaderCellDef style="width: 50px;">
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button (click)="Redirect(element)">
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="[
                            navigatorService.Dictionary?.TableColumnName,
                            navigatorService.Dictionary?.TableColumnVersion,
                            navigatorService.Dictionary?.TableColumnState,
                            navigatorService.Dictionary?.TableColumnUpdate,
                            'redirect']">
                        </tr>
                        <tr mat-row (click)="SelectRow(row)" [ngClass]="{'selected':row.Selected}" *matRowDef="let row;  columns: [
                             navigatorService.Dictionary?.TableColumnName,
                             navigatorService.Dictionary?.TableColumnVersion,
                             navigatorService.Dictionary?.TableColumnState,
                             navigatorService.Dictionary?.TableColumnUpdate,
                             'redirect']" style="position: relative;">
                        </tr>

                    </table>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 ">
                      <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
