import { HttpHeaders } from '@angular/common/http';
import { DictionaryInterface } from '../dictionary/dictionary.interface';
import { AccountDto } from './models/AccountModel';
import { ObjectDto } from './models/ObjectModel';
import { DocumentComponent } from './pages/dialog-menu/document/document.component';
import { FolderComponent } from './pages/dialog-menu/folder/folder.component';


export const HttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

export enum Constants {
  AppId = 'itdoc',
  ND = '-',
  DossierIdPrefix = 'DOS'
}

export enum DossierRole {
  Responsible = 'Responsible',
  Operator = 'Operator'
}

export enum DossierState {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE'
}
export enum WFUserType {
  UO = 'UO',
  USER = 'USER'
}

export enum AccountRole {
  USER = 'User',
  REVISOR = 'Revisor',
  APPROVER = 'Approver',
  ADMIN = 'Admin'
}

export enum InvitationState {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DEACTIVE = 'DEACTIVE'
}

export enum PDAStatus {
  APERTO = 'aperto',
  INFIRMA = 'infirma',
  CHIUSO = 'chiuso',
  DACANCELLARE = 'daCancellare',
  CONSERVATO = 'conservato',
  ERRORE = 'errore'

}



export enum Controller {
  Role = 'role',
  Account = 'account',
  Object = 'object',
  Tipology = 'tipology',
  MimeType = 'mimetype',
  Template = 'template',
  Fields = 'field',
  User = 'user',
  Uo = 'uo',
  MetadataTipology = 'metadatatipology',
  MetadataTipologyValue = 'metadatavalue',
  Permission = 'permission',
  FileManager = 'filemanager',
  Settings = 'config',
  Dossier = 'dossier',
  AccountDossier = 'accountdossier',
  ObjectDossier = 'objectdossier',
  Document = 'document',
  UsersGroupsMails = 'groups/mails',
  Share = 'share',
  OnlyOffice = 'onlyoffice',
  AccountRole = 'accountrole',
  TipologyUo = 'tipologyuo',
  Activity = 'activity',
  Favourites = 'favourite',
  UserGroup = 'usergroup',
  Workflow = 'workflow',
  WorkflowStep = 'workflowstep',
  WorkflowUser = 'workflowuser',
  WorkflowData = 'workflowdata',
  Group = 'group',
  Invitation = 'invitation',
  Notification = 'notification',
  UserApps = 'userapps',
  ResetPassword = 'resetpassword',
  Statistics = 'statistics',
  SignatureAccount = 'signatureaccount',
  SignatureService = 'signatureservice',
  Signature = 'signature',
  Attachment = 'attachment',
  File = 'file',
  Conservation = 'conservation',
  DocFly = 'docfly',
}

export enum PageAction {
  None = 'None',
  New = 'New',
  Edit = 'Edit',
}
export enum ObjectType {
  FOLDER = 'FOLDER',
  FILE = 'FILE',
}

export enum ModalRequest {
  YOURREQUEST = 'YOURREQUEST',
  YOURDOCS = 'YOURDOCS',
}








export enum Action {
  Create = 'create',
  CreateOrUpdate = 'createorupdate',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  Count = 'count',
  Load = 'load',
  Get = 'get',
  Search = 'search',
  Upload = 'upload',
  Download = 'download',
  CreateFromTemplate = 'write',
  ScanToPdf = 'scantopdf',
  Set = 'set',
  Open = 'open',
  Run = 'run',
  IsAdmin = 'isadmin',
  Protocol = 'protocol',
  GetConfigKey = 'getconfigkey',
  Send = 'send',
  Accept = 'accept',
  Reset = 'reset',
  LdapSearch = 'ldapsearch',
  Convert = 'convert',
  Sign = 'sign',
  Decrypt = 'decrypt',
  Encrypt = 'encrypt',
  ModelGet = 'model/get',
  ClassGet = 'class/get',
  GetSystemInfo = 'getsysteminfo',
  Login = 'signin',
  GetRepositoryInfo = 'getrepositoryinfo',
  DownloadPDD = 'distribute',
  PdvSend = 'pdv/send',
  PdaDelete = 'pda/delete',
  PdaLog = 'pda/error',
  PdvCreate = 'pdv/create',
  FilesSend = 'files/send',
  CheckAutologin = 'autosignin/check',
  UploadChunk = 'uploadchunk'
}

export enum Direction {
  ASC = 'asc',
  DESC = 'desc'
}

export enum GenericDialogAction {
  CONFIRM = 'Confirm',
  CANCEL = 'Cancel'
}

export enum WorkflowState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export enum DocumentState {
  PROGRESS = 'PROGRESS',
  APPROVAL = 'APPROVAL',
  APPROVED = 'APPROVED',
  REVISION = 'REVISION',
  REVISIONED = 'REVISIONED',
  ARCHIVED = 'ARCHIVED',
  REJECTED = 'REJECTED',
  SIGNED = 'SIGNED',
  START = 'START',
  EDIT = 'EDIT',
  PROTOCOLLED = 'PROTOCOLLED',
  // da eliminare??
  WORKING = 'In lavorazione',
  CONSERVATION = 'In conservazione',
}

export enum DocumentStateFilter {
  PROGRESS = 'PROGRESS',
  APPROVAL = 'APPROVAL',
  APPROVED = 'APPROVED',
  REVISION = 'REVISION',
  REVISIONED = 'REVISIONED',
  ARCHIVED = 'ARCHIVED',
  REJECTED = 'REJECTED',
  EDIT = 'EDIT',
  PROTOCOLLED = 'PROTOCOLLED',
}
































export enum Pages {
  None = '#',
  Login = 'login',
  Dashboard = 'dashboard',
  ViewWorkflow = 'workflow/view',
  ViewArchive = 'archive/view',
  ViewDossiers = 'dossiers/view',
  ViewProtocols = 'protocols/view',
  ViewArchivedProtocols = '',
  ViewDeletedProtocols = '',
  Dossiers = 'dossiers',
  ViewDossiersArchived = 'dossier-archive/view',
  Documents = 'documents',
  ViewUO = 'uo/view',
  UO = 'uo/new',
  Activities = 'activities',
  ViewTipology = 'tipology/view',
  Tipology = 'tipology',
  FilesDelete = 'trashed/view',
  Signature = 'signature',
  SignatureAuthentication = 'signature-authentication',
  PadesSignature = 'pades-signature',
  OnlyOffice = 'onlyoffice',
  Settings = 'settings',
  Invitation = 'invitation',
  InvitationLdap = 'invitationldap',
  ResetPassword = 'resetpassword',
  UoDelete = 'trashed/uo',
  UserProfile = 'profile',
  Conservation = 'conservation',
  ViewPda = 'conservation/pda/view',
  Pda = 'conservation/pda/new',
  DetailPda = 'conservation/pda/detail'
}

export enum DisplayMode {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

export const DATE_FORMATS2 = {
  parse: {
    dateInput: 'DD/MM/YYYY HH:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMM YYYY HH:mm',
    dateA11yLabel: 'DD/MM/YYYY HH:mm',
    monthYearA11yLabel: 'MM YYYY HH:mm',
  },
};

export enum DateFormat {
  ddMMyyyy = 'dd\'\/\'MM\'\/\'yyyy',
  ddMMyyyyHHmm = 'dd\'\/\'MM\'\/\'yyyy HH:mm',
  yyyy_MM_dd = 'yyyy\'-\'MM\'\-\'dd',
  Time = 'HH:mm',
  ddMMyyyyHHmmss = 'ddMMyyyyHHmmss',

}

export enum DocumentFormat {
  DOCX = 'docx',
  XLSX = 'xlsx',
  PPT = 'pptx',
  TXT = 'txt',
  PDF = 'pdf'
}

export enum DashboardCardType {
  Chart = 'Chart',
  ProgressBar = 'ProgressBar',
  MinimalCounter = 'MinimalCounter'
}

export enum Language {
  IT = 'it',
  EN = 'en'
}
export class ApplicationSidebar {
  ApplicationMenu: Array<any>;
  constructor(dictionary: DictionaryInterface, user: AccountDto) {
    this.ApplicationMenu = [
      {
        Name: 'dashboard',
        Title: 'Dashboard',
        Label: 'Dashboard',
        Link: Pages.Dashboard,
        Icon: 'home',
        Selected: false,
        Visible: true,
        Expanded: false,
      },
      {
        Name: 'uo_view',
        Title: dictionary.OrganizationalUnits,
        Label: dictionary.OrganizationalUnits,
        Link: Pages.ViewUO,
        Icon: 'group',
        Selected: false,
        Visible: user?.Id > 0 || user?.IsAdmin,
        Expanded: false,
        Childs: [
          {
            Name: 'uo_delete',
            Title: dictionary.TrashedUo,
            Label: dictionary.TrashedUo,
            Link: Pages.UoDelete,
            Icon: 'trash',
            Selected: false,
            Visible: user?.IsAdmin,
            Expanded: false
          }
        ]
      },
      {
        Name: 'tipology_view',
        Title: dictionary.Tipology,
        Label: dictionary.Tipology,
        Link: Pages.ViewTipology,
        Icon: 'microchip',
        Selected: false,
        Visible: user?.Id > 0 || user?.IsAdmin,
        Expanded: false,
      },
      {
        Name: 'workflow_view',
        Title: dictionary.YourWorkflows,
        Label: dictionary.YourWorkflows,
        Link: Pages.ViewWorkflow,
        Icon: 'goal',
        Selected: false,
        Visible: user?.Id > 0 || user?.IsAdmin,
        Expanded: false,
      },
      {
        Name: 'dossiers',
        Title: dictionary.Dossiers,
        Label: dictionary.Dossiers,
        Link: Pages.ViewDossiers,
        Icon: 'bookmark',
        Selected: false,
        Visible: user?.Id > 0,
        Expanded: false,
        Childs: [
          {
            Name: 'dossier_close',
            Title: dictionary.DossierClose,
            Label: dictionary.DossierClose,
            Link: Pages.ViewDossiersArchived,
            Icon: 'archived',
            Selected: false,
            Visible: user?.Id > 0 || user?.IsAdmin,
            Expanded: false
          }
        ]
      },
      {
        Name: 'protocols_view',
        Title: dictionary.Protocols,
        Label: dictionary.Protocols,
        Link: Pages.ViewProtocols,
        Icon: 'protocols',
        Selected: false,
        Visible: user?.Id > 0,
        Expanded: false,
      },
      {
        Name: 'documents',
        Title: dictionary.Documents,
        Label: dictionary.Documents,
        Link: Pages.Documents,
        Icon: 'note',
        Selected: false,
        Visible: user?.Id > 0,
        Expanded: false,
        Childs: [
          {
            Name: 'files_archived',
            Title: dictionary.FilesArchived,
            Label: dictionary.FilesArchived,
            Link: Pages.ViewArchive,
            Icon: 'archived',
            Selected: false,
            Visible: user?.Id > 0 || user?.IsAdmin,
            Expanded: false
          },
          {
            Name: 'files_delete',
            Title: dictionary.FilesDelete,
            Label: dictionary.FilesDelete,
            Link: Pages.FilesDelete,
            Icon: 'trash',
            Selected: false,
            Visible: user?.Id > 0 || user?.IsAdmin,
            Expanded: false
          }
        ]
      },
      {
        Name: 'conservation',
        Title: dictionary.Conservation,
        Label: dictionary.Conservation,
        Link: Pages.Conservation,
        Icon: 'work',
        Selected: false,
        Visible: true,
        Expanded: false,
        Childs: [
          {
            Name: 'view_pda',
            Title: dictionary.ViewPda,
            Label: dictionary.ViewPda,
            Link: Pages.ViewPda,
            Icon: 'work',
            Selected: false,
            Visible: false,
            Expanded: false
          },
          {
            Name: 'new_pda',
            Title: dictionary.NewPda,
            Label: dictionary.NewPda,
            Link: Pages.Pda,
            Icon: 'work',
            Selected: false,
            Visible: false,
            Expanded: false
          },
          {
            Name: 'detail_pda',
            Title: dictionary.DetailPda,
            Label: dictionary.DetailPda,
            Link: Pages.DetailPda,
            Icon: 'work',
            Selected: false,
            Visible: false,
            Expanded: false
          }
        ]
      },
      {
        Name: 'settings',
        Title: dictionary.Settings,
        Label: dictionary.Settings,
        Link: Pages.Settings,
        Icon: 'settings',
        Selected: false,
        Visible: true,
        Expanded: false,
      },
      {
        Name: 'user-profile',
        Title: dictionary.Profile,
        Label: dictionary.Profile,
        Link: Pages.UserProfile,
        Icon: 'person',
        Selected: false,
        Visible: true,
        Expanded: false,
      },
      {
        Name: 'wizard',
        Title: dictionary.HowWorks,
        Label: dictionary.HowWorks,
        Link: null,
        Icon: 'mouse',
        Selected: false,
        Visible: true,
        Expanded: false,
      },
    ];
  }

  GetMenu() {
    return this.ApplicationMenu.filter(x => x.Visible === true && x.Name !== 'dashboard' && x.Name !== 'settings' && x.Name !== 'user-profile'
      && x.Name !== 'wizard');
  }

  GetDashboardMenu() {
    return this.ApplicationMenu.find(x => x.Visible === true && x.Name === 'dashboard');
  }

  GetSettingsMenu() {
    return this.ApplicationMenu.find(x => x.Visible === true && x.Name === 'settings');
  }

  GetUserProfileMenu() {
    return this.ApplicationMenu.filter(x => x.Visible === true && x.Name === 'user-profile' || x.Name === 'wizard');
  }
}

export class DialogMenu {
  ApplicationMenu: Array<any>;
  constructor(dictionary: DictionaryInterface, isAdmin: boolean) {
    this.ApplicationMenu = [
      [
        {
          Name: 'document',
          Title: dictionary.NewDocument,
          Label: dictionary.NewDocument,
          Description: dictionary.CreateDocumentDescription,
          Component: DocumentComponent,
          Icon: 'big-doc',
          Selected: false,
          Visible: true,
        },
        {
          Name: 'folder',
          Title: dictionary.NewFolder,
          Label: dictionary.NewFolder,
          Description: dictionary.CreateFolderDescription,
          Component: FolderComponent,
          Icon: 'big-folder',
          Selected: false,
          Visible: true,
        },
      ],
      [
        {
          Name: 'doc-document',
          Title: dictionary.DocDocument,
          Label: dictionary.DocDocument,
          Description: '',
          Icon: 'big-doc',
          Type: DocumentFormat.DOCX,
          Selected: false,
          Visible: true,
        },
        {
          Name: 'xls-document',
          Title: dictionary.XlsDocument,
          Label: dictionary.XlsDocument,
          Description: '',
          Type: DocumentFormat.XLSX,
          Icon: 'excel',
          Selected: false,
          Visible: true,
        },
        {
          Name: 'ppt-document',
          Title: dictionary.PptDocument,
          Label: dictionary.PptDocument,
          Description: '',
          Icon: 'presentation',
          Type: DocumentFormat.PPT,
          Selected: false,
          Visible: true,
        },
        // {
        //     Name: 'txt-document',
        //     Title: dictionary.TxtDocument,
        //     Label: dictionary.TxtDocument,
        //     Description: '',
        //     Icon: 'short_text',
        //     Type: DocumentFormat.TXT,
        //     Selected: false,
        //     Visible: true,
        // },
        {
          Name: 'scan-document',
          Title: dictionary.ScanDocument,
          Label: dictionary.ScanDocument,
          Description: '',
          Icon: 'pdf',
          Type: DocumentFormat.PDF,
          Selected: false,
          Visible: true,
        },
        {
          Name: 'upload-document',
          Title: dictionary.UploadDocument,
          Label: dictionary.UploadDocument,
          Description: '',
          Icon: 'upload-file',
          Type: null,
          Selected: false,
          Visible: true,
        }
      ]
    ];
  }

  GetMenu(index: number) {
    return this.ApplicationMenu[index].filter(x => x.Visible === true);
  }
}
export class FileMenu {
  FileMenu: Array<any>;
  constructor(
    dictionary: DictionaryInterface, objectDto: ObjectDto, accountDto: AccountDto, trash: boolean = false) {
    const authorization = objectDto.Authorization;
    this.FileMenu = [
      {
        Name: 'edit',
        Title: dictionary.Modify,
        Label: dictionary.Modify,
        Icon: 'edit',
        Selected: false,
        Enabled: true,
        Visible: !trash && !objectDto.Crypt && (authorization?.Authorized &&
          (authorization?.Action === DocumentState.PROGRESS || authorization?.Action.includes(DocumentState.START)))
          || (objectDto.Type === 'folder' && !trash)
      },
      {
        Name: 'encrypt',
        Title: dictionary.Encrypt,
        Label: dictionary.Encrypt,
        Icon: 'lock',
        Selected: false,
        Enabled: true,
        Visible: !objectDto.Crypt && objectDto.Type === 'file' &&
          (objectDto.State !== DocumentState.PROGRESS && objectDto.State !== DocumentState.EDIT)
      },
      {
        Name: 'decrypt',
        Title: dictionary.Decrypt,
        Label: dictionary.Decrypt,
        Icon: 'lock',
        Selected: false,
        Enabled: true,
        Visible: objectDto.Crypt && objectDto.Type === 'file'
      },
      {
        Name: 'delete',
        Title: dictionary.Delete,
        Label: dictionary.Delete,
        Icon: null,
        Selected: false,
        Enabled: true,
        Visible: !trash && !objectDto.Crypt && (objectDto.State === DocumentState.PROGRESS || objectDto.State === DocumentState.REJECTED)
          || ((objectDto.Type === 'folder' && objectDto.Size?.toString()?.split(' ')[0] == 0) && !trash)
      },
      {
        Name: 'attached',
        Title: dictionary.Attached,
        Label: dictionary.Attached,
        Icon: 'delete',
        Selected: false,
        Enabled: true,
        Visible: !trash && !objectDto.Crypt && !objectDto.Crypt && objectDto.Type === 'file',
      },
      {
        Name: 'collate',
        Title: dictionary.AddToDossier,
        Label: dictionary.AddToDossier,
        Icon: null,
        Selected: false,
        Enabled: true,
        Visible: !trash && authorization?.Authorized &&
          (objectDto.State === DocumentState.ARCHIVED || objectDto.State === DocumentState.PROTOCOLLED)
      },
      {
        Name: 'archive',
        Title: dictionary.Archive,
        Label: dictionary.Archive,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized,
        Visible: !trash && authorization?.Authorized &&
          (authorization?.Action === 'ADMIN' || authorization?.Action === DocumentState.START + '-' + AccountRole.ADMIN)
      },
      {
        Name: 'protocol',
        Title: dictionary.Protocol,
        Label: dictionary.Protocol,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized,
        Visible: !trash && authorization?.Authorized &&
          (authorization?.Action === 'ADMIN' || authorization?.Action === DocumentState.START + '-' + AccountRole.ADMIN)
      },
      {
        Name: 'sign',
        Title: dictionary.Sign,
        Label: dictionary.Sign,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized,
        Visible: !trash && !objectDto.Crypt && objectDto.State === DocumentState.APPROVED && objectDto?.IsSignatory
      }, {
        Name: 'sign-verify',
        Title: dictionary.SignVerify,
        Label: dictionary.SignVerify,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized,
        Visible: !trash && !objectDto.Crypt && objectDto?.Sign
      },
      {
        Name: 'approve',
        Title: dictionary.Approve,
        Label: dictionary.Approve,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized,
        Visible: !trash && authorization?.Authorized &&
          (authorization?.Action === DocumentState.APPROVAL)
      }, {
        Name: 'reject',
        Title: dictionary.Reject,
        Label: dictionary.Reject,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized,
        Visible: !trash && authorization?.Authorized &&
          (authorization?.Action === 'ADMIN' || authorization?.Action === DocumentState.APPROVAL) && !objectDto.Sign
      },
      {
        Name: 'sendToApprovation',
        Title: dictionary.SendToApprovationTitle,
        Label: dictionary.SendToApprovationTitle,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized,
        Visible: !trash && authorization?.Authorized &&
          (authorization?.Action === DocumentState.REVISION
            || authorization?.Action === DocumentState.START + '-' + AccountRole.APPROVER)
      },
      {
        Name: 'sendToRevision',
        Title: dictionary.SendToRevisionTitle,
        Label: dictionary.SendToRevisionTitle,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized,
        Visible: !trash && authorization?.Authorized && authorization?.Action === DocumentState.START + '-' + AccountRole.REVISOR
      },
      {
        Name: 'requestEditing',
        Title: dictionary.RequestEditingTitle,
        Label: dictionary.RequestEditingTitle,
        Icon: null,
        Selected: false,
        Enabled: authorization?.Authorized && authorization?.RequestEditing,
        Visible: !trash && authorization?.Authorized && authorization?.RequestEditing && !objectDto.Sign
      },
      {
        Name: 'restore',
        Title: dictionary.Restore,
        Label: dictionary.Restore,
        Icon: 'refresh',
        Selected: false,
        Enabled: trash,
        Visible: trash,
      },
      {
        Name: 'permanent_delete',
        Title: dictionary.PermanentlyDelete,
        Label: dictionary.PermanentlyDelete,
        Icon: 'delete',
        Selected: false,
        Enabled: trash,
        Visible: trash,
      }
    ];
  }

  GetMenu() {
    return this.FileMenu.filter(x => x.Visible === true);
  }
}

export class FilesMenu {
  FilesMenu: Array<any>;
  constructor(
    dictionary: DictionaryInterface, menuDelete: boolean,
    menuSign: boolean,
    menuCollate: boolean,
    trash: boolean = false) {
    this.FilesMenu = [
      {
        Name: 'delete',
        Title: dictionary.Delete,
        Label: dictionary.Delete,
        Icon: null,
        Selected: false,
        Enabled: menuDelete,
        Visible: !trash && menuDelete
      },
      {
        Name: 'collate',
        Title: dictionary.AddToDossier,
        Label: dictionary.AddToDossier,
        Icon: null,
        Selected: false,
        Enabled: menuCollate,
        Visible: !trash && menuCollate
      },
      {
        Name: 'sign',
        Title: dictionary.Sign,
        Label: dictionary.Sign,
        Icon: null,
        Selected: false,
        Enabled: menuSign,
        Visible: false
      },
      {
        Name: 'restore',
        Title: dictionary.Restore,
        Label: dictionary.Restore,
        Icon: 'refresh',
        Selected: false,
        Enabled: trash,
        Visible: trash,
      },
      {
        Name: 'permanent_delete',
        Title: dictionary.PermanentlyDelete,
        Label: dictionary.PermanentlyDelete,
        Icon: 'delete',
        Selected: false,
        Enabled: trash,
        Visible: trash,
      }
    ];
  }

  GetMenu() {
    return this.FilesMenu.filter(x => x.Visible === true);
  }
}
