import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FileDto, FileModel } from '../../../models/FileModel';
import { NavigatorService } from '../../../../navigator.services';
import { UploadService } from '../../../../upload.service';
import { CommonService } from '../../../../common.service';

@Component({
  selector: 'app-progress-bar-popup',
  templateUrl: './progress-bar-popup.component.html',
  styleUrls: ['./progress-bar-popup.component.scss']
})
export class ProgressBarPopupComponent implements OnInit {
  FileModel: FileModel;
  Description = null;
  DataSourceFiles: MatTableDataSource<FileDto>;

  constructor(
    public navigatorService: NavigatorService, public uploadService: UploadService,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<ProgressBarPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.FileModel = data;
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StopLoading();
    let response = null;
    if (this.FileModel.Dtos?.length <= 0) {
      this.Description = this.navigatorService.Dictionary.Uploading.replace('{#filename#}', this.FileModel.Dto.PhysicalName) + '...';
      response = await this.uploadService.Upload(this.FileModel.Dto.File, this.FileModel.Dto.PhysicalName, this.FileModel.Dto.Path);
      this.dialogRef.close(response);
    } else if (this.FileModel.Dtos?.length > 0) {
      this.Description = this.navigatorService.Dictionary.Uploading.replace('{#filename#}', '') + '...';
      this.FileModel.Dtos.map(q => q.Progress = 0);
      this.RefreshTableFile(this.FileModel.Dtos);
      for (const dto of this.FileModel.Dtos) {
        this.FileModel.Dtos.map(q => q.Selected = false);
        dto.Selected = true;
        this.RefreshTableFile(this.FileModel.Dtos);
        response = await this.uploadService.Upload(dto.File, dto.PhysicalName, dto.Path, dto);
        if (!response.Performed) {
          this.uploadService.Cancel = true;
          return;
        }
      }
      this.dialogRef.close(response);
    } else {
      this.dialogRef.close(null);
    }
  }

  RefreshTableFile(dtos: Array<FileDto>) {
    for (const dto of dtos) {
      const ext = this.commonService.GetExtension(dto.PhysicalName);
      dto.Icon = this.GetIcon(ext);
    }
    this.DataSourceFiles = null;
    this.DataSourceFiles = new MatTableDataSource<FileDto>(dtos);
  }

  private GetIcon(ext) {
    let icon = null;
    // presentation
    if (ext === 'ppt' || ext === 'pptx' || ext === 'odp') {
      icon = '../../assets/icon/doc_presentation.svg';
    } else if (ext === 'html') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'txt') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'jpg' || ext === 'png') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'xls' || ext === 'xlsx' || ext === 'ods') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'doc' || ext === 'docx' || ext === 'odt') {
      icon = '../../assets/icon/doc_doc.svg';
    } else if (ext === 'pdf') {
      icon = '../../assets/icon/doc_pdf.svg';
    } else {
      icon = '../../assets/icon/doc_doc.svg';
    }
    return icon;
  }

}
