import { DataService } from '../../data.service';
import { WorkflowModel } from '../models/WorkflowModel';


export class WorkflowController {


    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: WorkflowModel): Promise<WorkflowModel> {
        const response = await this.dataService.CreateOrUpdateWorkflow(model);
        return response;
    }

    async Read(model: WorkflowModel): Promise<WorkflowModel> {
        const response = await this.dataService.ReadWorkflow(model);
        return response;
    }

    async Get(model: WorkflowModel): Promise<WorkflowModel> {
        const response = await this.dataService.GetWorkflow(model);
        return response;
    }

    async Load(model: WorkflowModel): Promise<WorkflowModel> {
        const response = await this.dataService.LoadWorkflow(model);
        return response;
    }

    async Delete(model: WorkflowModel): Promise<WorkflowModel> {
        const response = await this.dataService.DeleteWorkflow(model);
        return response;
    }

}
