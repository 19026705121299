import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {
  @Output() onPaging: EventEmitter<any> = new EventEmitter();
  Pages: Array<number>;
  TotalPages: number;
  StartPage = 0;
  CurrentPage = 1;
  ShowNumberPage = 10;
  private model: any = null;
  get Model() {
    return this.model;
  }

  @Input() set Model(value: any) {
    this.model = value;
  }

  constructor() {

  }

  ngOnInit(): void {
  }

  Paging(page, resetCheck = true) {
    page = (page === 0 ? 1 : page);
    if (resetCheck) {
      this.Model?.Dtos?.map(o => o.Checked = false);
    }
    this.TotalPages = Math.ceil(this.Model.Count / this.Model.Take);
    this.Pages = new Array<number>();
    while (this.StartPage > page) {
      this.StartPage -= this.Model.Take;
    }
    for (let i = this.StartPage; i < this.StartPage + this.ShowNumberPage; i++) {
      if ((i + 1) <= this.TotalPages) {
        this.Pages.push(i + 1);
      }
    }
    if (page > this.TotalPages) {
      page = this.TotalPages;
    }
    this.CurrentPage = page;
  }

  PagingNumber(page: number) {
    this.Model.Skip = this.Model.Take * (page - 1);
    this.onPaging.emit(page);
  }

  PagingBack() {
    let page = ((this.CurrentPage - 1) > 0 ? this.CurrentPage - 1 : 1);
    if (this.StartPage === page) {
      this.StartPage -= this.ShowNumberPage;
      page = this.StartPage + this.Model.Take;
    }
    this.Model.Skip = this.Model.Take * (page - 1);
    this.onPaging.emit(page);
  }

  PagingNext() {
    let page = ((this.CurrentPage + 1) <= this.Pages[this.Pages?.length - 1] ? this.CurrentPage + 1 : this.Pages[this.Pages?.length - 1]);
    if (this.StartPage + this.ShowNumberPage === page) {
      this.StartPage += this.ShowNumberPage;
      page = this.StartPage + 1;
    }
    this.Model.Skip = this.Model.Take * (page - 1);
    this.onPaging.emit(page);
  }

  SkipPagingNext() {
    this.TotalPages = Math.ceil(this.Model.Count / this.Model.Take);
    this.StartPage += this.ShowNumberPage;
    const page = ((this.Model.Take * (this.StartPage + 1)) <= this.TotalPages * this.Model.Take ? this.StartPage : this.Pages.length);
    this.Model.Skip = this.Model.Take * page;
    this.onPaging.emit(page + 1);
  }

  SkipPagingBack() {
    this.TotalPages = Math.ceil(this.Model.Count / this.Model.Take);
    this.StartPage -= this.ShowNumberPage;
    const page = ((this.Model.Take * (this.StartPage + 1)) >= 1 ? this.StartPage : 1);
    this.Model.Skip = this.Model.Take * page;
    this.onPaging.emit(page + 1);
  }

}
