<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 11px;" src="../../../../../assets/icon/uo-black.svg">
                    <h2 *ngIf="navigatorService.PageAction === 'New'">{{navigatorService.Dictionary?.OrganizationalUnitTitle}}</h2>
                    <h2 *ngIf="navigatorService.PageAction === 'Edit'">
                        {{navigatorService.Dictionary?.OrganizationalUnitTitleEdit}}</h2>
                </div>
                <div>
                    <span class="current-step">{{Step}}</span><span class="step-separator">|</span><span
                    class="steps">{{Steps}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <div *ngIf="Step===1">
        <p class="title-description">{{navigatorService.Dictionary?.OrganizationalUnitDescription}}</p>
        <mat-dialog-content>
            <div class="form mb-5">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.OrganizationUnitNamePlaceholder"
                            [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Description'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.Description"
                            [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12"
                        style="display: flex;flex-direction: column;justify-content: flex-end;">
                        <app-input [DataValue]='Model?.Dto' [Property]="'OfficeCode'" [Type]="'text'" [Required]='true'
                            [Maxlength]="16" [Placeholder]="navigatorService.Dictionary?.OfficeCodePlaceHolder"
                            [Description]="navigatorService.Dictionary?.Maxlength16"></app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <!-- Dropdown tree -->
                        <app-dropdown-tree [DataSource]="HierarchyModel?.Dtos" [Value]="Model?.Dto?.Parent"
                            [UoDto]="Model?.Dto" [Index]="0"
                            [Placeholder]="navigatorService.Dictionary?.OrganizationalUnitDescription5"
                            (ClickOnOption)="SelectUo($event)" [Description]="navigatorService.Dictionary?.OrganizationalUnitDescription4">
                        </app-dropdown-tree>
                        <!-- Dropdown tree -->
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div *ngIf="Step===2">
        <p class="title-description">{{navigatorService.Dictionary?.OrganizationalUnitDescription2}}</p>
        <div class="row">           
            <div class="col-md-12 col-sm-12">
                <app-mt-autocomplete #autocompleteUser [appearance]="'legacy'" [Items]="UserModel?.Dtos"
                    [DisplayProperties]="['Displayname']" 
                    [Placeholder]="navigatorService.Dictionary?.SelectUser" [SearchProperties]="['Displayname']"
                    [LoaderType]="'Spinner'" [DropDownMode]="false" [DataValue]=""
                    (SelectionChange)="LdapUsersChange($event,AccountDto)" [Multiple]="false"
                    (OnChange)='SearchUser($event)' [OptionIcon]="'add'"></app-mt-autocomplete>
            </div>
        </div>
        <mat-dialog-content class=" default-scrollbar">
            <div class="form2 mb-5">
                <div class="row header-user-table">
                    <div class="col-md-4 col-sm-8">
                        {{navigatorService.Dictionary?.Member}}
                    </div>
                    <div class="col-md-1 col-sm-2">

                    </div>
                    <div class="col-md-2 col-sm-2">
                        {{navigatorService.Dictionary?.ExpirationDate}}
                    </div>
                    <div class="col-md-3 col-sm-12">
                        {{navigatorService.Dictionary?.Role}} <span style="color:red;">*</span>
                    </div>
                    <div class="col-md-2 col-sm-2  text-center">
                        {{navigatorService.Dictionary?.Signatory}}
                    </div>
                </div>
                <table mat-table [dataSource]="DataSource" class="">
                    <ng-container matColumnDef="user">
                        <td mat-cell *matCellDef="let element">
                            <div class="row">
                                <div class="col-md-4 col-sm-8" style="padding-top: 20px;">
                                    <div class="d-flex">
                                        <img *ngIf="element.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + element.Avatar" style="margin-right: 10px;">
                                        <span>{{element.DisplayName}}</span>
                                    </div>
                                </div>
                                <div class="col-md-1 col-sm-2">
                                    <button mat-icon-button class="icon-button" (click)="RemoveUser(element)">
                                        <mat-icon class="icon">close</mat-icon>
                                    </button>
                                </div>
                                <div class="col-md-2 col-sm-2" style="padding-top: 11px;">
                                    <app-rangepicker [Property]="'DateString'" [DataValue]="element" [Minimize]="true">
                                    </app-rangepicker>
                                </div>
                                <div class="col-md-3 col-sm-12">
                                    <app-select [Placeholder]="navigatorService.Dictionary?.RolePlaceholder"
                                        [Required]='true' [DataValue]='element?.RoleId' [Property]="'Name'"
                                        [Items]="RoleModel.Dtos" (OnChange)="SelectRole($event,element)"></app-select>
                                </div>
                                <div class="col-md-2 col-sm-12 text-center">
                                    <mat-checkbox [(ngModel)]="element.Signatory" style="top: 21px;position: relative;"></mat-checkbox>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: ['user'];"></tr>
                </table>
            </div>
        </mat-dialog-content>
        <div *ngIf="Pages?.length>1" class="table-paginator">
            <span class="material-icons icon mr-2" [ngClass]="CurrentPage<=1?'disabled':''" (click)="PagingBack()">
                keyboard_arrow_left
            </span>
            <div class="page-number" (click)="Paging(page)" [ngClass]="CurrentPage===page?'selected':''"
                *ngFor="let page of Pages">{{page}}</div>
            <span class="material-icons icon" [ngClass]="CurrentPage===Pages?.length?'disabled':''"
                (click)="PagingNext()">
                keyboard_arrow_right
            </span>
        </div>
    </div>

    <div *ngIf="Step===3">
        <p class="title-description">{{navigatorService.Dictionary?.OrganizationalUnitDescription3}}</p>
        <mat-dialog-content class="default-scrollbar">
            <div class="form mb-5">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <p class="summary-box-title">{{navigatorService.Dictionary?.OrganizationUnitNamePlaceholder}}
                        </p>
                        <div class="summary-box">
                            <p class="value-text">{{Model?.Dto?.Name}}</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <p class="summary-box-title">{{navigatorService.Dictionary?.OrganizationalUnitDescription6}}
                        </p>
                        <div class="summary-box">
                            <p class="value-text">{{AccountModel?.Dtos?.length}}</p>
                        </div>
                    </div>
                    
                    <div class="col-md-4 col-sm-12">
                        <p class="summary-box-title">{{navigatorService.Dictionary?.OfficeCodePlaceHolder}}</p>
                        <div class="summary-box">

                            <p class="value-text">{{Model?.Dto?.OfficeCode}}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-4 col-sm-12">
                        <p class="summary-box-title">{{navigatorService.Dictionary?.OrganizationalUnitDescription5}}
                        </p>
                        <div class="summary-box">
                            <p class="value-text">{{Model?.Dto?.ParentName?Model?.Dto?.ParentName:ND}}</p>
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <p class="summary-box-title">{{navigatorService.Dictionary?.Description}}</p>
                        <div class="summary-box">
                            <p class="value-text">{{Model?.Dto?.Description}}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button *ngIf="Step===1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step>1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Back()">
            <span class="button-text">{{navigatorService.Dictionary?.Back.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step<3" (click)="Next()" mat-button class="it-doc-btn-primary" [disabled]="!ValidateForm(this.Step,false)" cdkFocusInitial>
            <span class="button-text">{{navigatorService.Dictionary?.Next.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step===3" (click)="Save()" mat-button class="it-doc-btn-primary" cdkFocusInitial>
            <span *ngIf="navigatorService.PageAction === 'New'"
                class="button-text">{{navigatorService.Dictionary?.Create.toUpperCase()}}</span>
            <span *ngIf="navigatorService.PageAction === 'Edit'"
                class="button-text">{{navigatorService.Dictionary?.Update.toUpperCase()}}</span>
        </button>
    </div>
</div>