<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container ">
          <div class="grid-container">
            <div class="grid-column" (click)='Load(1)' [ngClass]="Tab===1?'tab-selected':''"
              [ngStyle]="{'cursor':Tab===2?'pointer':'default'}">
              <span class="grid-column-title">
                {{navigatorService.Dictionary?.YourUo}}
              </span>
            </div>
            <div class="grid-column" *ngIf="authenticationService.CurrentUser?.IsAdmin" (click)='Load(2)'
              [ngClass]="Tab===2?'tab-selected':'default'" [ngStyle]="{'cursor':Tab===1?'pointer':''}">
              <span class="grid-column-title">
                {{navigatorService.Dictionary?.AllUo}}
              </span>
            </div>
          </div>
        </div>
        <div class="search-create-container">
          <app-search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
            [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
            [Interactive]="true" [HaveActions]="false" (OnEnterPress)="Search($event, Model.Dtos)"
            (OnClearBtnPress)="ClearSearchInput($event)">
          </app-search>
          <button (click)='Create()' *ngIf="authenticationService.CurrentUser?.IsAdmin" mat-raised-button
            class="it-doc-btn-create create-button">
            <span class="text">{{navigatorService.Dictionary?.Create.toUpperCase()}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="height: 100%;">
    <div class="col-md-12">
      <div class="form mt-3">
        <div *ngIf="Model?.Dtos?.length > 0" class="row w-100 table-uo-header">
          <div class="col-md-3 uo-col-1">
            <div class="valign-icon-text">
              <div>{{navigatorService.Dictionary?.Name}}</div>
            </div>
          </div>
          <div class="col-md-2 uo-col-2">
            <div class="valign-icon-text">
              <div>{{navigatorService.Dictionary?.OfficeCodePlaceHolder}}</div>
            </div>
          </div>

          <div class="col-md-3 uo-col-3 no-sort-column">{{navigatorService.Dictionary?.Description}}</div>
          <div class="col-md-2 uo-col-4 no-sort-column">{{navigatorService.Dictionary?.Admin}}</div>
          <div class="col-md-1 uo-col-5 no-sort-column">{{navigatorService.Dictionary?.Users}}</div>
          <div class="col-md-1 uo-col-6"></div>
        </div>
        <app-tree #uotree [DataSource]="FilteredUo" [Tab]="Tab" [Index]="0"></app-tree>
        <div *ngIf="Model?.Dtos?.length <=0 && !navigatorService.Loading" class="empty-table-box">
          <img src="../../../../../assets/icon/uo-empty-icon.svg">
          <div class="description">{{navigatorService.Dictionary?.EmptyUoViewMessage}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
