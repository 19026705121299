export class GenericDialogModel {
    TitleIcon: string;
    Title: string;
    Message: string;
    ConfirmBtnLabel: string;
    CancelBtnLabel: string;
    DialogData: any;
    Icon: string;
    Name: string;
    Description: string;
}
