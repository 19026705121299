<div id="newWorkflow" class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <h2>{{navigatorService.Dictionary?.NewWorkflow}}</h2>
                </div>
                <div>
                    <span class="current-step">{{Step}}</span><span class="step-separator">|</span><span
                        class="steps">{{Steps}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>
    <!-- Step 1 -->
    <div *ngIf="Step === 1">
        <p class="title-description">{{navigatorService.Dictionary?.SetYourWF}}</p>
        <div class="required-label">
            <span class="star">*</span>
            <span>{{navigatorService.Dictionary?.RequiredFields}}</span>
        </div>
        <mat-dialog-content class="default-scrollbar">
            <div class="form">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.WFNamePlaceholder"
                            [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'RelevantSector'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.WFRelevantSectorPlaceholder"
                            [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <!-- Step 2 -->
    <div *ngIf="Step === 2">
        <p class="title-description">{{navigatorService.Dictionary?.SetYourWF}}</p>
        <div class="required-label">
            <span class="star">*</span>
            <span>{{navigatorService.Dictionary?.RequiredFields}}</span>
        </div>
        <mat-dialog-content class="default-scrollbar">
            <div class="form">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <app-input [DataValue]='WorkFlowStepInput' [Property]="'Name'" [Type]="'text-button'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.WFStepNamePlaceholder"
                            [Description]="navigatorService.Dictionary?.Maxlength50" (Add)="AddWFStep($event)"></app-input>
                        <div cdkDropList class="draggable-list" (cdkDropListDropped)="DropWFStepName($event, WorkflowStepModel?.Dtos)">
                            <div *ngFor="let wfStep of WorkflowStepModel?.Dtos" class="draggable-box" cdkDrag>
                                <div class="row">
                                    <div class="col-md-11 col-sm-11">
                                        <div class="valign-icon-text">
                                            <span class="material-icons move" cdkDragHandle>drag_indicator</span> 
                                            <span>{{wfStep.Name}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-1 col-sm-1 text-end">
                                        <span class="material-icons pointer" (click)="RemoveWFStepName(wfStep)">close</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <!-- Step 3 -->
    <div *ngIf="Step === 3">
        <p class="title-description">{{navigatorService.Dictionary?.SetYourWF}}</p>
        <div class="required-label">
            <span class="star">*</span>
            <span>{{navigatorService.Dictionary?.RequiredFields}}</span>
        </div>
        <mat-dialog-content class="default-scrollbar">
            <!-- Personalize steps -->
            <div *ngIf="PersonalizeSteps" class="form">
                <div class="wf-step-container">
                    <ng-container *ngFor="let dto of WorkflowStepModel?.Dtos; let isLast = last; let index = index">
                        <span (click)="WFStepTab(index)" class="wf-step-text"
                            [ngClass]="{'selected-wf-step-page': CurrentWFStepTab === index}">{{dto?.Name}}</span>
                        <span *ngIf="!isLast" class="wf-step-separator"> | </span>
                    </ng-container>
                </div>
                <hr class="wf-step-bottom-hr">
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="mb-3"><b>{{navigatorService.Dictionary?.Revisor}}</b></div>
                        <div class="user-box">
                            <app-mt-autocomplete #autocompleteRevisorUser [appearance]="'legacy'" [Items]="GetItems(AccountRole.REVISOR)"
                            [DisplayProperties]="['Name']" [Placeholder]="navigatorService.Dictionary?.WFUserOrUoPlaceholder" [SearchProperties]="['Name']"
                            [LoaderType]="'Spinner'" [DropDownMode]="true" [DataValue]=""
                            (SelectionChange)="UoUsersChange($event, autocompleteRevisorUser, AccountRole.REVISOR)" [Multiple]="false"
                            (OnChange)='GetItems(AccountRole.REVISOR)' [OptionIcon]="'add'"></app-mt-autocomplete>
                            <ng-container *ngIf="FindUsersPerRole(CurrentWFStep?.WorkflowStepUsers, AccountRole.REVISOR); let Users">
                                <div *ngFor="let user of Users; let isFirst = first" [ngClass]="{'mt-3': !isFirst}">
                                    <div class="d-flex justify-content-between">
                                        <div class="valign-icon-text">
                                            <img *ngIf="user.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + user.Avatar" style="margin-right: 10px;">
                                            <div *ngIf="!user.Avatar" class="avatar-circle" style="margin-right: 10px;">{{user.Name | acronym}}</div>
                                            <span>{{user.Name}}</span>
                                        </div>
                                        <span class="material-icons pointer" (click)="RemoveWFStepUser(user, CurrentWFStep)">close</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="mb-3"><b>{{navigatorService.Dictionary?.Approver}}</b></div>
                        <div class="user-box">
                            <app-mt-autocomplete #autocompleteApproverUser [appearance]="'legacy'" [Items]="GetItems(AccountRole.APPROVER)"
                            [DisplayProperties]="['Name']" [Placeholder]="navigatorService.Dictionary?.WFUserOrUoPlaceholder" [SearchProperties]="['Name']"
                            [LoaderType]="'Spinner'" [DropDownMode]="true" [DataValue]=""
                            (SelectionChange)="UoUsersChange($event, autocompleteApproverUser, AccountRole.APPROVER)" [Multiple]="false"
                            (OnChange)='GetItems(AccountRole.APPROVER)' [OptionIcon]="'add'"></app-mt-autocomplete>
                            <ng-container *ngIf="FindUsersPerRole(CurrentWFStep?.WorkflowStepUsers, AccountRole.APPROVER); let Users">
                                <div *ngFor="let user of Users; let isFirst = first" [ngClass]="{'mt-3': !isFirst}">
                                    <div class="d-flex justify-content-between">
                                        <div class="valign-icon-text">
                                            <img *ngIf="user.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + user.Avatar" style="margin-right: 10px;">
                                            <div *ngIf="!user.Avatar" class="avatar-circle" style="margin-right: 10px;">{{user.Name | acronym}}</div>
                                            <span>{{user.Name}}</span>
                                        </div>
                                        <span class="material-icons pointer" (click)="RemoveWFStepUser(user, CurrentWFStep)">close</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div *ngIf="CurrentWFStepTab === WorkflowStepModel?.Dtos?.length -1" class="row">
                    <div class="col-md-6 col-sm-6 mt-3">
                        <div class="mb-3"><b>{{navigatorService.Dictionary?.Admin}}</b><span class="required-color">*</span></div>
                        <div class="user-box">
                            <app-mt-autocomplete #autocompleteAdminUser [appearance]="'legacy'" [Items]="GetItems(AccountRole.ADMIN)"
                            [DisplayProperties]="['Name']" [Placeholder]="navigatorService.Dictionary?.WFUserOrUoPlaceholder" [SearchProperties]="['Name']"
                            [LoaderType]="'Spinner'" [DropDownMode]="true" [DataValue]=""
                            (SelectionChange)="UoUsersChange($event, autocompleteAdminUser, AccountRole.ADMIN)" [Multiple]="false"
                            (OnChange)='GetItems(AccountRole.ADMIN)' [OptionIcon]="'add'"></app-mt-autocomplete>
                            <ng-container *ngIf="FindUsersPerRole(CurrentWFStep?.WorkflowStepUsers, AccountRole.ADMIN); let Users">
                                <div *ngFor="let user of Users; let isFirst = first" [ngClass]="{'mt-3': !isFirst}">
                                    <div class="d-flex justify-content-between">
                                        <div class="valign-icon-text">
                                            <img *ngIf="user.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + user.Avatar" style="margin-right: 10px;">
                                            <div *ngIf="!user.Avatar" class="avatar-circle" style="margin-right: 10px;">{{user.Name | acronym}}</div>
                                            <span>{{user.Name}}</span>
                                        </div>
                                        <span class="material-icons pointer" (click)="RemoveWFStepUser(user, CurrentWFStep)">close</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Preview steps -->
            <div *ngIf="!PersonalizeSteps">
                <div class="row">
                    <div class="col-md-12">
                        <mat-accordion *ngFor="let dto of WorkflowStepModel?.Dtos; let isLast = last; let index = index">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>{{dto?.Name}}</mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- Content -->
                                <div class="row preview-row preview-row-top-radius preview-row-bottom-radius">
                                    <div class="col-md-6 col-sm-6">
                                        <div><b>{{navigatorService.Dictionary?.Revisor}}</b></div>
                                        <div class="preview-user-box">
                                            <ng-container *ngIf="FindUsersPerRole(dto?.WorkflowStepUsers, AccountRole.REVISOR); let Users">
                                                <div *ngFor="let user of Users; let isFirst = first" [ngClass]="{'mt-3': !isFirst}">
                                                    <div class="valign-icon-text">
                                                        <img *ngIf="user.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + user.Avatar" style="margin-right: 10px;">
                                                        <div *ngIf="!user.Avatar" class="avatar-circle" style="margin-right: 10px;">{{user.Name | acronym}}</div>
                                                        <span>{{user.Name}}</span>
                                                    </div>
                                                </div>
                                                <span *ngIf="!Users || Users?.length <= 0">-</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <div><b>{{navigatorService.Dictionary?.Approver}}</b></div>
                                        <div class="preview-user-box">
                                            <ng-container *ngIf="FindUsersPerRole(dto?.WorkflowStepUsers, AccountRole.APPROVER); let Users">
                                                <div *ngFor="let user of Users; let isFirst = first" [ngClass]="{'mt-3': !isFirst}">
                                                    <div class="valign-icon-text">
                                                        <img *ngIf="user.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + user.Avatar" style="margin-right: 10px;">
                                                        <div *ngIf="!user.Avatar" class="avatar-circle" style="margin-right: 10px;">{{user.Name | acronym}}</div>
                                                        <span>{{user.Name}}</span>
                                                    </div>
                                                </div>
                                                <span *ngIf="!Users || Users?.length <= 0">-</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isLast" class="row preview-row preview-row-bottom-radius">
                                    <div class="col-md-6 col-sm-6">
                                        <div><b>{{navigatorService.Dictionary?.Admin}}</b><span class="required-color">*</span></div> 
                                        <div class="preview-user-box">
                                            <ng-container *ngIf="FindUsersPerRole(dto?.WorkflowStepUsers, AccountRole.ADMIN); let Users">
                                                <div *ngFor="let user of Users; let isFirst = first" [ngClass]="{'mt-3': !isFirst}">
                                                    <div class="valign-icon-text">
                                                        <img *ngIf="user.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + user.Avatar" style="margin-right: 10px;">
                                                        <div *ngIf="!user.Avatar" class="avatar-circle" style="margin-right: 10px;">{{user.Name | acronym}}</div>
                                                        <span>{{user.Name}}</span>
                                                    </div>
                                                </div> 
                                                <span *ngIf="!Users || Users?.length <= 0">-</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12 col-sm-12">
                        <mat-form-field style="width:100%;">
                            <mat-label>{{navigatorService.Dictionary?.WorkflowDurationPlaceholder}}</mat-label>
                            <mat-select [value]="Model?.Dto?.TimeLimit" (selectionChange)="SelectTimeLimit($event.value)" required>
                                <mat-option *ngFor="let data of WFTimeLimitData" [value]="data" >
                                {{data}} {{navigatorService.Dictionary?.Days}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button *ngIf="Step===1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step>1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Back()">
            <span class="button-text">{{navigatorService.Dictionary?.Back.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step<3" (click)="Next()" mat-button class="it-doc-btn-primary"
            [disabled]="!ValidateForm(this.Step,false)" cdkFocusInitial>
            <span class="button-text">{{navigatorService.Dictionary?.Next.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step===3 && PersonalizeSteps" (click)="Next()" mat-button class="it-doc-btn-primary"
            [disabled]="!ValidateForm(this.Step,false)" cdkFocusInitial>
            <span class="button-text">{{navigatorService.Dictionary?.Next.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step===3 && !PersonalizeSteps" (click)="Save()" mat-button class="it-doc-btn-primary" cdkFocusInitial>
            <span *ngIf="!data" class="button-text">{{navigatorService.Dictionary?.Create.toUpperCase()}}</span>
            <span *ngIf="data" class="button-text">{{navigatorService.Dictionary?.Update.toUpperCase()}}</span>
        </button>
    </div>
</div>
