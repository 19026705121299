import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class DocClassModel extends BaseModel<DocClassEntity, DocClassDto, DocClassFilter> {
  PdvModels: Array<any>;
  constructor() {
    super();
    this.Take = 1;
    this.PdvModels = new Array<any>();
  }
}

export class DocClassEntity extends BaseEntity {
  Owner!: string;
  PdaName!: string;
  PdvId!: string;
  DocClass!: string;
  Files: Array<File>;

  constructor() {
    super();
    this.Files = new Array<File>();
  }
}

export class DocClassDto extends DocClassEntity {
  Username!: string;
  Password!: string;
  PdvName!: string;

  constructor() {
    super();
  }
}

export class DocClassFilter extends DocClassDto {

}


export class File {
  File!: {
    ObjectId: string;
    FilePath: string;
    DocId: string;
    Filename: string;
    MimeType: string;
    Destination: string;
    ClosingDate: number;
    Hash: string;
    Metadata: any;
    SingleMetadata: [];
    ComplexMetadata: [];
    ExtraInfos: [];
  };
}
