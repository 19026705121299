import { BaseEntity, BaseModel } from './BaseModel';

export class NotificationModel extends BaseModel<NotificationEntity, NotificationDto, NotificationFilter> {

  constructor() {
    super();
  }
}

export class NotificationEntity extends BaseEntity {
  type: string;
  notifiable_type: string;
  notifiable_id: number;
  data: DataNotification;
  created_at: Date;
  id: string;

  constructor() {
    super();
    this.type = null;
    this.notifiable_type = null;
    this.notifiable_id = null;
    this.data = null;
  }
}

export class NotificationDto extends NotificationEntity {
  WorkflowName: string;
  Subject: string;
  Message: string;
  Icon: string;
  constructor() {
    super();
  }
}

export class NotificationFilter extends NotificationDto {
  Username: string;
}

export class DataNotification {
  Id: number;
  RecipientDisplayname: string;
  RecipientUsername: string;
  Role: string;
  SenderDisplayname: string;
  SenderUsername: string;
  Type: string;
  WorkflowName: string;
  ObjectName: string;
  PreviousStepName: string;
  NextStepName: string;
  State: string;
  DossierName: string;
  TipologyName: string;
  DossierId: number;
}
