import { DataService } from '../../data.service';
import { DocumentState } from '../../itdoc/itdoc.configuration';
import { WorkflowDataModel, WorkflowDataDto } from '../models/WorkflowDataModel';


export class WorkflowDataController {


    constructor(private dataService: DataService) { }

    async Run(
        objectId: number, workflowId: number, workflowDataId: number,
        state: string = DocumentState.START, action: string = null): Promise<WorkflowDataModel> {

        const model = new WorkflowDataModel();
        model.Dto = new WorkflowDataDto();
        model.Dto.Id = workflowDataId;
        model.Dto.ObjectId = objectId;
        model.Dto.WorkflowId = workflowId;
        model.Dto.State = state.toString();
        model.Dto.Action = action;
        const response = await this.dataService.RunWorkflowData(model);
        return response;
    }

    // async CreateOrUpdate(model: WorkflowDataModel): Promise<WorkflowDataModel> {
    //     const response = await this.dataService.CreateOrUpdateWorkflowData(model);
    //     return response;
    // }

    async Read(model: WorkflowDataModel): Promise<WorkflowDataModel> {
        const response = await this.dataService.ReadWorkflowData(model);
        return response;
    }

    // async Get(model: WorkflowDataModel): Promise<WorkflowDataModel> {
    //     const response = await this.dataService.GetWorkflowData(model);
    //     return response;
    // }

    // async Load(model: WorkflowDataModel): Promise<WorkflowDataModel> {
    //     const response = await this.dataService.LoadWorkflowData(model);
    //     return response;
    // }

    async Delete(model: WorkflowDataModel): Promise<WorkflowDataModel> {
        const response = await this.dataService.DeleteWorkflowData(model);
        return response;
    }

}
