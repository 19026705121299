import { BaseEntity, BaseModel } from "../../models/BaseModel";
import { WorkflowDataDto } from "../../workflow/models/WorkflowDataModel";
import { WorkflowUserDto } from "../../workflow/models/WorkflowUserModel";

export class ObjectDossierModel extends BaseModel<ObjectDossierEntity, ObjectDossierDto, ObjectDossierFilter> {
    constructor() {
        super();
        this.Take = 10;
    }
}

export class ObjectDossierEntity extends BaseEntity {
    DossierId: number;
    ObjectId: number;
    constructor() {
        super();
        this.DossierId = null;
        this.ObjectId = null;
    }
}

export class ObjectDossierDto extends ObjectDossierEntity {
    DossierName: string;
    TipologyId: number;
    UoId: number;
    Type: string; // 10 = Folder/File
    Description: string; // 500
    Trashed: boolean;
    Name: string; // 500
    PhysicalName: string; // 500
    Path: string; // 4000
    Creator: string; // 500
    CreationDate: number;
    CreationDateString: string;
    Modifier: string; // 500
    ModificationDate: number;
    ModificationDateString: string;
    ProgressiveString: string; // 200
    Progressive: number;
    Lock: boolean;
    Size: number;
    Version: number;
    Current: boolean;
    Parent: number;
    Deep: number;
    Hash: number;
    State: any;
    MimeType: string;
    Icon: string;
    ClassIcon: string;
    Checked?: boolean;
    Selected: boolean;
    IsShared: boolean;
    WorkflowData: WorkflowDataDto;
    Team: Array<WorkflowUserDto>;
    OriginalExtension: string;
    Crypt: boolean;
    constructor() {
        super();
        this.TipologyId = null;
        this.Type = null;
        this.Description = null;
        this.Trashed = false;
        this.Name = null;
        this.PhysicalName = null;
        this.Path = null;
        this.Creator = null;
        this.CreationDate = 0;
        this.CreationDateString = null;
        this.Modifier = null;
        this.ModificationDate = 0;
        this.ModificationDateString = null;
        this.ProgressiveString = null;
        this.Progressive = 0;
        this.Lock = false;
        this.Size = 0;
        this.Version = 0;
        this.Current = false;
        this.Parent = 0;
        this.Deep = 0;
        this.Hash = 0;
        this.DossierName = null;
        this.State = null;
        this.MimeType = null;
        this.Icon = null;
        this.ClassIcon = null;
        this.Crypt = false;
    }
}

export class ObjectDossierFilter extends ObjectDossierDto {
    SearchUo: any;
    SearchFileExtension: any;
    SearchTipology: any;
    AccountId: number;
}
