import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountRole, WFUserType, WorkflowState } from '../../../../itdoc/itdoc.configuration';
import { WorkflowDto, WorkflowModel } from '../../../models/WorkflowModel';
import { WorkflowStepDto, WorkflowStepModel } from '../../../models/WorkflowStepModel';
import { WorkflowUserDto, WorkflowUserModel } from '../../../models/WorkflowUserModel';
import { OrganizationUnitModel } from '../../../../itdoc/models/OrganizationUnitModel';
import { RoleModel } from '../../../../itdoc/models/RoleModel';
import { AccountModel } from '../../../../itdoc/models/AccountModel';
import { OrganizationUnitController } from '../../../../itdoc/controllers/OrganizationUnitController';
import { AccountController } from '../../../../itdoc/controllers/AccountController';
import { RoleController } from '../../../../itdoc/controllers/RoleController';
import { WorkflowController } from '../../../controllers/WorkflowController';
import { WorkflowStepController } from '../../../controllers/WorkflowStepController';
import { WorkflowUserController } from '../../../controllers/WorkflowUserController';
import { NotificationController } from '../../../../controllers/NotificationController';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';


@Component({
  selector: 'app-new-workflow',
  templateUrl: './new-workflow.component.html',
  styleUrls: ['./new-workflow.component.scss']
})
export class NewWorkflowComponent implements OnInit {
  Step = 1;
  Steps = 3;
  CurrentWFStepTab = 0;
  AccountRole = AccountRole;
  AdminRoleId = null;
  PersonalizeSteps = false;
  WFTimeLimitData: Array<number>;

  Model: WorkflowModel;
  WorkflowStepModel: WorkflowStepModel;
  WorkflowUserModel: WorkflowUserModel;
  WorkFlowStepInput: WorkflowStepDto;
  CurrentWFStep: WorkflowStepDto;

  OrganizationUnitModel: OrganizationUnitModel;
  AccountModel: AccountModel;
  RoleModel: RoleModel;

  OrganizationUnitController: OrganizationUnitController;
  AccountController: AccountController;
  RoleController: RoleController;
  WorkflowController: WorkflowController;
  WorkflowStepController: WorkflowStepController;
  WorkflowUserController: WorkflowUserController;

  NotificationController: NotificationController;


  constructor(
    private dataService: DataService,
    public navigatorService: NavigatorService,
    public dialogRef: MatDialogRef<NewWorkflowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Model = new WorkflowModel();
    this.Model.Dto = new WorkflowDto();
    this.WorkflowStepModel = new WorkflowStepModel();
    this.WorkFlowStepInput = new WorkflowStepDto();
    this.WorkflowUserModel = new WorkflowUserModel();
    this.OrganizationUnitModel = new OrganizationUnitModel();
    this.AccountModel = new AccountModel();
    this.RoleModel = new RoleModel();

    this.AccountController = new AccountController(dataService);
    this.OrganizationUnitController = new OrganizationUnitController(dataService);
    this.RoleController = new RoleController(dataService);
    this.WorkflowController = new WorkflowController(dataService);
    this.WorkflowStepController = new WorkflowStepController(dataService);
    this.WorkflowUserController = new WorkflowUserController(dataService);

    this.NotificationController = new NotificationController(dataService);

    this.WFTimeLimitData = [3, 5, 10, 15, 20, 25, 30];
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    // load users
    this.AccountModel = await this.AccountController.Get(this.AccountModel);
    if (this.AccountModel && this.AccountModel.Dtos && this.AccountModel.Dtos.length > 0) {
      // remove duplicate users for role
      this.AccountModel.Dtos = this.AccountModel.Dtos.filter((a, i) => {
        return this.AccountModel.Dtos.findIndex(x => {
          return x.Id === a.Id && x.RoleId === a.RoleId;
        }) === i;
      });
    }
    // load account role
    this.RoleModel = await this.RoleController.Get(this.RoleModel);
    this.AdminRoleId = this.RoleModel?.Dtos?.find(r => r.Name === AccountRole.ADMIN).Id;
    // load uo
    this.OrganizationUnitModel = await this.OrganizationUnitController.Get(this.OrganizationUnitModel);
    this.BuildWFUsers();
    this.navigatorService.StopLoading();
  }

  BuildWFUsers() {
    const users = this.AccountModel?.Dtos?.map(({ Id, DisplayName, RoleId, Avatar }) =>
      ({ Avatar: Avatar, AccountId: Id, Name: DisplayName, RoleId, Id: 0, Type: WFUserType.USER, UoId: null, WorkflowStepId: 0 }));

    const uos = this.OrganizationUnitModel?.Dtos?.map(({ Id, Name }) =>
      ({ Avatar: null, UoId: Id, Name, RoleId: null, Id: 0, Type: WFUserType.UO, AccountId: null, WorkflowStepId: 0 }));

    this.WorkflowUserModel.Dtos = [...users, ...uos];
  }

  Next() {
    const isValid = this.ValidateForm(this.Step);
    if (isValid) {
      if (this.Step === 2 && !this.PersonalizeSteps) {
        this.PersonalizeSteps = true;
        this.CurrentWFStep = this.WorkflowStepModel?.Dtos[0];
        this.Step += 1;
      } else {
        if (this.Step === 3 && this.PersonalizeSteps) {
          this.PersonalizeSteps = false;
        } else {
          this.PersonalizeSteps = false;
          this.Step += 1;
        }
      }
    }
  }

  Back() {
    if (this.Step === 3 && !this.PersonalizeSteps) {
      this.PersonalizeSteps = true;
    } else {
      if (this.PersonalizeSteps) {
        this.PersonalizeSteps = false;
        this.CurrentWFStepTab = 0;
        this.Step -= 1;
      } else {
        this.Step -= 1;
      }
      if (this.Step <= 0) {
        this.Step = 1;
      }
    }
  }

  ValidateForm(step: number, showMessage = true) {
    if (step === 1) {
      if (!this.Model?.Dto?.Name || !this.Model?.Dto?.RelevantSector) {
        if (showMessage) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RequiredFieldsMessage);
        }
        return false;
      }
    }
    if (step === 2) {
      if (!this.WorkflowStepModel?.Dtos || this.WorkflowStepModel?.Dtos?.length === 0) {
        if (showMessage) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RequiredFieldsMessage);
        }
        return false;
      }
    }
    if (step === 3 && this.PersonalizeSteps) {
      const adminIndex = this.WorkflowStepModel?.Dtos[this.WorkflowStepModel?.Dtos?.length - 1]?.WorkflowStepUsers?.findIndex(u => u.RoleId === this.AdminRoleId);
      if (adminIndex === -1) {
        if (showMessage) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RequiredAdminMessage);
        }
        return false;
      }
    }
    if (step === 3 && !this.PersonalizeSteps) {
      if (!this.Model?.Dto?.TimeLimit || this.Model?.Dto?.TimeLimit <= 0) {
        if (showMessage) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.RequiredFieldsMessage);
        }
        return false;
      }
    }
    return true;
  }

  AddWFStep(dto: WorkflowStepDto) {
    if (dto && dto.Name) {
      let lastStepAdminUsers = null;
      if (this.WorkflowStepModel?.Dtos?.length > 0) {
        lastStepAdminUsers = this.WorkflowStepModel?.Dtos[this.WorkflowStepModel?.Dtos?.length - 1]?.WorkflowStepUsers.filter(u => u.RoleId === this.AdminRoleId);
      }

      const newStepDto = new WorkflowStepDto();
      newStepDto.Name = dto.Name;
      if (lastStepAdminUsers && lastStepAdminUsers.length > 0) {
        // if last step contains admin users the new step will be added to penultimate position
        this.WorkflowStepModel?.Dtos?.splice(this.WorkflowStepModel?.Dtos?.length - 1, 0, newStepDto);
      } else {
        this.WorkflowStepModel?.Dtos?.push(newStepDto);
      }
      this.WorkFlowStepInput.Name = '';
    }
  }

  RemoveWFStepName(dto: WorkflowStepDto) {
    const index = this.WorkflowStepModel?.Dtos?.findIndex(s => s.Name === dto.Name);
    if (index !== -1) {
      this.WorkflowStepModel?.Dtos?.splice(index, 1);
    }
  }

  DropWFStepName(event: CdkDragDrop<string[]>, workflowSteps: WorkflowStepDto[]) {
    let index = null;
    if (event.currentIndex === workflowSteps?.length - 1) {
      index = event.currentIndex;
    }
    if (event.previousIndex === workflowSteps?.length - 1) {
      index = event.previousIndex;
    }
    if (index && index >= 0) {
      let stepUsers = workflowSteps[index]?.WorkflowStepUsers;
      const adminUsers = stepUsers?.filter(u => u.RoleId === this.AdminRoleId);
      if (adminUsers && adminUsers.length > 0) {
        stepUsers = stepUsers?.filter(el => {
          const indexUser = adminUsers.indexOf(el);
          return (indexUser < 0);
        });
        workflowSteps[index].WorkflowStepUsers = stepUsers;
      }
    }
    moveItemInArray(this.WorkflowStepModel?.Dtos, event.previousIndex, event.currentIndex);
  }

  WFStepTab(index: number) {
    this.CurrentWFStepTab = index;
    this.CurrentWFStep = this.WorkflowStepModel?.Dtos[index];
  }

  UoUsersChange(dto: WorkflowUserDto, autocomplete: any, roleName: string) {
    if (dto) {
      const find = this.CurrentWFStep?.WorkflowStepUsers?.find(u => u.Name === dto.Name);
      if (!find) {
        if (dto.Type === WFUserType.UO) {
          const roleId = this.RoleModel?.Dtos?.find(r => r.Name === roleName).Id;
          dto.RoleId = roleId;
        }
        this.CurrentWFStep?.WorkflowStepUsers?.push(dto);
      } else {
        if (dto.Type === WFUserType.UO) {
          // UO can add in multiple role sections
          const roleId = this.RoleModel?.Dtos?.find(r => r.Name === roleName).Id;
          const exist = this.CurrentWFStep?.WorkflowStepUsers.find(x => x.RoleId === roleId && dto.UoId === x.UoId);
          if (!exist) {
            const newDto = Object.assign({}, dto);
            newDto.RoleId = roleId;
            this.CurrentWFStep?.WorkflowStepUsers?.push(newDto);
          }
        }
      }
      autocomplete.Clear();
    }

  }

  GetItems(roleName: string) {
    const roleId = this.RoleModel?.Dtos?.find(r => r.Name === roleName)?.Id;
    return this.WorkflowUserModel?.Dtos?.filter(item => item.RoleId === roleId || item.Type === WFUserType.UO);
  }

  FindUsersPerRole(users: Array<WorkflowUserDto>, roleName: string) {
    const roleId = this.RoleModel?.Dtos?.find(r => r.Name === roleName)?.Id;
    return users.filter(item => item.RoleId === roleId);
  }

  RemoveWFStepUser(userDto: WorkflowUserDto, CurrentWFStep: WorkflowStepDto) {
    if (userDto && CurrentWFStep) {
      const index = this.CurrentWFStep?.WorkflowStepUsers?.findIndex(u =>
        userDto.AccountId ? u.AccountId === userDto.AccountId :
          userDto.UoId ? u.UoId === userDto.UoId : null);
      if (index >= 0) {
        this.CurrentWFStep?.WorkflowStepUsers?.splice(index, 1);
      }
    }
  }

  SelectTimeLimit(timeLimitValue: number) {
    if (timeLimitValue) {
      this.Model.Dto.TimeLimit = timeLimitValue;
    }
  }

  async Save() {
    this.navigatorService.StartLoading();
    if (this.ValidateForm(this.Step)) {
      for (let i = 1; i <= this.WorkflowStepModel?.Dtos?.length; i++) {
        this.WorkflowStepModel.Dtos[i - 1].Order = i;
      }
      // create workflow
      this.Model.Dto.State = WorkflowState.DRAFT;
      this.Model = await this.WorkflowController.CreateOrUpdate(this.Model);
      if (this.Model?.Performed && this.Model.Entity && this.Model.Entity.Id > 0) {
        this.WorkflowStepModel?.Dtos?.map(s => s.WorkflowId = this.Model.Entity.Id);
        // create workflow steps
        let wfStepModel = new WorkflowStepModel();
        wfStepModel.Dtos = this.WorkflowStepModel.Dtos;
        wfStepModel = await this.WorkflowStepController.CreateOrUpdate(wfStepModel);
        if (wfStepModel?.Performed && wfStepModel?.Dtos && wfStepModel?.Dtos?.length > 0) {
          for (const resultStepDto of wfStepModel?.Dtos) {
            const stepUsersToCreate = this.WorkflowStepModel?.Dtos?.find(s => s.Order === resultStepDto.Order)?.WorkflowStepUsers;
            if (stepUsersToCreate && stepUsersToCreate.length > 0) {
              // create workflow step users
              stepUsersToCreate.map(u => u.WorkflowStepId = resultStepDto.Id);
              let wfUserModel = new WorkflowUserModel();
              wfUserModel.Dtos = stepUsersToCreate;
              wfUserModel = await this.WorkflowUserController.CreateOrUpdate(wfUserModel);
            }
          }
        }
        await this.NotificationController.NewWorkflow(this.Model.Entity.Id, this.Model.Entity.Name);
      }
      this.dialogRef.close(true);
    }
    this.navigatorService.StopLoading();
  }

}
