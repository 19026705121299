import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class TemplateModel extends BaseModel<TemplateEntity, TemplateDto, TemplateFilter> {
    constructor() {
        super();
    }
}

export class TemplateEntity extends BaseEntity {
    TipologyId: number;
    Name: string;
    PhysicalName: string;
    Path: string;
    Creator: string;
    CreationDate: number;
    Modifier: string;
    ModificationDate: number;
    Size: number;
    constructor() {
        super();
        this.TipologyId = null;
        this.Name = null;
        this.PhysicalName = null;
        this.Path = null;
        this.Creator = null;
        this.CreationDate = null;
        this.Modifier = null;
        this.ModificationDate = null;
        this.Size = null;
    }
}

export class TemplateDto extends TemplateEntity {
    constructor() {
        super();
    }
}

export class TemplateFilter extends TemplateDto {
}
