import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class DocumentModel extends BaseModel<DocumentEntity, DocumentDto, DocumentFilter> {
    constructor() {
        super();
    }
}

export class DocumentEntity extends BaseEntity {
    FileName: string|null;
    constructor() {
        super();
        this.FileName = null;
    }
}

export class DocumentDto extends DocumentEntity {
    Tokens: Array<Token>;
    TemplateName: string|null;
    FilePath: string|null;
    MimeType!: string|null;
    constructor() {
        super();
        this.Tokens = new Array<Token>();
        this.TemplateName = null;
        this.FilePath = null;
    }
}

export class DocumentFilter extends DocumentDto {

}


export class Token {
    Key: string|null;
    Value: string|null;
    constructor() {
        this.Key = null;
        this.Value = null;
    }
}
