import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileManagerController } from '../../controllers/FileManagerController';
import { DialogMenu } from '../../itdoc.configuration';
import { DataService } from '../../../data.service';
import { NavigatorService } from '../../../navigator.services';
import { CommonService } from '../../../common.service';
import { AuthService } from '../../../auth.service';


@Component({
  selector: 'app-dialog-menu',
  templateUrl: './dialog-menu.component.html',
  styleUrls: ['./dialog-menu.component.scss']
})
export class DialogMenuComponent implements OnInit {
  DocumentaryMenuItems: Array<any>;
  DocumentMenuItems: Array<any>;
  MenuItemSelected: any;
  FileManagerController: FileManagerController;

  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService, private authenticationService: AuthService,
    private commonService: CommonService, public dialogRef: MatDialogRef<DialogMenuComponent>) {
    this.FileManagerController = new FileManagerController(dataService);
  }

  ngOnInit(): void {
    const dialogMenu = new DialogMenu(this.navigatorService.Dictionary, this.authenticationService.ITDocAccount.Dto.IsAdmin);
    this.DocumentaryMenuItems = dialogMenu.GetMenu(0);
    this.DocumentMenuItems = dialogMenu.GetMenu(1);
  }

  ItemClick(item) {
    this.DocumentaryMenuItems?.map(x => x.Selected = false);
    item.Selected = !item.Selected;
    this.MenuItemSelected = item;
  }

  Next() {
    if (this.MenuItemSelected) {
      this.dialogRef.close({ MenuItemSelected: this.MenuItemSelected, DocumentMenuItems: this.DocumentMenuItems });
    }
  }
}

