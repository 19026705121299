<div id="ActivitiesCard">
    <div class="card-container">
        <div class="row m-0">
            <div class="col-md-12">
                <div>
                    <div class="card-title">{{navigatorService.Dictionary?.LastActivity}}</div>
                </div>
            </div>
        </div>
        <div class="activities-container tiny-scrollbar">
            <ng-container *ngFor="let dto of DataValue">
                <div class="row m-0 activity-row" *ngIf="dto.ObjectType!=='workflow'">
                    <div class="col-md-10">
                        <div class="d-flex">
                            <img class="icon" src="{{dto.Icon}}">
                            <div>
                                <span class="activity-date">{{dto.DateString }}</span>
                                <div class="doc-name">{{dto.Subject}} </div>
                                <div class="activity-text">{{dto.Message}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="d-flex justify-content-end">
                            <div class="profile-picture-circle"> <img class="avatar-circle"
                                    [src]="'data:image/png;base64,' +dto.Avatar" [matTooltip]="dto.DisplayName" /></div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!DataValue || (DataValue && DataValue?.length<=0)">
                <div class="m-0 empty-table-box">
                    <mat-icon class="empty-icon">hourglass_empty</mat-icon>
                    <div class="description">{{navigatorService.Dictionary?.ActivityEmpty}}</div>
                </div>
            </ng-container>
        </div>
        <div class="bottom-card">

        </div>
    </div>
</div>