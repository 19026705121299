import { BaseEntity, BaseModel } from "../../models/BaseModel";
import { WorkflowUserDto, WorkflowUserModel } from './WorkflowUserModel';

export class WorkflowStepModel extends BaseModel<WorkflowStepEntity, WorkflowStepDto, WorkflowStepFilter> {
    constructor() {
        super();
    }
}

export class WorkflowStepEntity extends BaseEntity {
    WorkflowId: number;
    Name: string;
    Order: number;
    constructor() {
        super();
        this.WorkflowId = null;
        this.Name = null;
        this.Order = null;
    }
}

export class WorkflowStepDto extends WorkflowStepEntity {
    WorkflowStepUsers: Array<WorkflowUserDto>;
    constructor() {
        super();
        this.WorkflowStepUsers = new Array<WorkflowUserDto>();
    }
}

export class WorkflowStepFilter extends WorkflowStepDto {
}
