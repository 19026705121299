import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EncryptionDto, EncryptionModel } from '../../../../models/EncryptionModel';
import { ObjectDto } from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { CommonService } from '../../../../common.service';
import { AuthService } from '../../../../auth.service';

@Component({
  selector: 'app-encrypt',
  templateUrl: './encrypt.component.html',
  styleUrls: ['./encrypt.component.scss']
})
export class EncryptComponent implements OnInit {
  Model: EncryptionModel;
  ObjectDto: ObjectDto;
  ObjectController: ObjectController;
  ErrorMessage: string;
  constructor(
    private dataService: DataService, public navigatorService: NavigatorService,
    private authenticationService: AuthService, public commonService: CommonService, public datepipe: DatePipe,
    public dialogRef: MatDialogRef<EncryptComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ObjectDto = data.Dto;
    this.Model = new EncryptionModel();
    this.Model.Dto = new EncryptionDto();
    this.Model.Dto.Id = this.ObjectDto.Id;
    this.Model.Dto.Path = this.ObjectDto.Path + '/' + this.ObjectDto.PhysicalName;
    this.Model.Dto.SoftDecrypt = data.SoftDecrypt;
    this.ObjectController = new ObjectController(dataService);
  }

  ngOnInit(): void {

  }

  async Confirm() {
    this.navigatorService.StartLoading();
    if (this.ObjectDto.Crypt) {
      this.Model = await this.ObjectController.Decrypt(this.Model);
      if (!this.Model.Performed) {
        if (this.Model.Code == 205) {
          this.ErrorMessage = this.navigatorService.Dictionary?.WrongPassword;
        } else if (this.Model.Code == 404) {
          this.ErrorMessage = this.navigatorService.Dictionary?.FileNotFound;
        } else if (this.Model.Code == 501) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.CryptographyDisabled);
        } else {
          this.ErrorMessage = this.navigatorService.Dictionary?.MessageGenericError;
        }
      } else {
        this.ObjectDto.Crypt = this.Model.Dto.SoftDecrypt ? true : false;
        this.commonService.GetIcon(this.ObjectDto);
        this.ErrorMessage = null;
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.FileDecryptedSuccess);
        this.dialogRef.close({ Performed: true, Dto: this.ObjectDto, Password: this.Model?.Dto?.Password });
      }
    } else {
      this.Model = await this.ObjectController.Encrypt(this.Model);
      if (!this.Model.Performed) {
        if (this.Model.Code == 205) {
          this.ErrorMessage = this.navigatorService.Dictionary?.WrongPassword;
        } else if (this.Model.Code == 404) {
          this.ErrorMessage = this.navigatorService.Dictionary?.FileNotFound;
        } else if (this.Model.Code == 501) {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.CryptographyDisabled);
        } else {
          this.ErrorMessage = this.navigatorService.Dictionary?.MessageGenericError;
        }
      } else {
        this.ObjectDto.Crypt = true;
        this.commonService.GetIcon(this.ObjectDto);
        this.ErrorMessage = null;
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.FileEncryptedSuccess);
        this.dialogRef.close({ Performed: true, Dto: this.ObjectDto });
      }
    }
    this.navigatorService.StopLoading();
  }

  IsValid() {
    return this.Model?.Dto?.Password && this.Model?.Dto?.Password?.length >= 5 && this.Model?.Dto?.Path;
  }

}
