import { RoleModel } from '../models/RoleModel';
import { DataService } from '../../data.service';

export class RoleController {
    constructor(private dataService: DataService) { }

    async Get(model: RoleModel): Promise<RoleModel> {
        return await this.dataService.GetRole(model);
    }

}
