import { AccountRoleModel } from '../models/AccountRoleModel';
import { DataService } from '../../data.service';

export class AccountRoleController {

    constructor(private dataService: DataService) { }

    async Get(model: AccountRoleModel): Promise<AccountRoleModel> {
        return await this.dataService.GetAccountRole(model);
    }

    async Delete(model: AccountRoleModel): Promise<AccountRoleModel> {
        return await this.dataService.DeleteAccountRole(model);
      }
}
