import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../../data.service';
import { DossierDto, DossierFilter, DossierModel } from '../../../models/DossierModel';
import { ObjectDossierDto, ObjectDossierFilter, ObjectDossierModel } from '../../../models/ObjectDossierModel';
import { DossierController } from '../../../controllers/DossierController';
import { ObjectDossierController } from '../../../controllers/ObjectDossierController';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { ObjectDto } from '../../../models/ObjectModel';
import { DossierState } from '../../../itdoc.configuration';

@Component({
  selector: 'app-add-to-dossier',
  templateUrl: './add-to-dossier.component.html',
  styleUrls: ['./add-to-dossier.component.scss']
})
export class AddToDossierComponent implements OnInit {
  Model: DossierModel;
  ObjectDossierModel: ObjectDossierModel;
  SelectedDossierIds: Array<number>;
  Controller: DossierController;
  // AccountDossierController: AccountDossierController;
  ObjectDossierController: ObjectDossierController;

  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    private authenticationService: AuthService,
    public dialogRef: MatDialogRef<AddToDossierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<ObjectDto>
  ) {
    this.Model = new DossierModel();
    this.ObjectDossierModel = new ObjectDossierModel();

    this.Controller = new DossierController(dataService);
    // this.AccountDossierController = new AccountDossierController(dataService);
    this.ObjectDossierController = new ObjectDossierController(dataService);
    this.SelectedDossierIds = new Array<number>();
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    // await this.navigatorService.Wait();
    this.Model.Filter = new DossierFilter();
    this.Model.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
    this.Model = await this.Controller.Get(this.Model);
    this.navigatorService.StopLoading();
  }

  SelectDossier(dossierDto: DossierDto) {
    if (dossierDto && dossierDto.Id > 0) {
      const exist = this.SelectedDossierIds?.includes(dossierDto.Id);
      if (!exist) {
        this.SelectedDossierIds?.push(dossierDto.Id);
      }
    }
  }

  GetSelectedDossiers() {
    return this.Model.Dtos.filter(dto => this.SelectedDossierIds.includes(dto.Id));
  }

  RemoveDossier(dto: DossierDto) {
    if (dto && dto.Id > 0) {
      const index = this.SelectedDossierIds?.findIndex(x => x === dto.Id);
      this.SelectedDossierIds?.splice(index, 1);
    }
  }

  async Save() {
    this.navigatorService.StartLoading();
    let performed = true;
    if (this.data && this.SelectedDossierIds?.length > 0) {
      for (const dossierId of this.SelectedDossierIds) {
        this.ObjectDossierModel.Filter = new ObjectDossierFilter();
        this.ObjectDossierModel.Filter.DossierId = dossierId;
        this.ObjectDossierModel.Filter.AccountId = this.authenticationService.ITDocAccount.Dto.Id;
        this.ObjectDossierModel = await this.ObjectDossierController.Get(this.ObjectDossierModel);
        for (const dto of this.data) {
          let exist = null;
          if (this.ObjectDossierModel?.Performed) {
            exist = this.ObjectDossierModel.Dtos.find(o => o.ObjectId === dto.Id);
          }
          if (!exist) {
            const objectDossierDto = new ObjectDossierDto();
            objectDossierDto.DossierName = this.Model.Dtos.find(x => x.Id === dossierId)?.Name;
            objectDossierDto.DossierId = dossierId;
            objectDossierDto.ObjectId = dto.Id;
            let objectDossierModel = new ObjectDossierModel();
            objectDossierModel.Dto = objectDossierDto;
            objectDossierModel = await this.ObjectDossierController.CreateOrUpdate(objectDossierModel);
            performed = objectDossierModel.Performed;
            if (performed) {
              const dossier = this.Model.Dtos.find(d => d.Id === dossierId);
              if (dossier.State === DossierState.CLOSE) {
                dossier.State = DossierState.OPEN;
                let model = new DossierModel();
                model.Dto = dossier;
                model = await this.Controller.CreateOrUpdate(model);
              }
            }
          }
        }
      }
    }
    if (performed) {
      if (this.data.length === 1) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.CollateSuccess, this.data[0].Name);
      } else if (this.data.length > 1) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.CollateSuccess);
      }
      this.dialogRef.close(true);
    } else {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
    this.navigatorService.StopLoading();
  }
}
