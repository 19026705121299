import { Component, OnInit, ViewChild } from '@angular/core';
import { ConservationLoginComponent } from '../../../dialog-menu/conservation-login/conservation-login.component';
import { DocumentClassComponent } from '../../../dialog-menu/document-class/document-class.component';
import { DocumentListComponent } from '../../../dialog-menu/document-list/document-list.component';
import { UploadquequeComponent } from '../../../dialog-menu/uploadqueque/uploadqueque.component';
import { PagerComponent } from '../../../../custom-components/pager/pager.component';
import { DocClassDto, DocClassModel, File } from '../../../../models/DocClassModel';
import { DocFlyController } from '../../../../controllers/DocFlyController';
import { MetadataTipologyValueValueController } from '../../../../controllers/MetaDataTipologyValueController';
import { Pages, PDAStatus } from '../../../../itdoc.configuration';
import { DataService } from '../../../../../data.service';
import { NavigatorService } from '../../../../../navigator.services';
import { AuthService } from '../../../../../auth.service';
import { CommonService } from '../../../../../common.service';
import { ObjectDto } from '../../../../models/ObjectModel';
import { ArchivesInfo, SubClasses, SystemInfoDto, SystemInfoModel } from '../../../../models/SystemInfoModel';
import { MetadataTipologyValueDto, MetadataTipologyValueFilter, MetadataTipologyValueModel } from '../../../../models/MetadataTipologyValueModel';

@Component({
  selector: 'app-pda',
  templateUrl: './pda-new.component.html',
  styleUrls: ['./pda-new.component.scss']
})
export class PdaNewComponent implements OnInit {
  @ViewChild('pager') Pager: PagerComponent;
  Model: DocClassModel;
  DocClassBaseModel: DocClassModel;
  Controller: DocFlyController;
  MetadataTipologyValueController: MetadataTipologyValueValueController;
  PanelOpenState = true;
  DocClass: string;
  Culture: string;
  NodeRef: string;
  Pda: string;
  PdaStatus = PDAStatus;
  DataSource = [];
  IsFilesLimit: boolean;
  Pdv: string;
  constructor(
    dataService: DataService, public navigatorService: NavigatorService, public authenticationService: AuthService,
    public commonService: CommonService
  ) {
    this.Culture = sessionStorage.getItem('user_language');
    this.NodeRef = this.navigatorService.GetQueryParameter('node');
    this.Pda = this.navigatorService.GetQueryParameter('pda');
    this.Pdv = this.navigatorService.GetQueryParameter('pdv');
    this.IsFilesLimit = false;
    this.Model = new DocClassModel();
    this.Model.Dto = new DocClassDto();
    this.DocClassBaseModel = new DocClassModel();
    this.DocClassBaseModel.Dto = new DocClassDto();
    this.Controller = new DocFlyController(dataService);
    this.MetadataTipologyValueController = new MetadataTipologyValueValueController(dataService);
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load() {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    if (this.Pda) {
      this.navigatorService.CurrentPage.Title = this.navigatorService.Dictionary?.EditPda;
    }
    const archive = await this.GetArchiveInfo();
    if (archive) {
      if (this.Pda) {
        const pda = archive.ViewRepository.Children.find(q => q.NodeRef === this.Pda);
        if (pda) {
          this.Model.Dto.DocClass = null;
          this.Model.Dto.PdaName = pda.Title;
          this.Model.Dto.PdvName = this.Pdv;
          this.Model = await this.Controller.GetDocumentModel(this.Model);
          if (this.Model.Performed) {
            for (const file of this.Model.Dto.Files) {
              const objectDto = new ObjectDto();
              objectDto.Id = file.File.ObjectId;
              this.Model.Dtos.push(objectDto);
            }
            this.DocClass = this.Model.Dto.DocClass;
            this.DocClassBaseModel.Dto.DocClass = this.Model.Dto.DocClass;

            this.DocClass = this.Model.Dto.DocClass;
            this.DocClassBaseModel = await this.Controller.GetDocumentModel(this.DocClassBaseModel);
            if (this.DocClassBaseModel.Performed) {
              this.Model.Dto.Username = this.DocClassBaseModel.Dto.Username;
              this.Model.Dto.Password = this.DocClassBaseModel.Dto.Password;
              this.Model.Dto.Owner = archive.Name;
              this.BuildFile(this.Model.Dtos, []);
              this.Paging();
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.MessageGenericError);
              this.navigatorService.GoBack();
            }
          } else {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.MessageGenericError);
            this.navigatorService.GoBack();
          }
        }
      } else {
        const data = { SubClasses: this.GetSubClasses(archive) };
        this.navigatorService.ShowDialog(DocumentClassComponent, data, '300px', '300px', '300px', async (docClassDto) => {
          if (docClassDto) {
            this.navigatorService.StartLoading();
            this.DocClassBaseModel.Dto.DocClass = docClassDto.Name;
            this.DocClass = docClassDto.Name;
            this.DocClassBaseModel = await this.Controller.GetDocumentModel(this.DocClassBaseModel);
            if (this.DocClassBaseModel.Performed) {
              this.Model.Dto.Username = this.DocClassBaseModel.Dto.Username;
              this.Model.Dto.Password = this.DocClassBaseModel.Dto.Password;
              this.Model.Dto.Owner = archive.Name;
              this.Model.Dto.PdaName = this.DocClassBaseModel.Dto.PdaName;
              this.Model.Dto.PdvId = this.DocClassBaseModel.Dto.PdvId;
              this.Model.Dto.DocClass = this.DocClassBaseModel.Dto.DocClass;
            } else {
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.MessageGenericError);
              this.navigatorService.GoBack();
            }
          } else {
            this.navigatorService.GoBack();
          }
          this.navigatorService.StopLoading();
        });
      }
    }
    this.navigatorService.StopLoading();
  }

  GetSubClasses(archive: ArchivesInfo) {
    let subclasses = new Array<SubClasses>();
    const docClassPermissions = archive.DocClassPermissions.filter(q => q.Write === true);
    if (docClassPermissions?.length > 0) {
      const types = docClassPermissions.map(d => d.ClassType);
      subclasses = this.navigatorService.SystemInfoModel.Dto.SubClasses.filter(q => types.includes(q.Name));
      // tslint:disable-next-line: no-string-literal
      subclasses.map(q => q['Id'] = q.Name);
    }
    return subclasses;
  }

  async GetArchiveInfo() {
    if (!this.navigatorService.SystemInfoModel) {
      if (this.authenticationService.IsAuthenticatedDocFly()) {
        this.navigatorService.SystemInfoModel = new SystemInfoModel();
        this.navigatorService.SystemInfoModel.Dto = new SystemInfoDto();
        this.navigatorService.SystemInfoModel.Dto = this.authenticationService.DocflyUser;
        this.navigatorService.SystemInfoModel.Dto.Page = 1;
        this.navigatorService.SystemInfoModel = await this.Controller.GetArchivesInfo(this.navigatorService.SystemInfoModel);
        if (this.navigatorService.SystemInfoModel?.Code === 500) {
          if (this.navigatorService.SystemInfoModel.Message.includes('Token valido inesistente')) {
            this.navigatorService.SystemInfoModel = null;
            this.authenticationService.DocflyUser = null;
            this.navigatorService.ShowDialog(ConservationLoginComponent, null, '300px', '330px', '330px', async (model) => {
              let result=null;
              if (model?.Performed && model?.Code === 200) {
                this.authenticationService.DocflyUser = {
                  Username: model.Username,
                  Ticket: model.Dto.Ticket
                };
                result= await this.GetArchiveInfo();
              }
              return result;
            });
          } else {
            this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.MessageGenericError);
          }
        }
      } else {
        this.navigatorService.ShowDialog(ConservationLoginComponent, null, '300px', '330px', '330px', async (model) => {
          let result=null;
          if (model?.Performed && model?.Code === 200) {
            this.authenticationService.DocflyUser = {
              Username: model.Username,
              Ticket: model.Dto.Ticket
            };
            result= await this.GetArchiveInfo();
          }
          return result;
        });
      }
    }
    return this.navigatorService.SystemInfoModel?.Dto?.ArchivesInfo?.find(q => q.NodeRef = this.NodeRef);
  }

  AddDocuments() {
    const data = {
      FileEnabled: this.GetFilesEnabled(),
      Documents: this.Model.Dto.Files ? this.Model.Dtos : new Array<File>()
    };
    this.navigatorService.ShowDialog(DocumentListComponent, data, '45%', 'fit-content', '200px', async (objectDtos) => {
      if (objectDtos) {
        this.navigatorService.StartLoading();
        // for (let i = 0; i < 5001; i++) {
        //   const obj = JSON.parse(JSON.stringify(objectDtos[i]));
        //   obj.Id += 1;
        //   objectDtos.push(obj);
        // }
        this.Model.Dtos = objectDtos;
        this.ClearFiles(objectDtos);
        const metadataTipologyValueDtos = await this.GetMetadataTipologyFiles(objectDtos);
        this.BuildFile(objectDtos, metadataTipologyValueDtos);
        this.Paging();
        if (this.Model.Dtos.length > 5000) {
          this.IsFilesLimit = true;
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.LimitFiles);
        }
        this.navigatorService.StopLoading();

      }
    });
  }

  async GetMetadataTipologyFiles(objectDtos: any) {
    if (objectDtos && objectDtos?.length > 0) {
      let modelMetadataTipologyValue = new MetadataTipologyValueModel();
      modelMetadataTipologyValue.Filter = new MetadataTipologyValueFilter();
      modelMetadataTipologyValue.Filter.ObjectId = objectDtos.map(q => q.Id);
      modelMetadataTipologyValue = await this.MetadataTipologyValueController.Get(modelMetadataTipologyValue);
      if (modelMetadataTipologyValue.Performed) {
        return modelMetadataTipologyValue.Dtos;
      }
    }
    return [];
  }
  GetFilesEnabled(): Array<string> {
    if (this.Model.Dto.DocClass) {
      const docClassNode = this.navigatorService.SystemInfoModel.Dto.SubClasses.find(q => q.Name = this.DocClass);
      if (docClassNode) {
        return docClassNode.FilesEnabled;
      }
    }
    return [];
  }

  ClearFiles(objectDtos: any) {
    const documents = this.Model.Dto.Files.filter(f => !objectDtos?.includes(objectDtos.find(q => q.Id === f.File.ObjectId)));
    for (const file of documents) {
      this.DeleteFile(file);
    }
  }

  DeleteFile(file) {
    let index = this.Model.Dto.Files?.findIndex(q => q.File.ObjectId === file.File.ObjectId);
    if (index >= 0) {
      this.Model.Dto.Files.splice(index, 1);
      index = this.Model.Dtos.findIndex(q => q.Id === file.File.ObjectId);
      if (index >= 0) {
        this.Model.Dtos.splice(index, 1);
      }
    }
    if (this.Model.Dtos.length > 5000) {
      this.IsFilesLimit = true;
    } else {
      this.IsFilesLimit = false;
    }
    this.Paging(this.Pager.CurrentPage);
  }

  BuildFile(objectDtos: Array<ObjectDto>, metadataTipologyValueDtos: Array<MetadataTipologyValueDto>) {
    for (const dto of objectDtos) {
      const find = this.Model.Dto.Files?.find(q => q.File.ObjectId === dto.Id);
      if (!find) {
        const file = new File();
        file.File = JSON.parse(JSON.stringify(this.DocClassBaseModel.Dto.Files[0].File));
        file.File.ObjectId = dto.Id;
        file.File.FilePath = dto.Path;
        file.File.Filename = dto.PhysicalName;
        file.File.MimeType = dto.MimeType;
        file.File.Destination = this.Model.Dto.PdaName;
        file.File.ClosingDate = dto.ModificationDate;
        file.File.Hash = dto.Hash;
        const metadata = metadataTipologyValueDtos.filter(q => q.ObjectId = dto.Id);
        this.BindModel(dto, file, metadata);
        this.Model.Dto.Files.push(file);
      }
    }
  }

  BindModel(dto: ObjectDto, file: File, metadataFile: Array<MetadataTipologyValueDto>) {
    const singleMetadata = file?.File?.Metadata?.Mandatory?.SingleMetadata;
    this.BindModelSingleMetadata(dto, singleMetadata, metadataFile);
    // this.BindModelComplexMetadata(dto, file?.File?.Metadata?.Mandatory.ComplexMetadata, metadataFile);
    // this.BindModelExtraInfos(dto, file?.File?.Metadata?.ExtraInfos, metadataFile);
  }

  BindModelSingleMetadata(dto: ObjectDto, singleMetadata, metadataFile: Array<MetadataTipologyValueDto>) {
    try {
      if (singleMetadata) {
        let node = singleMetadata.find(q => q.name === 'numero_allegati');
        if (node) {
          node.value = dto.CountAttachment;
        }
        node = singleMetadata.find(q => q.name === 'firmato_digitalmente');
        if (node) {
          node.value = dto.Sign ? 'Si' : 'No';
        }
        node = singleMetadata.find(q => q.name === 'nome_del_documento');
        if (node) {
          node.value = dto.Name;
        }
        node = singleMetadata.find(q => q.name === 'data_di_registrazione');
        if (node) {
          node.value = dto.CreationDate;
        }
        node = singleMetadata.find(q => q.name === 'versione_del_documento');
        if (node) {
          node.value = dto.Version;
        }
        node = singleMetadata.find(q => q.name === 'oggettodocumento');
        if (node) {
          const titolo = metadataFile.find(q => q.Key === 'Titolo');
          node.value = titolo ? titolo.Value : null;
        }
        node = singleMetadata.find(q => q.name === 'numeroProtocollo');
        if (node) {
          node.value = dto.ProgressiveString;
        }
        node = singleMetadata.find(q => q.name === 'dataRegistrazioneProtocollo');
        if (node) {
          node.value = dto.ModificationDate;
        }
      }
    } catch (ex) {
      const c = ex;
    }

  }

  GetComplexMetadataArray(complexMetadata) {
    const array = new Array<any>();
    Object.keys(complexMetadata).forEach((property) => {
      if (typeof complexMetadata[property] === 'object') {
        array.push(complexMetadata[property]);
      }
    });
    return array;
  }

  async SendPdv() {
    this.navigatorService.StartLoading();
    const isAutoLogin = await this.Controller.CheckAutoLogin();
    if (!isAutoLogin) {
      this.navigatorService.ShowDialog(ConservationLoginComponent, { Fake: true }, '300px', '330px', '330px', async (user) => {
        if (user && user?.Username && user?.Password) {
          this.StartSend(user);
        }
      });
    } else {
      this.StartSend();
    }

  }

  async StartSend(user = null) {
    this.Model.Message = null;
    this.Model.Code = null;
    this.Model.Dto.Username = user ? user.Username : null;
    this.Model.Dto.Password = user ? user.Password : null;
    this.Model = await this.Controller.CreatePdv(this.Model);
    if (!this.Model.Performed) {
      this.Model.PdvModels = [];
      this.navigatorService.StopLoading();
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.MessageGenericError);
    } else {
      this.navigatorService.StopLoading();
      if (this.Model.PdvModels?.length > 0) {
        this.navigatorService.ShowDialog(UploadquequeComponent, { Model: this.Model }, '45%', 'fit-content', '200px'
          , async (performed) => {
            if (performed === true) {
              this.navigatorService.StopLoading();
              this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.CreatedPda, this.Model.Dto.PdaName);
              this.commonService.SidebarMenuClick(Pages.ViewPda, this.navigatorService,
                { queryParams: { node: this.NodeRef } });
            } else if (performed === false) {
              //  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.MessageGenericError);
            }
          });
      }

    }
  }

  Cancel() {
    this.navigatorService.GoBack();
  }

  Paging(page: number = 1) {
    this.Model.Count = this.Model.Dto.Files.length;
    this.Pager.Model = this.Model;
    this.Pager.Paging(page, false);
    const items = this.Model.Dto.Files.slice(this.Model.Skip, this.Model.Skip + this.Model.Take);
    this.DataSource = items;
  }

  async PagingChange(page) {
    this.Paging(page);
  }

  // #region debug
  // BindModelSingleMetadata(dto: ObjectDto, singleMetadata, metadataFile: Array<MetadataTipologyValueDto>) {
  //   try {
  //     for (const file of singleMetadata) {
  //       if (file.type) {
  //         if (file.type === 'DateTime') {
  //           file.value = dto.ModificationDate;
  //         } else if (file.type === 'Integer') {
  //           file.value = 5456;
  //         }
  //       } else {
  //         file.value = 'Lorem Ipsum is simply dummy text of the printing';
  //       }
  //     }
  //   } catch (ex) {
  //     const c = ex;
  //   }

  // }

  // BindModelComplexMetadata(dto: ObjectDto, complexMetadata, metadataFile: Array<MetadataTipologyValueDto>) {
  //   try {
  //     for (const node of complexMetadata) {
  //       Object.keys(node).forEach((key) => {
  //         const file = node[key];
  //         if (!isString(file) && !isBoolean(file)) {
  //           if (file.type) {
  //             if (file.type === 'DateTime') {
  //               file.value = dto.ModificationDate;
  //             } else if (file.type === 'Integer') {
  //               file.value = 5456;
  //             } else if (file.type === 'CF') {
  //               file.value = 'PLTPRN80A01H501A';
  //             } else if (file.type === 'PIVACF') {
  //               file.value = 'PLTPRN80A01H501A';
  //             } else if (file.type === 'PIVA') {
  //               file.value = '76670320746';
  //             }

  //           } else {
  //             file.value = 'Lorem Ipsum is simply dummy text of the printing';
  //           }
  //         }
  //       });
  //     }

  //   } catch (ex) {
  //     const c = ex;
  //   }

  // }
  // BindModelExtraInfos(dto: ObjectDto, extraInfos, metadataFile: Array<MetadataTipologyValueDto>) {
  //   try {
  //     for (const file of extraInfos) {
  //       if (!isString(file) && !isBoolean(file)) {
  //         if (file.type) {
  //           if (file.type === 'DateTime') {
  //             file.value = dto.ModificationDate;
  //           } else if (file.type === 'Integer') {
  //             file.value = 5456;
  //           } else if (file.type === 'CF') {
  //             file.value = 'PLTPRN80A01H501A';
  //           } else if (file.type === 'PIVACF') {
  //             file.value = 'PLTPRN80A01H501A';
  //           } else if (file.type === 'PIVA') {
  //             file.value = '76670320746';
  //           }

  //         } else {
  //           file.value = 'Lorem Ipsum is simply dummy text of the printing';
  //         }

  //       }
  //     }

  //   } catch (ex) {
  //     const c = ex;
  //   }

  // }
  //#endregion debug


}
