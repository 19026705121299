<div *ngIf="!ShowTipologyDetail" class="itdoc-page itdoc-app-container-box">
    <div class="row m-0">
        <div class="col-md-12 p-0">
            <div class="title-header-container">
                <div class="title-container">
                    <h2>{{navigatorService?.CurrentPage?.Title}}</h2>
                </div>
                <div class="search-create-container">
                    <app-search #search
                        [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
                        [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
                        [HaveActions]="false" (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)"
                        (OnFiltersBtnPress)="OpenFiltersDialog($event)">
                    </app-search>
                    <button *ngIf="authService?.CurrentUser?.IsAdmin" (click)='Create()' mat-raised-button class="it-doc-btn-create create-button">
                        <span class="text">{{navigatorService.Dictionary?.Create.toUpperCase()}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!ShowTipologyDetail" class="form mt-3">
        <!-- START TABLE -->
        <div class="it-doc-table-container">
            <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">
                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnName}}">
                    <th mat-header-cell *matHeaderCellDef>{{navigatorService.Dictionary?.TableColumnName}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.Name}}</span>
                        <span *ngIf="element.Archived">
                            ({{navigatorService.Dictionary?.Archived}})</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnOrganizationUnit}}">
                    <th mat-header-cell *matHeaderCellDef>{{navigatorService.Dictionary?.TableColumnOrganizationUnit}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let uo of element?.TipologyUos;let i = index">
                            {{uo.UoName}}
                            <span *ngIf="i<element?.TipologyUos?.length-1">-</span>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnFileType}}">
                    <th mat-header-cell *matHeaderCellDef>{{navigatorService.Dictionary?.TableColumnFileType}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{GetDisplayTypes(element)}}</span>
                    </td>
                </ng-container>

                <!-- <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnSignatories}}">
                    <th mat-header-cell *matHeaderCellDef>{{navigatorService.Dictionary?.TableColumnSignatories}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span>

                        </span>
                    </td>
                </ng-container> -->

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUpdate}}">
                    <th mat-header-cell *matHeaderCellDef>{{navigatorService.Dictionary?.TableColumnUpdate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span>
                           {{element.UpdateDate | date:'dd-MM-yy'}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="more-action">
                    <th mat-header-cell *matHeaderCellDef class="icon-column">
                        <img src="../../../../../assets/icon/more-vert.svg">
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <img *ngIf="authService?.CurrentUser?.IsAdmin" #m="matMenuTrigger" [matMenuTriggerFor]="moreAction" class="clickable-icon"
                            src="../../../../../assets/icon/more-vert.svg"
                            [ngClass]="{'open-menu-trigger-color': m.menuOpen}">
                        <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
                            <button mat-menu-item
                                (click)='Update(element)'>{{navigatorService.Dictionary?.Update}}</button>
                            <button mat-menu-item (click)="DeleteTipology(element)">{{navigatorService.Dictionary?.Delete}}</button>
                        </mat-menu>
                    </td>
                </ng-container>

                <ng-container matColumnDef="filter">
                    <th mat-header-cell *matHeaderCellDef class="icon-column">
                        <img class="clickable-icon" src="../../../../../assets/icon/filter-icon.svg">
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <img  class="clickable-icon" src="../../../../../assets/icon/forward.svg" (click)="ToggleTipologyDetail(element)">
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="[
                        navigatorService.Dictionary?.TableColumnName,
                        navigatorService.Dictionary?.TableColumnOrganizationUnit,
                        navigatorService.Dictionary?.TableColumnFileType,
                        navigatorService.Dictionary?.TableColumnUpdate,
                        'more-action',
                        'filter'
                    ]">
                </tr>
                <tr mat-row *matRowDef="let row; columns: [
                        navigatorService.Dictionary?.TableColumnName,
                        navigatorService.Dictionary?.TableColumnOrganizationUnit,
                        navigatorService.Dictionary?.TableColumnFileType,
                        navigatorService.Dictionary?.TableColumnUpdate,
                        'more-action',
                        'filter'
                    ];">
                </tr>
            </table>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 ">
              <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
            </div>
        </div>
        <!-- END TABLE -->
        <div *ngIf="Model?.Dtos?.length <= 0 && !navigatorService.Loading" class="m-0 empty-table-box" style="padding-bottom: 200px;">
            <img src="../../../../../assets/icon/tipology-empty.svg">
            <div class="description">{{navigatorService.Dictionary?.EmptyTipologyViewMessage}}</div>
        </div>
    </div>
</div>

<div *ngIf="ShowTipologyDetail && TipologyDtoDetail">
    <app-tipology-detail #tipologydetail
        [TipologyDto]="TipologyDtoDetail"
        [MimeTypeModel]="MimeTypeModel"
        (GoBackEmit)="ToggleTipologyDetail()">
    </app-tipology-detail>
</div>
