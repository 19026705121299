<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <h2>{{WorkflowDataModel?.Dto?.WorkflowName}}</h2>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <div>
        <p class="title-description">{{navigatorService.Dictionary?.WorkflowReport}}</p>
        <div id="workflow-report-preview">
            <div *ngFor="let step of data?.WorkflowData?.WorkflowDataSteps;let i = index" class="step" 
            [ngStyle]="{'width':i<data?.WorkflowData?.WorkflowDataSteps?.length-1?GetWidth():0}">
                <div *ngIf="i<data?.WorkflowData?.WorkflowDataSteps?.length-1"
                    class="circle{{'-'+step.State?.toLowerCase()}}">
                    <mat-icon class="circle-flag{{'-'+step.State?.toLowerCase()}}">flag
                    </mat-icon>
                    <mat-icon *ngIf="step.State === 'COMPLETE'" class="circle-done">done
                    </mat-icon>
                </div>
                <div *ngIf="i===data?.WorkflowData?.WorkflowDataSteps?.length-1"
                    class="circle{{'-'+step.State?.toLowerCase()}}">
                    <mat-icon class="circle-flag{{'-'+step.State?.toLowerCase()}}">flag
                    </mat-icon>
                    <mat-icon *ngIf="data?.WorkflowData.State === 'REJECTED'" class="circle-donerejected">close
                    </mat-icon>
                    <mat-icon *ngIf="data?.WorkflowData.State  === 'ARCHIVED' && data?.WorkflowData.State  === 'PROTOCOLLED'" 
                    class="circle-done">done
                    </mat-icon>
                </div>

                <div *ngIf="i<data?.WorkflowData?.WorkflowDataSteps?.length-1 &&
                step.State === 'PROGRESS' && (i+1<=data?.WorkflowData?.WorkflowDataSteps?.length-1 &&
                data?.WorkflowData?.WorkflowDataSteps[i+1]?.State==='EDIT')" class="line-edit">
                    &nbsp;</div>

                <div *ngIf="i<data?.WorkflowData?.WorkflowDataSteps?.length-1 &&
                step.State === 'COMPLETE' && (i+1<=data?.WorkflowData?.WorkflowDataSteps?.length-1 &&
                data?.WorkflowData?.WorkflowDataSteps[i+1]?.State==='PROGRESS')" class="line-complete-progress">
                    &nbsp;</div>

                <div *ngIf="i<data?.WorkflowData?.WorkflowDataSteps?.length-1 &&
                (!step.State  || step.State === 'PROGRESS' || step.State === 'EDIT')  &&  (i+1<=data?.WorkflowData?.WorkflowDataSteps?.length-1 &&
                !data?.WorkflowData?.WorkflowDataSteps[i+1]?.State)" class="line-null">
                    &nbsp;</div>

                <div *ngIf="i<data?.WorkflowData?.WorkflowDataSteps?.length-2 &&
                step.State === 'COMPLETE' && (i+1<=data?.WorkflowData?.WorkflowDataSteps?.length-1 &&
                data?.WorkflowData?.WorkflowDataSteps[i+1]?.State==='COMPLETE')" class="line-complete">
                    &nbsp;</div>

                <div *ngIf="i===data?.WorkflowData?.WorkflowDataSteps?.length-2 &&
                step.State === 'COMPLETE' && data?.WorkflowData.State === 'REJECTED'" class="line-complete-rejected">
                    &nbsp;</div>
            </div>
            <br>
            <div *ngFor="let step of data?.WorkflowData?.WorkflowDataSteps;let i = index" class="step-text"
            [ngStyle]="{'width':i<data?.WorkflowData?.WorkflowDataSteps?.length-1?GetWidth():'40px'}">
                <div class="step-name" matTooltip="{{step.Name}}">{{step.Name}}</div>
                <div *ngIf="i<data?.WorkflowData?.WorkflowDataSteps?.length-1" class="text-line">
                    &nbsp;</div>
            </div>


        </div>

        <mat-dialog-content style="min-height: 26vh;overflow: hidden ">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="it-doc-table-container tiny-scrollbar" style="height: 26vh; overflow: auto">
                        <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">

                            <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnStep}}">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{navigatorService.Dictionary?.TableColumnStep}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="element?.ParamsDecode?.StepName">
                                        {{element?.ParamsDecode?.StepName?.substring(0,14) + 
                                            (element?.ParamsDecode?.StepName?.length>14?'...':'')}}
                                    </span>
                                    <span *ngIf="!element?.ParamsDecode?.StepName">
                                       -
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnState}}">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{navigatorService.Dictionary?.TableColumnState}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <span>
                                        {{commonService.GetDisplayState(navigatorService.Dictionary,element.Type)}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="{{navigatorService.Dictionary?.Approver}}">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{navigatorService.Dictionary?.Approver}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <span>
                                        {{element.DisplayName}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="{{navigatorService.Dictionary?.Date}}">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{navigatorService.Dictionary?.Date}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <span>
                                        {{element.DateString}}
                                    </span>
                                </td>
                            </ng-container>

                            <tr mat-header-row
                                *matHeaderRowDef="[navigatorService.Dictionary?.TableColumnStep,navigatorService.Dictionary?.TableColumnState,navigatorService.Dictionary?.Approver,navigatorService.Dictionary?.Date]">
                            </tr>
                            <tr mat-row [ngClass]="{'selected':row.Selected}"
                                *matRowDef="let row; columns: [navigatorService.Dictionary?.TableColumnStep,navigatorService.Dictionary?.TableColumnState,navigatorService.Dictionary?.Approver,navigatorService.Dictionary?.Date];">
                            </tr>

                        </table>
                    </div>
                </div>
            </div>           
        </mat-dialog-content>
    </div>
</div>