import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class FileModel extends BaseModel<FileEntity, FileDto, FileFilter> {
    constructor() {
        super();
    }
}

export class FileEntity extends BaseEntity {
    PhysicalName: string;
    Path: string;
    File: any;
    Index: number;
    Merge: boolean;
    Size: number;
    constructor() {
        super();
        this.PhysicalName = null;
        this.Path = null;
        this.File = null;
        this.Index = null;
        this.Merge = false;
        this.Size = 0;
    }
}

export class FileDto extends FileEntity {
    ShareToken: string;
    UoId: number;
    MimeType: string;
    Name: string;
    Selected: boolean;
    OriginalExtension: string;
    Icon: string;
    Message: string;
    Performed: boolean;
    Progress: number;
    Dimension: any;
    Pages: Array<number>;
    CssClass: string;
    ShowSign: boolean;
    BlobUrl: any;
    constructor() {
        super();
    }
}

export class FileFilter extends FileDto {
}
