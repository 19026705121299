import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../auth.service';
import { ItCoreController } from '../controllers/ItCoreController';
import { SettingsController } from '../controllers/SettingsController';
import { DataService } from '../data.service';
import { CurrentUserDto, CurrentUserFilter, CurrentUserModel } from '../models/CurrentUserModel';
import { LinkTokenDto, LinkTokenModel } from '../models/LinkToken';
import { SignatureAccountDto, SignatureAccountFilter, SignatureAccountModel } from '../models/SignatureAccount';
import { UserAppsDto, UserAppsFilter, UserAppsModel } from '../models/UserApps';
import { SearchComponent } from '../itdoc/custom-components/search/search.component';
import { GenericDialogModel } from '../itdoc/models/GenericDialogModel';
import { GenericConfirmDialogComponent } from '../itdoc/pages/dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { Constants, GenericDialogAction, InvitationState, PageAction, Pages } from '../itdoc/itdoc.configuration';
import { SignatureAccountComponent } from './dialog/signature-account/signature-account.component';
import { UserComponent } from './dialog/user/user.component';
import { PagerComponent } from '../itdoc/custom-components/pager/pager.component';
import { BaseOrder } from '../models/BaseModel';
import { NavigatorService } from '../navigator.services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;

  AddBtnSrc: string;
  Tab = 1;
  CurrentPage = 1;
  Pages: Array<number>;
  InvitationState = InvitationState;
  DataSource: MatTableDataSource<CurrentUserDto>;
  DataSourceSignatureAccount: MatTableDataSource<SignatureAccountDto>;
  UserModel: CurrentUserModel;
  SignatureAccountModel: SignatureAccountModel;
  SettingsController: SettingsController;
  ItCoreController: ItCoreController;
  LdapEnabled: boolean;
  constructor(
    public navigatorService: NavigatorService,
    private dataService: DataService,
    public authService: AuthService
  ) {
    this.AddBtnSrc = '../../../../../assets/icon/add-circle.svg';
    this.DataSource = null;
    this.LdapEnabled = false;
    this.UserModel = new CurrentUserModel();
    this.SignatureAccountModel = new SignatureAccountModel();
    this.SettingsController = new SettingsController(dataService);
    this.ItCoreController = new ItCoreController(dataService);
  }

  ngOnInit(): void {
    const tab = this.authService.CurrentUser.IsAdmin ? 1 : 2;
    if (tab === 1) {
      this.Load(tab);
    } else {
      this.LoadDigitalSignage(tab);
    }
  }

  async Load(tab = 1) {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.Tab = tab;
    await this.GetUsers();
    this.LdapEnabled = await this.ItCoreController.LdapEnabled();
    this.navigatorService.StopLoading();
  }

  async LoadDigitalSignage(tab = 2) {
    this.navigatorService.StartLoading();
    // await this.navigatorService.Wait();
    this.DataSourceSignatureAccount = null;
    this.Tab = tab;
    this.SignatureAccountModel = await this.SettingsController.GetSignatureAccount(this.SignatureAccountModel);
    if (this.SignatureAccountModel.Performed) {
      this.DataSourceSignatureAccount = new MatTableDataSource<SignatureAccountDto>(this.SignatureAccountModel?.Dtos);
    }
    this.navigatorService.StopLoading();
  }

  //#region Users
  async GetUsers(page = 1) {
    this.UserModel.Filter = new CurrentUserFilter();
    this.UserModel.Filter.Trashed = false;
    this.UserModel.Filter.IsAdmin = null;
    this.UserModel.Filter.IsOwner = null;
    this.UserModel.Order = new BaseOrder();
    this.UserModel.Order.Name = 'Displayname';
    this.UserModel.Order.Direction = 'asc';
    this.UserModel.Search = this.SearchComponent?.GetSearchValue();
    this.UserModel = await this.SettingsController.LoadUser(this.UserModel);
    this.Paging(page);
  }

  // #region Paginazione
  RefreshTable(dtos: Array<CurrentUserDto>) {
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<CurrentUserDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.UserModel;
    this.Pager.Paging(page);
    this.RefreshTable(this.UserModel.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    if (this.Tab === 1) {
      await this.GetUsers(page);
    } else {
      await this.LoadDigitalSignage();
    }
    this.navigatorService.StopLoading();
  }
  // #endregion Paginazione

  GetUserRole(user: CurrentUserDto) {
    if (user) {
      const find = user.Groups.find(g => g.Name === 'admin');
      if (user.IsOwner && find) {
        return 'Owner, Admin';
      } else if (find) {
        return 'Admin';
      } else if (user.IsOwner) {
        return 'Owner';
      } else {
        return 'Worker';
      }
    }
    return null;
  }

  GetStatus(user: CurrentUserDto) {
    if (user.Apps && user.Apps.length > 0) {
      const app = user?.Apps?.find(app => app.Appname === Constants.AppId);
      if (app) {
        return app?.State;
      } else {
        return InvitationState.DEACTIVE;
      }
    } else {
      return InvitationState.DEACTIVE;
    }
  }

  GetStatusDictionary(user: CurrentUserDto) {
    const status = this.GetStatus(user);
    switch (status) {
      case InvitationState.ACTIVE:
        return this.navigatorService.Dictionary?.Activated;
      case InvitationState.DEACTIVE:
        return this.navigatorService.Dictionary?.Deactivated;
      case InvitationState.PENDING:
        return this.navigatorService.Dictionary?.Pending;
    }
    return null;
  }

  async AddUser() {
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(UserComponent, null, '45%', 'fit-content', '200px', async (result) => {
      if (result) {
        this.navigatorService.StartLoading();
        let model = new CurrentUserModel();
        model.Filter = new CurrentUserFilter();
        model.Filter.Id = result;
        model = await this.SettingsController.ReadUser(model);
        if (model.Performed && model.Dto) {
          await this.SendInvitation(model.Dto);
        } else {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        }
        this.navigatorService.StopLoading();
      }
    });
  }

  ImportFromLdap() {
    this.navigatorService.PageAction = PageAction.New;
    const data = { Action: 'ldap' };
    this.navigatorService.ShowDialog(UserComponent, data, '45%', 'fit-content', '200px', async (result) => {
      if (result) {
        this.navigatorService.StartLoading();
        let model = new CurrentUserModel();
        model.Filter = new CurrentUserFilter();
        model.Filter.Id = result;
        model = await this.SettingsController.ReadUser(model);
        if (model.Performed && model.Dto) {
          await this.SendInvitation(model.Dto);
        } else {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        }
        this.navigatorService.StopLoading();
      }
    });
  }

  UpdateUser(dto: CurrentUserDto) {
    const data = { Dto: dto, Action: 'add' };
    this.navigatorService.PageAction = PageAction.Edit;
    this.navigatorService.ShowDialog(UserComponent, data, '45%', 'fit-content', '200px', async (result) => {
      if (result) {
        await this.GetUsers();
      }
    });
  }

  async SendInvitation(dto: CurrentUserDto) {
    this.navigatorService.StartLoading();

    let linkTokenModel = new LinkTokenModel();
    linkTokenModel.Dto = new LinkTokenDto();
    linkTokenModel.Dto.Email = dto.Email;
    linkTokenModel.Dto.Username = dto.Username;
    linkTokenModel.Dto.Type = dto.Type === 'ldap' ? 'INVITATIONLDAP' : 'INVITATION';
    linkTokenModel.Dto.RelativeUrl = window.location.origin + '/' +
      Constants.AppId + '/' + (dto.Type === 'ldap' ? Pages.InvitationLdap : Pages.Invitation);
    const groupAdmin = dto.Groups.find(r => r.Name === 'admin');
    linkTokenModel.Dto.Role = groupAdmin ? 'Admin' : 'Worker';
    linkTokenModel = await this.SettingsController.SendInvitation(linkTokenModel);

    if (linkTokenModel?.Performed) {
      this.UserModel.Skip = 0;
      await this.GetUsers();
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.SendInvitationMessage, linkTokenModel.Dto.Username);
    } else {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
    this.navigatorService.StopLoading();
  }

  async DeactiveUser(dto: CurrentUserDto) {
    this.navigatorService.StartLoading();
    let userAppsModel = new UserAppsModel();
    userAppsModel.UpdateProperties = ['Enabled', 'State'];
    userAppsModel.Dto = new UserAppsDto();
    userAppsModel.Dto = dto?.Apps?.find(app => app.Appname === Constants.AppId && app.UserId === dto.Id);
    userAppsModel.Dto.Enabled = false;
    userAppsModel.Dto.State = InvitationState.DEACTIVE;
    userAppsModel = await this.ItCoreController.DisableUser(userAppsModel);
    if (userAppsModel.Performed) {
      await this.GetUsers();
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.DeactivatedUserMessage, dto.Username);
    } else {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
    this.navigatorService.StopLoading();
  }

  async DeleteUser(dto: CurrentUserDto) {
    this.navigatorService.StartLoading();
    let userAppsModel = new UserAppsModel();
    userAppsModel.Filter = new UserAppsFilter();
    userAppsModel.Filter.Id = dto?.Apps?.find(app => app.Appname === Constants.AppId && app.UserId === dto.Id)?.Id;
    userAppsModel = await this.ItCoreController.DeleteUserApps(userAppsModel);
    if (userAppsModel.Performed) {
      await this.GetUsers();
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.DeleteUserMessage, dto.Username);
    } else {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
    this.navigatorService.StopLoading();
  }
  //#endregion

  //#region signature
  AddSignatureAccount() {
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(SignatureAccountComponent, null, '45%', 'fit-content', '200px', async (result) => {
      if (result) {
        await this.LoadDigitalSignage(2);
      }
    });
  }

  UpdateSignatureAccount(dto: SignatureAccountDto) {
    this.navigatorService.PageAction = PageAction.Edit;
    this.navigatorService.ShowDialog(SignatureAccountComponent, dto, '45%', 'fit-content', '200px', async (result) => {
      if (result) {
        await this.LoadDigitalSignage();
      }
    });
  }

  async DeleteSignatureAccount(dto: SignatureAccountDto) {
    const data = new GenericDialogModel();
    data.Icon = '../../../../../assets/icon/signature.svg';
    data.Name = dto.Identifier;
    data.Title = this.navigatorService.Dictionary?.Delete;
    data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
      this.navigatorService.Dictionary?.ToDelete + '<br>' + data.Name + '?';
    data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
    data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
    this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
      if (performed === GenericDialogAction.CONFIRM) {
        this.navigatorService.StartLoading();
        let model = new SignatureAccountModel();
        model.Filter = new SignatureAccountFilter();
        model.Filter.Id = dto.Id;
        model = await this.SettingsController.DeleteSignatureAccount(model);
        if (model.Performed) {
          this.LoadDigitalSignage(2);
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.DeleteSuccess, dto.Identifier);
        } else {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        }
        this.navigatorService.StopLoading();
      }
    });
  }

  //#endregion
  async Search(search: string) {
    this.navigatorService.StartLoading();
    this.UserModel.Skip = 0;
    await this.GetUsers();
    this.navigatorService.StopLoading();
  }
}
