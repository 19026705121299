import { DataService } from '../../data.service';
import { WorkflowUserModel } from '../models/WorkflowUserModel';


export class WorkflowUserController {


    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: WorkflowUserModel): Promise<WorkflowUserModel> {
        const response = await this.dataService.CreateOrUpdateWorkflowUser(model);
        return response;
    }

    async Read(model: WorkflowUserModel): Promise<WorkflowUserModel> {
        const response = await this.dataService.ReadWorkflowUser(model);
        return response;
    }

    async Get(model: WorkflowUserModel): Promise<WorkflowUserModel> {
        const response = await this.dataService.GetWorkflowUser(model);
        return response;
    }

    async Load(model: WorkflowUserModel): Promise<WorkflowUserModel> {
        const response = await this.dataService.LoadWorkflowUser(model);
        return response;
    }

    async Delete(model: WorkflowUserModel): Promise<WorkflowUserModel> {
        const response = await this.dataService.DeleteWorkflowUser(model);
        return response;
    }

}
