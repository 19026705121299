<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <h2 *ngIf="navigatorService.PageAction === 'New'">{{navigatorService.Dictionary?.NewFolder}}</h2>
            <h2 *ngIf="navigatorService.PageAction === 'Edit'">{{navigatorService.Dictionary?.EditFolder}}</h2>
            <p class="title-description">
                {{navigatorService.Dictionary?.NewFolderDescription}}</p>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>
    <div *ngIf="Step===1">
        <mat-dialog-content>
            <div class="form">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.Name"
                            [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-select [Placeholder]="navigatorService.Dictionary?.TipologyPlaceholder" [Required]='true'
                            [DataValue]='Model?.Dto?.TipologyId' [Property]="'Name'" [Items]="TipologyModel.Dtos"
                            (OnChange)="SelectTipology($event)" [Disabled]="navigatorService.PageAction === 'Edit'">
                        </app-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <app-select [Placeholder]="navigatorService.Dictionary?.OrganizationalUnit" [Required]='true'
                            [DataValue]='Model?.Dto?.UoId' [Property]="'Name'" [Items]="OrganizationUnitModel.Dtos"
                            [Disabled]="navigatorService.PageAction === 'Edit'" (OnChange)="SelectUo($event)"></app-select>
                    </div>

                </div>
            </div>

        </mat-dialog-content>
    </div>

    <div *ngIf="Step===2">
        <mat-dialog-content style="min-height: 26vh;overflow: hidden ">
            <div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 ">
                        <app-search #search [Placeholder]="navigatorService.Dictionary?.FolderSearchPlaceholder"
                            [HaveFilters]="false" [HaveActions]="false" [ModalStyle]="true"
                            (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)">
                        </app-search>
                    </div>
                    <div class="col-md-6 col-sm-12">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="row breadcrumb-lite" *ngIf="this.CurrentFolder?.Deep>=1">
                            <div class="col-md-4">
                                <button mat-icon-button (click)="BreadcrumbBack()" [disabled]="!this.CurrentFolder">
                                    <mat-icon class="icon-text-right"> keyboard_arrow_left</mat-icon>
                                    <span>{{navigatorService.Dictionary?.Back}}
                                    </span>
                                </button>
                            </div>
                            <div class="col-md-4 text-center current-folder">
                                <span>{{CurrentFolder?.Name}}</span>
                            </div>
                            <div class="col-md-4"></div>
                        </div>

                        <div class="it-doc-table-container default-scrollbar" style="height: 26vh; overflow: auto">
                            <table mat-table [dataSource]="DataSource" matSort>
                                <ng-container matColumnDef="FileIcon">
                                    <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                                    <td mat-cell *matCellDef="let element">
                                        <img src=" {{element.Icon}}" (click)="ObjectsClick(element)"
                                            class="{{element.ClassIcon}}">
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnName}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{navigatorService.Dictionary?.TableColumnName}} </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span (click)="ObjectsClick(element)"  class="clickable-icon">
                                            {{element.Name + (element.Type==='file'?'.' +
                                            commonService.GetExtension(element.PhysicalName):'')}}
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnCreate}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{navigatorService.Dictionary?.TableColumnCreate}} </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>
                                            {{element.DisplayName}}
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUpdate}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{navigatorService.Dictionary?.TableColumnUpdate}} </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.Deep>=0">
                                            {{element.ModificationDateString}}
                                        </span>
                                        <span *ngIf="!element.Deep">
                                            {{element.UpdateDate | date:'dd/MM/yyyy'}}
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ArrowIcon">
                                    <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                                    <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                                        <mat-icon *ngIf="element.Type==='folder'"  class="clickable-icon">arrow_forward_ios</mat-icon>
                                    </td>
                                </ng-container>

                                <tr mat-header-row
                                    *matHeaderRowDef="['FileIcon', navigatorService.Dictionary?.TableColumnName,navigatorService.Dictionary?.TableColumnCreate,navigatorService.Dictionary?.TableColumnUpdate,'ArrowIcon']">
                                </tr>
                                <tr mat-row (click)="SelectRow(row)" [ngClass]="{'selected':row.Selected}"
                                    *matRowDef="let row; columns: ['FileIcon', navigatorService.Dictionary?.TableColumnName,navigatorService.Dictionary?.TableColumnCreate,navigatorService.Dictionary?.TableColumnUpdate,'ArrowIcon'];">
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 ">
                        <div *ngIf="Pages?.length>1 && ObjectModel.Count>0" class="paging-container">
                            <button mat-icon-button (click)="PagingBack()" class="back" [disabled]="CurrentPage<=1">
                                <mat-icon> keyboard_arrow_left</mat-icon>
                            </button>
                            <div class="page-number-container" *ngFor="let page of Pages">
                                <div [ngClass]="CurrentPage===page?'page-number-selected':'page-number'">
                                    <span class="page" (click)="Paging(page)">{{page}}
                                    </span>
                                </div>
                            </div>
                            <button mat-icon-button (click)="PagingNext()" class="next"
                                [disabled]="CurrentPage===Pages?.length">
                                <mat-icon> keyboard_arrow_right</mat-icon>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>


    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button *ngIf="Step===1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step>1 && navigatorService.PageAction === 'New'" class="it-doc-btn-cancel"
            style="margin-inline-end: auto;" (click)="Back()">
            <span class="button-text">{{navigatorService.Dictionary?.Back.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step<2 && navigatorService.PageAction === 'New'" (click)="Next()" mat-button
            class="it-doc-btn-primary" cdkFocusInitial>
            <span class="button-text">{{navigatorService.Dictionary?.Next.toUpperCase()}}</span>
        </button>
        <button *ngIf="Step===2 || navigatorService.PageAction === 'Edit'" (click)="Save()" mat-button
            class="it-doc-btn-primary" cdkFocusInitial>
            <span class="button-text"
                *ngIf="navigatorService.PageAction === 'New'">{{navigatorService.Dictionary?.Create.toUpperCase()}}</span>
            <span class="button-text"
                *ngIf="navigatorService.PageAction === 'Edit'">{{navigatorService.Dictionary?.Update.toUpperCase()}}</span>
        </button>
    </div>
</div>
