<div appNoRightClick>
    <div class="closedialog">
        <mat-icon><span class="material-icons" mat-dialog-close>close</span></mat-icon>
    </div>
    <div class="file-name">
        <span *ngIf="FileName">{{FileName}}</span>
    </div>

    <div class="media-container" #mediacontainer>

        <img id="mediaImg" *ngIf="data?.MimeType?.includes('image')" width="100%" height="auto">

        <video id="mediaVideo" *ngIf="data?.MimeType?.includes('video')" style="width: 100%;" controls></video>

        <div *ngIf="data?.MimeType?.includes('audio')">           
            <audio id="mediaAudio" controls></audio>
        </div>
    </div>

</div>