import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigatorService } from '../../../navigator.services';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent<TDto> implements OnInit {

  SelectedItems = new FormControl();
  @Input() Property: string;
  private currentValue: any;
  private dataValue: any = null;
  get DataValue() {
    if (this.MultiSelect) {
      return this.dataValue;
    } else {
      return this.dataValue;
    }
  }

  @Input() set DataValue(value: any) {
    if (value) {
      if (this.MultiSelect) {
        if (this.Property) {
          if (value[this.Property]) {
            const valueParse = JSON.parse(value[this.Property]);
            if (valueParse) {
              this.dataValue = valueParse.map(x => x.Id);
            }
          }
          if (Array.isArray(value)) {
            this.dataValue = value;
          }
        }
        // this.OnChange.emit(this.dataValue);
      } else {
        if (typeof value === 'number') {
          this.dataValue = (value ? value : null);
        } else if (typeof value === 'string') {
          this.dataValue = value;
        }
        else {
          if (value[this.Property]) {
            const valueParse = JSON.parse(value[this.Property]);
            if (valueParse) {
              this.dataValue = valueParse.Id;
            }
          }
        }

      }
    }
    if (this.currentValue !== this.dataValue) {
      this.currentValue = this.dataValue;
      this.OnChange.emit(this.dataValue);
    }
  }


  @Input() Items: Array<TDto> = null;

  @Input() Placeholder = '';
  @Input() Required = false;
  @Input() MultiSelect = false;
  @Input() Disabled = false;
  @Output() OnChange: EventEmitter<any> = new EventEmitter();
  constructor(public navigatorService: NavigatorService) {
    this.currentValue = null;
  }

  OnChangeItems($event, dto) {
    if ($event.isUserInput) {
      dto.Checked = !dto.Checked;
      this.OnChange.emit(dto);
    }
  }

  ngOnInit(): void {
  }

  // OnChange($event){
  //   this.DataValue = $event.value;
  // }
}
