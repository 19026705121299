import { MetadataTipologyModel } from '../models/MetadataTipologyModel';
import { DataService } from '../../data.service';

export class MetaDataTipologyController {


    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: MetadataTipologyModel): Promise<MetadataTipologyModel> {
        let response = model;
        if (model && (model.Dto || model.Dtos.length > 0)) {
            response = await this.dataService.CreateOrUpdateMetaDataTipology(model);
        }
        return response;
    }

    async Read(model: MetadataTipologyModel): Promise<MetadataTipologyModel> {
        const response = await this.dataService.ReadMetaDataTipology(model);
        return response;
    }

    async Get(model: MetadataTipologyModel): Promise<MetadataTipologyModel> {
        const response = await this.dataService.GetMetaDataTipology(model);
        return response;
    }

    async Load(model: MetadataTipologyModel): Promise<MetadataTipologyModel> {
        const response = await this.dataService.LoadMetaDataTipology(model);
        return response;
    }

    async Delete(model: MetadataTipologyModel): Promise<MetadataTipologyModel> {
        let response = model;
        if (model) {
            if (model.Dtos?.length > 0) {
                response = await this.dataService.DeleteMetaDataTipology(model);
            } else {
                response.Performed = true;
            }
        }
        return response;
    }

}
