<mat-form-field appearance="fill" *ngIf="!Minimize">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input  [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="{{Placeholder + (Required ?' *':'')}}">
        <input matEndDate formControlName="end"  placeholder="{{Placeholder + (Required ?' *':'')}}">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>

<div *ngIf="Minimize" class="data-container">
    <div class="row">
        <div class="col-md-2">
            <div #calendaricon>
                <button title="calendar" mat-icon-button (click)="Open(picker)" style="top:-3px;left:-8px;">
                    <img title="calendar" src="../../../../assets/icon/calendar.svg">
                </button>
            </div>
        </div>
        <div class="col-md-6">
            <span class="value" >{{End | date: FormatDate}}</span>
        </div>
    </div>

    <mat-form-field class="form-field-hidden">
        <mat-date-range-input   [rangePicker]="picker" style="display: none;">
            <input matStartDate  placeholder="{{Placeholder + (Required ?' *':'')}}" [value]="Start" (dateChange)="Change($event,'start')" >
            <input matEndDate   placeholder="{{Placeholder + (Required ?' *':'')}}" [value]="End"  (dateChange)="Change($event,'end')">
        </mat-date-range-input>        
        <mat-date-range-picker #picker ></mat-date-range-picker>
    </mat-form-field>
</div>
<!-- <ejs-daterangepicker *ngIf="!Minimize" cssClass="date-range-picker" locale='it' #dateformat [format]="FormatDate"
    autocomplete="off" [startDate]='Start' [endDate]='End' placeholder="{{Placeholder + (Required ?' *':'')}}"
    (change)="Change($event)" floatLabelType='Auto'>
</ejs-daterangepicker>

<div *ngIf="Minimize" class="data-container">
    <div class="row">       
        <div class="col-md-2">
            <div #calendaricon>
                <button mat-icon-button (click)="OpenCalendar()" style="top:-3px;left:-8px;">
                    <img src="../../../../assets/icon/calendar.svg">
                </button>
            </div>
        </div>
         <div class="col-md-6">
            <span class="value">{{GetDisplayValue()}}</span>
        </div>
    </div>

    <ejs-daterangepicker #calendar cssClass="date-range-picker" locale='it' #dateformat [format]="FormatDate"
        autocomplete="off" [startDate]='Start' [endDate]='End' placeholder="{{Placeholder + (Required ?' *':'')}}"
        (change)="Change($event)" floatLabelType='Auto' style="display: none;" (open)="SetPosition($event)">
    </ejs-daterangepicker>
</div> -->