import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigatorService } from '../../../../navigator.services';
import { GenericDialogModel } from '../../../models/GenericDialogModel';
import { GenericDialogAction } from '../../../itdoc.configuration';


@Component({
  selector: 'app-generic-confirm-dialog',
  templateUrl: './generic-confirm-dialog.component.html',
  styleUrls: ['./generic-confirm-dialog.component.scss']
})
export class GenericConfirmDialogComponent implements OnInit {

  constructor(
    public navigatorService: NavigatorService,
    public dialogRef: MatDialogRef<GenericConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericDialogModel) {

  }

  ngOnInit() {
  }

  Confirm() {
    this.dialogRef.close(GenericDialogAction.CONFIRM);
  }

}
