import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocFlyController } from '../../../controllers/DocFlyController';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';

@Component({
  selector: 'app-conservation-login',
  templateUrl: './conservation-login.component.html',
  styleUrls: ['./conservation-login.component.scss']
})

export class ConservationLoginComponent implements OnInit {
  Model: any;
  Controller: DocFlyController;
  Fake = false;
  constructor(
    public dataService: DataService,
    public navigatorService: NavigatorService,
    public dialogRef: MatDialogRef<ConservationLoginComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.Fake = data && data?.Fake ? data.Fake : false;
    this.Model = {
      Dto: {
        Username: null,
        Password: null,
        SaveCredential: false
      }
    };
    this.Controller = new DocFlyController(dataService);

  }

  ngOnInit() {
    this.Load();
  }

  async Load() {

  }

  async Login() {
    this.navigatorService.StartLoading();
    if (!this.Fake) {
      const model = await this.Controller.Login(this.Model.Dto.Username, this.Model.Dto.Password,this.Model.Dto.SaveCredential);
      if (model?.Performed && model?.Code === 200) {
        this.dialogRef.close(model);
      } else {
        this.Model.Dto.Password = model.Password;
        this.navigatorService.ShowSnackBar(model.Message);
      }
    } else {
      this.dialogRef.close(this.Model.Dto);
    }
    this.navigatorService.StopLoading();
  }
}
