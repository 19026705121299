<div class="search-container">
    <mat-form-field class="form-field search-field" [ngClass]="{'no-underline': !ModalStyle}">
        <input #searchInput matInput
            class="input-text"
            placeholder="{{Placeholder}}"
            (keydown.enter)="SearchEvent(searchInput.value)"
            (keyup)="InteractiveSearch(searchInput.value)"
            [(ngModel)]="searchValue">
        <mat-icon *ngIf="searchValue" class="clear-icon" matSuffix mat-icon-button aria-label="Clear" (click)="ClearInputValue()">close</mat-icon>
        <img class="search-icon" matSuffix src="../../../../assets/icon/search.svg" (click)="SearchEvent(searchInput.value)">
    </mat-form-field>
    <div *ngIf="HaveFilters" class="btn-container">
        <div *ngIf="FiltersLabelBtn">{{FiltersLabelBtn}}</div>
        <mat-icon class="material-icons btn-icon" matBadge="{{FilterCount}}" (click)="OpenFiltersDialog()">filter_list</mat-icon>
    </div>
    <div *ngIf="HaveActions" class="btn-container">
        <div *ngIf="ActionsLabelBtn">{{ActionsLabelBtn}}</div>
        <button mat-menu-item [disabled]="ItemsCount<=0" class="p-0">
            <mat-icon class="material-icons btn-icon"  [matMenuTriggerFor]="MoreAction">more_vert</mat-icon>
        </button>
        <mat-menu #MoreAction="matMenu">
            <button *ngFor="let item of TriggerMenuItems" mat-menu-item (click)="ItemActionsMenuClick(item)"  [disabled]="!item.Enabled" class="menu-item">
              {{item?.Label}}
            </button>
        </mat-menu>
    </div>
</div>


