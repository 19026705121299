import { BaseEntity, BaseModel } from './BaseModel';

export class UserAppsModel extends BaseModel<UserAppsEntity, UserAppsDto, UserAppsFilter> {

    constructor() {
        super();
    }
}

export class UserAppsEntity extends BaseEntity {
    UserId: string;
    Appname: string;
    State: string;
    Enabled: boolean;
    constructor() {
        super();
    }
}

export class UserAppsDto extends UserAppsEntity {
    constructor() {
        super();
    }
}

export class UserAppsFilter extends UserAppsDto {
}
