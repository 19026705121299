<p *ngIf="Placeholder" class="placeholder">{{Placeholder}}</p>
<div class="d-flex align-items-center">
    <div *ngIf="DataValue[Property]" style="margin-right: 10px;" (click)="OpenFile()" ngClass="{{DataValue['Id'] > 0?'over-template':''}}">{{DataValue[Property]}}</div>
    <div>
        <button *ngIf="!DataValue.Readonly" (click)="FileSelected()" class="button-upload-remove" [ngClass]="Selected?'float-right':''" >{{ButtonText}}
            <span class="material-icons icon">file_upload</span>
        </button>
        <span class="required" *ngIf="Required">*</span>
        <input type="file" class="d-none" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" #uploadFile>
    </div>
</div>

